import React, { useCallback } from 'react';
import { CircularProgress } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { Button } from '@ocx/ui';
import { PrimaryInternalFuelingStatus } from '../../../modules/transaction/types';
import { SizingMultiplier } from '../../../theme/constants';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';

export interface IFuelingStatusActionProps {
  status: PrimaryInternalFuelingStatus;
  navigateToReceipt(): void;
  navigateToHome(): void;
}

export const FuelingStatusAction: React.FC<IFuelingStatusActionProps> = ({
  status,
  navigateToHome,
  navigateToReceipt,
}) => {
  const handleRetryClick = useCallback(() => window.location.reload(), []);

  if (status === PrimaryInternalFuelingStatus.CompletedSuccessfully) {
    return (
      <Button variant="outlined" onClick={navigateToReceipt}>
        <FormattedMessage
          id="fueling:viewReceiptButtonText"
          defaultMessage="View receipt"
          description="Fueling page: View receipt button text"
        />
      </Button>
    );
  }

  if (
    [PrimaryInternalFuelingStatus.CompletedUnsuccessfully, PrimaryInternalFuelingStatus.CompletedCancelled].includes(
      status,
    )
  ) {
    return (
      <Button variant="outlined" onClick={navigateToHome}>
        <FormattedMessage {...buttonTextMessages['button-text:close']} />
      </Button>
    );
  }

  if (status === PrimaryInternalFuelingStatus.Authorizing) {
    return <CircularProgress sx={{ mt: SizingMultiplier.xl }} />;
  }

  if (status === PrimaryInternalFuelingStatus.Unknown) {
    return (
      <Button variant="outlined" onClick={handleRetryClick}>
        <FormattedMessage
          id="transactionStatus:transactionStatusReachedTimeoutLimit:reloadPageButtonText"
          defaultMessage="Reconnect"
          description="Fueling page: Retry button text"
        />
      </Button>
    );
  }

  return null;
};
