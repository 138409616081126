import { FormattedMessage } from 'react-intl';

import { Typography } from '@ocx/ui';

import { profileMessages } from '../profile.messages';

export type AppVersionProps = {
  version: string;
};

export const AppVersion = ({ version }: AppVersionProps) => {
  return (
    <Typography variant="body2" align="center" color="text.disabled">
      <FormattedMessage {...profileMessages['profile:appVersionTitle']} values={{ version }} />
    </Typography>
  );
};
