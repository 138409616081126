import { OfferFragment, SpinWheelGamePrizeFragment } from '@ocx/graphql';
import { Award, AwardPoints, AwardSweepstakeEntries, AwardVouchers } from './award.types';

/**
 * Member Progress represents current membership offer challenge status.
 * It's built around earningMechanism
 *
 * **To create Offer with Challenge please use Activate EM type:**
 *
 * 1. ...
 *
 */
export type OfferMemberProgress = {
  required: number;
  achieved: number;
};

export enum EarningMechanismType {
  Unknown = 'Unknown',
  UnknownWithProgress = 'UnknownWithProgress',
  PurchaseVoucher = 'PurchaseVoucher',
  PurchaseSweepstakesEntry = 'PurchaseSweepstakesEntry',
  PurchaseProductToGetSweepstakesEntry = 'PurchaseProductToGetSweepstakesEntry',
  SpinWheelGame = 'SpinWheelGame',
  Promocodes = 'Promocodes',
}

export type EarningMechanismTypename = Exclude<OfferFragment['earningMechanism'], null>['__typename'];
export type EarningMechanismWithProgressTypename =
  | 'EarningMechanismVisits'
  | 'EarningMechanismTotalSpendOverTime'
  | 'EarningMechanismTotalEligiblePurchaseOverTime'
  | 'EarningMechanismVarietyPurchaseOverTime';

export interface BaseEarningMechanism {
  /**
   * @deprecated Please use type instead
   */
  __typename: Exclude<EarningMechanismTypename, EarningMechanismWithProgressTypename | 'EarningMechanismPointSpend'>;
  type: EarningMechanismType.Unknown;
  award: Award | null;
}

export interface BaseEarningMechanismWithProgress {
  /**
   * @deprecated Please use type instead
   */
  __typename: EarningMechanismWithProgressTypename;
  type: EarningMechanismType.UnknownWithProgress;
  award: Award | null;
  memberProgress: OfferMemberProgress | null;
}

type SpecificEarningMechanismTemplate = Omit<BaseEarningMechanism, '__typename' | 'type' | 'award'>;

export type PurchaseVoucherEarningMechanism = SpecificEarningMechanismTemplate & {
  type: EarningMechanismType.PurchaseVoucher;
  award: AwardVouchers;
  /**
   * Shows the price for award.entriesCount or award.voucherCount
   */
  fulfilmentPrice: number;
};

export type SweepstakesPointsEarningMechanism = SpecificEarningMechanismTemplate & {
  type: EarningMechanismType.PurchaseSweepstakesEntry;
  award: AwardSweepstakeEntries;
  /**
   * Shows the price for award.entriesCount or award.voucherCount
   */
  fulfilmentPrice: number;
};

export type SweepstakesProductEarningMechanism = SpecificEarningMechanismTemplate & {
  type: EarningMechanismType.PurchaseProductToGetSweepstakesEntry;
  award: AwardSweepstakeEntries;
};

export type SpinWheelGameEarningMechanism = SpecificEarningMechanismTemplate & {
  type: EarningMechanismType.SpinWheelGame;
  award: null;
  prizePool: SpinWheelGamePrizeFragment[];
  gameplayVoucher: { id: string; price: number } | null;
};

export type PromocodesEarningMechanism = SpecificEarningMechanismTemplate & {
  type: EarningMechanismType.Promocodes;
  award: AwardPoints | AwardVouchers | null;
};

export type EarningMechanism =
  | BaseEarningMechanism
  | BaseEarningMechanismWithProgress
  | PurchaseVoucherEarningMechanism
  | SweepstakesPointsEarningMechanism
  | SweepstakesProductEarningMechanism
  | SpinWheelGameEarningMechanism
  | PromocodesEarningMechanism;
