import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { BackButton } from '@ocx-app/components/Layout/NavigationBar/BackButton/BackButton';
import { HtmlTitle } from '@ocx-app/components/router/HtmlTitle';
import { PoweredByOpenCommerce, NavigationBar, Page, PageContent, Typography } from '@ocx/ui';

import { messages } from './transaction-receipt.messages';

type ActivateTransactionReceiptLayoutProps = {
  children: ReactNode;
};

export const ActivateTransactionReceiptLayout = ({ children }: ActivateTransactionReceiptLayoutProps) => {
  return (
    <Page>
      <HtmlTitle title={messages['transactionReceipt:htmlTitle']} />
      <NavigationBar
        left={<BackButton />}
        center={
          <Typography component="h2">
            <FormattedMessage {...messages['transactionReceipt:navBar:title']} />
          </Typography>
        }
      />
      <PageContent>
        {children}
        <PoweredByOpenCommerce />
      </PageContent>
    </Page>
  );
};
