import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { PaymentInstrumentIcon } from '@ocx-app/components/payment-instrument-icon';
import { messages as walletMessages } from '../../../../../../modules/wallet/wallet.messages';
import { messages } from './messages';
import { PaymentInstrument } from '../../../../../../modules/wallet/wallet.types';
import { ListSelector } from '../../../../../../hooks/useListController';
import { getPaymentInstrumentListItemInfo } from '../../../../../../modules/wallet/wallet.utils';
import { PaymentMethodButton } from './PaymentMethodButton';
import { PaymentMethodSelectorPopup, usePaymentMethodSelectorPopup } from '../../../../../../components/modals';

export interface ISelectPaymentMethodProps {
  paymentInstrumentsListSelector: ListSelector<PaymentInstrument>;
  onAddPaymentMethodClick: () => void;
}

export const PaymentMethodSelector: React.FC<ISelectPaymentMethodProps> = (props) => {
  const paymentMethodSelectorPopup = usePaymentMethodSelectorPopup();
  const { paymentInstrumentsListSelector, onAddPaymentMethodClick } = props;
  const { selectedListItem, listItems } = paymentInstrumentsListSelector;

  const paymentMethodButton = useMemo(() => {
    if (!selectedListItem) {
      return (
        <PaymentMethodButton onClick={paymentMethodSelectorPopup.show}>
          <FormattedMessage {...messages.choosePaymentMethod} />
        </PaymentMethodButton>
      );
    }
    const { displayName, formattedBalance } = getPaymentInstrumentListItemInfo(selectedListItem);

    return (
      <PaymentMethodButton
        onClick={paymentMethodSelectorPopup.show}
        startIcon={
          selectedListItem.paymentType ? <PaymentInstrumentIcon type={selectedListItem.paymentType} /> : undefined
        }
        balance={formattedBalance}
        withEndArrow>
        {displayName}
      </PaymentMethodButton>
    );
  }, [paymentMethodSelectorPopup.show, selectedListItem]);

  if (!listItems.length) {
    return (
      <PaymentMethodButton onClick={onAddPaymentMethodClick}>
        <FormattedMessage {...walletMessages['wallet:add-payment-instrument-button:title']} />
      </PaymentMethodButton>
    );
  }

  return (
    <>
      {paymentMethodButton}
      <PaymentMethodSelectorPopup
        paymentInstrumentsListSelector={paymentInstrumentsListSelector}
        onAddPaymentMethodClick={onAddPaymentMethodClick}
        id={paymentMethodSelectorPopup.id}
      />
    </>
  );
};
