import { Stack } from '@mui/material';

import { SizingMultiplier } from '@ocx/ui';

import { ReceiptBody } from '../components/receipt-body';
import { ActivateTransactionReceipt as ActivateTransactionReceiptProps } from '../receipts.types';
import { ReceiptLoyaltyCard } from '../components/receipt-loyalty-card';
import { ReceiptSummaryCard } from '../components/receipt-summary-card';
import { ReceiptStoreAddressCard } from '../components/receipt-store-address-card';
import { ReceiptPaymentInstrumentCard } from '../components/receipt-payment-instrument-card';

export const ActivateTransactionReceipt = (props: ActivateTransactionReceiptProps) => {
  const {
    totalAmount,
    subtotalAmount,
    totalTax,
    totalDiscountAmount,
    lineItems,
    date,
    pointChangeItems,
    numberOfAppliedDiscountOffers,
    address,
    locationName,
    paymentInstrumentType,
    setReceiptRef,
  } = props;
  const isLoyaltyCardApplicable = !!pointChangeItems.length || !!totalDiscountAmount;

  return (
    <Stack spacing={SizingMultiplier.sm} p={SizingMultiplier.sm} ref={setReceiptRef}>
      <ReceiptSummaryCard totalAmount={totalAmount} date={date} />
      {isLoyaltyCardApplicable && (
        <ReceiptLoyaltyCard
          pointChangeItems={pointChangeItems}
          savedAmount={totalDiscountAmount}
          numberOfAppliedDiscountOffers={numberOfAppliedDiscountOffers}
        />
      )}
      <ReceiptBody
        lineItems={lineItems}
        total={totalAmount}
        subtotal={subtotalAmount}
        totalDiscount={totalDiscountAmount}
        tax={totalTax}
      />
      {address && <ReceiptStoreAddressCard name={locationName} address={address} />}
      {paymentInstrumentType && (
        <ReceiptPaymentInstrumentCard paymentType={paymentInstrumentType} lastFourDigits={null} />
      )}
    </Stack>
  );
};
