import { useCallback, useEffect } from 'react';
import { ActionPerformed, PushNotifications } from '@capacitor/push-notifications';
import { addBreadcrumb } from '@sentry/react';

import { getDevicePlatform } from '@ocx/utils-device';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';
import { PUSH_NOTIFICATION_ACTION_TAP } from '../deep-linking/deep-linking.constants';
import { useDeepLinkNavigate } from '../deep-linking/deep-linking.utils';
import { getPushNotificationDataSchema } from './push-notification.schema';
import { useConfiguration } from '../configuration/useConfiguration';

export const usePushNotificationReceivedListener = () => {
  const devicePlatform = getDevicePlatform();

  const navigate = useDeepLinkNavigate();
  const { triggerEvent } = useRudderStack();
  const universalLinkOrigins = useConfiguration('localRetailerConfig.universalLinkOrigins');
  const PushNotificationDataSchema = getPushNotificationDataSchema(universalLinkOrigins);

  const onPushNotificationActionPerformed = useCallback(
    async (action: ActionPerformed) => {
      if (action.actionId !== PUSH_NOTIFICATION_ACTION_TAP) {
        return;
      }

      triggerEvent('push_notification_tapped');
      try {
        const data = await PushNotificationDataSchema.parseAsync(action.notification.data);
        if (!data.url) {
          return;
        }
        triggerEvent('push_notification_link_opened');
        navigate(data.url);
      } catch (e) {
        // Error is tracked here, because yup skips validation if the property is not provided
        triggerEvent('push_notification_link_error');
        // TODO: Sentry.captureMessage push_notification_link_error?
      }
    },
    [navigate, triggerEvent, PushNotificationDataSchema],
  );

  useEffect(() => {
    if (devicePlatform === 'web') {
      addBreadcrumb({
        message: '[usePushNotificationReceivedListener] skipping pushNotificationActionPerformed on web',
      });
      return () => {};
    }
    const subscription = PushNotifications.addListener(
      'pushNotificationActionPerformed',
      onPushNotificationActionPerformed,
    );
    return () => {
      subscription.then((s) => s.remove());
    };
  }, [devicePlatform, onPushNotificationActionPerformed]);

  useEffect(() => {
    if (devicePlatform === 'web') {
      addBreadcrumb({
        message: '[usePushNotificationReceivedListener] skipping pushNotificationReceived on web',
      });
      return () => {};
    }
    const subscription = PushNotifications.addListener('pushNotificationReceived', () =>
      triggerEvent('push_notification_received'),
    );
    return () => {
      subscription.then((s) => s.remove());
    };
  }, [devicePlatform, triggerEvent]);
};
