import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { DeviceType } from '@ocx/graphql';
import { CapacitorPlatform } from './device.types';

const mapPlatformToDeviceType: Record<CapacitorPlatform, DeviceType> = {
  ios: DeviceType.Iphone,
  android: DeviceType.Android,
  web: DeviceType.Web,
};
export const getDeviceId = async () => {
  const { identifier: deviceId } = await Device.getId();
  return deviceId;
};
export const getDevicePlatform = () => {
  return Capacitor.getPlatform() as CapacitorPlatform;
};
export const getDeviceType = () => {
  const platform = getDevicePlatform();
  return mapPlatformToDeviceType[platform];
};
export const isIosPlatform = () => getDeviceType() === DeviceType.Iphone;
export const isAndroidPlatform = () => getDeviceType() === DeviceType.Android;
export const isNativePlatform = () => Capacitor.isNativePlatform();
// https://github.com/mui/material-ui/issues/32737#issuecomment-1347356610
export const isAppleWithTouchScreen = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
