import React, { useCallback, useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { ScreenLoaderBackdrop } from '@ocx/ui';

import { PinCodeAction } from './wallet-pin-code-popup.types';
import { ModalFullScreenDrawer, ModalFullScreenHeader } from '../components';
import { WalletPinCodePopupView } from './wallet-pin-code-popup.view';
import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';
import { usePinCodeController } from './wallet-pin-code-popup.controller.hook';

export const actionLocations: { [key in PinCodeAction]: string } = {
  [PinCodeAction.VERIFY]: '/verify-pin',
  [PinCodeAction.SET]: '/set-pin',
  [PinCodeAction.SET_CONFIRM]: '/set-confirm-pin',
};

export const WalletPinCodePopupController: React.FC = (props) => {
  const { visible, resolve, hide, remove } = useModal();
  const { triggerLocation } = useRudderStack();

  const handleCompleted = useCallback(
    (pinCode: string | null) => {
      resolve(pinCode);
      hide();
    },
    [hide, resolve],
  );

  const { action, title, loading, validationError, handleSubmit, handleReset } = usePinCodeController({
    onComplete: handleCompleted,
  });

  const handleExited = useCallback(() => {
    remove();
  }, [remove]);

  const handleCancel = useCallback(() => {
    resolve(null);
    hide();
  }, [hide, resolve]);

  // track popup as page for GA
  useEffect(() => triggerLocation(actionLocations[action]), [action, triggerLocation]);

  return (
    <ModalFullScreenDrawer open={visible} onClose={handleCancel} onExited={handleExited}>
      <ModalFullScreenHeader onBackClick={handleCancel} />
      <ScreenLoaderBackdrop open={loading} />
      <WalletPinCodePopupView
        title={title}
        onSubmit={handleSubmit}
        onReset={handleReset}
        validationError={validationError}
      />
    </ModalFullScreenDrawer>
  );
};

export default WalletPinCodePopupController;
