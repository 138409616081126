import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { ScreenLoaderBackdrop } from '@ocx/ui';

import { ReadyToUse } from './ReadyToUse';
import { ReadyToUseLayout } from './ReadyToUseLayout';
import { useVoucheredOffers } from '../../modules/offers/use-vouchered-offers.hook';
import { OfferDetailsController } from '../OfferDetails/offer-details.controller';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { AppRoutePath } from '../AppRoutePath';

export const ReadyToUseController: React.FC = () => {
  const { offers, loading: isOffersLoading } = useVoucheredOffers();
  const { pushToOfferDetails, pushToHome } = useGetNavigationActions();

  const isLoading = isOffersLoading;

  return (
    <ReadyToUseLayout onBackButtonClick={pushToHome}>
      <ScreenLoaderBackdrop open={isLoading} />
      <ReadyToUse offers={offers} getOfferOnClick={(offer) => () => pushToOfferDetails(offer.id)} loading={isLoading} />
      <Routes>
        <Route
          path={AppRoutePath.OfferDetails}
          element={<OfferDetailsController returnPath={AppRoutePath.ReadyToUseOffers} />}
        />
      </Routes>
    </ReadyToUseLayout>
  );
};

export default ReadyToUseController;
