import React, { useMemo } from 'react';
import { Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import configuration from '@ocx/buildtime-retailer-configuration/retailer.config.json';
import { Button, Typography } from '@ocx/ui';

import { getDevicePlatform } from '@ocx/utils-device';

import forceUpgradeBackgroundImage from '@ocx/buildtime-retailer-configuration/assets/force-upgrade-background.svg';

import { ModalDrawer } from '../components';
import { SizingMultiplier } from '../../../theme/constants';
import { messages } from './force-upgrade-popup.messages';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';
import { isValidLocalRetailerConfiguration } from '../../../modules/configuration/utils/isValidLocalRetailerConfiguration';

type ForceUpgradePopupProps = {
  isOpen?: boolean;
};
export const ForceUpgradePopup = (props: ForceUpgradePopupProps) => {
  const { isOpen = true } = props;

  const appStoreURL = useMemo(() => {
    if (!isValidLocalRetailerConfiguration(configuration)) {
      return null;
    }
    const { appStoreURLs } = configuration;
    const platform = getDevicePlatform();

    if (platform === 'ios') {
      return appStoreURLs.ios;
    }
    if (platform === 'android') {
      return appStoreURLs.android;
    }

    return null;
  }, []);

  return (
    <ModalDrawer open={isOpen} backgroundImageSrc={forceUpgradeBackgroundImage}>
      <Stack
        spacing={SizingMultiplier.md}
        p={SizingMultiplier.lg}
        pb={(theme) => `calc(${theme.spacing(SizingMultiplier.lg)} + var(--ion-safe-area-bottom, 0px))`}>
        <Typography variant="h5" component="h1">
          <FormattedMessage {...messages['force-upgrade-popup:title']} />
        </Typography>
        <Typography>
          <FormattedMessage {...messages['force-upgrade-popup:description']} />
        </Typography>
        {appStoreURL && (
          <Button href={appStoreURL} target="_blank">
            <FormattedMessage {...buttonTextMessages['button-text:upgrade']} />
          </Button>
        )}
      </Stack>
    </ModalDrawer>
  );
};
