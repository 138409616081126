import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { PoweredByOpenCommerce, NavigationBar, Page, PageContent } from '@ocx/ui';
import { BackButton } from '@ocx-app/components/Layout/NavigationBar/BackButton/BackButton';
import { HtmlTitle } from '@ocx-app/components/router/HtmlTitle';

import { messages } from './points-change-receipt.messages';

type Props = {
  children: ReactNode;
};

export const PointsChangeReceiptLayout: React.FC<Props> = ({ children }) => {
  return (
    <Page>
      <HtmlTitle title={messages['pointsChangeReceipt:htmlTitle']} />
      <NavigationBar
        left={<BackButton />}
        center={
          <Typography component="h2">
            <FormattedMessage {...messages['pointsChangeReceipt:navBar:title']} />
          </Typography>
        }
      />
      <PageContent>
        {children}
        <PoweredByOpenCommerce />
      </PageContent>
    </Page>
  );
};
