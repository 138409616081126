import { captureException, addBreadcrumb } from '@sentry/react';
import { useEffect } from 'react';
import { PushNotifications } from '@capacitor/push-notifications';

import { getDevicePlatform } from '@ocx/utils-device';
import { PUSH_NOTIFICATIONS_MARKETING_CHANNEL_ID } from '../deep-linking/deep-linking.constants';
import { intl } from '../../lib/translation/TranslationProvider';
import { logInDev } from '../../lib/logger/logger';

const createMarketingNotificationsChannel = async () => {
  const devicePlatform = getDevicePlatform();
  // Push notifications are not supported on the web.
  if (devicePlatform !== 'android') {
    addBreadcrumb({
      message: `[createMarketingNotificationsChannel] skipping on ${devicePlatform}`,
    });
    return;
  }

  try {
    await PushNotifications.createChannel({
      id: PUSH_NOTIFICATIONS_MARKETING_CHANNEL_ID,
      name: intl.formatMessage({
        id: 'push-notification:channel:updates:name',
        defaultMessage: 'Offers & Transactions',
      }),
      description: intl.formatMessage({
        id: 'push-notification:channel:updates:description',
        defaultMessage: 'To display the latest offers and updates.',
      }),
      // Importance 4 allows android push to be visible in foreground
      importance: 4,
      visibility: 1,
    });
  } catch (e) {
    logInDev(e);
    captureException(e);
  }
};

export const useCreateMarketingNotificationsChannel = () => {
  useEffect(() => {
    createMarketingNotificationsChannel();
  }, []);
};
