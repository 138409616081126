import { FormattedMessage } from 'react-intl';

import { Typography, TypographyProps } from '@ocx/ui';

import { messages } from './support.messages';
import { SupportLink } from './support-link.component';

export const SignupSupportLink = (props: TypographyProps) => {
  return (
    <Typography variant="caption" align="center" {...props}>
      <FormattedMessage {...messages['support:support-link:message-text']} />{' '}
      <SupportLink>
        <FormattedMessage {...messages['support:support-link:action-text']} />
      </SupportLink>
    </Typography>
  );
};
