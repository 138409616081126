import { EMPTY_IMAGE_PLACEHOLDER, BASE_64_SVG_IMAGE_PREFIX } from './constants';

let GOOGLE_FONTS_CACHE = '';

const arrayBufferToBase64 = (buffer: ArrayBuffer): string => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
};

const fetchResourceAsBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  return arrayBuffer ? arrayBufferToBase64(arrayBuffer) : '';
};

const getGoogleFontFaces = async (fontsUrl: string): Promise<string> => {
  if (GOOGLE_FONTS_CACHE) {
    return GOOGLE_FONTS_CACHE;
  }
  const fontUrlRegex = /url\((https:\/\/fonts\.gstatic\.com\/[^)]+)\)/g;
  const response = await fetch(fontsUrl);
  let cssText = await response.text();

  let match;
  while ((match = fontUrlRegex.exec(cssText)) !== null) {
    const fontUrl = match[1];
    const base64Font = await fetchResourceAsBase64(fontUrl);
    if (base64Font) {
      const fontFormat = fontUrl.includes('woff2') ? 'woff2' : 'woff';
      cssText = cssText.replace(fontUrl, `data:font/${fontFormat};base64,${base64Font}`);
    }
  }
  GOOGLE_FONTS_CACHE = cssText;
  return GOOGLE_FONTS_CACHE;
};

const getCSSStyles = (removeMediaRules = false) => {
  let css = '';

  const mediasMatched = [
    window.matchMedia('(min-width: 600px)').matches ? 600 : 0,
    window.matchMedia('(min-width: 900px)').matches ? 900 : 0,
    window.matchMedia('(min-width: 1200px)').matches ? 1200 : 0,
  ].filter(Boolean);

  const mediasRegex = mediasMatched.length ? new RegExp(`min-width:\\s*(${mediasMatched.join('|')})px`) : null;

  const styleSheets = document.styleSheets;
  for (let i = 0; i < styleSheets.length; i++) {
    try {
      const rules = styleSheets[i].cssRules || styleSheets[i].rules;
      for (let j = 0; j < rules.length; j++) {
        const rule = rules[j];
        if (removeMediaRules && rule.type === CSSRule.MEDIA_RULE && mediasRegex) {
          const mediaText = (rule as CSSMediaRule).media.mediaText || '';
          if (mediaText && mediasRegex.test(mediaText)) {
            css += rule.cssText;
          }
        } else {
          css += rule.cssText;
        }
      }
    } catch (e) {
      // ...
    }
  }
  return css;
};

const loadImageAsBase64 = async (options: {
  src: string;
  padding?: number;
  format?: 'png' | 'jpeg';
  backgroundColor?: string;
  pixelRatio?: number;
}): Promise<string> => {
  const { src, padding = 0, format = 'png', backgroundColor, pixelRatio = 1 } = options;

  return new Promise((resolve) => {
    const onError = () => {
      resolve(EMPTY_IMAGE_PLACEHOLDER);
    };

    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = src;
    img.onerror = onError;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = Math.round(img.width * pixelRatio);
      canvas.height = Math.round(img.height * pixelRatio);
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return onError();
      }

      if (backgroundColor) {
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      ctx.scale(pixelRatio, pixelRatio);
      ctx.drawImage(img, 0, 0);

      if (padding) {
        const paddingCanvas = document.createElement('canvas');
        paddingCanvas.width = canvas.width + padding * 2;
        paddingCanvas.height = canvas.height + padding * 2;
        const paddingCtx = paddingCanvas.getContext('2d');

        if (!paddingCtx) {
          return onError();
        }

        if (backgroundColor) {
          paddingCtx.fillStyle = backgroundColor;
          paddingCtx.fillRect(0, 0, paddingCanvas.width, paddingCanvas.height);
        }

        paddingCtx.drawImage(canvas, padding, padding);
        resolve(paddingCanvas.toDataURL(`image/${format}`));
      } else {
        resolve(canvas.toDataURL(`image/${format}`));
      }
    };
  });
};

const convertHTMLImagesToBase64 = async (svgElement: SVGElement) => {
  const images = svgElement.querySelectorAll('img');
  return await Promise.allSettled(
    Array.from(images).map(async (img) => {
      const src = img.getAttribute('src');

      if (src && !src.startsWith('data:')) {
        const base64Image = await loadImageAsBase64({ src });
        img.setAttribute('src', base64Image);
      }
    }),
  );
};

export const elementToImage = async (
  element: Element,
  padding = 0,
  areGoogleFontsExcluded?: boolean,
): Promise<string | null> => {
  if (!element) {
    return null;
  }

  const container = document.createElement('div');
  const width = element.clientWidth;
  const height = element.clientHeight;
  container.style.position = 'absolute';
  container.style.top = '0';
  container.style.left = '0';

  container.innerHTML = `
  <svg xmlns="http://www.w3.org/2000/svg"
    width="${width}"
    height="${height}"
    viewBox="0 0 ${width} ${height}"
  >
    <style>
      foreignObject * {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
      }
    </style>
    <foreignObject width="100%" height="100%">
      ${element.outerHTML}
    </foreignObject>
  </svg>`;

  if (!container.firstElementChild) {
    return null;
  }

  const svgElement = container.firstElementChild as SVGElement;

  await convertHTMLImagesToBase64(svgElement);

  const googleFontFaces = areGoogleFontsExcluded
    ? ''
    : await getGoogleFontFaces('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600;700&display=swap');

  const style = getCSSStyles(true);
  const styleElement = document.createElement('style');
  styleElement.setAttribute('type', 'text/css');
  styleElement.innerHTML = googleFontFaces + '\n\n' + style;
  svgElement.insertBefore(styleElement, svgElement.firstChild);

  const svgString = new XMLSerializer().serializeToString(svgElement);

  return loadImageAsBase64({
    src: BASE_64_SVG_IMAGE_PREFIX + btoa(unescape(encodeURIComponent(svgString))),
    padding,
    backgroundColor: '#fff',
    pixelRatio: window.devicePixelRatio || 1,
  });
};

export const htmlToImage = async (selector: string, padding?: number): Promise<string | null> => {
  const content = document.querySelector(selector);

  if (!content) {
    return null;
  }

  return elementToImage(content, padding);
};
