import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as NiceModalProvider } from '@ebay/nice-modal-react';
import vendorPalette from '@ocx/buildtime-retailer-configuration/palette.json';

import { createApolloClient } from '@ocx/apollo-client';
import { getDeviceId, getDevicePlatform } from '@ocx/utils-device';
import { AppRoutes } from './pages/AppRoutes';
import { SnackbarProvider } from './lib/snackbar/SnackbarProvider';
import { TranslationProvider } from './lib/translation/TranslationProvider';
import { LoaderProvider } from './lib/loader/LoaderProvider';
import { AppLoadingGuard } from './AppLoadingGuard';
import { AppContextProvider } from './modules/app-context/AppContextProvider';
import { VendorPalette } from './theme/palette/types';
import { createTheme } from './theme/createTheme';
import { renderErrorFallback } from './modules/sentry/ErrorBoundaryFallback';
import { SurveysContextProvider } from './modules/survey/survey.context';
import { MembershipProvider } from './modules/membership/context/membership.provider';
import { SmsOptInContextProvider } from './modules/sms-opt-in/context/sms-opt-in.provider';
import { GlobalErrorHandlerProvider, handleGlobalError } from './modules/global-error-handler';
import { EnvConfiguration } from './modules/configuration/EnvConfiguration';
import { authService } from './modules/auth/instances/authService';
import { clearCache } from './modules/cache-layer/utils';
import { logInDev } from './lib/logger/logger';

const apolloClient = createApolloClient({
  api: {
    url: EnvConfiguration.api.url,
    key: EnvConfiguration.api.key,
  },
  release: EnvConfiguration.release,
  gitRevision: EnvConfiguration.gitRevision,
  authService,
  getDeviceId,
  getDevicePlatform,
  clearCache,
  logInDev,
  globalErrorHandler: handleGlobalError,
});

authService.injectNetworkClient(apolloClient);

const theme = createTheme(vendorPalette as VendorPalette);

// R TODO: consider adding more context helpers
// R TODO: consider adding business logic context helpers
interface MuiContextProps {
  children: React.ReactNode;
}
const MuiContext = ({ children }: MuiContextProps) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider>
          <LoaderProvider>{children}</LoaderProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

const App = () => (
  <Sentry.ErrorBoundary fallback={renderErrorFallback}>
    <HelmetProvider>
      <TranslationProvider>
        <MuiContext>
          <ApolloProvider client={apolloClient}>
            <GlobalErrorHandlerProvider>
              <AppContextProvider>
                <AppLoadingGuard>
                  <MembershipProvider>
                    <BrowserRouter>
                      <NiceModalProvider>
                        <SurveysContextProvider>
                          <SmsOptInContextProvider>
                            <AppRoutes />
                          </SmsOptInContextProvider>
                        </SurveysContextProvider>
                      </NiceModalProvider>
                    </BrowserRouter>
                  </MembershipProvider>
                </AppLoadingGuard>
              </AppContextProvider>
            </GlobalErrorHandlerProvider>
          </ApolloProvider>
        </MuiContext>
      </TranslationProvider>
    </HelmetProvider>
  </Sentry.ErrorBoundary>
);

export default App;
