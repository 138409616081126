import React, { useCallback } from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { ModalFullScreenDrawer, ModalFullScreenHeader } from '../components';
import { AddCardControllerProps, AddCardController } from '../../../pages/AddCard/AddCardController';

export type AddPaymentInstrumentPopupProps = {
  type: AddCardControllerProps['type'];
  onCreated?(params: { paymentInstrumentId: string | null }): void;
};

export const AddPaymentInstrumentPopupController = (props: AddPaymentInstrumentPopupProps) => {
  const { type, onCreated } = props;

  const modal = useModal();
  const handleCreated = useCallback(
    (params: { paymentInstrumentId: string | null }) => {
      onCreated?.(params);
      modal.hide();
    },
    [modal, onCreated],
  );

  return (
    <ModalFullScreenDrawer open={modal.visible} onClose={modal.hide} onExited={modal.remove}>
      <ModalFullScreenHeader onBackClick={modal.hide} />
      <AddCardController type={type} onCardAdded={handleCreated} />
    </ModalFullScreenDrawer>
  );
};
