import { ShoppingModeIcon } from '@ocx-app/components/Icons/ShoppingModeIcon';
import { Paper, SizingMultiplier, Typography } from '@ocx/ui';

import { TransactionIconBox } from '../transaction-icon-box';

type PointsChangeReasonCardProps = {
  title: string;
};

export const PointsChangeReasonCard = ({ title }: PointsChangeReasonCardProps) => {
  return (
    <Paper sx={{ p: SizingMultiplier.md, display: 'flex', alignItems: 'center' }}>
      <TransactionIconBox>
        <ShoppingModeIcon sx={{ color: 'Receipts.iconColor' }} />
      </TransactionIconBox>
      <Typography ml={SizingMultiplier.sm} variant="subtitle1">
        {title}
      </Typography>
    </Paper>
  );
};
