import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Paper, SvgIcon } from '@mui/material';
import AccountBalanceWalletOutlined from '@mui/icons-material/AccountBalanceWalletOutlined';
import NotificationsActiveOutlined from '@mui/icons-material/NotificationsActiveOutlined';
import ReceiptLongOutlined from '@mui/icons-material/ReceiptLongOutlined';
import LocalMallOutlined from '@mui/icons-material/LocalMallOutlined';
import LocalGasStationOutlined from '@mui/icons-material/LocalGasStationOutlined';

import { List, ListItemText, ListItemStartIcon, ListItemNavLink } from '@ocx/ui';
import { AppRoutePath } from '@ocx-app/pages/AppRoutePath';

import { profileMessages } from '../profile.messages';

export type LoyaltyDetailsListProps = {
  isWalletEnabled: boolean;
  isCommunicationPreferencesEnabled: boolean;
  isProductInterestsEnabled: boolean;
  isPreferredFuelGradeEnabled: boolean;
};

type LoyaltyDetailsFeatureFlagKey = keyof LoyaltyDetailsListProps;
type LoyaltyDetailsMenuItem = {
  Icon: typeof SvgIcon;
  title: MessageDescriptor;
  path: AppRoutePath;
  featureFlagName?: LoyaltyDetailsFeatureFlagKey;
};

const items: LoyaltyDetailsMenuItem[] = [
  {
    featureFlagName: 'isCommunicationPreferencesEnabled',
    path: AppRoutePath.CommunicationPreferences,
    Icon: NotificationsActiveOutlined,
    title: profileMessages['profile:communicationPreference'],
  },
  {
    featureFlagName: 'isProductInterestsEnabled',
    path: AppRoutePath.ProductInterests,
    Icon: LocalMallOutlined,
    title: profileMessages['profile:productInterests'],
  },
  {
    path: AppRoutePath.TransactionHistory,
    Icon: ReceiptLongOutlined,
    title: profileMessages['profile:receiptsButtonTitle'],
  },
  {
    featureFlagName: 'isWalletEnabled',
    path: AppRoutePath.Wallet,
    Icon: AccountBalanceWalletOutlined,
    title: profileMessages['profile:walletButtonTitle'],
  },
  {
    featureFlagName: 'isPreferredFuelGradeEnabled',
    path: AppRoutePath.PreferredFuelGrade,
    Icon: LocalGasStationOutlined,
    title: profileMessages['profile:preferredFuelGradeButtonTitle'],
  },
];

export const LoyaltyDetailsList = (props: LoyaltyDetailsListProps) => {
  return (
    <Paper elevation={0} sx={{ overflow: 'hidden' }}>
      <List>
        {items
          .filter((item) => (item.featureFlagName ? props[item.featureFlagName] : true))
          .map(({ Icon, title, path }) => {
            return (
              <ListItemNavLink key={path} to={path}>
                <ListItemStartIcon>
                  <Icon />
                </ListItemStartIcon>
                <ListItemText>
                  <FormattedMessage {...title} />
                </ListItemText>
              </ListItemNavLink>
            );
          })}
      </List>
    </Paper>
  );
};
