import React from 'react';

import { Box, ButtonBase } from '@mui/material';
import { TransactionHistoryItemType } from '@ocx-app/modules/transaction-history/transaction-history.types';

import { convertDollarsToCents, formatPrice } from '@ocx-app/lib/formatters/formatPrice';
import { PointsStrategy } from '@ocx/graphql';
import { SizingMultiplier, Typography } from '@ocx/ui';
import { PointsChange } from '../../../components/PointsChange/PointsChange';
import { TransactionIcon } from './TransactionIcon';

export type TransactionHistoryItemProps = {
  date: string;
  title: string;
  points?: number | null;
  onClick(): void;
  pointsStrategy: PointsStrategy;
  transactionType: TransactionHistoryItemType;
  totalAmount: number | null;
  totalAmountWithoutDiscounts: number | null;
};

export const TransactionHistoryItem: React.FC<TransactionHistoryItemProps> = (props) => {
  const {
    date,
    title,
    points = null,
    onClick,
    pointsStrategy,
    transactionType,
    totalAmount,
    totalAmountWithoutDiscounts,
  } = props;

  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        display: 'flex',
        p: SizingMultiplier.md,
        minHeight: 56,
        alignItems: 'stretch',
      }}>
      <TransactionIcon transactionType={transactionType} />
      <Box
        display="flex"
        flex={1}
        textAlign="left"
        flexDirection="column"
        justifyContent="space-between"
        ml={SizingMultiplier.sm}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body2" component="span" color="text.disabled">
          {date}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', textAlign: 'right', flexDirection: 'column', justifyContent: 'space-between' }}>
        {transactionType === TransactionHistoryItemType.Transaction ? (
          <TransactionTotal total={totalAmount} prevTotal={totalAmountWithoutDiscounts} />
        ) : (
          pointsStrategy !== PointsStrategy.NotUsed &&
          typeof points === 'number' && (
            <PointsChange pointsStrategy={pointsStrategy} points={points} allowPlus={false} iconPosition="right" />
          )
        )}
      </Box>
    </ButtonBase>
  );
};

const TransactionTotal = ({ total, prevTotal }: { total: number | null; prevTotal: number | null }) => {
  if (typeof total !== 'number') {
    return null;
  }
  return (
    <>
      <Typography variant="subtitle1">{formatPrice(convertDollarsToCents(total))}</Typography>
      {typeof prevTotal === 'number' &&
        prevTotal > total && ( // do not show prev price if it is not bigger than current
          <Typography variant="body2" component="span" color="text.disabled" sx={{ textDecoration: 'line-through' }}>
            {formatPrice(convertDollarsToCents(prevTotal))}
          </Typography>
        )}
    </>
  );
};
