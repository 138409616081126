import merge from 'lodash/merge';
import type {} from '@mui/lab/themeAugmentation';

import { BasePalette, PaletteOverrides, VendorPalette } from './types';
import darkBase from './darkBase.json';
import lightBase from './lightBase.json';
import { createOfferLabelPalette } from './createOfferLabelPalette';

const extendVendorPalette = (palette: VendorPalette): BasePalette & VendorPalette => {
  if (palette.mode === 'dark') {
    // TODO: think about d.ts for json files
    return merge(palette, darkBase as BasePalette);
  }

  return merge(palette, lightBase as BasePalette);
};

export const createPaletteOverrides = (vendorPalette: VendorPalette): PaletteOverrides => {
  const extendedPalette = extendVendorPalette(vendorPalette);
  const offerLabelPalette = createOfferLabelPalette(extendedPalette.offerLabel);

  return {
    mode: 'light',
    retailerMode: vendorPalette.mode,
    primary: extendedPalette.primary,
    secondary: extendedPalette.secondary,
    text: extendedPalette.text,
    success: extendedPalette.success,
    error: extendedPalette.error,
    info: extendedPalette.info,
    warning: extendedPalette.warning,
    action: extendedPalette.action,
    divider: extendedPalette.divider,
    background: {
      default: extendedPalette.background1,
      paper: extendedPalette.background2,
      box: extendedPalette.background3,
    },
    offerLabel: offerLabelPalette,
    payments: {
      googlePayButtonVariant: extendedPalette.payments.googlePayButtonVariant,
      applePayButtonVariant: extendedPalette.payments.applePayButtonVariant,
    },
    appBar: {
      background: extendedPalette.components.AppBar.background,
      color: extendedPalette.components.AppBar.color,
    },
    tabBar: {
      background: extendedPalette.components.TabBar.background,
      color: extendedPalette.components.TabBar.color,
      selectedColor: extendedPalette.components.TabBar.selectedColor,
    },
    sweepsTicket: {
      background: extendedPalette.components.SweepsTicket.background,
      color: extendedPalette.components.SweepsTicket.color,
    },
    readyToUse: {
      background: extendedPalette.components.ReadyToUse.background,
    },
    SpinWheel: {
      sectorColors: extendedPalette.components.SpinWheel.sectorColors,
      rimLightColor: extendedPalette.components.SpinWheel.rimLightColor,
      rimNormalColor: extendedPalette.components.SpinWheel.rimNormalColor,
      rimDarkColor: extendedPalette.components.SpinWheel.rimDarkColor,
      rimShadowColor: extendedPalette.components.SpinWheel.rimShadowColor,
      risingSunBackColor: extendedPalette.components.SpinWheel.risingSunBackColor,
      risingSunRaysColor: extendedPalette.components.SpinWheel.risingSunRaysColor,
      risingSunTextColor: extendedPalette.components.SpinWheel.risingSunTextColor,
      buttonColor: extendedPalette.components.SpinWheel.buttonColor,
    },
    Receipts: {
      iconColor: extendedPalette.components.Receipts.iconColor,
      iconBackground: extendedPalette.components.Receipts.iconBackground,
      offerLinkColor: extendedPalette.components.Receipts.offerLinkColor,
      offerLinkBackground: extendedPalette.components.Receipts.offerLinkBackground,
      currencyBackground: extendedPalette.components.Receipts.currencyBackground,
    },
    googleMapsMapId: vendorPalette.googleMapsMapId,
  };
};
