import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'support:support-link:message-text': {
    id: 'support:support-link:message-text',
    defaultMessage: 'Having Trouble?',
    description: 'Signup: support link message text',
  },
  'support:support-link:action-text': {
    id: 'support:support-link:action-text',
    defaultMessage: 'Contact Support',
    description: 'Signup: support link action text',
  },
});
