import Button from '@mui/lab/LoadingButton';
import React from 'react';
import { ButtonProps } from '../button/button.component';

export type LoadingButtonProps = Omit<ButtonProps, 'endIcon'> & {
  /**
   * If true, the button is showing loading spinner.
   * @defaultValue false
   */
  loading?: boolean;
  /**
   * Name of downloaded file
   * @defaultValue undefined
   */
  download?: string;
};

export const LoadingButton: React.FC<LoadingButtonProps> = React.forwardRef<HTMLButtonElement, LoadingButtonProps>(
  (props, ref) => {
    const { variant = 'contained', size = 'medium', color = 'primary', ...rest } = props;
    return <Button variant={variant} color={color} size={size} {...rest} ref={ref} />;
  },
);
