import React from 'react';
import { useIntl } from 'react-intl';

import { TextField, TextFieldProps } from '@ocx-app/components/TextField';
import { parseSecurityCode } from './prepaid-card-pin-field.utils';

export const PrepaidCardPinField = (props: TextFieldProps) => {
  const intl = useIntl();

  return (
    <TextField
      type="password"
      inputProps={{
        inputMode: 'numeric',
        autoComplete: 'on',
        autocompletetype: 'cc-csc',
        pattern: '\\d*',
      }}
      autoComplete="on"
      fieldProps={{
        parse: parseSecurityCode,
      }}
      label={intl.formatMessage({
        id: 'payments:prepaid:pin-number-field-label',
        defaultMessage: 'PIN Number',
        description: 'PIN number field label',
      })}
      {...props}
    />
  );
};
