import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  downloadReceiptButtonLabel: {
    defaultMessage: 'Download Receipt',
    description: 'Label of download receipt button',
    id: 'transactionReceipt:downloadReceiptButtonLabel',
  },
  receiptLoadingLabel: {
    id: 'transactionReceipt:loading-label',
    defaultMessage: 'Fetching Receipt...',
    description: 'Transaction Receipt: Loading text',
  },
});
