import { ReactNode } from 'react';
import { ListItemButton } from './list-item-button.component';
import { Typography } from '../typography/typography.component';

type ListHeaderProps = {
  children?: ReactNode;
};

export const ListHeader = (props: ListHeaderProps) => {
  const { children } = props;
  return (
    <ListItemButton disableRipple>
      <Typography variant="h5" align="left" sx={{ textTransform: 'none', width: '100%' }}>
        {children}
      </Typography>
    </ListItemButton>
  );
};
