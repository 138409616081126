import { FormatNumberOptions } from 'react-intl';
import find from 'lodash/find';
import * as Sentry from '@sentry/react';

import { convertDollarsToCents, formatPrice } from '@ocx-app/lib/formatters/formatPrice';
import {
  ActivateTransactionFragment,
  CarWashInput,
  LoyaltyActivateTransactionItemFragment,
  LoyaltyPointChangeItemFragment,
  PaymentInstrumentType,
  PointChangeType,
  PrimaryTransactionStatus,
  ActivateLineItemPromotionFragment,
  SecondaryTransactionStatus,
  TransactionFragment,
  TransactionHistoryItemFragment,
  TransactionStart,
  TransactionType,
  PointChangeFragment,
  ExternalLineItemPromotion,
} from '@ocx/graphql';
import { TransactionPaymentInstrumentType } from '../../components/payment-instrument-icon';
import {
  ActivateTransactionReceipt,
  IReceipt,
  ActivateTransactionLoyaltyItem,
  PrimaryInternalFuelingStatus,
  SanitizedReceipt,
  IActivateTransactionReceiptLineItem,
  PointChangeReceipt,
  TransformedLineItemPromotion,
  TransformLineItem,
} from './types';
import { externalStatusToInternalStatus } from './statuses';
import { PAYMENT_INSTRUMENT_TYPE } from '../../lib/payments/payments.types';
import { PaymentInstrument } from '../wallet/wallet.types';
import { KountUniversal } from '../../lib/payments/Kount/KountUniversal';
import { sanitizePaymentInstrumentFragment } from '../wallet/wallet.utils';
import { formatDateTime } from '../../lib/date/date';
import { ITransactionHistoryItem, TransactionHistoryItemType } from '../transaction-history/transaction-history.types';
import { DateTimeFormat } from '../../lib/date/date.types';
import { ON_DEVICE_ACCOUNT_TYPES, TOKENIZABLE_ACCOUNT_TYPES } from '../wallet/wallet.constants';

export const isReceiptReady = (transaction?: TransactionFragment | null): transaction is SanitizedReceipt => {
  if (!transaction) {
    return false;
  }
  if (!transaction.receiptLines) {
    return false;
  }
  if (typeof transaction.amount?.price !== 'number') {
    return false;
  }
  if (!transaction.completedAt) {
    return false;
  }
  if (!transaction.location?.address) {
    return false;
  }
  return true;
};

export const mapReceipt = (transaction?: TransactionFragment | null): IReceipt | null => {
  if (!isReceiptReady(transaction)) {
    return null;
  }
  const {
    receiptLines,
    fuelReceipt,
    amount,
    completedAt,
    tax,
    location: { address, name: locationName },
    paymentInstrument,
  } = transaction;

  const receipt: IReceipt = {
    total: amount.price,
    receiptLines: receiptLines.map((line) => line?.value || '') || [],
    gallons: null,
    pricePerGallon: null,
    fuelTotal: null,
    tax: tax ? tax.price : null,
    date: completedAt,
    carWash: null,
    address: `${address.street1}, ${address.city} ${address.state}`,
    locationName: locationName ?? null,
    paymentInstrument: paymentInstrument
      ? sanitizePaymentInstrumentFragment(paymentInstrument, {
          // It's not important for that case
          // This file requires probably own query and own helper
          availableAccountTypes: [...TOKENIZABLE_ACCOUNT_TYPES, ...ON_DEVICE_ACCOUNT_TYPES],
        })
      : null,
  };

  if (fuelReceipt) {
    receipt.gallons = parseFloat(fuelReceipt.gallons);
    receipt.pricePerGallon = parseFloat(fuelReceipt.priceG);
    receipt.fuelTotal = parseFloat(fuelReceipt.fuelSale);
  }

  if (fuelReceipt && fuelReceipt.carWashCode && fuelReceipt.carWashName && fuelReceipt.carWashPrice) {
    receipt.carWash = {
      name: fuelReceipt.carWashName,
      code: fuelReceipt.carWashCode,
      price: parseFloat(fuelReceipt.carWashPrice),
    };
  }

  return receipt;
};

export const TRANSACTION_RECEIPT_NUMBER_FORMAT_OPTIONS: FormatNumberOptions = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
};
export const getTransactionGroupedDate = (createdAt: string) =>
  formatDateTime(new Date(createdAt), DateTimeFormat.DateYearMonthGroupBy);

// Part of the logic from mapPointsChangeToTransactionHistoryItem are moved to a this function
export const getPointChangeComboValues = (props: {
  type: PointChangeType | null;
  notes: LoyaltyPointChangeItemFragment['notes'];
  source: LoyaltyPointChangeItemFragment['source'];
  offer: LoyaltyPointChangeItemFragment['offer'];
}): { title: string; type: TransactionHistoryItemType } => {
  const { type, notes, source, offer } = props;
  const defaultTitle = 'Points Change';

  // Handle specific point change type
  if (type === PointChangeType.BreakageReturn) {
    return { title: defaultTitle, type: TransactionHistoryItemType.PointsChange };
  }
  // Check source conditions
  if (!source || ['Chain', 'ActivateUser'].includes(source.__typename)) {
    return { title: notes || defaultTitle, type: TransactionHistoryItemType.PointsChange };
  }
  // Handle specific source types
  switch (source.__typename) {
    case 'LoyaltyEvent':
      return {
        title: offer?.name || source.eventType || defaultTitle,
        type: TransactionHistoryItemType.LoyaltyEvent,
      };
    case 'OfferPurchase':
      return {
        title: source.purchasedWith.marketingContents[0]?.title || defaultTitle,
        type: TransactionHistoryItemType.PointsChange,
      };
    case 'ActivateTransaction':
      return {
        title: 'Transaction',
        type: TransactionHistoryItemType.PointsChange,
      };
    case 'PointChange':
      return {
        title: source.offer?.marketingContents[0]?.title || defaultTitle,
        type: TransactionHistoryItemType.PointsChange,
      };
    default:
      return {
        title: defaultTitle, // Default case for unexpected source types
        type: TransactionHistoryItemType.PointsChange,
      };
  }
};

export const mapPointsChangeToTransactionHistoryItem = (
  node: LoyaltyPointChangeItemFragment,
): ITransactionHistoryItem => {
  const { source, createdAt, notes, quantity, offer, type, id } = node;
  const values = getPointChangeComboValues({ type, notes, source, offer });
  return {
    id,
    title: values.title,
    type: values.type,
    points: quantity,
    date: createdAt,
    dateTitle: formatDateTime(new Date(createdAt), DateTimeFormat.MonthDateTime),
    groupedDate: getTransactionGroupedDate(createdAt),
    totalAmount: null,
    totalAmountWithoutDiscounts: null,
  };
};

export const mapActivateTransactionToTransactionHistoryItem = (
  node: LoyaltyActivateTransactionItemFragment,
): ITransactionHistoryItem => {
  const { id, createdAt, totalAmount, totalAmountWithoutDiscounts } = node;

  return {
    id,
    title: 'Transaction',
    points: null,
    type: TransactionHistoryItemType.Transaction,
    date: createdAt,
    dateTitle: formatDateTime(new Date(createdAt), DateTimeFormat.MonthDateTime),
    groupedDate: getTransactionGroupedDate(createdAt),
    totalAmount,
    totalAmountWithoutDiscounts,
  };
};

export const mapTransactionToTransactionHistoryItem = (
  node: TransactionHistoryItemFragment,
): ITransactionHistoryItem => {
  const { uuid, completedAt } = node;
  return {
    id: uuid,
    title: 'Transaction',
    points: null,
    type: TransactionHistoryItemType.Transaction,
    date: completedAt,
    dateTitle: formatDateTime(new Date(completedAt), DateTimeFormat.MonthDateTime),
    groupedDate: getTransactionGroupedDate(completedAt),
    totalAmount: null,
    totalAmountWithoutDiscounts: null,
  };
};

const mapPointsToPointChangeItems = ({
  totalPointsEarned,
  totalPointsSpent,
  totalPointsRefunded,
}: Pick<
  ActivateTransactionFragment,
  'totalPointsEarned' | 'totalPointsSpent' | 'totalPointsRefunded'
>): ActivateTransactionLoyaltyItem[] => {
  return [
    { pointsDiff: totalPointsEarned || 0, offersNumber: 0, type: 'earned' as const },
    { pointsDiff: totalPointsSpent || 0, offersNumber: 0, type: 'spent' as const },
    { pointsDiff: totalPointsRefunded || 0, offersNumber: 0, type: 'refund' as const },
  ].filter(({ pointsDiff }) => pointsDiff !== 0);
};

const transformLineItemPromotions = ({
  promotions,
  isDiscountItemPromotions = false,
}: {
  promotions: ActivateLineItemPromotionFragment[];
  isDiscountItemPromotions: boolean;
}): TransformedLineItemPromotion[] => {
  return (
    promotions.map((promotion) => ({
      offerId: promotion.offer?.id || null,
      reason:
        promotion.offer?.marketingContents[0]?.title || promotion.offer?.marketingContents[0]?.shortDescription || '',
      amount: isDiscountItemPromotions ? null : promotion.promotionAmount,
    })) || []
  );
};

const LINE_ITEM_GROUP_VALUES = {
  TRANSACTION_DISCOUNT: 'transaction_discount',
};

const transformLineItems = (lineItems: ActivateTransactionFragment['lineItems']): TransformLineItem[] => {
  return (
    lineItems
      .filter((item) => item !== null)
      .sort(
        (a, b) =>
          Number(a.group === LINE_ITEM_GROUP_VALUES.TRANSACTION_DISCOUNT) -
          Number(b.group === LINE_ITEM_GROUP_VALUES.TRANSACTION_DISCOUNT), // transaction_discount should be at the end
      )
      .map((lineItem) => ({
        id: lineItem.id,
        amount: lineItem.moneyAmount,
        name:
          lineItem.group === LINE_ITEM_GROUP_VALUES.TRANSACTION_DISCOUNT
            ? `${formatPrice(convertDollarsToCents(-(lineItem?.moneyAmount || 0)))} Discount`
            : lineItem.sku || lineItem.upc,
        quantity: lineItem.group === LINE_ITEM_GROUP_VALUES.TRANSACTION_DISCOUNT ? null : lineItem.quantity,
        fuelGradeId: lineItem.fuelGradeId,
        promotions: transformLineItemPromotions({
          promotions: lineItem.promotions ?? [],
          isDiscountItemPromotions: lineItem.group === LINE_ITEM_GROUP_VALUES.TRANSACTION_DISCOUNT,
        }),
        externalPromotionsTotalDiscount: getTotalDiscountAmountFromExternalPromotions(
          lineItem.externalLineItemPromotions,
        ),
      })) || []
  );
};

const getNumberOfAppliedDiscountOffersFromLineItems = (lineItems: IActivateTransactionReceiptLineItem[]): number => {
  return lineItems.reduce((count, item) => {
    return count + (item?.promotions?.filter((promotion) => (promotion.amount ?? 0) > 0).length ?? 0);
  }, 0);
};

const getTotalDiscountAmountFromExternalPromotions = (
  externalLineItemPromotions: ExternalLineItemPromotion[] | null,
): number | null => {
  if (!externalLineItemPromotions || !externalLineItemPromotions.length) {
    return null;
  }

  return externalLineItemPromotions?.reduce((sum, promotion) => {
    return sum + (promotion.discountAmount ?? 0);
  }, 0);
};

export const mapPointChangeToPointChangeReceipt = (pointChange: PointChangeFragment): PointChangeReceipt => {
  return {
    id: pointChange.id,
    quantity: pointChange.quantity,
    date: pointChange.createdAt,
    reason: getPointChangeComboValues({
      notes: pointChange.notes,
      type: pointChange.type,
      source: pointChange.source,
      offer: pointChange.offer,
    }).title,
  };
};

const formatAddress = (location: ActivateTransactionFragment['location']): string | null => {
  if (!location) {
    return null;
  }

  const { address1, city, state, zipcode } = location;
  return `${address1}, ${city} ${state} ${zipcode ?? ''}`.trimEnd();
};

const tenderTypetoPaymentInstrumentTypeMap = {
  cash: 'CASH' as const,
  creditCards: PaymentInstrumentType.Credit,
  debitCards: PaymentInstrumentType.Debit,
  prepaidCards: PaymentInstrumentType.Prepaid,
};

const getPaymentInstrumentTypeFromTransaction = (
  paymentInfo: ActivateTransactionFragment['paymentInfos'],
): TransactionPaymentInstrumentType => {
  if (!paymentInfo || !paymentInfo.length) {
    return PaymentInstrumentType.Unknown;
  }

  if (paymentInfo.length > 1) {
    return 'SPLIT_PAYMENT';
  }

  if (!paymentInfo[0].tenderSubtype && !paymentInfo[0].tenderType) {
    return PaymentInstrumentType.Unknown;
  }

  if (!paymentInfo[0].tenderSubtype) {
    const tenderType = paymentInfo[0].tenderType as keyof typeof tenderTypetoPaymentInstrumentTypeMap;
    return tenderTypetoPaymentInstrumentTypeMap[tenderType] as TransactionPaymentInstrumentType;
  }

  return paymentInfo[0].tenderSubtype as PaymentInstrumentType;
};

export const mapActivateTransactionToTransactionReceipt = (
  transaction: ActivateTransactionFragment,
): ActivateTransactionReceipt => {
  const filteredLineItems = transformLineItems(transaction.lineItems);

  return {
    id: transaction.id,
    date: transaction.createdAt,
    locationName: transaction.location?.name ?? null,
    address: formatAddress(transaction.location),
    totalTax: transaction.totalTax,
    subtotalAmount: transaction.subtotalAmount,
    totalAmount: transaction.totalAmount || 0,
    totalAmountWithoutDiscounts: transaction.totalAmountWithoutDiscounts || 0,
    totalDiscountAmount: transaction.totalDiscountAmount,
    lineItems: filteredLineItems,
    pointChangeItems: mapPointsToPointChangeItems({
      totalPointsEarned: transaction.totalPointsEarned,
      totalPointsSpent: transaction.totalPointsSpent,
      totalPointsRefunded: transaction.totalPointsRefunded,
    }),
    numberOfAppliedDiscountOffers: getNumberOfAppliedDiscountOffersFromLineItems(filteredLineItems),
    paymentInstrumentType: getPaymentInstrumentTypeFromTransaction(transaction.paymentInfos),
  };
};

export const getTransactionPrimaryFuelingStatus = (
  primary: PrimaryTransactionStatus,
  secondary: SecondaryTransactionStatus,
): PrimaryInternalFuelingStatus => {
  const status = find(externalStatusToInternalStatus, ({ primaries, secondaries }) => {
    return primaries.includes(primary) && secondaries.includes(secondary);
  });

  return status?.internalStatus || PrimaryInternalFuelingStatus.Unknown;
};

export interface BuildPayAtPumpTransactionStartInput {
  transactionType: TransactionType.AtPump;
  paymentType: PAYMENT_INSTRUMENT_TYPE | null;
  locationId: string;
  siteId: string;
  fuelingPosition: string | null;
  carWash: CarWashInput | null;
  printReceipt: boolean;
  paymentInstrument: PaymentInstrument | null;
  token: string;
  nonce: string | null;
  kountSessionId: string | null;
  pinCode: string | null;
}

export interface BuildPayInsideTransactionStartInput {
  transactionType: TransactionType.InStore;
  paymentType: 'CARD';
  paymentInstrument: PaymentInstrument;
  kountSessionId: string | null;
  pinCode: string;
}

export const buildTransactionStartInput = (
  params: BuildPayAtPumpTransactionStartInput | BuildPayInsideTransactionStartInput,
): TransactionStart => {
  const emptyParams = {
    transactionType: params.transactionType,
    kountSessionId: params.kountSessionId,
    locationUuid: null,
    siteId: null,
    printReceipt: null,
    fuelingPosition: null,
    carWash: null,

    // TODO finish all data collecting
    loyaltyInstruments: null,
    items: null,
    stac: null,
    nonce: null,
    initiationResource: null,
    geoLocation: null,
    promptForCarWash: null,
    loyaltyIdentifier: null,

    paymentInstruments: null,
    paymentInstrumentType: null,
    billingZipCode: null,
    paymentToken: null,
    pinCode: null,
  };

  const transactionStartParams: TransactionStart =
    params.transactionType === TransactionType.AtPump
      ? {
          ...emptyParams,
          locationUuid: params.locationId || null,
          siteId: params.siteId || null,
          printReceipt: params.printReceipt,
          fuelingPosition: params.fuelingPosition || null,
          carWash: params.carWash || null,
        }
      : { ...emptyParams };

  if (params.transactionType === TransactionType.AtPump) {
    if (params.nonce) {
      transactionStartParams.nonce = params.nonce;
      if (params.paymentType === 'APPLE_PAY') {
        transactionStartParams.paymentInstrumentType = PaymentInstrumentType.ApplePay;
      }
      if (params.paymentType === 'GOOGLE_PAY') {
        transactionStartParams.paymentInstrumentType = PaymentInstrumentType.GooglePay;
      }
    } else {
      if (params.paymentType === 'APPLE_PAY') {
        transactionStartParams.paymentToken = { applePay: params.token, googlePay: null };
      }
      if (params.paymentType === 'GOOGLE_PAY') {
        transactionStartParams.paymentToken = { googlePay: params.token, applePay: null };
      }
    }
  }

  if (params.paymentType === 'CARD' && params.paymentInstrument) {
    transactionStartParams.paymentInstruments = [params.paymentInstrument.uuid];
    transactionStartParams.paymentInstrumentType = params.paymentInstrument.paymentType;
    transactionStartParams.billingZipCode = params.paymentInstrument?.address?.zipCode || null;
  }
  if (params.pinCode !== null) {
    transactionStartParams.pinCode = { pinCode: params.pinCode };
  }

  return transactionStartParams;
};

export const getKountSessionId = async (): Promise<string | null> => {
  try {
    await KountUniversal.collect();
  } catch (e: any) {
    Sentry.captureException(e, {
      extra: {
        sessionId: KountUniversal.sessionId,
      },
    });
  }
  // Return Kount Session ID in any case
  return KountUniversal.sessionId;
};
