import React from 'react';
import Payment from 'payment';

import { PaymentInstrumentIcon } from '@ocx-app/components/payment-instrument-icon';
import { PaymentInstrumentType } from '@ocx/graphql';
import { TextField, TextFieldProps } from '../../TextField';

export type PaymentsJSCardType =
  | 'visa'
  | 'mastercard'
  | 'discover'
  | 'amex'
  | 'jcb'
  | 'dinersclub'
  | 'maestro'
  | 'laser'
  | 'unionpay'
  | 'elo'
  | 'hipercard';

const PaymentsJStoPaymentInstrumentType: Record<PaymentsJSCardType, PaymentInstrumentType> = {
  amex: PaymentInstrumentType.AmericanExpress,
  dinersclub: PaymentInstrumentType.DinersClub,
  discover: PaymentInstrumentType.Discover,
  elo: PaymentInstrumentType.Elo,
  hipercard: PaymentInstrumentType.Unknown,
  jcb: PaymentInstrumentType.Jcb,
  laser: PaymentInstrumentType.Laser,
  maestro: PaymentInstrumentType.Maestro,
  mastercard: PaymentInstrumentType.Mastercard,
  unionpay: PaymentInstrumentType.ChinaUnionPay,
  visa: PaymentInstrumentType.Visa,
};

export const CardNumberField: React.FC<TextFieldProps> = (props) => {
  /**
   * Attaches Payment to input element. Since we don't need a reference to the `<input />` after the initial mount, use
   * the classic callback-style ref pattern rather than the newer `useRef()` approach.
   *
   * We can probably extract this to a `usePaymentRef()` hook if we use this lib to drive other payment-related fields
   * such as expiry and CVV.
   */
  const attachFormatter = React.useCallback((input?: HTMLInputElement) => {
    if (!input) {
      return;
    }
    Payment.formatCardNumber(input);
  }, []);

  /**
   * Payment exposes card type as a classname on the `<input />` element. That would require a DOM query on every
   * input event, so track it in state instead.
   */
  const [cardType, setCardType] = React.useState<PaymentInstrumentType | null>(null);
  const onInputChange = React.useCallback<React.FormEventHandler<HTMLInputElement>>((event) => {
    const paymentJsCardType: string | null = Payment.fns.cardType(event.currentTarget.value);
    setCardType(
      paymentJsCardType
        ? PaymentsJStoPaymentInstrumentType[paymentJsCardType as PaymentsJSCardType]
        : PaymentInstrumentType.Unknown,
    );
  }, []);

  return (
    <TextField
      fullWidth
      type="text"
      autoComplete="on"
      inputProps={{
        inputMode: 'numeric',
        autoComplete: 'on',
        autocompletetype: 'cc-number',
        pattern: '\\d*',
        onChange: onInputChange,
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        endAdornment: <PaymentInstrumentIcon type={cardType || PaymentInstrumentType.Unknown} />,
      }}
      fieldProps={{
        format: Payment.fns.formatCardNumber,
        formatOnBlur: true,
      }}
      label="Card Number"
      inputRef={attachFormatter}
      {...props}
    />
  );
};
