import { Box, Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography, SizingMultiplier } from '@ocx/ui';

import { StoreAddress } from '../../../components/StoreAddress/StoreAddress';
import { CarWash } from './components/CarWash';
import { OptionItem } from './components/OptionItem';
import { IReceiptOption } from '../../../modules/transaction/types';
import { buttonTextMessages } from '../../../modules/localization/button-text.messages';

export type ReceiptProps = {
  total: number;
  addressLine: string;
  carWashCode?: string | null;
  options: IReceiptOption[];
  receiptLines: string[];
  gratitudeLabelHidden?: boolean;
  onCloseButtonClick?(): void;
};

const TransactionReceiptHeader = ({
  total,
  gratitudeLabelHidden,
}: {
  total: number;
  gratitudeLabelHidden?: boolean;
}) => {
  const intl = useIntl();

  return (
    <div>
      {!gratitudeLabelHidden && (
        <Typography variant="h5" paragraph textTransform="uppercase" align="center">
          <FormattedMessage
            id="receipt:headerTitle"
            defaultMessage="Thank you!"
            description="Receipt page header text"
          />
        </Typography>
      )}
      <Typography variant="h4" align="center">
        {intl.formatNumber(total, { style: 'currency', currency: 'USD', maximumFractionDigits: 2 })}
      </Typography>
    </div>
  );
};

export const TransactionReceiptLines = ({ lines }: { lines: string[] }) => {
  return (
    <Box px={SizingMultiplier.md} py={SizingMultiplier.sm} borderRadius={SizingMultiplier.md} bgcolor="background.box">
      {lines.map((line, index) => (
        <Typography
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}_${line}`}
          variant="caption"
          component="p"
          color="textSecondary"
          sx={{ overflowWrap: 'break-word' }}>
          {line}
        </Typography>
      ))}
    </Box>
  );
};

export const TransactionReceiptTotalLine = ({ total }: { total: number }) => {
  const intl = useIntl();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography variant="h5">
        <FormattedMessage id="receipt:totalRowText" defaultMessage="Total" description="Receipt page: total row text" />
      </Typography>
      <Typography variant="h5">
        {intl.formatNumber(total, { style: 'currency', currency: 'USD', maximumFractionDigits: 2 })}
      </Typography>
    </Box>
  );
};

export const Receipt = (props: ReceiptProps) => {
  const { total, addressLine, carWashCode, options, receiptLines, gratitudeLabelHidden, onCloseButtonClick } = props;

  return (
    <Stack p={SizingMultiplier.md} spacing={SizingMultiplier.lg}>
      <TransactionReceiptHeader total={total} gratitudeLabelHidden={gratitudeLabelHidden} />
      <Stack spacing={SizingMultiplier.sm}>
        <StoreAddress>{addressLine}</StoreAddress>
        {carWashCode && <CarWash>{carWashCode}</CarWash>}
      </Stack>
      <div>
        {options.map((option) => (
          <OptionItem key={option.title} option={option} />
        ))}
      </div>
      <TransactionReceiptTotalLine total={total} />
      <TransactionReceiptLines lines={receiptLines} />
      {onCloseButtonClick && (
        <Button variant="outlined" onClick={onCloseButtonClick}>
          <FormattedMessage {...buttonTextMessages['button-text:close']} />
        </Button>
      )}
    </Stack>
  );
};
