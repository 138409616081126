import { ZodError, ZodSchema, ZodIssue, ZodType } from 'zod';
import set from 'lodash/set';
import get from 'lodash/get';
import { ReactNode } from 'react';

export type Translator = (errorObj: ZodIssue) => string | ReactNode;
export interface ValidationError {
  [key: string]: ZodError | string[];
}

function normalizeValidationError(err: ZodError, translator?: Translator): ValidationError {
  return err.issues.reduce((errors, innerError) => {
    const { path, message } = innerError;
    const extendedPath = path.map((item) => (typeof item === 'number' ? `[${item}]` : item)).join('.');
    const el: ReturnType<Translator> = translator ? translator(innerError) : message;

    if (path === undefined) {
      return errors;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (errors.hasOwnProperty(extendedPath)) {
      const prev = get(errors, path);
      prev.push(el);
      set(errors, extendedPath, prev);
    } else {
      set(errors, extendedPath, [el]);
    }
    return errors;
  }, {});
}

export function makeValidate<T>(validator: ZodType<T>, translator?: Translator) {
  return async (values: T): Promise<ValidationError> => {
    try {
      await validator.parseAsync(values);
      return {};
    } catch (e: any) {
      return normalizeValidationError(e, translator);
    }
  };
}

export function makeValidateSync<T>(validator: ZodSchema<T>, translator?: Translator) {
  return (values: T): ValidationError => {
    try {
      validator.parse(values);
      return {};
    } catch (e: any) {
      return normalizeValidationError(e, translator);
    }
  };
}

/**
 * Return truncated string if length more than max
 * Can be used to set max value length for numeric fields
 * fieldProps={{ parse: value => maxLengthParser(value.toString(), 5) }}
 * @param limit
 * @param value
 */
export const maxLengthParser = (value: string, limit: number) => value.slice(0, limit);
export const getMaxLengthParser = (limit: number) => (value: string) => maxLengthParser(value, limit);
export const onlyNumbersParser = (value: string) => value.replace(/\D/g, '');
