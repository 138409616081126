import { useTheme } from '@mui/material';
import { CSSProperties, useMemo } from 'react';

const DEFAULT_RAYS_NUMBER = 16;
const DEFAULT_WIDTH = '200px';
const DEFAULT_HEIGHT = '200px';

const getPointOnCircle = (r: number, cx: number, cy: number, angle: number): number[] => {
  const rad = (angle * Math.PI) / 180;
  return [cx + r * Math.cos(rad), cy - r * Math.sin(rad)];
};

const getSvgPoint = (angle: number): number[] => {
  return getPointOnCircle(100, 50, 50, angle);
};

export type RisingSunProps = {
  /**
   * Back color of sun
   */
  backColor?: string;
  /**
   * Color of sun rays
   */
  raysColor?: string;
  /**
   * Number of sun rays
   */
  raysNumber?: number;
  /**
   * Sun xOrigin: 0 - 100
   * @defaultValue 50
   */
  xOrigin?: number;
  /**
   * Sun yOrigin: 0 - 100
   * @defaultValue 50
   */
  yOrigin?: number;
  /**
   * Side Fading start position: 0 - 100
   */
  sideFadingStartPosition?: number;
  /**
   * Side Fading end position: 0 - 100
   */
  sideFadingEndPosition?: number;
  /**
   * Animate
   */
  animate?: boolean;
  style?: CSSProperties;
};

export const RisingSun = (props: RisingSunProps) => {
  const { palette } = useTheme();
  const backColor = props.backColor || palette.SpinWheel.risingSunBackColor;
  const raysColor = props.raysColor || palette.SpinWheel.risingSunRaysColor;

  const {
    raysNumber = DEFAULT_RAYS_NUMBER,
    xOrigin = 50,
    yOrigin = 50,
    sideFadingStartPosition = 0,
    sideFadingEndPosition = 0,
    animate = false,
    style,
  } = props;

  const segmentPath = useMemo(() => {
    const angle = 360 / (raysNumber * 2);
    return `M${xOrigin},${yOrigin} L${getSvgPoint(-angle / 2).join(',')} L${getSvgPoint(angle / 2).join(',')} Z`;
  }, [raysNumber, xOrigin, yOrigin]);

  return (
    <svg
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid slice"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        pointerEvents: 'none',
        width: DEFAULT_WIDTH,
        height: DEFAULT_HEIGHT,
        backgroundColor: backColor,
        ...style,
      }}>
      <defs>
        <radialGradient id="gradient" cx="50%" cy="50%" r="50%">
          <stop offset={`${sideFadingStartPosition || 0}%`} stopColor="white" />
          <stop offset={`${sideFadingEndPosition || 100}%`} stopColor="black" />
        </radialGradient>
        <mask id="mask" maskContentUnits="userSpaceOnUse">
          <rect x="0" y="0" width="100" height="100" fill="url(#gradient)" />
        </mask>
        <linearGradient id="fill" x1="0%" y1="0%" x2="50%" y2="0%">
          <stop offset="0%" style={{ stopColor: raysColor, stopOpacity: 0 }} />
          <stop offset="100%" style={{ stopColor: raysColor, stopOpacity: 1 }} />
        </linearGradient>
        <path id="segment" d={segmentPath} fill="url(#fill)" />
      </defs>
      <g mask={sideFadingStartPosition || sideFadingEndPosition ? 'url(#mask)' : ''}>
        {new Array(raysNumber).fill(0).map((_, index) => {
          const angle = 360 / raysNumber;
          const rotate = angle * index - 90;
          return (
            <use
              key={`${rotate}-${angle}`}
              href="#segment"
              x="0"
              y="0"
              transform={`rotate(${rotate}, ${xOrigin}, ${yOrigin})`}
            />
          );
        })}
        {animate ? (
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from={`0 ${xOrigin} ${yOrigin}`}
            to={`360 ${xOrigin} ${yOrigin}`}
            dur="60s"
            repeatCount="indefinite"
          />
        ) : null}
      </g>
    </svg>
  );
};
