import { FormattedMessage } from 'react-intl';
import { Paper } from '@mui/material';
import DoNotDisturbOnOutlined from '@mui/icons-material/DoNotDisturbOnOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';

import {
  List,
  ListHeader,
  ListItemButton,
  ListItemNavLink,
  ListItemStartIcon,
  ListItemText,
  SizingMultiplier,
} from '@ocx/ui';
import { AppRoutePath } from '@ocx-app/pages/AppRoutePath';

import { profileMessages as messages } from '../profile.messages';

export type AccountActionsListProps = {
  isDeleteAccountEnabled: boolean;
  onLogoutClick: () => void;
};

export const AccountActionsList = ({ isDeleteAccountEnabled, onLogoutClick }: AccountActionsListProps) => {
  return (
    <Paper elevation={0} sx={{ overflow: 'hidden', paddingTop: SizingMultiplier.md }}>
      <ListHeader>
        <FormattedMessage {...messages['profile:accountSection:title']} />
      </ListHeader>

      <List>
        {isDeleteAccountEnabled && (
          <ListItemNavLink to={AppRoutePath.DeleteAccount}>
            <ListItemStartIcon>
              <DoNotDisturbOnOutlined />
            </ListItemStartIcon>
            <ListItemText>
              <FormattedMessage {...messages['profile:accountActions:deleteAccountButtonTitle']} />
            </ListItemText>
          </ListItemNavLink>
        )}
        <ListItemButton onClick={onLogoutClick}>
          <ListItemStartIcon>
            <LogoutOutlined />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...messages['profile:accountActions:signOutButtonTitle']} />
          </ListItemText>
        </ListItemButton>
      </List>
    </Paper>
  );
};
