import { OfferEngagementType, OfferPresentationPriority } from '@ocx/graphql';
import { EarningMechanismType } from './earning-mechanism.types';
import {
  CarouselOffer,
  CustomDataWithAction,
  FeaturedOffer,
  Offer,
  OfferWithExternalUrl,
  PromocodesOffer,
  PurchaseVoucherOffer,
  SpinWheelGameOffer,
  SweepstakesPointsOffer,
  SweepstakesProductOffer,
} from './offer.types';
import { AwardType } from './award.types';

export const isCustomDataWithAction = (customData: Offer['customData']): customData is CustomDataWithAction => {
  return !!customData?.action;
};

export const isOfferWithExternalUrl = (offer: Offer): offer is OfferWithExternalUrl => {
  return !!offer.marketingContent.externalUrl;
};

export const isPromocodesOffer = (offer: Offer): offer is PromocodesOffer => {
  return (
    offer.earningMechanism?.type === EarningMechanismType.Promocodes &&
    offer.engagementType === OfferEngagementType.Promocode
  );
};

export const isPurchaseVoucherOffer = (offer: Offer): offer is PurchaseVoucherOffer => {
  if (
    offer.engagementType !== OfferEngagementType.Purchaseable ||
    offer.earningMechanism?.type !== EarningMechanismType.PurchaseVoucher
  ) {
    return false;
  }
  return true;
};

export const isSweepstake = (engagementType: OfferEngagementType | null): boolean => {
  return [
    OfferEngagementType.SweepstakesEntry,
    OfferEngagementType.SweepstakesTransactional,
    OfferEngagementType.SweepstakesPurchasable,
  ].some((et) => et === engagementType);
};

export const isSpinWheelGameOffer = (offer: Offer): offer is SpinWheelGameOffer => {
  return offer.earningMechanism?.type === EarningMechanismType.SpinWheelGame;
};

export const isOfferSweepstakesPoints = (offer: Offer): offer is SweepstakesPointsOffer => {
  if (offer.engagementType !== OfferEngagementType.SweepstakesPurchasable) {
    return false;
  }

  if (offer.earningMechanism?.type !== EarningMechanismType.PurchaseSweepstakesEntry) {
    // Log it to sentry
    return false;
  }

  return true;
};

export const isOfferSweepstakesProduct = (offer: Offer): offer is SweepstakesProductOffer => {
  if (offer.engagementType !== OfferEngagementType.SweepstakesTransactional) {
    return false;
  }

  if (!offer.earningMechanism || !('__typename' in offer.earningMechanism)) {
    return false;
  }

  // Command center allows to setup different EMs for sweepstakes,
  // but only EarningMechanismProductPurchase is supported for current iteration
  if (offer.earningMechanism.__typename === 'EarningMechanismProductPurchase') {
    if (offer.earningMechanism.award?.type === AwardType.SweepstakeEntries) {
      return true;
    }
  }

  return false;
};

export const isVoucherOffer = (offer: { engagementType: OfferEngagementType | null }) =>
  offer.engagementType === OfferEngagementType.Vouchered;
export const isReadyToUseOffer = (offer: { engagementType: OfferEngagementType | null; voucherCount: number }) =>
  isVoucherOffer(offer) && offer.voucherCount > 0;
export const isFeaturedOffer = (offer: Offer): offer is FeaturedOffer =>
  offer.presentationPriority === OfferPresentationPriority.Featured;
export const isCarouselOffer = (offer: Offer): offer is CarouselOffer => {
  if (isVoucherOffer(offer)) {
    return false;
  }
  if (!offer.displayCategories.length) {
    return false;
  }
  return offer.presentationPriority === OfferPresentationPriority.Default;
};

export const isDiscountEarningMechanism = (__typename: string): boolean => {
  return [
    'EarningMechanismProductDiscount',
    'EarningMechanismTransactionDiscount',
    'EarningMechanismVarietyDiscount',
  ].includes(__typename);
};
