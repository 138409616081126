import { FormattedMessage } from 'react-intl';
import { Paper } from '@mui/material';
import AppShortcutOutlined from '@mui/icons-material/AppShortcutOutlined';
import { generatePath } from 'react-router-dom';

import { List, ListItemText, ListItemStartIcon, ListItemNavLink } from '@ocx/ui';
import { AppRoutePath } from '@ocx-app/pages/AppRoutePath';

import { profileMessages } from '../profile.messages';

export const RedeemPromocodeMenuItem = () => {
  return (
    <Paper elevation={0} sx={{ overflow: 'hidden' }}>
      <List>
        <ListItemNavLink to={generatePath(AppRoutePath.RedeemPromocode, { code: '' })}>
          <ListItemStartIcon>
            <AppShortcutOutlined />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...profileMessages['profile:redeem-promocode:title']} />
          </ListItemText>
        </ListItemNavLink>
      </List>
    </Paper>
  );
};
