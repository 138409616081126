import React from 'react';

import { MapMarkerIcon } from '@ocx/ui-icons';
import { IconButton } from '@ocx/ui';

export interface IStoreLocatorButtonComponentProps {
  onClick: () => void;
}

export const StoreLocatorButtonComponent = (props: IStoreLocatorButtonComponentProps) => {
  const { onClick } = props;

  return (
    <IconButton onClick={onClick} color="inherit">
      <MapMarkerIcon />
    </IconButton>
  );
};
