import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Stack } from '@mui/material';
import { Loading, LoadingButton, SizingMultiplier, Typography } from '@ocx/ui';
import { useHtmlToImage } from '@ocx/html-to-image';
import { isNativePlatform, downloadAndShare, isIosPlatform } from '@ocx/utils-device';
import { ActivateTransactionReceiptLayout, ActivateTransactionReceipt } from '@ocx/ui-receipts';

import { useTransactionReceiptController } from './hooks/useTransactionReceiptController';
import { formatDateTime } from '../../lib/date/date';
import { useShowGenericErrorSnackbar } from '../../hooks/useShowGenericErrorSnackbar';
import { ReplaceToHome } from '../Home/ReplaceToHome';
import { DateTimeFormat } from '../../lib/date/date.types';
import { RECEIPT_DOWNLOAD_IMAGE_PADDING } from './constants';
import { EnvConfiguration } from '../../modules/configuration/EnvConfiguration';
import { messages } from './messages';

export const TransactionReceiptController = () => {
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const { transactionId = '' } = useParams<{ transactionId: string }>();
  const { loading, receipt } = useTransactionReceiptController(transactionId);
  const areGoogleFontsExcluded = isIosPlatform();
  const [receiptUrl, setReceiptRef] = useHtmlToImage(RECEIPT_DOWNLOAD_IMAGE_PADDING, areGoogleFontsExcluded);

  const receiptDate = formatDateTime(new Date(receipt?.date || Date.now()), DateTimeFormat.DateSlashedTimeFull);
  const receiptFileName = `receipt-${EnvConfiguration.retailer}-${receiptDate}.png`;
  const downloadAndShareReceipt = useCallback(async () => {
    if (!receiptUrl) {
      return;
    }
    try {
      await downloadAndShare({
        url: receiptUrl,
        filename: receiptFileName,
      });
    } catch {
      showGenericErrorSnackbar();
    }
  }, [receiptUrl, receiptFileName, showGenericErrorSnackbar]);

  if (!receipt && loading) {
    return (
      <ActivateTransactionReceiptLayout>
        <Stack flex={1} alignItems="center" justifyContent="center" spacing={SizingMultiplier.lg}>
          <Loading />
          <Typography variant="h6" color="primary.main">
            <FormattedMessage {...messages.receiptLoadingLabel} />
          </Typography>
        </Stack>
      </ActivateTransactionReceiptLayout>
    );
  }

  if (!receipt) {
    return <ReplaceToHome />;
  }

  return (
    <ActivateTransactionReceiptLayout>
      <ActivateTransactionReceipt
        setReceiptRef={setReceiptRef}
        locationName={receipt.locationName}
        address={receipt.address}
        date={receiptDate}
        totalAmount={receipt.totalAmount}
        subtotalAmount={receipt.subtotalAmount}
        totalAmountWithoutDiscounts={receipt.totalAmountWithoutDiscounts}
        totalDiscountAmount={receipt.totalDiscountAmount}
        lineItems={receipt.lineItems}
        totalTax={receipt.totalTax}
        numberOfAppliedDiscountOffers={receipt.numberOfAppliedDiscountOffers}
        pointChangeItems={receipt.pointChangeItems}
        paymentInstrumentType={receipt.paymentInstrumentType}
      />

      {isNativePlatform() ? (
        <LoadingButton sx={{ mx: SizingMultiplier.sm }} onClick={downloadAndShareReceipt} loading={!receiptUrl}>
          <FormattedMessage {...messages.downloadReceiptButtonLabel} />
        </LoadingButton>
      ) : (
        <LoadingButton
          sx={{ mx: SizingMultiplier.sm }}
          loading={!receiptUrl}
          download={receiptFileName}
          href={receiptUrl || ''}>
          <FormattedMessage {...messages.downloadReceiptButtonLabel} />
        </LoadingButton>
      )}
    </ActivateTransactionReceiptLayout>
  );
};
