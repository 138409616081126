import { useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { captureException } from '@sentry/react';
import configuration from '@ocx/buildtime-retailer-configuration/retailer.config.json';

import { useGetAppConfigQuery } from '@ocx/graphql';
import { isValidLocalRetailerConfiguration } from './utils/isValidLocalRetailerConfiguration';
import { EnvConfiguration } from './EnvConfiguration';
import { buildRuntimeConfig } from './utils/buildRuntimeConfig';
import { MembershipConfigOverrides } from '../membership/membership.types';
import { mergeRuntimeConfigWithMembershipOverrides } from './utils/mergeRuntimeConfigWithMembershipOverrides';
import { retailerConfigurationFallback } from './app-config.constants';
import { IConfigurationContextValue } from './app-config.context';

export const useCreateConfigurationContext = (): IConfigurationContextValue => {
  const localRetailerConfiguration = useMemo(() => {
    if (isValidLocalRetailerConfiguration(configuration)) {
      return configuration;
    }
    return retailerConfigurationFallback;
  }, []);
  const [membershipOverrides, setMembershipOverrides] = useState<MembershipConfigOverrides | null>(null);

  const handleLoadingError = (error: ApolloError) => {
    captureException(error, {
      level: 'fatal',
    });
  };

  const { data, error, refetch } = useGetAppConfigQuery({
    // `network-only` because we use it in context
    fetchPolicy: 'network-only',
    onError: handleLoadingError,
  });

  const config = useMemo(() => {
    // R TODO: we are protected from nulls using the AppLoadingGuard
    // "null" is used because application will not start without remote config.
    if (!data?.appConfig) {
      return null;
    }

    const runtimeConfig = buildRuntimeConfig({
      remoteConfig: data.appConfig,
      envConfig: EnvConfiguration,
      localRetailerConfig: localRetailerConfiguration,
    });

    if (membershipOverrides) {
      return mergeRuntimeConfigWithMembershipOverrides(runtimeConfig, membershipOverrides);
    }

    return runtimeConfig;
  }, [localRetailerConfiguration, membershipOverrides, data?.appConfig]);

  return useMemo(
    () => ({
      error,
      loaded: !!(config || error),
      config,
      setMembershipOverrides,
      refetch,
    }),
    [config, error, refetch],
  );
};
