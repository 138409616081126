import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { isApolloError } from '@apollo/client';
import { isErrorHandledGlobally } from '@ocx-app/modules/global-error-handler';
import { formatApolloError } from '@ocx/apollo-client';
import { useSnackbar } from '../lib/snackbar/snackbar';
import { genericMessages } from '../modules/localization/genericMessages';

export const useShowGraphqlUserVisibleErrorSnackbar = () => {
  const intl = useIntl();
  const snackbar = useSnackbar();

  return useCallback(
    (error: unknown) => {
      if (isErrorHandledGlobally(error)) {
        return;
      }

      const message =
        error instanceof Error && isApolloError(error)
          ? formatApolloError(error, { ignoreGraphqlError: true, ignoreNetworkError: true })
          : intl.formatMessage(genericMessages['generic:errorMessageText']);
      snackbar.open(message, {
        variant: 'error',
      });
    },
    [intl, snackbar],
  );
};
