import React, { ReactNode } from 'react';
import { Stack, SxProps } from '@mui/material';

import { PoweredByOpenCommerce, ScreenLoaderBackdrop } from '@ocx/ui';

export type AddCardLayoutProps = {
  loading: boolean;
  children: ReactNode;
  wrapperSx?: SxProps;
};

export const AddCardLayout = ({ loading, children, wrapperSx }: AddCardLayoutProps) => (
  <>
    <ScreenLoaderBackdrop open={loading} />
    <Stack justifyContent="space-between" flexGrow="1" overflow="auto" sx={wrapperSx}>
      {children}
      <PoweredByOpenCommerce />
    </Stack>
  </>
);
