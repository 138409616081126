import { FormattedMessage } from 'react-intl';
import { alpha, Theme } from '@mui/material';
import { SupportLink } from '@ocx/feat-support';

export const ServiceLink = () => {
  return (
    <SupportLink
      typographyProps={{
        variant: 'body1',
        color: 'textSecondary',
        align: 'center',
        sx: {
          // based on default opacity used for MUI Link with underline decorator
          textDecoration: (theme: Theme) => `underline ${alpha(theme.palette.text.secondary, 0.4)}`,
        },
      }}>
      <FormattedMessage
        id="actionSheet:serviceLinkTitle"
        defaultMessage="Need help?"
        description="Service link title"
      />
    </SupportLink>
  );
};
