import { Capacitor } from '@capacitor/core';

import { isAndroidPlatform } from '@ocx/utils-device';
import { ButtonOptions, Environment } from './types';
import { GooglePay } from './GooglePay';
import { GooglePayPluginWeb } from './web';
import { DEFAULT_ANDROID_BUTTON_COLOR } from './constants';
import styles from './GooglePayButton.module.css';

export class GooglePayUniversal {
  static environment: Environment | null = null;

  // WEB Only
  static init(environment: Environment) {
    this.environment = environment;
    if (Capacitor.isNativePlatform()) {
      return Promise.resolve();
    }
    return GooglePay.init(environment);
  }

  static isReadyToPay = GooglePay.isReadyToPay;

  static requestPay = GooglePay.requestPay;

  static async getButton(params: { onClick?: () => void; buttonColor?: ButtonOptions['buttonColor'] }) {
    const { onClick, buttonColor = 'white' } = params;
    const paymentsClient = await this.getPaymentsClient();

    if (paymentsClient === null) {
      return null;
    }

    const options: ButtonOptions = {
      buttonColor: isAndroidPlatform() ? DEFAULT_ANDROID_BUTTON_COLOR : buttonColor,
      buttonType: 'plain',
      buttonSizeMode: 'fill',
      onClick: () => null,
    };

    if (onClick) {
      options.onClick = onClick;
    }

    const button = paymentsClient.createButton(options);
    button.classList.add(styles.buttonContainer);

    if (isAndroidPlatform()) {
      button.classList.add(styles.androidNativeButtonContainer);
    }

    return button;
  }

  // Used for getting GooglePay button only
  private static async getPaymentsClient() {
    if (!this.environment) {
      return null;
    }

    if (!Capacitor.isNativePlatform()) {
      return GooglePay.getPaymentsClient();
    }

    const plugin = new GooglePayPluginWeb();
    await plugin.init(this.environment); // Web plugin does not work without any environment
    return plugin.getPaymentsClient();
  }
}
