import { useTheme } from '@mui/material';
import QRCode, { QRCodeRenderersOptions } from 'qrcode';
import React, { useEffect, useRef } from 'react';

export const QR_CODE_CANVAS_SIZE = 184;

type QRCodeProps = {
  text: string;
  canvasSize?: number;
  options?: QRCodeRenderersOptions;
  className?: string;
};

export const QRCodeComponent: React.FC<QRCodeProps> = ({
  text,
  options,
  canvasSize = QR_CODE_CANVAS_SIZE,
  className,
}) => {
  const qrCanvas = useRef<HTMLCanvasElement | null>(null);
  const { palette } = useTheme();

  useEffect(() => {
    if (qrCanvas.current) {
      QRCode.toCanvas(qrCanvas.current, text, {
        margin: 2,
        width: canvasSize,
        color: {
          dark: palette.common.black,
          light: palette.common.white,
        },
        ...options,
      });
    }
  }, [canvasSize, options, palette.common.black, palette.common.white, text]);

  return <canvas ref={qrCanvas} width={canvasSize} height={canvasSize} className={className} />;
};

export default QRCodeComponent;
