import React from 'react';
import { Divider, Stack } from '@mui/material';
import { useIntl } from 'react-intl';

import { Paper, SizingMultiplier, Typography } from '@ocx/ui';
import { formatPrice, convertDollarsToCents } from '@ocx-app/lib/formatters/formatPrice';

import { ReceiptLineItem } from './components/receipt-line-item.components';
import { ReceiptBodyLineItem } from '../../receipts.types';
import { receiptLabels } from './receipt-labels.messages';
import { TwoColsTypography } from '../two-cols-typography';

export type ReceiptBodyProps = {
  lineItems: ReceiptBodyLineItem[];
  total: number | null;
  subtotal: number | null;
  tax: number | null;
  totalDiscount: number | null;
};

export const ReceiptBody: React.FC<ReceiptBodyProps> = (props) => {
  const { lineItems, total, subtotal, tax, totalDiscount } = props;
  const intl = useIntl();

  return (
    <Stack component={Paper} spacing={SizingMultiplier.md} divider={<Divider />}>
      <Typography variant="h5" textTransform="none">
        {intl.formatMessage(receiptLabels['receipt-labels:title'])}
      </Typography>
      {lineItems.map(({ id, name, amount, quantity, promotions, externalPromotionsTotalDiscount, fuelGradeId }) => {
        return (
          <ReceiptLineItem
            key={id}
            title={name}
            value={amount}
            quantity={quantity}
            promotions={promotions}
            externalPromotionsTotalDiscount={externalPromotionsTotalDiscount}
            unit={fuelGradeId ? 'gal' : ''} //There may be additional units of measurement in the future.
          />
        );
      })}

      {typeof subtotal === 'number' && (
        <TwoColsTypography
          variant="subtitle1"
          title={intl.formatMessage(receiptLabels['receipt-labels:subtotal'])}
          value={formatPrice(convertDollarsToCents(subtotal))}
        />
      )}

      {typeof totalDiscount === 'number' && (
        <TwoColsTypography
          variant="subtitle1"
          title={intl.formatMessage(receiptLabels['receipt-labels:total-discount'])}
          value={formatPrice(convertDollarsToCents(-totalDiscount))}
        />
      )}

      <div>
        {typeof tax === 'number' && (
          <TwoColsTypography
            variant="body2"
            color="text.disabled"
            title={intl.formatMessage(receiptLabels['receipt-labels:tax'])}
            value={formatPrice(convertDollarsToCents(tax))}
          />
        )}
        {typeof total === 'number' && (
          <TwoColsTypography
            variant="subtitle1"
            textTransform="uppercase"
            title={intl.formatMessage(receiptLabels['receipt-labels:total'])}
            value={formatPrice(convertDollarsToCents(total))}
          />
        )}
      </div>
    </Stack>
  );
};
