import { Navigate, useParams } from 'react-router-dom';

import { ScreenLoaderBackdrop } from '@ocx/ui';

import { SurveyDetails } from './survey-details';
import { useSurveyDetailsController } from './survey-details.controller.hook';

type SurveyDetailsControllerProps = {
  returnPath: string;
};

export const SurveyDetailsController = ({ returnPath }: SurveyDetailsControllerProps) => {
  const { surveyUuid = '' } = useParams<{ surveyUuid: string }>();

  const { sanitizedSurvey, loading, handleClose, handleExit, isOpen } = useSurveyDetailsController({
    surveyUuid,
    returnPath,
  });

  if (!sanitizedSurvey && loading) {
    return <ScreenLoaderBackdrop open />;
  }

  if (!sanitizedSurvey) {
    return <Navigate to={returnPath} replace />;
  }

  return <SurveyDetails isOpen={isOpen} onClose={handleClose} onExit={handleExit} sanitizedSurvey={sanitizedSurvey} />;
};
