import React from 'react';
import { Stack } from '@mui/material';

import { DateTimeFormat } from '@ocx-app/lib/date/date.types';
import { formatDateTime } from '@ocx-app/lib/date/date';
import { PointsStrategy } from '@ocx/graphql';
import { PointsChange } from '@ocx-app/components/PointsChange/PointsChange';
import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';
import { SizingMultiplier, Typography, Paper } from '@ocx/ui';

import { PointsChangeReasonCard } from '../components/points-change-reason-card';

export type PointsChangeReceiptProps = {
  quantity: number;
  date: string;
  reason: string;
};

export const PointsChangeReceipt: React.FC<PointsChangeReceiptProps> = (props) => {
  const { quantity, date, reason } = props;
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy') as Exclude<
    PointsStrategy,
    PointsStrategy.NotUsed
  >;

  return (
    <Stack spacing={SizingMultiplier.sm} p={SizingMultiplier.sm}>
      <Paper sx={{ p: SizingMultiplier.md, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <PointsChange
          typographyProps={{ variant: 'h2' }}
          iconSize="lg"
          pointsStrategy={pointsStrategy}
          points={quantity}
          iconPosition="right"
          iconMode="multiple"
        />
        <Typography textAlign="center" variant="body2" component="span" color="text.disabled">
          {formatDateTime(new Date(date), DateTimeFormat.DateTimeFull)}
        </Typography>
      </Paper>
      <PointsChangeReasonCard title={reason} />
    </Stack>
  );
};
