import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'transaction-history:transactions:html-title': {
    id: 'transaction-history:transactions:html-title',
    defaultMessage: 'Mobile Payments',
    description: 'HTML title tag for Transaction History: Mobile Payments tab',
  },
  'transaction-history:transactions:tab-title': {
    id: 'transaction-history:transactions:tab-title',
    defaultMessage: 'Mobile Payments',
    description: 'Transaction History: Mobile Payments tab title',
  },
  'transaction-history:loyalty-activities:html-title': {
    id: 'transaction-history:loyalty-activities:html-title',
    defaultMessage: 'Loyalty Transactions',
    description: 'HTML title tag for Transaction History: Loyalty Transactions page',
  },
  'transaction-history:loyalty-activities:tab-title': {
    id: 'transaction-history:loyalty-activities:tab-title',
    defaultMessage: 'Loyalty Transactions',
    description: 'Transaction History: Loyalty Transactions tab title',
  },
  'transaction-history:no-receipts-title': {
    id: 'transaction-history:no-receipts-title',
    defaultMessage: 'No receipts yet',
    description: 'Transaction History: Title for empty receipts list',
  },
  'transaction-history:no-receipts-description': {
    id: 'transaction-history:no-receipts-description',
    defaultMessage: 'Receipt will display here when transactions are made.',
    description: 'Transaction History: Description for empty receipts list',
  },
});
