import React, { useCallback, useMemo } from 'react';
import { LinearProgress, Stack } from '@mui/material';
import { Route, Routes, useMatch } from 'react-router-dom';

import { ScreenLoaderBackdrop } from '@ocx/ui';

import { useHomeController } from './hooks/useHomeController';
import { useGetNavigationActions } from '../../hooks/useGetNavigationActions';
import { useActivateOfferButtonHint } from './hooks/useActivateOfferButtonHint';
import { HomeLayout } from './HomeLayout';
import { useConfiguration } from '../../modules/configuration/useConfiguration';
import { ActivateOfferButtonHint } from './components/ActivateOfferButtonHint';
import { OffersCarouselController } from './features/offers-carousel';
import { PointsBox } from './components/PointsBox/PointsBox';
import { FeaturedOffersController } from './components/FeaturedOffersController';
import { RewardsController } from './components/RewardsController';
import { HowDoesItWorkCardController } from './components/HowDoesItWorkCardController';
import { BottomNavigationController } from './components/BottomNavigationController';
import { SizingMultiplier } from '../../theme/constants';
import { isCarouselOffer, isFeaturedOffer } from '../../modules/offers/offer.type-guards';
import { OfferDetailsController } from '../OfferDetails/offer-details.controller';
import { AppRoutePath } from '../AppRoutePath';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';
import { useCreateLoyaltyEventWithStatusModal } from '../../modules/events/hooks/useCreateLoyaltyEventWithStatusModal';
import { SurveyDetailsController } from '../SurveyDetails/survey-details.controller';

export const HomeController: React.FC = () => {
  const { triggerEvent } = useRudderStack();
  const surveyRouteMatch = useMatch(`${AppRoutePath.Home}${AppRoutePath.SurveyDetails}`);
  const shouldTriggerSurvey = !surveyRouteMatch;
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');

  const {
    isOffersLoading,
    isMembershipLoading,
    membership,
    rewardsQuantity,
    offers,
    totalAvailableRollbackMoneyAmount,
    isTotalAvailableRollbackMoneyAmountLoading,
    refreshAll,
  } = useHomeController(shouldTriggerSurvey);

  const activateOfferButtonHint = useActivateOfferButtonHint();
  const { pushToTransactionHistory } = useGetNavigationActions();

  const { loading: isLoyaltyEventSubmitting } = useCreateLoyaltyEventWithStatusModal({ membershipId: membership.id });

  const isHomeScreenDataLoading = isOffersLoading || isTotalAvailableRollbackMoneyAmountLoading;
  const bottom = useMemo(
    () => (
      <div>
        {isHomeScreenDataLoading && <LinearProgress sx={{ opacity: 0.8 }} />}
        <BottomNavigationController />
      </div>
    ),
    [isHomeScreenDataLoading],
  );

  const pullToRefresh = useCallback(async () => {
    triggerEvent('pull_to_refresh');
    await refreshAll();
  }, [refreshAll, triggerEvent]);

  return (
    <HomeLayout bottom={bottom} onRefresh={pullToRefresh}>
      {/* TODO move "mt" to "layout" */}
      <Stack spacing={SizingMultiplier.sm} mt={SizingMultiplier.sm}>
        <PointsBox
          loading={isMembershipLoading}
          points={membership?.points}
          name={membership?.firstName}
          pointsStrategy={pointsStrategy}
          handlePointsClick={pushToTransactionHistory}
        />
        <HowDoesItWorkCardController />
        <RewardsController
          rewardsQuantity={rewardsQuantity}
          totalAvailableRollbackMoneyAmount={totalAvailableRollbackMoneyAmount}
        />
        <FeaturedOffersController offers={offers.filter(isFeaturedOffer)} />
        <OffersCarouselController offers={offers.filter(isCarouselOffer)} />
      </Stack>
      <ActivateOfferButtonHint coords={activateOfferButtonHint.coords} isOpen={activateOfferButtonHint.isOpen} />
      <ScreenLoaderBackdrop open={isLoyaltyEventSubmitting} />
      <Routes>
        <Route path={AppRoutePath.OfferDetails} element={<OfferDetailsController returnPath={AppRoutePath.Home} />} />
        <Route path={AppRoutePath.SurveyDetails} element={<SurveyDetailsController returnPath={AppRoutePath.Home} />} />
      </Routes>
    </HomeLayout>
  );
};

export default HomeController;
