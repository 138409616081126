import { useCallback } from 'react';

import { ExternalOfferFragment } from '@ocx/graphql';
import { ExternalBannerCard } from './components';
import { useLogOfferActivity } from '../../../../modules/external-loyalty';
import { useExternalBannerDetailsPopup } from '../../../../components/modals';

export type ExternalBannerCardControllerProps = {
  banner: ExternalOfferFragment;
};

export const ExternalBannerCardController = ({ banner }: ExternalBannerCardControllerProps) => {
  const { open: openExternalBannerDetailsPopup } = useExternalBannerDetailsPopup();
  const { logClick, logImpression } = useLogOfferActivity();

  const onBannerCardClick = useCallback(
    (trackingId: string) => {
      logClick(trackingId, banner.externalCampaignId, banner.bannerConfigurationUuid);
      openExternalBannerDetailsPopup({ banner });
    },
    [banner, logClick, openExternalBannerDetailsPopup],
  );

  const onImpression = useCallback(
    (offerId: string) => {
      logImpression(offerId, banner.externalCampaignId, banner.bannerConfigurationUuid);
    },
    [banner.bannerConfigurationUuid, banner.externalCampaignId, logImpression],
  );

  return (
    <ExternalBannerCard
      trackingId={banner.trackingId}
      content={banner.content}
      onImpression={onImpression}
      onClick={onBannerCardClick}
    />
  );
};
