import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ShoppingModeIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M12.4984 21.0734C12.1484 21.4234 11.7234 21.5984 11.2234 21.5984C10.7234 21.5984 10.2984 21.4234 9.94844 21.0734L2.92344 14.0484C2.57344 13.7034 2.39844 13.2797 2.39844 12.7772C2.39844 12.2747 2.57344 11.8484 2.92344 11.4984L11.5053 2.91589C11.684 2.73759 11.8798 2.60677 12.0926 2.52344C12.3054 2.4401 12.5323 2.39844 12.7734 2.39844H19.7984C20.2934 2.39844 20.7172 2.57469 21.0697 2.92719C21.4222 3.27969 21.5984 3.70344 21.5984 4.19844V11.2484C21.5984 11.4896 21.5526 11.7165 21.4609 11.9293C21.3693 12.1421 21.2401 12.3318 21.0734 12.4984L12.4984 21.0734ZM17.3984 8.09844C17.8151 8.09844 18.1693 7.9526 18.4609 7.66094C18.7526 7.36927 18.8984 7.0151 18.8984 6.59844C18.8984 6.18177 18.7526 5.8276 18.4609 5.53594C18.1693 5.24427 17.8151 5.09844 17.3984 5.09844C16.9818 5.09844 16.6276 5.24427 16.3359 5.53594C16.0443 5.8276 15.8984 6.18177 15.8984 6.59844C15.8984 7.0151 16.0443 7.36927 16.3359 7.66094C16.6276 7.9526 16.9818 8.09844 17.3984 8.09844Z" />
    </SvgIcon>
  );
};
