import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'receipt:loyalty-card:points-earned': {
    id: 'receipt:loyalty-card:points-earned',
    defaultMessage: 'Points Earned',
    description: 'Points earned from transaction',
  },
  'receipt:loyalty-card:points-spent': {
    id: 'receipt:loyalty-card:points-spent',
    defaultMessage: 'Points Spent',
    description: 'Points spent from transaction',
  },
  'receipt:loyalty-card:points-refund': {
    id: 'receipt:loyalty-card:points-refund',
    defaultMessage: 'Points Refund',
    description: 'Points refunded from transaction',
  },
  'receipt:loyalty-card:offers-applied': {
    id: 'receipt:loyalty-card:offers-applied',
    defaultMessage: '{appliedOffersNumber, plural, one {# offer} other {# offers}} applied',
    description: 'Number of offers applied to loyalty line item label',
  },
  'receipt:loyalty-card:saved': {
    id: 'receipt:loyalty-card:saved',
    defaultMessage: 'Total Saved This Visit',
    description: 'Saved money amount is difference between totalAmountWithoutDiscounts and totalAmount',
  },
});
