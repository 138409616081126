import { useCallback, useEffect } from 'react';
import { addBreadcrumb } from '@sentry/react';

/* eslint-disable @nx/enforce-module-boundaries */
import { useMembership } from '@ocx-app/modules/membership/use-membership.hook';
import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';
import { useEditProfilePopup } from '@ocx-app/components/modals';
import { useHowDoesItWork } from '@ocx-app/modules/tutorial/howDoesItWork/hooks/useHowDoesItWork';
import { useLogoutController } from '@ocx-app/modules/auth/useLogoutController';
import { useShowGenericErrorSnackbar } from '@ocx-app/hooks/useShowGenericErrorSnackbar';
import { logInDev } from '@ocx-app/lib/logger/logger';
/* eslint-enable @nx/enforce-module-boundaries */
import { ScreenLoaderBackdrop } from '@ocx/ui';

import { ProfileLayout } from './profile.layout';
import { MemberInformation } from './components/member-information.component';
import { LoyaltyDetailsList } from './components/loyalty-details-list.component';
import { AboutList } from './components/about-list.components';
import { AccountActionsList } from './components/account-actions-list.component';
import { AppVersion } from './components/app-version.component';
import { RedeemPromocodeMenuItem } from './components/redeem-promocode-menu-item.component';

export const ProfileController = () => {
  const { config } = useConfiguration();
  const showGenericErrorSnackbar = useShowGenericErrorSnackbar();
  const { membership, loading, refetch: refetchMembership } = useMembership();
  const { open: openEditProfilePopup } = useEditProfilePopup();
  const { logout } = useLogoutController({ onError: showGenericErrorSnackbar });
  const { handleHowDoesItWorkOfferClick, isHowDoesItWorkAvailable } = useHowDoesItWork();

  const onLogoutClick = useCallback(() => {
    logout().catch(logInDev);
  }, [logout]);

  useEffect(() => {
    addBreadcrumb({
      message: 'Profile: start refetch membership',
    });
    refetchMembership()
      .then((membership) => {
        addBreadcrumb({
          message: 'Profile: refetch membership success',
        });
      })
      .catch(() => {
        addBreadcrumb({
          message: 'Profile: refetch membership fail',
        });
      });
  }, [refetchMembership]);

  if (loading && !membership) {
    return (
      <ProfileLayout>
        <ScreenLoaderBackdrop open />
      </ProfileLayout>
    );
  }

  return (
    <ProfileLayout>
      <MemberInformation
        username={[membership.firstName, membership.lastName].join(' ').trim()}
        onClick={openEditProfilePopup}
        isLoading={loading}
      />
      {config.loyaltyProgram.isPromocodesEnabled && <RedeemPromocodeMenuItem />}
      <LoyaltyDetailsList
        isWalletEnabled={config.wallet.enabled}
        isCommunicationPreferencesEnabled={config.communicationPreference.enabled}
        isProductInterestsEnabled={config.productInterests.enabled}
        isPreferredFuelGradeEnabled={config.totalAvailableRollback.enabled}
      />
      <AboutList
        isHowDoesItWorkEnabled={isHowDoesItWorkAvailable}
        onHowDoesItWorkClick={handleHowDoesItWorkOfferClick}
      />
      <AccountActionsList isDeleteAccountEnabled={config.deleteAccount.enabled} onLogoutClick={onLogoutClick} />
      <AppVersion version={config.release} />
    </ProfileLayout>
  );
};
