import { v4 as uuid } from 'uuid';

import { wait } from '@ocx/utils';
import { Kount } from './Kount';
import { IKountPluginInitCollectorParams, KountCollectorStatus } from './types';

export type KountUniversalInitParams = Omit<IKountPluginInitCollectorParams, 'sessionId'>;

const kountCollectorStatusAttemptsLimit = 10;
const kountCollectorStatusAttemptsInterval = 1000; // 1sec

export class KountUniversal {
  // sessionId used during starting transaction
  static sessionId: string | null = null;

  static isEnabled = false;

  // Max sessionID length is 32. UUIDv4 length without dashes is 32
  static generateSessionId = () => uuid().replace(/-/g, '');

  static async init(params: KountUniversalInitParams): Promise<{ success: boolean }> {
    const result = await Kount.initCollector({
      merchantId: params.merchantId,
      collectorHostname: params.collectorHostname,
      environment: params.environment,
    });

    if (result.success) {
      KountUniversal.isEnabled = true;
    }
    return result;
  }

  static async collect(): Promise<{ sessionId: string | null }> {
    if (!KountUniversal.isEnabled) {
      return { sessionId: null };
    }

    // Max sessionID length is 32. UUIDv4 length without dashes is 32
    KountUniversal.sessionId = KountUniversal.generateSessionId();
    await Kount.collect({ sessionId: KountUniversal.sessionId });
    const isReady = await KountUniversal.isCollectorReady();

    if (!isReady) {
      throw new Error('Kount DDC can not collect data');
    }

    return { sessionId: KountUniversal.sessionId };
  }

  static async isCollectorReady(count: number = 0): Promise<boolean> {
    const { status } = await Kount.getCollectorStatus();
    if (status === KountCollectorStatus.COMPLETED) {
      return true;
    }
    if (status === KountCollectorStatus.FAILED) {
      return false;
    }
    if (count >= kountCollectorStatusAttemptsLimit) {
      return false;
    }
    await wait(kountCollectorStatusAttemptsInterval);
    return KountUniversal.isCollectorReady(count + 1);
  }
}
