import { useCallback, useState } from 'react';

import { elementToImage } from './html-to-image';

type UseHtmlToImageResultType = [string | null, (element: Element) => Promise<void>];

export const useHtmlToImage = (padding?: number, areGoogleFontsExcluded?: boolean): UseHtmlToImageResultType => {
  const [url, setUrl] = useState<string | null>(null);

  const elementRef = useCallback(
    async (node: Element) => {
      if (node !== null && !url) {
        const dataUrl = await elementToImage(node, padding, areGoogleFontsExcluded);
        setUrl(dataUrl);
      }
    },
    [padding, url, areGoogleFontsExcluded],
  );

  return [url, elementRef];
};
