import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'wallet:size-limit:title': {
    id: 'wallet:size-limit:title',
    defaultMessage: 'You may add up to {sizeLimit} payment {sizeLimit, plural, one {method} other {methods}}.',
    description: 'Wallet size limit description',
  },
  'wallet:navigation-action:title': {
    id: 'wallet:navigation-action:title',
    defaultMessage: 'Go to Wallet',
    description: 'Navigation action (button or link etc) which leads customer to the Wallet page',
  },
  'wallet:size-limit-reached:pay-inside:title': {
    id: 'wallet:size-limit-reached:pay-inside:title',
    defaultMessage: "You've reached the limit.",
    description: 'Payment methods limit reached title on Pay Inside page',
  },
  'wallet:size-limit-reached:pay-inside:description': {
    id: 'wallet:size-limit-reached:pay-inside:description',
    defaultMessage: 'Delete an existing payment method to add a new one.',
    description: 'Payment methods limit reached description on Pay Inside page',
  },
  'wallet:size-limit-reached:snackbar:title': {
    id: 'wallet:size-limit-reached:snackbar:title',
    defaultMessage: "You've reached the limit. Delete an existing payment method to add a new one.",
    description: 'Popup title when user tries add a card out of the wallet size limit',
  },
  'wallet:add-payment-instrument-button:title': {
    id: 'wallet:add-payment-instrument-button:title',
    defaultMessage: 'Add payment method',
    description: 'Add payment instrument button title on Wallet page',
  },
});

export const paymentInstrumentBalanceLabel = defineMessages({
  'payments:payment-instrument:balance': {
    id: 'payments:payment-instrument:balance',
    defaultMessage: 'Balance {balance}',
    description: 'The label for payment instrument balance',
  },
});
