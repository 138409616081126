import React from 'react';
import { useModal } from '@ebay/nice-modal-react';

import { useSupportWidget } from '@ocx/feat-support';
import { useConfiguration } from '../../modules/configuration/useConfiguration';
import { ContactSupportView } from './contact-support.view';
import { ContactSupportLayout } from './contact-support.layout';

export const ContactSupportController = () => {
  const {
    isEnabled: isSupportWidgetEnabled,
    open: openSupportWidget,
    isLoading: isSupportWidgetLoading,
  } = useSupportWidget();

  const {
    config: { supportPhoneNumber, supportTeamEmail },
  } = useConfiguration();

  const modal = useModal();

  return (
    <ContactSupportLayout isOpen={modal.visible} onClose={modal.hide} onExited={modal.remove}>
      <ContactSupportView
        phoneNumber={supportPhoneNumber}
        email={supportTeamEmail}
        onCancel={modal.hide}
        isSupportWidgetLoading={isSupportWidgetLoading}
        isSupportWidgetEnabled={isSupportWidgetEnabled}
        onSupportWidgetClick={openSupportWidget}
      />
    </ContactSupportLayout>
  );
};
