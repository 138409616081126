import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'transactionReceipt:htmlTitle': {
    id: 'transactionReceipt:htmlTitle',
    defaultMessage: 'Transaction details',
    description: 'HTML title tag for Transaction details Page',
  },
  'transactionReceipt:navBar:title': {
    id: 'transactionReceipt:navBar:title',
    defaultMessage: 'Transaction details',
    description: 'Transaction details title',
  },
});
