import { CircularProgress, ButtonBase, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { SizingMultiplier, Typography } from '@ocx/ui';
import { ProfileIcon } from '@ocx/ui-icons';

import { profileMessages } from '../profile.messages';

export type MemberInformationProps = {
  username: string;
  onClick(): void;
  isLoading: boolean;
};

export const MemberInformation = ({ isLoading, username, onClick }: MemberInformationProps) => {
  return (
    <ButtonBase
      sx={{
        borderRadius: 4,
        backgroundColor: 'background.paper',
        p: SizingMultiplier.md,
        minHeight: 64,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
      onClick={onClick}>
      <ProfileIcon sx={{ color: 'action.active' }} />
      <Stack ml={SizingMultiplier.sm} mr="auto" alignItems="flex-start">
        <Typography component="span">{username}</Typography>
        <Typography variant="caption" color="textSecondary">
          <FormattedMessage {...profileMessages['profile:profileSection:subtitle']} />
        </Typography>
      </Stack>
      {isLoading && <CircularProgress size={20} />}
    </ButtonBase>
  );
};
