import React from 'react';
import { useIntl } from 'react-intl';

import { TextField, TextFieldProps } from '@ocx-app/components/TextField';
import { PaymentInstrumentIcon } from '@ocx-app/components/payment-instrument-icon';
import { PaymentInstrumentType } from '@ocx/graphql';
import { formatCardNumber, parseCardNumber } from './prepaid-card-number-field.utils';

export const PrepaidCardNumberField = (props: TextFieldProps) => {
  const intl = useIntl();

  return (
    <TextField
      autoComplete="on"
      inputProps={{
        inputMode: 'numeric',
        autoComplete: 'on',
        autocompletetype: 'cc-number',
        pattern: '\\d*',
      }}
      fieldProps={{
        format: formatCardNumber,
        parse: parseCardNumber,
      }}
      label={intl.formatMessage({
        id: 'payments:prepaid:card-number-field-label',
        defaultMessage: 'Card Number',
        description: 'Card Number field label',
      })}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        endAdornment: <PaymentInstrumentIcon type={PaymentInstrumentType.Prepaid} />,
      }}
      {...props}
    />
  );
};
