import React, { useCallback } from 'react';

import { ScreenLoader } from '@ocx/ui';

import { useReceiptController } from './hooks/useReceiptController';
import { Receipt } from './Receipt';
import { ReceiptLayout } from './ReceiptLayout';
import { useGetNavigationActions } from '../../../hooks/useGetNavigationActions';
import { ReplaceToHome } from '../../Home/ReplaceToHome';
import { useAppRatePendingState } from '../../../modules/app-rate/useAppRatePendingState';
import { useSurveys } from '../../../modules/survey/survey.context.hook';

type ReceiptControllerProps = {
  modal?: boolean;
  gratitudeLabelHidden?: boolean;
  safeAreaBottomOffsetEnabled?: boolean;
};

export const ReceiptController: React.FC<ReceiptControllerProps> = ({
  modal,
  gratitudeLabelHidden,
  safeAreaBottomOffsetEnabled,
}) => {
  const { pushToHome } = useGetNavigationActions();
  const { loading, receipt, options } = useReceiptController();
  const [, setAppRateIsPending] = useAppRatePendingState();
  const { fetchAndOpenSurveyModal } = useSurveys();
  const handleClose = useCallback(() => {
    pushToHome();
    fetchAndOpenSurveyModal();
    setAppRateIsPending(true);
  }, [fetchAndOpenSurveyModal, pushToHome, setAppRateIsPending]);

  if (!receipt && loading) {
    return (
      <ReceiptLayout modal={modal}>
        <ScreenLoader />
      </ReceiptLayout>
    );
  }

  if (!receipt) {
    return <ReplaceToHome />;
  }

  return (
    <ReceiptLayout modal={modal} safeAreaBottomOffsetEnabled={safeAreaBottomOffsetEnabled}>
      <Receipt
        gratitudeLabelHidden={gratitudeLabelHidden}
        addressLine={receipt.address}
        carWashCode={receipt.carWash ? receipt.carWash.code : null}
        receiptLines={receipt.receiptLines}
        total={receipt.total}
        options={options}
        onCloseButtonClick={modal ? undefined : handleClose}
      />
    </ReceiptLayout>
  );
};

export default ReceiptController;
