import { App as CapApp } from '@capacitor/app';
import { useCallback, useEffect, useState } from 'react';
import { isAndroidPlatform, isIosPlatform } from '@ocx/utils-device';
import { OnWindowFocusChangeEvent } from './types.d';

export const useIsAppFocused = () => {
  const [isAppFocused, setIsAppFocused] = useState(true);

  const handleAndroidWindowsFocusChange = useCallback((event: OnWindowFocusChangeEvent) => {
    setIsAppFocused(event.isFocused);
  }, []);

  useEffect(() => {
    let cleanup;

    if (isIosPlatform()) {
      const appStatusPromise = CapApp.addListener('appStateChange', (appState) => {
        setIsAppFocused(appState.isActive);
      });

      cleanup = () => {
        appStatusPromise.then((listener) => {
          listener.remove();
        });
      };
    }

    if (isAndroidPlatform()) {
      window.addEventListener('onWindowFocusChange', handleAndroidWindowsFocusChange);

      cleanup = () => {
        window.removeEventListener('onWindowFocusChange', handleAndroidWindowsFocusChange);
      };
    }

    return cleanup;
  }, [handleAndroidWindowsFocusChange]);

  return isAppFocused;
};
