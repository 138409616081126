import React from 'react';
import { Navigate } from 'react-router-dom';

import { RouteLoading } from '@ocx/ui';

import { AppRoutePath } from '../../../pages/AppRoutePath';
import { useMembershipContext } from './membership.context';
import { RedirectToRequestLink } from '../../../pages/Signup/RedirectToRequestLink';
import { Membership } from '../membership.types';

export type MembershipGuardPros = {
  children: React.ReactNode;
  _isRedirectRequired?(membership: Membership): boolean;
  redirectToPath?: string;
};

export const MembershipGuard: React.FC<MembershipGuardPros> = (props) => {
  const { children, _isRedirectRequired, redirectToPath = AppRoutePath.Home } = props;
  const { membership, loading } = useMembershipContext();

  if (loading && !membership) {
    return <RouteLoading />;
  }

  if (!membership) {
    return <RedirectToRequestLink />;
  }

  if (_isRedirectRequired?.(membership)) {
    return <Navigate to={redirectToPath} replace />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
