import { Stack } from '@mui/material';
import StoreIcon from '@mui/icons-material/Store';
import { FormattedMessage } from 'react-intl';

import { Paper, SizingMultiplier, Typography } from '@ocx/ui';

import { messages } from './receipt-store-address-card.messages';
import { TransactionIconBox } from '../transaction-icon-box';

type ReceiptStoreAddressCardProps = {
  name?: string | null;
  address: string | null;
};

export const ReceiptStoreAddressCard = ({ name, address }: ReceiptStoreAddressCardProps) => {
  return (
    <Stack component={Paper} direction="row" gap={SizingMultiplier.sm}>
      <TransactionIconBox>
        <StoreIcon sx={{ color: 'Receipts.iconColor' }} />
      </TransactionIconBox>
      <Stack flex={1} gap={SizingMultiplier.xs} justifyContent="center">
        <Typography variant="subtitle1" color="text.primary">
          {name || <FormattedMessage {...messages['receipt-store-address-card:default-name-label']} />}
        </Typography>
        <Typography variant="body2" color="text.disabled">
          {address}
        </Typography>
      </Stack>
    </Stack>
  );
};
