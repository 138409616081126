import { loadScript } from '@ocx-app/lib/scriptLoader/scriptLoader';
import { wait } from '@ocx/utils';

import { OpenFreshdeskSupportWidgetParams } from './freshdesk-widget.types';

export class FreshdeskSupportWidget {
  private static isLoaded: boolean = false;

  private static async init(params: {
    token: string;
    host: string;
    widgetUuid: string;
    sdkUrl: string;
    externalId?: string | null;
  }): Promise<void> {
    if (this.isLoaded) {
      return;
    }

    // Required CSP:
    // script-src: stuzo.freshchat.com
    // frame-src: *.freshchat.com
    // style-src: *.freshchat.com
    await loadScript(params.sdkUrl);

    window.fcWidget.init({
      token: params.token,
      host: params.host,
      widgetUuid: params.widgetUuid,
      externalId: params.externalId || undefined,
      fullscreen: true,
      config: {
        headerProperty: {
          hideChatButton: true,
        },
      },
    });

    window.fcWidget.on('widget:opened', () => {
      this.isOpening = false;
      const widgetElement = document.getElementById('fc_widget');
      if (!widgetElement) {
        return;
      }
      widgetElement.style.paddingTop = 'var(--ion-safe-area-top)';
      widgetElement.style.paddingBottom = 'var(--ion-safe-area-bottom)';
    });

    this.isLoaded = true;
  }

  public static async open(params: OpenFreshdeskSupportWidgetParams): Promise<void> {
    this.isOpening = true;
    await this.init({
      token: params.token,
      host: params.host,
      widgetUuid: params.widgetUuid,
      sdkUrl: params.sdkUrl,
      externalId: params.externalId,
    });

    if (params.firstName) {
      window.fcWidget.user.setFirstName(params.firstName);
    }
    if (params.lastName) {
      window.fcWidget.user.setLastName(params.lastName);
    }
    if (params.email) {
      window.fcWidget.user.setEmail(params.email);
    }

    window.fcWidget.open();
    await this.waitOpened();
  }

  private static isOpening = false;
  private static async waitOpened(): Promise<boolean> {
    if (!this.isOpening) {
      return true;
    }
    await wait(1000);
    return this.waitOpened();
  }
}
