import React from 'react';
import Verified from '@ocx/buildtime-retailer-configuration/assets/verified.svg';

export const VerifiedDecoration: React.FC = () => (
  <img
    style={{
      display: 'block',
      boxSizing: 'border-box',
      position: 'absolute',
      left: 0,
      right: 0,
      top: -65,
      margin: '0 auto',
    }}
    width={130}
    height={130}
    src={Verified}
    alt="Achievement"
  />
);
