import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  OCBase64Cursor: any;
  OCCountryCode2: any;
  OCDate: any;
  OCDateTime: any;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  OCEmailAddress: any;
  OCJSON: any;
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  OCPhoneNumber: any;
  OCPromocode: any;
  OCTime: any;
  OCTimeZone: any;
  OCURL: any;
  OCUUID: string;
};

export enum ActivatePaymentMethod {
  MobileApp = 'MOBILE_APP',
}

export enum ActivateTransactionSortBy {
  CreatedAt = 'CREATED_AT',
}

export enum AwardPerCountingStrategy {
  IndividualPurchase = 'INDIVIDUAL_PURCHASE',
  SellingUnitPurchase = 'SELLING_UNIT_PURCHASE',
  TotalPurchase = 'TOTAL_PURCHASE',
}

export enum AwardPointsRoundingStrategy {
  Down = 'DOWN',
  HalfUp = 'HALF_UP',
}

export type AccessTokenByRefreshTokenInput = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type ActivateLineItemPromotion = {
  offer: Maybe<Offer>;
  promotionAmount: Scalars['Float'];
  promotionReason: Scalars['String'];
};

export type ActivateLocation = {
  address1: Maybe<Scalars['String']>;
  address2: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['OCCountryCode2']>;
  createdAt: Scalars['OCDateTime'];
  id: Scalars['ID'];
  lat: Maybe<Scalars['Float']>;
  lon: Maybe<Scalars['Float']>;
  name: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['OCPhoneNumber']>;
  state: Maybe<Scalars['String']>;
  zipcode: Maybe<Scalars['String']>;
};

export type ActivatePaymentInfo = {
  amountCharged: Maybe<Scalars['Float']>;
  isoPrefix: Maybe<Scalars['String']>;
  tenderSubtype: Maybe<Scalars['String']>;
  tenderType: Maybe<Scalars['String']>;
};

export type ActivateTransaction = {
  createdAt: Scalars['OCDateTime'];
  customData: Maybe<Scalars['OCJSON']>;
  fulfilledOffers: Array<Maybe<Offer>>;
  id: Scalars['ID'];
  lineItems: Array<Maybe<ActivateTransactionLineItem>>;
  location: Maybe<ActivateLocation>;
  offerFulfillments: Maybe<Array<RuleFulfillment>>;
  paymentInfos: Maybe<Array<ActivatePaymentInfo>>;
  subtotalAmount: Maybe<Scalars['Float']>;
  totalAmount: Maybe<Scalars['Float']>;
  totalAmountWithoutDiscounts: Maybe<Scalars['Float']>;
  totalDiscountAmount: Maybe<Scalars['Float']>;
  totalPointsEarned: Scalars['Int'];
  totalPointsRefunded: Scalars['Int'];
  totalPointsSpent: Scalars['Int'];
  totalTax: Maybe<Scalars['Float']>;
  transactionTimeAt: Maybe<Scalars['OCDateTime']>;
};

export type ActivateTransactionConnection = {
  edges: Array<Maybe<ActivateTransactionEdge>>;
  pageInfo: PageInfo;
};

export type ActivateTransactionEdge = {
  cursor: Scalars['ID'];
  node: ActivateTransaction;
};

export type ActivateTransactionFilter = {
  idEquals?: InputMaybe<Scalars['ID']>;
};

export type ActivateTransactionLineItem = {
  externalLineItemPromotions: Maybe<Array<ExternalLineItemPromotion>>;
  fuelGradeId: Maybe<Scalars['String']>;
  group: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lineItemNumber: Maybe<Scalars['Int']>;
  moneyAmount: Maybe<Scalars['Float']>;
  productGroups: Array<Maybe<ProductGroup>>;
  promotions: Maybe<Array<ActivateLineItemPromotion>>;
  quantity: Maybe<Scalars['Float']>;
  sku: Maybe<Scalars['String']>;
  transaction: ActivateTransaction;
  upc: Maybe<Scalars['String']>;
};

export type ActivateUser = {
  email: Scalars['OCEmailAddress'];
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type ActivePaymentProviderConfiguration = FiservConfiguration | WorldpayConfiguration;

export type ActivityFeedConnection = {
  edges: Array<ActivityFeedEdge>;
  pageInfo: PageInfo;
};

export type ActivityFeedEdge = {
  cursor: Scalars['OCBase64Cursor'];
  node: ActivityFeedEvent;
};

export type ActivityFeedEvent = {
  event: ActivityType;
  id: Scalars['ID'];
  uuid: Scalars['OCUUID'];
};

export type ActivityFeedFilter = {
  customerUuid: Scalars['OCUUID'];
  includeLoyaltyTransactions: Scalars['Boolean'];
  includeMobileTransactions: Scalars['Boolean'];
  includePointChanges: Scalars['Boolean'];
};

export type ActivityType = ActivateTransaction | CombinedTransaction | PointChange | Transaction;

export type Address = {
  alias: Maybe<Scalars['String']>;
  city: Scalars['String'];
  countryCode: Scalars['OCCountryCode2'];
  geoLocation: Maybe<GeoLocation>;
  state: Scalars['String'];
  street1: Scalars['String'];
  street2: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type AppConfig = {
  appRating: AppRatingConfiguration;
  applePay: Maybe<ApplePayConfiguration>;
  auth: AuthConfiguration;
  communicationPreference: CommunicationPreferenceConfiguration;
  deleteAccount: DeleteAccountConfiguration;
  eaivAgeVerification: EaivAgeVerificationConfiguration;
  eaivIdentityVerification: EaivIdentityVerificationConfiguration;
  freshdeskSupportWidget: FreshdeskSupportWidgetConfiguration;
  googlePay: Maybe<GooglePayConfiguration>;
  kount: Maybe<KountConfiguration>;
  loyaltyProgram: LoyaltyProgramConfiguration;
  mobilePaymentsReceiptsView: MobilePaymentsReceiptsViewConfiguration;
  payAtPump: PayAtPump;
  payInside: PayInside;
  paymentProvider: Maybe<ActivePaymentProviderConfiguration>;
  paymentWallet: PaymentWalletConfiguration;
  pinCode: PinCodeConfiguration;
  /** @deprecated Use totalAvailableRollback instead. This one will be removed after all envs will use TotalAvailableRollbackConfiguration */
  preferredFuelGrade: PreferredFuelGradeConfiguration;
  privacyPolicy: BrandAgreement;
  productInterests: ProductInterestsConfiguration;
  rudderStack: RudderStackConfiguration;
  smsOptIn: SmsOptInConfiguration;
  storeLocator: StoreLocatorConfiguration;
  supportPhoneNumber: Maybe<Scalars['String']>;
  supportTeamEmail: Scalars['String'];
  termsConditions: BrandAgreement;
  totalAvailableRollback: TotalAvailableRollbackConfiguration;
  wallet: WalletConfiguration;
};

export type AppRatingConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
  promptIntervalDays: Scalars['Int'];
  transactionHistoryLookupDays: Scalars['Int'];
};

export enum ApplePayCardNetwork {
  Amex = 'AMEX',
  CartesBancaires = 'CARTES_BANCAIRES',
  ChinaUnionPay = 'CHINA_UNION_PAY',
  Discover = 'DISCOVER',
  Eftpos = 'EFTPOS',
  Electron = 'ELECTRON',
  Elo = 'ELO',
  Interac = 'INTERAC',
  Jcb = 'JCB',
  Mada = 'MADA',
  Maestro = 'MAESTRO',
  MasterCard = 'MASTER_CARD',
  PrivateLabel = 'PRIVATE_LABEL',
  Visa = 'VISA',
  VPay = 'V_PAY',
}

export type ApplePayConfiguration = {
  allowCreditCards: Scalars['Boolean'];
  allowPrepaidCards: Scalars['Boolean'];
  merchantId: Scalars['ID'];
  merchantName: Maybe<Scalars['String']>;
  supportedNetworks: Array<ApplePayCardNetwork>;
};

export type ApplePaySession = {
  data: Maybe<Scalars['OCJSON']>;
};

export type AuthConfiguration = {
  otp: AuthOtpConfiguration;
  primaryMethod: AuthMethod;
};

export enum AuthMethod {
  MagicLink = 'MAGIC_LINK',
  Otp = 'OTP',
}

export type AuthOtpConfiguration = FeatureConfiguration & {
  channels: Array<OtpChannel>;
  enabled: Scalars['Boolean'];
};

export type AuthPayload = {
  customer: Maybe<Customer>;
  tokens: Maybe<Tokens>;
};

export type AuthTokens = {
  tokens: Maybe<Tokens>;
};

export type AwardBuyAndGet = {
  discountThreshold: Scalars['Int'];
  percentDiscount: Scalars['Int'];
};

export type AwardExternalPoints = {
  maximum: Maybe<Scalars['Int']>;
};

export type AwardExternalPointsOrPointsOrVouchers = AwardExternalPoints | AwardPoints | AwardVouchers;

export type AwardMoneyDiscount = {
  awardPer: AwardPerCountingStrategy;
  moneyAmount: Scalars['Int'];
  partialDiscount: Scalars['Boolean'];
};

export type AwardMoneyDiscountOrPriceOverrideOrBuyAndGet = AwardBuyAndGet | AwardMoneyDiscount | AwardPriceOverride;

export type AwardPoints = {
  points: Scalars['Int'];
};

export type AwardPointsOrVouchers = AwardPoints | AwardVouchers;

export type AwardPointsPer = {
  points: Scalars['Int'];
  rounding: AwardPointsRoundingStrategy;
};

export type AwardPriceOverride = {
  maximumCost: Scalars['Int'];
};

export type AwardProductDiscount = {
  eligibleProductGroups: Array<ProductGroup>;
  percentDiscount: Scalars['Int'];
  purchaseThreshold: Scalars['Int'];
};

export type AwardTransactionMoneyDiscount = {
  moneyDiscount: Scalars['Int'];
};

export type AwardTransactionPercentDiscount = {
  percentDiscount: Scalars['Int'];
};

export type AwardVouchers = {
  voucherCount: Scalars['Int'];
  voucherExpirationDays: Maybe<Scalars['Int']>;
  voucheredOffer: Offer;
};

export enum BannerConfigurationProvider {
  Altria = 'ALTRIA',
}

export enum BannerConfigurationType {
  ExternalAd = 'EXTERNAL_AD',
}

export enum BreakagePointRounding {
  RoundDown = 'ROUND_DOWN',
  RoundUp = 'ROUND_UP',
}

export type Banner = {
  bannerConfigurationUuid: Scalars['OCUUID'];
  content: BannerContent;
  externalCampaignId: Maybe<Scalars['String']>;
  trackingId: Scalars['ID'];
};

export type BannerContent = {
  imageUrl: Maybe<Scalars['OCURL']>;
  surgeonGeneralWarning: Maybe<Scalars['String']>;
  title: Scalars['String'];
  title2: Maybe<Scalars['String']>;
  titleMiddle: Maybe<Scalars['String']>;
};

export type BannerFilter = {
  bannerConfigurationUuidIncludes: Array<Scalars['OCUUID']>;
};

export type BillingAddress = {
  alias: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  countryCode: Maybe<Scalars['OCCountryCode2']>;
  geoLocation: Maybe<GeoLocation>;
  state: Maybe<Scalars['String']>;
  street1: Maybe<Scalars['String']>;
  street2: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type Brand = {
  agreements: Maybe<Array<Maybe<BrandAgreement>>>;
  name: Scalars['String'];
  preferences: Maybe<Array<Maybe<BrandCustomerPreference>>>;
  supportedPaymentTypes: Maybe<Array<Maybe<PaymentInstrumentType>>>;
  tenant: Tenant;
};

export type BrandAgreement = {
  activeAt: Maybe<Scalars['OCDateTime']>;
  name: Scalars['String'];
  url: Maybe<Scalars['OCURL']>;
  uuid: Scalars['ID'];
};

export type BrandCustomerPreference = {
  description: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

export type BrandFilter = {
  nameContains?: InputMaybe<Scalars['String']>;
  nameEquals?: InputMaybe<Scalars['String']>;
  tenantContains?: InputMaybe<Scalars['String']>;
  tenantEquals?: InputMaybe<Scalars['String']>;
};

export enum CategoryRestrictions {
  Age = 'AGE',
  Eaiv = 'EAIV',
}

export enum ChangeSource {
  CommandCenter = 'COMMAND_CENTER',
  EtlImport = 'ETL_IMPORT',
  Experience = 'EXPERIENCE',
  RetailerConnect = 'RETAILER_CONNECT',
  SqlUpdate = 'SQL_UPDATE',
}

export enum ConexxusPriceTier {
  Cash = 'cash',
  Credit = 'credit',
  Debit = 'debit',
  Fleet = 'fleet',
  Loyalty = 'loyalty',
  Proprietary = 'proprietary',
  StoredValue = 'storedValue',
}

export enum ConstraintPurchaseThresholdCountingAttribute {
  MoneyAmount = 'MONEY_AMOUNT',
  Quantity = 'QUANTITY',
  UnitsCount = 'UNITS_COUNT',
}

export enum CustomerRequestType {
  Complaint = 'COMPLAINT',
  Compliment = 'COMPLIMENT',
  Question = 'QUESTION',
}

export enum CustomerStatus {
  Active = 'ACTIVE',
  Blacklisted = 'BLACKLISTED',
  FlaggedForReview = 'FLAGGED_FOR_REVIEW',
  Inactive = 'INACTIVE',
  SignedUp = 'SIGNED_UP',
  SignupNotCompleted = 'SIGNUP_NOT_COMPLETED',
  WaitingForVerification = 'WAITING_FOR_VERIFICATION',
  Whitelisted = 'WHITELISTED',
}

export enum CustomDataMode {
  Merge = 'MERGE',
  Replace = 'REPLACE',
}

export type CarWashInput = {
  amount: Scalars['Float'];
  posCode: Scalars['String'];
  posCodeModifier: Scalars['String'];
  productCode: Scalars['String'];
};

export type CarWashItem = {
  additionalAttributes: Maybe<Scalars['OCJSON']>;
  description: Maybe<Scalars['String']>;
  itemType: Maybe<ResourceItemType>;
  merchandiseCode: Maybe<Scalars['String']>;
  posCode: Maybe<Scalars['String']>;
  posCodeFormat: Maybe<Scalars['String']>;
  posCodeModifier: Maybe<Scalars['String']>;
  receiptDescription: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  taxCode: Maybe<Scalars['String']>;
  unitOfMeasure: Maybe<Scalars['String']>;
  unitPrice: Maybe<ItemPrice>;
  uuid: Scalars['ID'];
};

export type CarWashItemConnection = {
  edges: Maybe<Array<Maybe<CarWashItemEdge>>>;
  pageInfo: PageInfo;
};

export type CarWashItemEdge = {
  cursor: Scalars['ID'];
  node: Maybe<CarWashItem>;
};

export type Chain = {
  configuration: ChainConfiguration;
  createdAt: Scalars['OCDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ChainConfiguration = {
  id: Scalars['ID'];
  pointsStrategy: PointsStrategy;
};

export type ClientToken = {
  token: Maybe<Scalars['String']>;
};

export type CombinedTransaction = {
  loyalty: Maybe<ActivateTransaction>;
  mobile: Maybe<Transaction>;
  pointChange: Maybe<PointChange>;
  startedAt: Scalars['OCDateTime'];
};

export type CommandCenterUser = {
  email: Scalars['OCEmailAddress'];
  name: Maybe<Scalars['String']>;
};

export type CommunicationPreference = {
  emailOptIn: Scalars['Boolean'];
  emailOptInAt: Maybe<Scalars['OCDateTime']>;
  emailOptOutAt: Maybe<Scalars['OCDateTime']>;
  pushOptIn: Scalars['Boolean'];
  pushOptInAt: Maybe<Scalars['OCDateTime']>;
  pushOptOutAt: Maybe<Scalars['OCDateTime']>;
  smsOptIn: Scalars['Boolean'];
  smsOptInAt: Maybe<Scalars['OCDateTime']>;
  smsOptOutAt: Maybe<Scalars['OCDateTime']>;
};

export type CommunicationPreferenceConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
  isPushOptInConfigurable: Scalars['Boolean'];
  isSmsOptInConfigurable: Scalars['Boolean'];
};

export type CommunicationPreferenceUpdateInput = {
  emailOptInAt?: InputMaybe<Scalars['OCDateTime']>;
  emailOptOutAt?: InputMaybe<Scalars['OCDateTime']>;
  pushOptInAt?: InputMaybe<Scalars['OCDateTime']>;
  pushOptOutAt?: InputMaybe<Scalars['OCDateTime']>;
  smsOptInAt?: InputMaybe<Scalars['OCDateTime']>;
  smsOptOutAt?: InputMaybe<Scalars['OCDateTime']>;
};

export type Constraint =
  | ConstraintDaysOfWeek
  | ConstraintInAudience
  | ConstraintLocationInState
  | ConstraintPaymentMethod
  | ConstraintPreAuth
  | ConstraintPurchaseThreshold
  | ConstraintRequiresActivation
  | ConstraintRequiresTransactionVoucher
  | ConstraintRequiresVoucher
  | ConstraintRequiresVoucherAvailableForDiscount
  | ConstraintStoreLocation
  | ConstraintTimeOfDay;

export type ConstraintDaysOfWeek = {
  availableWeeklyOnDays: Array<Weekday>;
  id: Scalars['ID'];
  startOfDayTimezone: Scalars['String'];
};

export type ConstraintInAudience = {
  audienceId: Scalars['ID'];
  id: Scalars['ID'];
};

export type ConstraintLocationInState = {
  id: Scalars['ID'];
  states: Array<Scalars['String']>;
};

export type ConstraintPaymentMethod = {
  evaluationMethod: PaymentMethodEvaluationMethod;
  id: Scalars['ID'];
  paymentMethods: Array<ActivatePaymentMethod>;
};

export type ConstraintPreAuth = {
  id: Scalars['ID'];
};

export type ConstraintPurchaseThreshold = {
  countProductsBy: ConstraintPurchaseThresholdCountingAttribute;
  eligibleProductGroupIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
  threshold: Scalars['Int'];
};

export type ConstraintRequiresActivation = {
  allowMultipleFulfillmentsOnActivation: Scalars['Boolean'];
  id: Scalars['ID'];
  mustFulfillWithinDays: Maybe<Scalars['Int']>;
};

export type ConstraintRequiresTransactionVoucher = {
  allowSingleOfferVoucherStacking: Maybe<Scalars['Boolean']>;
  breakagePointRounding: Maybe<BreakagePointRounding>;
  breakageRefund: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type ConstraintRequiresVoucher = {
  id: Scalars['ID'];
};

export type ConstraintRequiresVoucherAvailableForDiscount = {
  id: Scalars['ID'];
};

export type ConstraintStoreLocation = {
  evaluationMethod: StoreLocationEvaluationMethod;
  id: Scalars['ID'];
  locations: Array<ActivateLocation>;
};

export type ConstraintTimeOfDay = {
  availableDailyEndingAtTime: Scalars['OCTime'];
  availableDailyStartingAtTime: Scalars['OCTime'];
  id: Scalars['ID'];
  timezone: Scalars['String'];
};

export type ContactPhoneNumber = {
  alias: Maybe<Scalars['String']>;
  phoneNumber: Scalars['OCPhoneNumber'];
  phoneType: PhoneType;
  uuid: Scalars['ID'];
};

export type CreateOtpSessionInput = {
  otpChannel?: InputMaybe<OtpChannel>;
  otpPlatform: OtpPlatform;
  phoneNumber: Scalars['OCPhoneNumber'];
};

export type Currency = {
  code: Maybe<Scalars['String']>;
  symbol: Maybe<Scalars['String']>;
};

export type CustomDataInput = {
  data: Scalars['OCJSON'];
  mode?: InputMaybe<CustomDataMode>;
};

export type Customer = {
  addresses: Maybe<Array<Maybe<Address>>>;
  agreements: Maybe<Array<Maybe<CustomerAgreement>>>;
  brands: Maybe<Array<Maybe<Brand>>>;
  communicationPreference: CommunicationPreference;
  defaultLocation: Maybe<Location>;
  demographics: Maybe<Demographics>;
  devices: Maybe<Array<Maybe<CustomerDevice>>>;
  email: Maybe<Scalars['OCEmailAddress']>;
  hasPasscode: Maybe<Scalars['Boolean']>;
  hasPasswordSet: Maybe<Scalars['Boolean']>;
  isNewCustomer: Scalars['Boolean'];
  loginHistory: Maybe<CustomerLoginAttemptConnection>;
  loyaltyAccounts: Maybe<Array<Maybe<LoyaltyAccount>>>;
  loyaltyWallet: Maybe<Array<Maybe<LoyaltyInstrument>>>;
  membership: Maybe<Membership>;
  name: Maybe<PersonName>;
  paymentWallet: Maybe<Array<Maybe<PaymentInstrument>>>;
  phoneNumbers: Maybe<Array<Maybe<ContactPhoneNumber>>>;
  preferences: Maybe<Array<Maybe<CustomerPreference>>>;
  privacyGovernance: Maybe<Scalars['String']>;
  signUpOrigin: Maybe<SignUpOrigin>;
  status: CustomerStatus;
  transactions: Maybe<TransactionConnection>;
  username: Scalars['String'];
  uuid: Scalars['ID'];
};

export type CustomerLoginHistoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CustomerLoginAttemptFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerAddressCreate = {
  alias?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  geoLocation?: InputMaybe<GeoLocationCreate>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CustomerAddressUpdate = {
  alias?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  geoLocation?: InputMaybe<GeoLocationUpdate>;
  state?: InputMaybe<Scalars['String']>;
  street1?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CustomerAgreement = {
  agreedAt: Maybe<Scalars['OCDateTime']>;
  brandAgreement: Maybe<BrandAgreement>;
  name: Scalars['String'];
  url: Maybe<Scalars['OCURL']>;
};

export type CustomerAgreementCreate = {
  uuid: Scalars['ID'];
};

export type CustomerContact = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  countryCode?: InputMaybe<Scalars['OCCountryCode2']>;
  customerRequestType: CustomerRequestType;
  email?: InputMaybe<Scalars['OCEmailAddress']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stationId?: InputMaybe<Scalars['String']>;
};

export type CustomerCreate = {
  addresses?: InputMaybe<Array<InputMaybe<CustomerAddressCreate>>>;
  agreements?: InputMaybe<Array<InputMaybe<CustomerAgreementCreate>>>;
  brandUuid: Scalars['ID'];
  chainId?: InputMaybe<Scalars['ID']>;
  demographics?: InputMaybe<CustomerDemographicsCreate>;
  email?: InputMaybe<Scalars['OCEmailAddress']>;
  loyaltyAccounts?: InputMaybe<Array<InputMaybe<LoyaltyAccountCreate>>>;
  name?: InputMaybe<CustomerPersonNameCreate>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumbers?: InputMaybe<Array<InputMaybe<CustomerPhoneNumberCreate>>>;
  preferences?: InputMaybe<Array<InputMaybe<CustomerPreferenceCreate>>>;
  signUpOrigin?: InputMaybe<SignUpOrigin>;
  username: Scalars['String'];
};

export type CustomerCreateVerify = {
  otp: Otp;
};

export type CustomerDemographicsCreate = {
  birthday?: InputMaybe<Scalars['OCDate']>;
  customDemographics?: InputMaybe<Scalars['OCJSON']>;
  gender?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CustomerDemographicsUpdate = {
  birthday?: InputMaybe<Scalars['OCDate']>;
  customDemographics?: InputMaybe<Scalars['OCJSON']>;
  gender?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CustomerDevice = {
  additionalInfo: Maybe<Scalars['OCJSON']>;
  alias: Scalars['String'];
  deviceId: Scalars['String'];
  deviceType: DeviceType;
  osType: Maybe<Scalars['String']>;
  osVersion: Maybe<Scalars['String']>;
  signature: Maybe<Scalars['String']>;
};

export type CustomerDeviceFilter = {
  aliasEquals?: InputMaybe<Scalars['String']>;
  deviceIDEquals?: InputMaybe<Scalars['String']>;
};

export type CustomerDeviceInput = {
  additionalInfo?: InputMaybe<Scalars['OCJSON']>;
  alias: Scalars['String'];
  deviceId: Scalars['String'];
  deviceType: DeviceType;
  osType?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
};

export type CustomerFeedback = {
  comment?: InputMaybe<Scalars['String']>;
  rating: Scalars['Int'];
};

export type CustomerLoginAttempt = {
  device: Maybe<CustomerDevice>;
  loginAt: Scalars['OCDateTime'];
  loginLocation: Maybe<GeoLocation>;
  wasSuccessful: Scalars['Boolean'];
};

export type CustomerLoginAttemptConnection = {
  edges: Maybe<Array<Maybe<CustomerLoginAttemptEdge>>>;
  pageInfo: PageInfo;
};

export type CustomerLoginAttemptEdge = {
  cursor: Scalars['ID'];
  node: Maybe<CustomerLoginAttempt>;
};

export type CustomerLoginAttemptFilter = {
  device?: InputMaybe<CustomerDeviceFilter>;
  loginAtMax?: InputMaybe<Scalars['OCDateTime']>;
  loginAtMin?: InputMaybe<Scalars['OCDateTime']>;
  loginLocation?: InputMaybe<GeoLocationFilter>;
  wasSuccessful?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerLoyaltyInstrumentCreate = {
  accountNumber: Scalars['String'];
  alias: Scalars['String'];
};

export type CustomerLoyaltyInstrumentUpdate = {
  alias: Scalars['String'];
};

export type CustomerOtpPayload = {
  session: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type CustomerOtpPayloadOrAuthPayload = AuthPayload | CustomerOtpPayload;

export type CustomerOtpRequest = {
  brandUuid: Scalars['ID'];
  chainId?: InputMaybe<Scalars['ID']>;
  channel?: InputMaybe<OtpChannel>;
  identifier: Scalars['String'];
  mode?: InputMaybe<OtpMode>;
};

export type CustomerOtpVerify = {
  otp: Otp;
};

export type CustomerPassword = {
  password: Scalars['String'];
};

export type CustomerPasswordChange = {
  newPassword: CustomerPassword;
  oldPassword: CustomerPassword;
};

export type CustomerPasswordCredentials = {
  password: CustomerPassword;
  username: CustomerUsername;
};

export type CustomerPasswordReset = {
  newPassword: CustomerPassword;
};

export type CustomerPasswordResetByOtpVerify = {
  otp: Otp;
};

export type CustomerPasswordResetPaymentInstrumentVerify = {
  expiration: CustomerPaymentInstrumentExpirationInput;
};

export type CustomerPasswordSet = {
  newPassword: CustomerPassword;
};

export type CustomerPaymentInstrumentCreate = {
  accountNumber?: InputMaybe<Scalars['String']>;
  accountType?: InputMaybe<PaymentAccountType>;
  address?: InputMaybe<CustomerAddressCreate>;
  alias?: InputMaybe<Scalars['String']>;
  binRange?: InputMaybe<Scalars['String']>;
  encryptedPaymentInstrumentDetails?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<CustomerPaymentInstrumentExpirationInput>;
  externalRequestData?: InputMaybe<Scalars['OCJSON']>;
  fiservOAuthToken?: InputMaybe<Scalars['String']>;
  last4?: InputMaybe<Scalars['String']>;
  merchantId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<CustomerPersonNameCreate>;
  nonce?: InputMaybe<Scalars['String']>;
  paymentType?: InputMaybe<PaymentInstrumentType>;
  setAsDefault?: InputMaybe<Scalars['Boolean']>;
  transactionUuid?: InputMaybe<Scalars['ID']>;
};

export type CustomerPaymentInstrumentExpirationInput = {
  expirationMonth?: InputMaybe<Scalars['String']>;
  expirationYear?: InputMaybe<Scalars['String']>;
};

export type CustomerPaymentInstrumentUpdate = {
  address?: InputMaybe<CustomerAddressUpdate>;
  alias?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<CustomerPaymentInstrumentExpirationInput>;
  paymentType?: InputMaybe<PaymentInstrumentType>;
  uuid: Scalars['ID'];
};

export type CustomerPaymentInstrumentVerify = {
  otp: Otp;
};

export type CustomerPersonNameCreate = {
  first?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['String']>;
  middle?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CustomerPersonNameUpdate = {
  first?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['String']>;
  middle?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CustomerPhoneNumberCreate = {
  alias?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['OCPhoneNumber'];
  phoneType: PhoneType;
};

export type CustomerPhoneNumberUpdate = {
  alias?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['OCPhoneNumber'];
  phoneType: PhoneType;
  uuid?: InputMaybe<Scalars['ID']>;
};

export type CustomerPinCode = {
  pinCode: Scalars['String'];
};

export type CustomerPinCodeChange = {
  newPinCode: CustomerPinCode;
  oldPinCode: CustomerPinCode;
};

export type CustomerPinCodeCredentials = {
  pinCode: CustomerPinCode;
  username: CustomerUsername;
};

export type CustomerPinCodeSet = {
  pinCode: CustomerPinCode;
};

export type CustomerPinCodeVerify = {
  pinCode: CustomerPinCode;
};

export type CustomerPreference = {
  preference: Maybe<BrandCustomerPreference>;
  uuid: Scalars['ID'];
  value: Maybe<PreferenceValue>;
};

export type CustomerPreferenceCreate = {
  uuid: Scalars['ID'];
  value?: InputMaybe<PreferenceValueCreate>;
};

export type CustomerPreferenceUpdate = {
  uuid?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<PreferenceValueUpdate>;
};

export type CustomerRegistrationComplete = {
  acceptAgreement?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  defaultLocationId: Scalars['Int'];
  demographics?: InputMaybe<CustomerDemographicsCreate>;
  email?: InputMaybe<Scalars['OCEmailAddress']>;
  name?: InputMaybe<CustomerPersonNameCreate>;
  preferences?: InputMaybe<Array<InputMaybe<CustomerPreferenceCreate>>>;
};

export type CustomerUpdate = {
  addresses?: InputMaybe<Array<InputMaybe<CustomerAddressUpdate>>>;
  defaultLocationId?: InputMaybe<Scalars['Int']>;
  demographics?: InputMaybe<CustomerDemographicsUpdate>;
  email?: InputMaybe<Scalars['OCEmailAddress']>;
  loyaltyAccounts?: InputMaybe<Array<InputMaybe<LoyaltyAccountUpdate>>>;
  membership?: InputMaybe<MembershipUpdate>;
  name?: InputMaybe<CustomerPersonNameUpdate>;
  phoneNumbers?: InputMaybe<Array<InputMaybe<CustomerPhoneNumberUpdate>>>;
  preferences?: InputMaybe<Array<InputMaybe<CustomerPreferenceUpdate>>>;
  signUpOrigin?: InputMaybe<SignUpOrigin>;
};

export type CustomerUpdateStateInput = {
  ageVerifyAndEaivReset?: InputMaybe<Scalars['Boolean']>;
  payResetThrottle?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerUsername = {
  username: Scalars['String'];
};

export enum DeviceType {
  AlexaSkill = 'ALEXA_SKILL',
  Android = 'ANDROID',
  AndroidTablet = 'ANDROID_TABLET',
  AppServer = 'APP_SERVER',
  ConnectedCar = 'CONNECTED_CAR',
  Desktop = 'DESKTOP',
  Ipad = 'IPAD',
  Iphone = 'IPHONE',
  Pos = 'POS',
  Web = 'WEB',
}

export type DeleteAccountConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
};

export type DeletionRequestSourceInput = {
  membershipUuid?: InputMaybe<Scalars['ID']>;
};

export type Demographics = {
  birthday: Maybe<Scalars['OCDate']>;
  customDemographics: Maybe<Scalars['OCJSON']>;
  gender: Maybe<Scalars['String']>;
  zipCode: Maybe<Scalars['String']>;
};

export type DeviceTokenRegisterInput = {
  deviceId: Scalars['ID'];
  deviceType: DeviceType;
  token: Scalars['String'];
};

export type DisplayCategory = {
  /** @deprecated Replaced by categoryRestrictions. */
  ageRestricted: Maybe<Scalars['Boolean']>;
  bannerIds: Maybe<Array<Scalars['ID']>>;
  categoryRestrictions: Maybe<CategoryRestrictions>;
  chainId: Scalars['ID'];
  createdAt: Scalars['OCDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  offers: OfferConnection;
  rank: Scalars['Int'];
};

export type DisplayCategoryOffersArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<OfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DisplayCategoryConnection = {
  edges: Array<DisplayCategoryEdge>;
  pageInfo: PageInfo;
};

export type DisplayCategoryEdge = {
  cursor: Scalars['ID'];
  node: DisplayCategory;
};

export type DisplayCategoryFilter = {
  chainIdEquals?: InputMaybe<Scalars['ID']>;
  idEquals?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  uuidContains?: InputMaybe<Array<Scalars['ID']>>;
};

export enum EaivAdditionalPromptFields {
  EaivPromptForAdditionalAddress = 'EAIV_PROMPT_FOR_ADDITIONAL_ADDRESS',
  EaivPromptForSsn4 = 'EAIV_PROMPT_FOR_SSN4',
  EaivPromptForSsn4OrAdditionalAddress = 'EAIV_PROMPT_FOR_SSN4_OR_ADDITIONAL_ADDRESS',
}

export enum EaivAgeVerificationFailed {
  LimitReached = 'LIMIT_REACHED',
  VerificationFailed = 'VERIFICATION_FAILED',
}

export enum EaivIdentityVerificationFailed {
  LimitReached = 'LIMIT_REACHED',
  VerificationFailed = 'VERIFICATION_FAILED',
}

export enum EarningMechanismProductCountingAttribute {
  MoneyAmount = 'MONEY_AMOUNT',
  Quantity = 'QUANTITY',
  UnitsCount = 'UNITS_COUNT',
}

export enum EarningMechanismProductCountingRoundingStrategy {
  Down = 'DOWN',
  HalfUp = 'HALF_UP',
  Raw = 'RAW',
}

export enum EarningMechanismTypename {
  EarningMechanismExternalFulfillment = 'EarningMechanismExternalFulfillment',
  EarningMechanismLoyaltyEvent = 'EarningMechanismLoyaltyEvent',
  EarningMechanismMembershipEvent = 'EarningMechanismMembershipEvent',
  EarningMechanismPointSpend = 'EarningMechanismPointSpend',
  EarningMechanismProductDiscount = 'EarningMechanismProductDiscount',
  EarningMechanismProductPurchase = 'EarningMechanismProductPurchase',
  EarningMechanismPromocodes = 'EarningMechanismPromocodes',
  EarningMechanismSpinWheelGame = 'EarningMechanismSpinWheelGame',
  EarningMechanismTotalEligiblePurchase = 'EarningMechanismTotalEligiblePurchase',
  EarningMechanismTotalEligiblePurchaseOverTime = 'EarningMechanismTotalEligiblePurchaseOverTime',
  EarningMechanismTotalSpendOnTransaction = 'EarningMechanismTotalSpendOnTransaction',
  EarningMechanismTotalSpendOverTime = 'EarningMechanismTotalSpendOverTime',
  EarningMechanismTransactionDiscount = 'EarningMechanismTransactionDiscount',
  EarningMechanismVarietyDiscount = 'EarningMechanismVarietyDiscount',
  EarningMechanismVarietyPurchase = 'EarningMechanismVarietyPurchase',
  EarningMechanismVarietyPurchaseOverTime = 'EarningMechanismVarietyPurchaseOverTime',
  EarningMechanismVisits = 'EarningMechanismVisits',
}

export type EaivAgeVerification = {
  isAvailable: Scalars['Boolean'];
};

export type EaivAgeVerificationAddress = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type EaivAgeVerificationConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
};

export enum EaivAgeVerificationRequestStatusType {
  Success = 'Success',
}

export type EaivAgeVerificationRequireAdditionalPrompt = {
  prompt: EaivAdditionalPromptFields;
};

export type EaivAgeVerificationResultFailed = {
  reason: Maybe<EaivAgeVerificationFailed>;
};

export type EaivAgeVerificationStartInput = {
  addresses?: InputMaybe<Array<InputMaybe<EaivAgeVerificationAddress>>>;
  birthdate: Scalars['OCDate'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  ssn4?: InputMaybe<Scalars['String']>;
};

export type EaivAgeVerificationStartResult =
  | EaivAgeVerificationRequireAdditionalPrompt
  | EaivAgeVerificationResultFailed
  | EaivAgeVerificationStartResultSuccess;

export type EaivAgeVerificationStartResultSuccess = {
  status: EaivAgeVerificationRequestStatusType;
};

export type EaivIdentityVerification = {
  isAvailable: Scalars['Boolean'];
};

export type EaivIdentityVerificationAddress = {
  address1: Scalars['String'];
  address2?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
};

export type EaivIdentityVerificationAnswerInput = {
  answerValue: Scalars['String'];
  questionId: Scalars['String'];
};

export type EaivIdentityVerificationAnswerSetInput = {
  answers: Array<EaivIdentityVerificationAnswerInput>;
  questionSetId: Scalars['String'];
};

export type EaivIdentityVerificationChoiceQuestion = {
  choices: Array<EaivIdentityVerificationChoiceQuestionOption>;
  helpText: Maybe<Scalars['String']>;
  id: Scalars['String'];
  text: Scalars['String'];
};

export type EaivIdentityVerificationChoiceQuestionOption = {
  id: Scalars['String'];
  text: Scalars['String'];
};

export type EaivIdentityVerificationConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
};

export type EaivIdentityVerificationQuestion = EaivIdentityVerificationChoiceQuestion;

export type EaivIdentityVerificationQuestionSet = {
  id: Scalars['String'];
  questions: Array<EaivIdentityVerificationQuestion>;
};

export enum EaivIdentityVerificationRequestStatusType {
  Success = 'Success',
}

export type EaivIdentityVerificationRequireAdditionalPrompt = {
  prompt: EaivAdditionalPromptFields;
};

export type EaivIdentityVerificationResultFailed = {
  reason: Maybe<EaivIdentityVerificationFailed>;
};

export type EaivIdentityVerificationStartInput = {
  addresses?: InputMaybe<Array<InputMaybe<EaivIdentityVerificationAddress>>>;
  birthdate: Scalars['OCDate'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
  ssn4?: InputMaybe<Scalars['String']>;
};

export type EaivIdentityVerificationStartResult =
  | EaivIdentityVerificationRequireAdditionalPrompt
  | EaivIdentityVerificationResultFailed
  | EaivIdentityVerificationStartResultSuccess;

export type EaivIdentityVerificationStartResultSuccess = {
  conversationId: Scalars['String'];
  questionSet: EaivIdentityVerificationQuestionSet;
};

export type EaivIdentityVerificationSubmitInput = {
  answerSet: EaivIdentityVerificationAnswerSetInput;
  conversationId: Scalars['String'];
};

export type EaivIdentityVerificationSubmitResult =
  | EaivIdentityVerificationResultFailed
  | EaivIdentityVerificationSubmitResultContinue
  | EaivIdentityVerificationSubmitResultSuccess;

export type EaivIdentityVerificationSubmitResultContinue = {
  conversationId: Scalars['String'];
  questionSet: EaivIdentityVerificationQuestionSet;
};

export type EaivIdentityVerificationSubmitResultSuccess = {
  status: EaivIdentityVerificationRequestStatusType;
};

export type EarningMechanism =
  | EarningMechanismExternalFulfillment
  | EarningMechanismLoyaltyEvent
  | EarningMechanismMembershipEvent
  | EarningMechanismPointSpend
  | EarningMechanismProductDiscount
  | EarningMechanismProductPurchase
  | EarningMechanismPromocodes
  | EarningMechanismSpinWheelGame
  | EarningMechanismTotalEligiblePurchase
  | EarningMechanismTotalEligiblePurchaseOverTime
  | EarningMechanismTotalSpendOnTransaction
  | EarningMechanismTotalSpendOverTime
  | EarningMechanismTransactionDiscount
  | EarningMechanismVarietyDiscount
  | EarningMechanismVarietyPurchase
  | EarningMechanismVarietyPurchaseOverTime
  | EarningMechanismVisits;

export type EarningMechanismExternalFulfillment = {
  award: Maybe<AwardPointsOrVouchers>;
  id: Scalars['ID'];
};

export type EarningMechanismLoyaltyEvent = {
  award: Maybe<AwardExternalPointsOrPointsOrVouchers>;
  eventType: Scalars['String'];
  id: Scalars['ID'];
};

export type EarningMechanismMembershipEvent = {
  award: AwardPointsOrVouchers;
  eventType: MembershipEventType;
  id: Scalars['ID'];
};

export type EarningMechanismPointSpend = {
  award: AwardVouchers;
  id: Scalars['ID'];
  pointCost: Scalars['Int'];
};

export type EarningMechanismProductCriteria = {
  countProductsBy: EarningMechanismProductCountingAttribute;
  eligibleProductGroups: Array<ProductGroup>;
  id: Maybe<Scalars['ID']>;
  purchaseThreshold: Scalars['Float'];
};

export type EarningMechanismProductDiscount = {
  award: AwardMoneyDiscountOrPriceOverrideOrBuyAndGet;
  eligibleProductGroups: Array<ProductGroup>;
  id: Scalars['ID'];
  purchaseThreshold: Scalars['Int'];
};

export type EarningMechanismProductPurchase = {
  award: AwardPointsOrVouchers;
  countProductsBy: EarningMechanismProductCountingAttribute;
  eligibleProductGroups: Array<ProductGroup>;
  id: Scalars['ID'];
  purchaseThreshold: Scalars['Float'];
};

export type EarningMechanismPromocodes = {
  award: Maybe<AwardPointsOrVouchers>;
  id: Scalars['ID'];
};

export type EarningMechanismSpinWheelGame = {
  awards: Array<RandomAwardConfig>;
  id: Scalars['ID'];
  purchasableOffer: Maybe<Offer>;
};

export type EarningMechanismTotalEligiblePurchase = {
  award: AwardPointsPer;
  countProductsBy: EarningMechanismProductCountingAttribute;
  eligibleProductGroups: Array<ProductGroup>;
  id: Scalars['ID'];
  roundCountedProducts: EarningMechanismProductCountingRoundingStrategy;
};

export type EarningMechanismTotalEligiblePurchaseOverTime = {
  accumulationThreshold: Scalars['Float'];
  award: AwardPointsOrVouchers;
  countProductsBy: EarningMechanismProductCountingAttribute;
  eligibleProductGroups: Array<ProductGroup>;
  hiddenUntilProgress: Scalars['Boolean'];
  id: Scalars['ID'];
  purchaseMemberProgress: Maybe<PurchaseProgress>;
};

export type EarningMechanismTotalSpendOnTransaction = {
  award: AwardPointsPer;
  id: Scalars['ID'];
  ineligibleProductGroups: Array<ProductGroup>;
  roundMoneyAmount: EarningMechanismProductCountingRoundingStrategy;
};

export type EarningMechanismTotalSpendOverTime = {
  accumulationThreshold: Scalars['Float'];
  award: AwardPointsOrVouchers;
  id: Scalars['ID'];
  ineligibleProductGroups: Array<ProductGroup>;
  purchaseMemberProgress: Maybe<PurchaseProgress>;
};

export type EarningMechanismTransactionDiscount = {
  award: TransactionDiscountAwards;
  /** @deprecated Replaced by ineligibleProductGroups. */
  eligibleProductGroups: Array<ProductGroup>;
  id: Scalars['ID'];
  ineligibleProductGroups: Array<ProductGroup>;
  purchaseAmountThreshold: Scalars['Int'];
};

export type EarningMechanismVarietyDiscount = {
  award: VarietyDiscountAwards;
  id: Scalars['ID'];
  purchases: Array<EarningVarietyDiscountCriteria>;
};

export type EarningMechanismVarietyPurchase = {
  award: AwardPointsOrVouchers;
  id: Scalars['ID'];
  products: Array<EarningMechanismProductCriteria>;
};

export type EarningMechanismVarietyPurchaseOverTime = {
  award: AwardPointsOrVouchers;
  hiddenUntilProgress: Scalars['Boolean'];
  id: Scalars['ID'];
  products: Array<EarningMechanismProductCriteria>;
  varietyMemberProgress: Array<VarietyProgress>;
};

export type EarningMechanismVisits = {
  award: AwardPointsOrVouchers;
  id: Scalars['ID'];
  visitThreshold: Scalars['Int'];
  visitsMemberProgress: Maybe<VisitsProgress>;
};

export type EarningVarietyDiscountCriteria = {
  eligibleProductGroups: Array<ProductGroup>;
  id: Scalars['ID'];
  purchaseThreshold: Scalars['Int'];
};

export type ExchangeSessionAndOtpToAuthTokenResult = {
  success: Scalars['Boolean'];
};

export type ExchangeSessionAndOtpToAuthTokenResultFailedIncorrectOtp = ExchangeSessionAndOtpToAuthTokenResult & {
  success: Scalars['Boolean'];
};

export type ExchangeSessionAndOtpToAuthTokenResultSuccess = ExchangeSessionAndOtpToAuthTokenResult & {
  success: Scalars['Boolean'];
  tokens: Tokens;
};

export type ExchangeSessionAndOtpToAuthTokenResultUnion =
  | ExchangeSessionAndOtpToAuthTokenResultFailedIncorrectOtp
  | ExchangeSessionAndOtpToAuthTokenResultSuccess;

export type ExternalLineItemPromotion = {
  discountAmount: Scalars['Float'];
  id: Scalars['ID'];
  promotionReason: Scalars['String'];
};

export enum FuelPricesMode {
  Both = 'BOTH',
  CashOnly = 'CASH_ONLY',
  CreditOnly = 'CREDIT_ONLY',
}

export enum FuelPumpStatus {
  FuelCanceled = 'FUEL_CANCELED',
  FuelEnd = 'FUEL_END',
  FuelPreAuthSubmitted = 'FUEL_PRE_AUTH_SUBMITTED',
  FuelPumpActivated = 'FUEL_PUMP_ACTIVATED',
  FuelPumpDeactivated = 'FUEL_PUMP_DEACTIVATED',
  FuelReceiptReceived = 'FUEL_RECEIPT_RECEIVED',
  FuelSecondaryIdReceived = 'FUEL_SECONDARY_ID_RECEIVED',
  FuelStart = 'FUEL_START',
  Unknown = 'UNKNOWN',
}

export enum FuelType {
  Diesel = 'DIESEL',
  Electric = 'ELECTRIC',
  Gasoline = 'GASOLINE',
  NaturalGas = 'NATURAL_GAS',
  OffroadDiesel = 'OFFROAD_DIESEL',
  Propane = 'PROPANE',
}

export type FeatureConfiguration = {
  enabled: Scalars['Boolean'];
};

export type FiservClientToken = {
  algorithm: Scalars['String'];
  apiKey: Scalars['String'];
  baseUrl: Scalars['String'];
  expiresInSeconds: Scalars['Int'];
  fdCustomerId: Scalars['ID'];
  issuedOn: Scalars['OCDateTime'];
  oAuthToken: Scalars['String'];
  publicKey: Scalars['String'];
};

export type FiservConfiguration = PaymentProviderConfiguration & {
  customData: Maybe<Scalars['OCJSON']>;
};

export type FreshdeskSupportWidgetConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
  host: Maybe<Scalars['String']>;
  sdkUrl: Maybe<Scalars['String']>;
  token: Maybe<Scalars['String']>;
  widgetId: Maybe<Scalars['String']>;
};

export type FuelAndConvenienceProfile = {
  acquisitionChannel: Maybe<Scalars['String']>;
  /** @deprecated Replaced by restrictionVerification. */
  alcoholVerified: Maybe<Scalars['Boolean']>;
  /** @deprecated Replaced by restrictionVerification. */
  altriaVerified: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  memberDataShare: Maybe<Scalars['Boolean']>;
  pendingVerification: Maybe<CategoryRestrictions>;
  preferredFuelProductGroup: Maybe<FuelProductGroup>;
  restrictionVerification: Maybe<CategoryRestrictions>;
  /** @deprecated Replaced by restrictionVerification. */
  rjrVerified: Maybe<Scalars['Boolean']>;
};

export type FuelAndConvenienceProfileCreateOrUpdateInput = {
  memberDataShare?: InputMaybe<Scalars['Boolean']>;
  preferredFuelProductGroupUuid?: InputMaybe<Scalars['ID']>;
};

export type FuelGradePrice = {
  description: Scalars['String'];
  unitPrice: Maybe<FuelItemPrice>;
};

export type FuelItem = {
  additionalAttributes: Maybe<Scalars['OCJSON']>;
  description: Maybe<Scalars['String']>;
  fuelGrade: Maybe<Scalars['Float']>;
  fuelType: Maybe<FuelType>;
  itemType: Maybe<ResourceItemType>;
  merchandiseCode: Maybe<Scalars['String']>;
  posCode: Maybe<Scalars['String']>;
  posCodeFormat: Maybe<Scalars['String']>;
  posCodeModifier: Maybe<Scalars['String']>;
  receiptDescription: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  taxCode: Maybe<Scalars['String']>;
  unitOfMeasure: Maybe<Scalars['String']>;
  unitPrice: Maybe<FuelItemPrice>;
  uuid: Scalars['ID'];
};

export type FuelItemConnection = {
  edges: Maybe<Array<Maybe<FuelItemEdge>>>;
  pageInfo: PageInfo;
};

export type FuelItemEdge = {
  cursor: Scalars['ID'];
  node: Maybe<FuelItem>;
};

export type FuelItemPrice = {
  cashPrice: Scalars['Float'];
  creditPrice: Scalars['Float'];
  displayCashPrice: Scalars['String'];
  displayCreditPrice: Scalars['String'];
};

export type FuelProductGroup = {
  id: Scalars['ID'];
  productGroup: ProductGroup;
};

export type FuelProductGroupFilter = {
  userSelectable?: InputMaybe<Scalars['Boolean']>;
};

export type FuelReceipt = {
  carWashCode: Maybe<Scalars['String']>;
  carWashName: Maybe<Scalars['String']>;
  carWashPrice: Maybe<Scalars['String']>;
  fuelSale: Scalars['String'];
  gallons: Scalars['String'];
  priceG: Scalars['String'];
  product: Scalars['String'];
  tax: Scalars['String'];
};

export enum GlobalOffersFilter {
  All = 'ALL',
  PerLocation = 'PER_LOCATION',
}

export enum GooglePayEnvironment {
  Production = 'PRODUCTION',
  Test = 'TEST',
}

export type GameSession = {
  awardData: Maybe<Scalars['OCJSON']>;
  id: Scalars['ID'];
  membership: Membership;
  offer: Offer;
};

export type GeneralSurvey = Survey & {
  audienceUuid: Scalars['OCUUID'];
  award: Maybe<AwardPointsOrVouchers>;
  chainId: Scalars['OCUUID'];
  configuration: GeneralSurveyConfiguration;
  createdAt: Scalars['OCDateTime'];
  deletedAt: Maybe<Scalars['OCDateTime']>;
  description: Maybe<Scalars['String']>;
  name: Scalars['String'];
  questions: Array<SurveyQuestion>;
  status: SurveyStatus;
  type: SurveyType;
  uuid: Scalars['OCUUID'];
};

export type GeneralSurveyConfiguration = {
  endDate: Maybe<Scalars['OCDateTime']>;
  startDate: Scalars['OCDateTime'];
};

export type GeneralSurveyConfigurationInput = {
  endDate?: InputMaybe<Scalars['OCDateTime']>;
  startDate: Scalars['OCDateTime'];
};

export type GeneralSurveyCreateInput = {
  audienceUuid: Scalars['OCUUID'];
  chainId: Scalars['String'];
  configuration: GeneralSurveyConfigurationInput;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  questions: Array<GeneralSurveyQuestionInput>;
};

export type GeneralSurveyQuestionInput = {
  configuration: SurveyQuestionConfigurationInput;
  text: Scalars['String'];
};

export type GeneralSurveyQuestionUpdateInput = {
  text?: InputMaybe<Scalars['String']>;
  uuid: Scalars['OCUUID'];
};

export type GeneralSurveyStatusUpdateInput = {
  status: SurveyStatus;
  uuid: Scalars['OCUUID'];
};

export type GeneralSurveyUpdateInput = {
  audienceUuid?: InputMaybe<Scalars['OCUUID']>;
  chainId?: InputMaybe<Scalars['String']>;
  configuration?: InputMaybe<GeneralSurveyConfigurationInput>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<GeneralSurveyQuestionInput>>;
  updatedQuestions?: InputMaybe<Array<GeneralSurveyQuestionUpdateInput>>;
  uuid: Scalars['OCUUID'];
};

export type GeoLocation = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GeoLocationCreate = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GeoLocationFilter = {
  epicenter: GeoLocationCreate;
  radiusInMeters: Scalars['Float'];
};

export type GeoLocationUpdate = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export enum GooglePayCardNetwork {
  Amex = 'AMEX',
  Discover = 'DISCOVER',
  Electron = 'ELECTRON',
  Elo = 'ELO',
  EloDebit = 'ELO_DEBIT',
  Interac = 'INTERAC',
  Jcb = 'JCB',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Visa = 'VISA',
}

export type GooglePayConfiguration = {
  allowCreditCards: Scalars['Boolean'];
  allowPrepaidCards: Scalars['Boolean'];
  allowedCardNetworks: Array<GooglePayCardNetwork>;
  environment: GooglePayEnvironment;
  gateway: Scalars['String'];
  gatewayMerchantId: Scalars['ID'];
  merchantId: Scalars['ID'];
  merchantName: Maybe<Scalars['String']>;
};

export type Guest = {
  uuid: Scalars['ID'];
};

export type GuestLocationFilter = {
  siteSystemConfiguration?: InputMaybe<SiteSystemConfigurationFilter>;
};

export type GuestSurveyAnswerSendInput = {
  fields: Array<SurveyFieldInput>;
  guestSurveyId: Scalars['String'];
  questionUuid: Scalars['OCUUID'];
};

export type GuestSurveyAnswerSubmitInput = {
  fields: Array<SurveyFieldInput>;
  guestSurveyId: Scalars['String'];
  questionUuid: Scalars['OCUUID'];
};

export type GuestSurveyAnswersSubmitInput = {
  answers: Array<SurveyQuestionAnswerInput>;
  guestSurveyId: Scalars['String'];
};

export type GuestTransaction = {
  fuelReceipt: Maybe<FuelReceipt>;
  primaryStatus: PrimaryTransactionStatus;
  receiptLines: Maybe<Array<ReceiptLine>>;
  secondaryStatus: SecondaryTransactionStatus;
  stac: Maybe<Stac>;
  uuid: Scalars['ID'];
};

export type GuestTransactionStartInput = {
  billingZipCode?: InputMaybe<Scalars['String']>;
  fuelingPosition?: InputMaybe<Scalars['String']>;
  locationUuid: Scalars['ID'];
  loyaltyId?: InputMaybe<Scalars['String']>;
  nonce: Scalars['String'];
  paymentInstrumentType: PaymentInstrumentType;
  preAuthAmount: Scalars['Float'];
  siteId: Scalars['String'];
  transactionType: TransactionType;
};

export enum HardwareVendor {
  Comdata = 'COMDATA',
  Gilbarco = 'GILBARCO',
  Mashgin = 'MASHGIN',
  Ncr = 'NCR',
  Stuzo = 'STUZO',
  Verifone = 'VERIFONE',
}

export type HomeStore = {
  locationUuid: Scalars['ID'];
};

export type HoursOfOperation = {
  closeTime: Scalars['OCTime'];
  dayOfWeek: Weekday;
  genericHoursString: Maybe<Scalars['String']>;
  openTime: Scalars['OCTime'];
};

export type HoursOfOperationGroup = {
  /** @deprecated Removing alias */
  alias: Maybe<Scalars['String']>;
  hours: Array<HoursOfOperation>;
};

export type ItemPrice = {
  displayPrice: Scalars['String'];
  price: Scalars['Float'];
};

export enum KountEnvironment {
  Production = 'PRODUCTION',
  Test = 'TEST',
}

export type KountConfiguration = {
  collectorHostname: Scalars['String'];
  environment: KountEnvironment;
  merchantId: Scalars['Int'];
};

export enum LimitTimePeriod {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
}

export enum LocationOpenStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Unknown = 'UNKNOWN',
}

export enum LocationResourceStatus {
  Available = 'AVAILABLE',
  Inuse = 'INUSE',
  Offline = 'OFFLINE',
  Unavailable = 'UNAVAILABLE',
  Unknown = 'UNKNOWN',
}

export enum LocationResourceType {
  CarWash = 'CAR_WASH',
  FuelPump = 'FUEL_PUMP',
  Store = 'STORE',
}

export enum LoyaltyEventOrigin {
  ActivateInternalProcess = 'ACTIVATE_INTERNAL_PROCESS',
  CommandApi = 'COMMAND_API',
  ExperienceApi = 'EXPERIENCE_API',
  Rabbitmq = 'RABBITMQ',
}

export enum LoyaltyInstrumentStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum LoyaltyLevel {
  AboveSite = 'ABOVE_SITE',
  SiteLevel = 'SITE_LEVEL',
}

export enum LoyaltyProviderTypeEnum {
  Albertsons = 'ALBERTSONS',
  OpenCommerce = 'OPEN_COMMERCE',
}

export type Limit =
  | LimitGlobalActivation
  | LimitGlobalActivationInTime
  | LimitGlobalAwardCount
  | LimitGlobalAwardCountInTime
  | LimitInTransactionAwardCount
  | LimitMembershipActivation
  | LimitMembershipActivationInTime
  | LimitMembershipAwardCount
  | LimitMembershipAwardCountInTime;

export type LimitGlobalActivation = {
  globalQuantityFulfilled: Scalars['Int'];
  id: Scalars['ID'];
  limit: Scalars['Int'];
};

export type LimitGlobalActivationInTime = {
  globalQuantityFulfilled: Scalars['Int'];
  id: Scalars['ID'];
  limit: Scalars['Int'];
  timePeriod: LimitTimePeriod;
};

export type LimitGlobalAwardCount = {
  globalQuantityFulfilled: Scalars['Int'];
  id: Scalars['ID'];
  limit: Scalars['Int'];
};

export type LimitGlobalAwardCountInTime = {
  globalQuantityFulfilled: Scalars['Int'];
  id: Scalars['ID'];
  limit: Scalars['Int'];
  timePeriod: LimitTimePeriod;
};

export type LimitInTransactionAwardCount = {
  id: Scalars['ID'];
  limit: Scalars['Int'];
};

export type LimitMembershipActivation = {
  id: Scalars['ID'];
  limit: Scalars['Int'];
  membershipQuantityFulfilled: Maybe<Scalars['Int']>;
};

export type LimitMembershipActivationInTime = {
  id: Scalars['ID'];
  limit: Scalars['Int'];
  membershipQuantityFulfilled: Maybe<Scalars['Int']>;
  timePeriod: LimitTimePeriod;
};

export type LimitMembershipAwardCount = {
  id: Scalars['ID'];
  limit: Scalars['Int'];
  membershipQuantityAvailable: Maybe<Scalars['Int']>;
  membershipQuantityFulfilled: Maybe<Scalars['Int']>;
};

export type LimitMembershipAwardCountInTime = {
  id: Scalars['ID'];
  limit: Scalars['Int'];
  membershipQuantityAvailable: Maybe<Scalars['Int']>;
  membershipQuantityFulfilled: Maybe<Scalars['Int']>;
  timePeriod: LimitTimePeriod;
};

export type Location = {
  address: Maybe<Address>;
  attributes: Array<LocationAttribute>;
  brand: Maybe<Brand>;
  brandSpecificLocationId: Maybe<Scalars['String']>;
  customerId: Maybe<Scalars['String']>;
  customerName: Maybe<Scalars['String']>;
  distance: Maybe<Scalars['Float']>;
  fuelGradePrices: Array<FuelGradePrice>;
  hoursOfOperationGroup: Maybe<HoursOfOperationGroup>;
  lastChangeSource: Maybe<ChangeSource>;
  manuallyUpdated: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  openCommerceFeatures: Array<OpenCommerceFeature>;
  openStatus: LocationOpenStatus;
  paymentProviderMerchantId: Maybe<Scalars['String']>;
  phoneNumbers: Array<ContactPhoneNumber>;
  siteSystemConfiguration: Array<SiteSystemConfiguration>;
  specialHours: Maybe<Scalars['String']>;
  utcOffset: Maybe<Scalars['Int']>;
  uuid: Scalars['ID'];
};

export type LocationAttribute = {
  /** @deprecated Removed description, only using value and string */
  description: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type LocationAttributeFilter = {
  nameEquals?: InputMaybe<Scalars['String']>;
  valueEquals?: InputMaybe<Scalars['String']>;
};

export type LocationCheckIn = {
  geoLocation?: InputMaybe<GeoLocationCreate>;
};

export type LocationConnection = {
  edges: Maybe<Array<LocationEdge>>;
  pageInfo: PageInfo;
};

export type LocationEdge = {
  cursor: Scalars['ID'];
  node: Location;
};

export type LocationFilter = {
  attributes?: InputMaybe<LocationAttributeFilter>;
  brand?: InputMaybe<BrandFilter>;
  customerIdEquals?: InputMaybe<Scalars['String']>;
  customerNameEquals?: InputMaybe<Scalars['String']>;
  geoLocation?: InputMaybe<GeoLocationFilter>;
  geoLocationEquals?: InputMaybe<GeoLocationCreate>;
  openCommerceFeatures?: InputMaybe<OpenCommerceFeatureFilter>;
  radius?: InputMaybe<Scalars['Float']>;
  resources?: InputMaybe<LocationResourceFilter>;
  siteSystemConfiguration?: InputMaybe<SiteSystemConfigurationFilter>;
  uuidContains?: InputMaybe<Array<Scalars['ID']>>;
  uuidEquals?: InputMaybe<Scalars['ID']>;
};

export type LocationProfile = {
  city: Maybe<Scalars['String']>;
  locationId: Maybe<Scalars['ID']>;
  state: Maybe<Scalars['String']>;
  zipcode: Maybe<Scalars['String']>;
};

export type LocationProfileCreateOrUpdateInput = {
  city?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type LocationResource = {
  carWashItems: Maybe<CarWashItemConnection>;
  fuelItems: Maybe<FuelItemConnection>;
  position: Maybe<Scalars['String']>;
  resourceType: LocationResourceType;
  status: Maybe<LocationResourceStatus>;
  storeItems: Maybe<StoreItemConnection>;
  uuid: Scalars['ID'];
};

export type LocationResourceCarWashItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ResourceItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LocationResourceFuelItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ResourceItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LocationResourceStoreItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ResourceItemFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type LocationResourceFilter = {
  items?: InputMaybe<ResourceItemFilter>;
  statusContains?: InputMaybe<Array<InputMaybe<LocationResourceStatus>>>;
  statusEquals?: InputMaybe<LocationResourceStatus>;
  typeContains?: InputMaybe<Array<InputMaybe<LocationResourceType>>>;
  typeEquals?: InputMaybe<LocationResourceType>;
};

export type LocationsFilterInput = {
  globalOffersIncluded?: InputMaybe<GlobalOffersFilter>;
  locations?: InputMaybe<Array<Scalars['String']>>;
};

export type LoyaltyAccount = {
  accountNumber: Scalars['String'];
  alias: Scalars['String'];
  rewardProgram: RewardProgram;
  uuid: Scalars['ID'];
};

export type LoyaltyAccountCreate = {
  accountNumber: Scalars['String'];
  rewardProgramUuid: Scalars['ID'];
};

export type LoyaltyAccountUpdate = {
  accountNumber: Scalars['String'];
  rewardProgramUuid: Scalars['ID'];
  uuid?: InputMaybe<Scalars['ID']>;
};

export type LoyaltyEvent = {
  chain: Chain;
  createdAt: Scalars['OCDateTime'];
  eventData: Maybe<Scalars['OCJSON']>;
  eventType: Scalars['String'];
  origin: Maybe<LoyaltyEventOrigin>;
};

export type LoyaltyEventCreate = {
  chainId: Scalars['ID'];
  eventData?: InputMaybe<Scalars['OCJSON']>;
  eventType: Scalars['String'];
  origin?: InputMaybe<LoyaltyEventOrigin>;
};

export type LoyaltyInstrument = {
  accountNumber: Scalars['String'];
  alias: Scalars['String'];
  status: LoyaltyInstrumentStatus;
  uuid: Scalars['ID'];
};

export type LoyaltyInstrumentFilter = {
  accountNumberEquals?: InputMaybe<Scalars['String']>;
  aliasContains?: InputMaybe<Scalars['String']>;
  aliasEquals?: InputMaybe<Scalars['String']>;
};

export type LoyaltyProgramConfiguration = {
  homePageOffersLimit: Scalars['Int'];
  isExplicitSmsPromotionAcceptanceEnabled: Scalars['Boolean'];
  isExplicitTermsAcceptanceRequired: Scalars['Boolean'];
  isPromocodesEnabled: Scalars['Boolean'];
  isReturnRewardEnabled: Scalars['Boolean'];
  membershipMinAge: Scalars['Int'];
  /** @deprecated Functionality extended. Whether it is points based or not is now handled with pointsStrategy property. */
  pointsBased: Scalars['Boolean'];
  pointsStrategy: PointsStrategy;
  redeemOfferWithCompletedProfileOnly: Scalars['Boolean'];
};

export enum MembershipEventType {
  Active = 'ACTIVE',
  Birthday = 'BIRTHDAY',
  Created = 'CREATED',
}

export enum MEMBERSHIP_STATE {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  DeletionPlaceholder = 'DELETION_PLACEHOLDER',
  NewMember = 'NEW_MEMBER',
  Paused = 'PAUSED',
  Suspended = 'SUSPENDED',
}

export enum MembershipType {
  Employee = 'EMPLOYEE',
  Manager = 'MANAGER',
  RcUser = 'RC_USER',
  Standard = 'STANDARD',
}

export type MagicLinkExchangePayload = {
  tokens: Tokens;
};

export type MagicLinkExchangeSessionIdToAuthTokenInput = {
  brandUuid: Scalars['String'];
  chainId?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['String'];
};

export type MagicLinkRequestInput = {
  brandUuid: Scalars['ID'];
  chainId?: InputMaybe<Scalars['ID']>;
  identifier: Scalars['String'];
};

export type MarketingContent = {
  customData: Maybe<Scalars['OCJSON']>;
  disclaimer: Maybe<Scalars['String']>;
  disclaimerUrl: Maybe<Scalars['OCURL']>;
  discontinued: Scalars['Boolean'];
  externalUrl: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['OCURL']>;
  locale: Scalars['String'];
  longDescription: Maybe<Scalars['String']>;
  shortDescription: Maybe<Scalars['String']>;
  surgeonGeneralsWarning: Maybe<Scalars['String']>;
  terms: Maybe<Scalars['String']>;
  termsUrl: Maybe<Scalars['OCURL']>;
  title: Maybe<Scalars['String']>;
};

export type Membership = {
  birthdate: Maybe<Scalars['OCDate']>;
  chain: Chain;
  createdAt: Scalars['OCDateTime'];
  customData: Maybe<Scalars['OCJSON']>;
  customerUuid: Maybe<Scalars['ID']>;
  displayBrand: Maybe<Scalars['String']>;
  email: Maybe<Scalars['OCEmailAddress']>;
  firstName: Maybe<Scalars['String']>;
  fuelAndConvenienceProfile: Maybe<FuelAndConvenienceProfile>;
  homeStores: Maybe<Array<HomeStore>>;
  id: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
  lastName: Maybe<Scalars['String']>;
  locationProfile: Maybe<LocationProfile>;
  offers: Maybe<OfferConnection>;
  phone: Maybe<Scalars['OCPhoneNumber']>;
  pointChanges: Maybe<PointChangeConnection>;
  points: Scalars['Int'];
  primaryAddress: Maybe<MembershipAddress>;
  productInterests: Array<ProductCategory>;
  /** @deprecated Replaced by productInterests. */
  productPreferences: Array<DisplayCategory>;
  programTermsAcceptanceAt: Maybe<Scalars['OCDateTime']>;
  state: MEMBERSHIP_STATE;
  stateChanges: Array<MembershipState>;
  totalAvailableRollback: Maybe<TotalAvailableRollback>;
  transactions: Maybe<ActivateTransactionConnection>;
};

export type MembershipOffersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<OfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<OfferSort>;
};

export type MembershipPointChangesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PointChangeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MembershipTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ActivateTransactionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type MembershipAddress = {
  address1: Maybe<Scalars['String']>;
  address2: Maybe<Scalars['String']>;
  city: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  state: Maybe<Scalars['String']>;
  zipcode: Maybe<Scalars['String']>;
};

export type MembershipDeleteInput = {
  deletionRequestSource?: InputMaybe<DeletionRequestSourceInput>;
  membershipUuid: Scalars['ID'];
};

export type MembershipHomeStoresCreateOrUpdateInput = {
  locationUuids: Array<Scalars['ID']>;
};

export type MembershipPrimaryAddressInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type MembershipState = {
  effectiveAt: Maybe<Scalars['OCDateTime']>;
  state: MEMBERSHIP_STATE;
};

export type MembershipStateTransition = {
  state: MEMBERSHIP_STATE;
};

export type MembershipUpdate = {
  birthdate?: InputMaybe<Scalars['OCDate']>;
  customData?: InputMaybe<CustomDataInput>;
  displayBrand?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['OCEmailAddress']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  primaryAddress?: InputMaybe<MembershipPrimaryAddressInput>;
  programTermsAcceptanceAt?: InputMaybe<Scalars['OCDateTime']>;
};

export type MobilePaymentsReceiptsViewConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
};

export type Mutation = {
  applePaySessionCreate: ApplePaySession;
  /** @deprecated Deprecated because of createOTPSessionByPlatform. */
  createOTPSession: CreateOtpSessionResult;
  createOTPSessionByPlatform: CreateOtpSessionResult;
  customerDelete: Scalars['Boolean'];
  customerLoginByOTP: Maybe<CustomerOtpPayloadOrAuthPayload>;
  customerOTPRequest: Maybe<CustomerOtpPayload>;
  customerPaymentInstrumentCreate: PaymentInstrument;
  customerPaymentInstrumentDelete: Array<Maybe<PaymentInstrument>>;
  customerPinCodeReset: Maybe<Scalars['Boolean']>;
  customerPinCodeSet: Maybe<Scalars['Boolean']>;
  customerPinCodeVerify: Maybe<Scalars['Boolean']>;
  customerRegistrationComplete: Maybe<Customer>;
  customerUpdate: Maybe<Customer>;
  customerUpdateState: Maybe<Scalars['Boolean']>;
  customerUsernamePasswordSignIn: AuthTokens;
  customerUsernamePasswordSignUp: AuthTokens;
  deviceTokenRegister: Scalars['Boolean'];
  eaivAgeVerificationStart: EaivAgeVerificationStartResult;
  eaivIdentityVerificationStart: EaivIdentityVerificationStartResult;
  eaivIdentityVerificationSubmit: EaivIdentityVerificationSubmitResult;
  exchangeSessionAndOTPToAuthToken: ExchangeSessionAndOtpToAuthTokenResultUnion;
  fuelAndConvenienceProfileCreateOrUpdate: FuelAndConvenienceProfile;
  getAccessTokenByRefreshToken: Maybe<Tokens>;
  guestAccountCreate: Guest;
  /** @deprecated Used for WS Surveys only. Mutation will be removed after BE refactor. */
  guestSurveyAnswerSend: Scalars['Boolean'];
  guestSurveyAnswersSubmit: Scalars['Boolean'];
  guestTransactionCancel: Scalars['Boolean'];
  guestTransactionStart: GuestTransaction;
  locationProfileCreateOrUpdate: Maybe<LocationProfile>;
  logout: Maybe<Scalars['Boolean']>;
  loyaltyEventCreate: Maybe<LoyaltyEvent>;
  magicLinkExchangeSessionIdToAuthToken: MagicLinkExchangePayload;
  membershipDelete: Scalars['Boolean'];
  membershipHomeStoresCreateOrUpdate: Membership;
  membershipStateTransition: Maybe<Membership>;
  offerActivate: Maybe<Offer>;
  offerActivityLog: Scalars['Boolean'];
  offerDeactivate: Maybe<Offer>;
  offerPurchaseCreate: Maybe<Array<Maybe<OfferPurchase>>>;
  offerPurchaseReturn: Maybe<OfferPurchase>;
  paymentInstrumentSetDefault: Scalars['Boolean'];
  productInterestCreate: ProductInterest;
  productInterestDelete: Scalars['Boolean'];
  productInterestsReplace: Membership;
  productPreferenceCreate: ProductPreference;
  productPreferenceDelete: Scalars['Boolean'];
  productPreferenceReplace: Membership;
  redeemPromocode: RedeemPromocodeResult;
  refreshCustomerAccessToken: AuthTokens;
  requestMagicLink: Scalars['Boolean'];
  scrubData: Scalars['Boolean'];
  spinWheelGameExecute: Maybe<RandomAwardConfig>;
  /** @deprecated Used for WS Surveys only. Mutation will be removed after BE refactor. */
  surveyAnswerSend: Scalars['Boolean'];
  /** @deprecated Will be removed. */
  surveyAnswerSubmit: Scalars['Boolean'];
  surveyAnswersSubmit: Scalars['Boolean'];
  surveyDismiss: Scalars['Boolean'];
  surveyViewsCreate: Scalars['Boolean'];
  transactionCancel: Scalars['Boolean'];
  transactionStart: Maybe<Transaction>;
  updateCommunicationPreference: CommunicationPreference;
  validateAndResetCustomerPassword: AuthTokens;
};

export type MutationApplePaySessionCreateArgs = {
  input: PaymentApplePaySessionCreateInput;
};

export type MutationCreateOtpSessionArgs = {
  phoneNumber: Scalars['OCPhoneNumber'];
};

export type MutationCreateOtpSessionByPlatformArgs = {
  input: CreateOtpSessionInput;
};

export type MutationCustomerLoginByOtpArgs = {
  input: Otp;
};

export type MutationCustomerOtpRequestArgs = {
  input: CustomerOtpRequest;
};

export type MutationCustomerPaymentInstrumentCreateArgs = {
  input: CustomerPaymentInstrumentCreate;
};

export type MutationCustomerPaymentInstrumentDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationCustomerPinCodeSetArgs = {
  input: CustomerPinCodeSet;
};

export type MutationCustomerPinCodeVerifyArgs = {
  input: CustomerPinCodeVerify;
};

export type MutationCustomerRegistrationCompleteArgs = {
  input: CustomerRegistrationComplete;
};

export type MutationCustomerUpdateArgs = {
  input: CustomerUpdate;
};

export type MutationCustomerUpdateStateArgs = {
  input: CustomerUpdateStateInput;
};

export type MutationCustomerUsernamePasswordSignInArgs = {
  input: SignInUsernamePasswordUserInput;
};

export type MutationCustomerUsernamePasswordSignUpArgs = {
  input: SignUpUsernamePasswordUserInput;
};

export type MutationDeviceTokenRegisterArgs = {
  input: DeviceTokenRegisterInput;
};

export type MutationEaivAgeVerificationStartArgs = {
  input: EaivAgeVerificationStartInput;
};

export type MutationEaivIdentityVerificationStartArgs = {
  input: EaivIdentityVerificationStartInput;
};

export type MutationEaivIdentityVerificationSubmitArgs = {
  input: EaivIdentityVerificationSubmitInput;
};

export type MutationExchangeSessionAndOtpToAuthTokenArgs = {
  input: Otp;
};

export type MutationFuelAndConvenienceProfileCreateOrUpdateArgs = {
  input: FuelAndConvenienceProfileCreateOrUpdateInput;
};

export type MutationGetAccessTokenByRefreshTokenArgs = {
  input: AccessTokenByRefreshTokenInput;
};

export type MutationGuestSurveyAnswerSendArgs = {
  input: GuestSurveyAnswerSendInput;
};

export type MutationGuestSurveyAnswersSubmitArgs = {
  input: GuestSurveyAnswersSubmitInput;
};

export type MutationGuestTransactionCancelArgs = {
  uuid: Scalars['ID'];
};

export type MutationGuestTransactionStartArgs = {
  input: GuestTransactionStartInput;
};

export type MutationLocationProfileCreateOrUpdateArgs = {
  input: LocationProfileCreateOrUpdateInput;
};

export type MutationLoyaltyEventCreateArgs = {
  input: LoyaltyEventCreate;
};

export type MutationMagicLinkExchangeSessionIdToAuthTokenArgs = {
  input: MagicLinkExchangeSessionIdToAuthTokenInput;
};

export type MutationMembershipHomeStoresCreateOrUpdateArgs = {
  input: MembershipHomeStoresCreateOrUpdateInput;
};

export type MutationMembershipStateTransitionArgs = {
  input: MembershipStateTransition;
};

export type MutationOfferActivateArgs = {
  input: OfferActivate;
};

export type MutationOfferActivityLogArgs = {
  input: OfferActivityLogInput;
};

export type MutationOfferDeactivateArgs = {
  input: OfferDeactivate;
};

export type MutationOfferPurchaseCreateArgs = {
  input: OfferPurchaseCreate;
};

export type MutationOfferPurchaseReturnArgs = {
  input: OfferPurchaseReturn;
};

export type MutationPaymentInstrumentSetDefaultArgs = {
  uuid: Scalars['ID'];
};

export type MutationProductInterestCreateArgs = {
  input: ProductInterestInput;
};

export type MutationProductInterestDeleteArgs = {
  input: ProductInterestInput;
};

export type MutationProductInterestsReplaceArgs = {
  input: ProductInterestsReplaceInput;
};

export type MutationProductPreferenceCreateArgs = {
  input: ProductPreferenceInput;
};

export type MutationProductPreferenceDeleteArgs = {
  input: ProductPreferenceInput;
};

export type MutationProductPreferenceReplaceArgs = {
  input: ProductPreferenceReplaceInput;
};

export type MutationRedeemPromocodeArgs = {
  promocode: Scalars['String'];
};

export type MutationRefreshCustomerAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

export type MutationRequestMagicLinkArgs = {
  input: MagicLinkRequestInput;
};

export type MutationSpinWheelGameExecuteArgs = {
  input: SpinWheelGameExecuteInput;
};

export type MutationSurveyAnswerSendArgs = {
  input: SurveyAnswerSendInput;
};

export type MutationSurveyAnswerSubmitArgs = {
  input: SurveyAnswerSubmitInput;
};

export type MutationSurveyAnswersSubmitArgs = {
  input: SurveyAnswersSubmitInput;
};

export type MutationSurveyDismissArgs = {
  input: SurveyDismissInput;
};

export type MutationSurveyViewsCreateArgs = {
  input: SurveyViewsCreateInput;
};

export type MutationTransactionCancelArgs = {
  uuid: Scalars['ID'];
};

export type MutationTransactionStartArgs = {
  input: TransactionStart;
};

export type MutationUpdateCommunicationPreferenceArgs = {
  input: CommunicationPreferenceUpdateInput;
};

export type MutationValidateAndResetCustomerPasswordArgs = {
  input: ValidateAndResetCustomerPasswordInput;
};

export enum NotificationReceiverType {
  AndroidApp = 'ANDROID_APP',
  Email = 'EMAIL',
  IosApp = 'IOS_APP',
  IosWallet = 'IOS_WALLET',
}

export enum OfferActivationStatus {
  Activated = 'ACTIVATED',
  DoesNotRequireActivation = 'DOES_NOT_REQUIRE_ACTIVATION',
  RequiresActivation = 'REQUIRES_ACTIVATION',
}

export enum OfferActivityLogChannelType {
  App = 'APP',
  Email = 'EMAIL',
  Sms = 'SMS',
}

export enum OfferActivityLogType {
  Click = 'CLICK',
  Delivered = 'DELIVERED',
  Impression = 'IMPRESSION',
  Ltc = 'LTC',
  OptedOut = 'OPTED_OUT',
  Sent = 'SENT',
}

export enum OfferEngagementType {
  Base = 'BASE',
  Behavioral = 'BEHAVIORAL',
  Billboard = 'BILLBOARD',
  ExternallyFulfillable = 'EXTERNALLY_FULFILLABLE',
  FuelRollback = 'FUEL_ROLLBACK',
  Game = 'GAME',
  GameVoucher = 'GAME_VOUCHER',
  Promocode = 'PROMOCODE',
  Promotional = 'PROMOTIONAL',
  PunchcardChallenge = 'PUNCHCARD_CHALLENGE',
  Purchaseable = 'PURCHASEABLE',
  SurveyBehavioral = 'SURVEY_BEHAVIORAL',
  SweepstakesEntry = 'SWEEPSTAKES_ENTRY',
  SweepstakesPurchasable = 'SWEEPSTAKES_PURCHASABLE',
  SweepstakesTransactional = 'SWEEPSTAKES_TRANSACTIONAL',
  Transactional = 'TRANSACTIONAL',
  Vouchered = 'VOUCHERED',
}

export enum OfferFundingSource {
  Brand = 'BRAND',
  Other = 'OTHER',
  Partner = 'PARTNER',
  Retailer = 'RETAILER',
}

export enum OfferPresentationPriority {
  Default = 'DEFAULT',
  Featured = 'FEATURED',
}

export enum OfferPresentationType {
  Banner = 'BANNER',
  Default = 'DEFAULT',
  Hidden = 'HIDDEN',
}

export enum OfferPurchaseOrigin {
  ApiCall = 'API_CALL',
  OptionalOffer = 'OPTIONAL_OFFER',
}

export enum OfferSortBy {
  FulfillmentPriorityLevel = 'FULFILLMENT_PRIORITY_LEVEL',
}

export enum OfferVisibleStatus {
  /** @deprecated All audience related visibility reasons will use NOT_IN_REQUIRED_AUDIENCES going forward. */
  AgeRestricted = 'AGE_RESTRICTED',
  Discontinued = 'DISCONTINUED',
  Expired = 'EXPIRED',
  GlobalLimit = 'GLOBAL_LIMIT',
  HiddenUntilProgress = 'HIDDEN_UNTIL_PROGRESS',
  MemberLimit = 'MEMBER_LIMIT',
  /** @deprecated Do not support differences in visibility between new members and active members. */
  MemberNotActive = 'MEMBER_NOT_ACTIVE',
  MemberSuspended = 'MEMBER_SUSPENDED',
  /** @deprecated All audience related visibility reasons will use NOT_IN_REQUIRED_AUDIENCES going forward. */
  MissingRequiredSegments = 'MISSING_REQUIRED_SEGMENTS',
  NotInRequiredAudiences = 'NOT_IN_REQUIRED_AUDIENCES',
  NoVoucher = 'NO_VOUCHER',
  /** @deprecated All audience related visibility reasons will use NOT_IN_REQUIRED_AUDIENCES going forward. */
  PurchaseLimitExceeded = 'PURCHASE_LIMIT_EXCEEDED',
  /** @deprecated All audience related visibility reasons will use NOT_IN_REQUIRED_AUDIENCES going forward. */
  PurchaseLimitNotReached = 'PURCHASE_LIMIT_NOT_REACHED',
  Unpublished = 'UNPUBLISHED',
  /** @deprecated All audience related visibility reasons will use NOT_IN_REQUIRED_AUDIENCES going forward. */
  UnsatisfiedMembershipProfileAttribute = 'UNSATISFIED_MEMBERSHIP_PROFILE_ATTRIBUTE',
  Visible = 'VISIBLE',
}

export type Otp = {
  chainId?: InputMaybe<Scalars['String']>;
  otp: Scalars['String'];
  session: Scalars['String'];
};

export enum OtpChannel {
  Email = 'EMAIL',
  PhoneCall = 'PHONE_CALL',
  Sms = 'SMS',
}

export enum OtpMode {
  PasswordReset = 'PASSWORD_RESET',
  Signin = 'SIGNIN',
  Signup = 'SIGNUP',
  UpdateIdentifier = 'UPDATE_IDENTIFIER',
}

export enum OtpPlatform {
  Android = 'ANDROID',
  Iphone = 'IPHONE',
  Web = 'WEB',
}

export type Offer = {
  activationStatus: OfferActivationStatus;
  ageRestricted: Scalars['Boolean'];
  category: Maybe<Scalars['String']>;
  constraints: Array<Constraint>;
  createdAt: Scalars['OCDateTime'];
  customData: Maybe<Scalars['OCJSON']>;
  deleted: Scalars['Boolean'];
  deletedAt: Maybe<Scalars['OCDateTime']>;
  description: Maybe<Scalars['String']>;
  displayCategories: Array<DisplayCategory>;
  earningMechanism: Maybe<EarningMechanism>;
  eligible: Scalars['Boolean'];
  engagementType: Maybe<OfferEngagementType>;
  expireAt: Maybe<Scalars['OCDateTime']>;
  id: Scalars['ID'];
  limits: Array<Limit>;
  longDescription: Maybe<Scalars['String']>;
  marketingContents: Array<Maybe<MarketingContent>>;
  name: Scalars['String'];
  optionalAtPos: Maybe<Scalars['Boolean']>;
  presentationPriority: OfferPresentationPriority;
  presentationType: OfferPresentationType;
  publicationStatus: PublicationStatusType;
  publishAt: Maybe<Scalars['OCDateTime']>;
  shortDescription: Maybe<Scalars['String']>;
  stackable: Scalars['Boolean'];
  triggerPreferences: TriggerPreferences;
  visibleForCommandCenterFulfillment: Maybe<Scalars['Boolean']>;
  visibleStatus: OfferVisibleStatus;
  voucherCount: Scalars['Int'];
  vouchers: VoucherConnection;
};

export type OfferVouchersArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<VoucherFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type OfferActivate = {
  offerId: Scalars['ID'];
};

export type OfferActivityLogInput = {
  activityAt: Scalars['OCDateTime'];
  activityState?: InputMaybe<Scalars['String']>;
  activityType: OfferActivityLogType;
  bannerConfigurationUuid?: InputMaybe<Scalars['ID']>;
  channelType: OfferActivityLogChannelType;
  externalCampaignId?: InputMaybe<Scalars['String']>;
  marketingTransactionLogId?: InputMaybe<Scalars['String']>;
  offerId: Scalars['String'];
};

export type OfferConnection = {
  edges: Array<Maybe<OfferEdge>>;
  pageInfo: PageInfo;
};

export type OfferDeactivate = {
  offerId: Scalars['ID'];
};

export type OfferDisplayCategoryInput = {
  displayCategoryId: Scalars['ID'];
  offerId: Scalars['ID'];
};

export type OfferEdge = {
  cursor: Scalars['ID'];
  node: Offer;
};

export type OfferFilter = {
  activationStatusEquals?: InputMaybe<OfferActivationStatus>;
  categoryEquals?: InputMaybe<Scalars['String']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  engagementTypeEquals?: InputMaybe<OfferEngagementType>;
  engagementTypeExcludes?: InputMaybe<Array<OfferEngagementType>>;
  idEquals?: InputMaybe<Scalars['ID']>;
  isEligible?: InputMaybe<Scalars['Boolean']>;
  locationsFilter?: InputMaybe<LocationsFilterInput>;
  loyaltyEventEquals?: InputMaybe<Scalars['String']>;
  presentationTypeIncludes?: InputMaybe<Array<OfferPresentationType>>;
  publicationStatusEquals?: InputMaybe<PublicationStatusType>;
  visibleForCommandCenterFulfillment?: InputMaybe<Scalars['Boolean']>;
  visibleStatusEquals?: InputMaybe<OfferVisibleStatus>;
};

export type OfferPurchase = {
  createdAt: Scalars['OCDateTime'];
  discontinued: Scalars['Boolean'];
  pointCost: Scalars['Int'];
  purchasedWith: Offer;
  reservedUntil: Maybe<Scalars['OCDateTime']>;
  returnable: Scalars['Boolean'];
  voucherGrantFor: Offer;
};

export type OfferPurchaseCreate = {
  count?: InputMaybe<Scalars['Int']>;
  offerId: Scalars['ID'];
};

export type OfferPurchaseReturn = {
  offerId: Scalars['ID'];
};

export type OfferSort = {
  direction: SortOrder;
  field: OfferSortBy;
};

export type OpenCommerceFeature = {
  description: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Maybe<Scalars['String']>;
};

export type OpenCommerceFeatureFilter = {
  descriptionContains?: InputMaybe<Scalars['String']>;
  descriptionEquals?: InputMaybe<Scalars['String']>;
  nameContains?: InputMaybe<Scalars['String']>;
  nameEquals?: InputMaybe<Scalars['String']>;
  valueContains?: InputMaybe<Scalars['String']>;
  valueEquals?: InputMaybe<Scalars['String']>;
};

export type OptionSelectionCount = {
  count: Scalars['Int'];
  optionLabel: Scalars['String'];
  optionUuid: Scalars['OCUUID'];
};

export enum PaymentAccountType {
  ApplePay = 'APPLE_PAY',
  Credit = 'CREDIT',
  GooglePay = 'GOOGLE_PAY',
  Prepaid = 'PREPAID',
  Unknown = 'UNKNOWN',
}

export enum PaymentInstrumentStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export enum PaymentInstrumentType {
  AmazonPay = 'AMAZON_PAY',
  AmericanExpress = 'AMERICAN_EXPRESS',
  ApplePay = 'APPLE_PAY',
  ApplePayAmericanExpress = 'APPLE_PAY_AMERICAN_EXPRESS',
  ApplePayDiscover = 'APPLE_PAY_DISCOVER',
  ApplePayMastercard = 'APPLE_PAY_MASTERCARD',
  ApplePayVisa = 'APPLE_PAY_VISA',
  CartesBancaires = 'CARTES_BANCAIRES',
  CarteBlanche = 'CARTE_BLANCHE',
  ChasePay = 'CHASE_PAY',
  ChinaUnionPay = 'CHINA_UNION_PAY',
  CitiPay = 'CITI_PAY',
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  DinersClub = 'DINERS_CLUB',
  Discover = 'DISCOVER',
  Eftpos = 'EFTPOS',
  Elo = 'ELO',
  Gift = 'GIFT',
  GooglePay = 'GOOGLE_PAY',
  GooglePayAmericanExpress = 'GOOGLE_PAY_AMERICAN_EXPRESS',
  GooglePayDiscover = 'GOOGLE_PAY_DISCOVER',
  GooglePayMastercard = 'GOOGLE_PAY_MASTERCARD',
  GooglePayVisa = 'GOOGLE_PAY_VISA',
  GoogleWallet = 'GOOGLE_WALLET',
  IdCredit = 'ID_CREDIT',
  Interac = 'INTERAC',
  Jcb = 'JCB',
  Laser = 'LASER',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Masterpass = 'MASTERPASS',
  Paypal = 'PAYPAL',
  Prepaid = 'PREPAID',
  PrivateLabel = 'PRIVATE_LABEL',
  QuicPay = 'QUIC_PAY',
  Solo = 'SOLO',
  Suica = 'SUICA',
  Switch = 'SWITCH',
  Unknown = 'UNKNOWN',
  Venmo = 'VENMO',
  Visa = 'VISA',
  VisaCheckout = 'VISA_CHECKOUT',
  VisaVpay = 'VISA_VPAY',
}

export enum PaymentLevel {
  AboveSite = 'ABOVE_SITE',
  SiteLevel = 'SITE_LEVEL',
}

export enum PaymentMethod {
  Activation = 'Activation',
  Cash = 'Cash',
  CashChange = 'CashChange',
  Cheque = 'Cheque',
  Credit = 'Credit',
  Debit = 'Debit',
  Ebt = 'EBT',
  EbtFs = 'EBT_FS',
  Loyalty = 'Loyalty',
  Na = 'NA',
  Other = 'Other',
  PrePaid = 'PrePaid',
  Recharge = 'Recharge',
}

export enum PaymentMethodEvaluationMethod {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE',
}

export enum PhoneType {
  Home = 'HOME',
  Location = 'LOCATION',
  Mobile = 'MOBILE',
  Work = 'WORK',
}

export enum PointChangeSortBy {
  CreatedAt = 'CREATED_AT',
}

export enum PointChangeSourceType {
  CommandCenterUser = 'COMMAND_CENTER_USER',
  Expiration = 'EXPIRATION',
  GameSession = 'GAME_SESSION',
  LoyaltyEvent = 'LOYALTY_EVENT',
  OfferPurchase = 'OFFER_PURCHASE',
  PointChange = 'POINT_CHANGE',
  PromocodeUseRequest = 'PROMOCODE_USE_REQUEST',
  Transaction = 'TRANSACTION',
  User = 'USER',
}

export enum PointChangeType {
  ApiPointAdjustment = 'API_POINT_ADJUSTMENT',
  BreakageReturn = 'BREAKAGE_RETURN',
  CancelRedemption = 'CANCEL_REDEMPTION',
  CancelTransaction = 'CANCEL_TRANSACTION',
  Migration = 'MIGRATION',
  OfferPurchase = 'OFFER_PURCHASE',
  OfferPurchaseReturn = 'OFFER_PURCHASE_RETURN',
  OptVoucherPurchase = 'OPT_VOUCHER_PURCHASE',
  PointAward = 'POINT_AWARD',
  PointExpiration = 'POINT_EXPIRATION',
  ScrubData = 'SCRUB_DATA',
  VoucherExpiration = 'VOUCHER_EXPIRATION',
}

export enum PrimaryTransactionStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Refunding = 'REFUNDING',
  Voiding = 'VOIDING',
}

export enum ProductGroupKey {
  FuelGradeId = 'FUEL_GRADE_ID',
  Group = 'GROUP',
  Sku = 'SKU',
  Upc = 'UPC',
}

export enum PublicationStatusType {
  All = 'ALL',
  Expired = 'EXPIRED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export type PageInfo = {
  endCursor: Maybe<Scalars['ID']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['ID']>;
};

export type PayAtPump = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
};

export type PayInside = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
};

export type PaymentApplePaySessionCreateInput = {
  displayName: Scalars['String'];
  initiative: Scalars['String'];
  initiativeContext: Scalars['String'];
  merchantIdentifier: Scalars['String'];
};

export type PaymentInstrument = {
  accountNumber: Maybe<Scalars['String']>;
  accountType: PaymentAccountType;
  address: Maybe<BillingAddress>;
  alias: Maybe<Scalars['String']>;
  balance: Maybe<Scalars['Float']>;
  binRange: Maybe<Scalars['String']>;
  createdByDevice: Maybe<CustomerDevice>;
  displayNumber: Maybe<Scalars['String']>;
  expiration: Maybe<PaymentInstrumentExpiration>;
  isDefault: Maybe<Scalars['Boolean']>;
  last4: Maybe<Scalars['String']>;
  paymentProvider: Maybe<PaymentProvider>;
  paymentType: Maybe<PaymentInstrumentType>;
  providerNickName: Maybe<Scalars['String']>;
  status: PaymentInstrumentStatus;
  uuid: Scalars['ID'];
};

export type PaymentInstrumentExpiration = {
  expirationMonth: Maybe<Scalars['String']>;
  expirationYear: Maybe<Scalars['String']>;
};

export type PaymentProvider = {
  name: Scalars['String'];
};

export type PaymentProviderConfiguration = {
  customData: Maybe<Scalars['OCJSON']>;
};

export type PaymentTokenInput = {
  applePay?: InputMaybe<Scalars['String']>;
  googlePay?: InputMaybe<Scalars['String']>;
};

export type PaymentWalletConfiguration = {
  allowedAccountTypes: Array<PaymentAccountType>;
  maxInstruments: Scalars['Int'];
};

export type PersonName = {
  first: Maybe<Scalars['String']>;
  last: Maybe<Scalars['String']>;
  middle: Maybe<Scalars['String']>;
  suffix: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
};

export type PinCodeConfiguration = {
  attemptThrottle: Scalars['Int'];
  blockingDuration: Scalars['Int'];
  maxAttempts: Scalars['Int'];
  verifyMaxAttempts: Scalars['Int'];
  verifyThrottling: Scalars['Boolean'];
};

export type PointChange = {
  createdAt: Scalars['OCDateTime'];
  id: Scalars['ID'];
  notes: Maybe<Scalars['String']>;
  offer: Maybe<Offer>;
  quantity: Scalars['Int'];
  source: Maybe<PointChangeSource>;
  sourceType: Maybe<PointChangeSourceType>;
  type: Maybe<PointChangeType>;
};

export type PointChangeConnection = {
  edges: Array<Maybe<PointChangeEdge>>;
  pageInfo: PageInfo;
};

export type PointChangeEdge = {
  cursor: Scalars['ID'];
  node: PointChange;
};

export type PointChangeFilter = {
  idEquals?: InputMaybe<Scalars['ID']>;
};

export type PointChangeSource =
  | ActivateTransaction
  | ActivateUser
  | Chain
  | CommandCenterUser
  | GameSession
  | LoyaltyEvent
  | OfferPurchase
  | PointChange
  | PromocodeUseRequest;

export enum PointsStrategy {
  Cents = 'CENTS',
  NotUsed = 'NOT_USED',
  Points = 'POINTS',
}

export type PreferenceValue = {
  booleanType: Maybe<Scalars['Boolean']>;
  floatType: Maybe<Scalars['Float']>;
  intType: Maybe<Scalars['Int']>;
  stringType: Maybe<Scalars['String']>;
};

export type PreferenceValueCreate = {
  booleanType?: InputMaybe<Scalars['Boolean']>;
  floatType?: InputMaybe<Scalars['Float']>;
  intType?: InputMaybe<Scalars['Int']>;
  stringType?: InputMaybe<Scalars['String']>;
};

export type PreferenceValueUpdate = {
  booleanType?: InputMaybe<Scalars['Boolean']>;
  floatType?: InputMaybe<Scalars['Float']>;
  intType?: InputMaybe<Scalars['Int']>;
  stringType?: InputMaybe<Scalars['String']>;
};

export type PreferredFuelGradeConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
};

export type ProductCategory = {
  categoryRestriction: Maybe<CategoryRestrictions>;
  chainId: Scalars['ID'];
  createdAt: Scalars['OCDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  rank: Scalars['Int'];
};

export type ProductCategoryConnection = {
  edges: Array<ProductCategoryEdge>;
  pageInfo: PageInfo;
};

export type ProductCategoryEdge = {
  cursor: Scalars['ID'];
  node: ProductCategory;
};

export type ProductCategoryFilter = {
  categoryRestrictionEquals?: InputMaybe<CategoryRestrictions>;
  chainIdEquals?: InputMaybe<Scalars['ID']>;
  idEquals?: InputMaybe<Scalars['ID']>;
};

export type ProductGroup = {
  createdAt: Scalars['OCDateTime'];
  description: Maybe<Scalars['String']>;
  discontinued: Scalars['Boolean'];
  id: Scalars['ID'];
  key: ProductGroupKey;
  name: Scalars['String'];
  values: Array<Maybe<Scalars['String']>>;
};

export type ProductInterest = {
  membershipId: Scalars['ID'];
  productCategoryId: Scalars['ID'];
};

export type ProductInterestInput = {
  productCategoryId: Scalars['ID'];
};

export type ProductInterestsConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
  isEAIVPromotionOnProfileCompletedEnabled: Scalars['Boolean'];
};

export type ProductInterestsReplaceInput = {
  productCategoryIds: Array<Scalars['ID']>;
};

export type ProductPreference = {
  displayCategoryId: Scalars['ID'];
  membershipId: Scalars['ID'];
};

export type ProductPreferenceInput = {
  displayCategoryId: Scalars['ID'];
};

export type ProductPreferenceReplaceInput = {
  displayCategoryIds: Array<Scalars['ID']>;
};

export enum PromocodePublicationStatus {
  Expired = 'EXPIRED',
  NotPublished = 'NOT_PUBLISHED',
  Published = 'PUBLISHED',
}

export enum PromocodeStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
}

export type PromocodeUseRequest = {
  id: Scalars['ID'];
  membership: Membership;
  offer: Offer;
};

export type PurchaseProgress = {
  quantityPurchased: Scalars['Float'];
  quantityRequired: Scalars['Float'];
};

export type Query = {
  activityFeed: ActivityFeedConnection;
  appConfig: AppConfig;
  banners: Array<Banner>;
  currentCustomer: Maybe<Customer>;
  displayCategories: DisplayCategoryConnection;
  eaivAgeVerification: EaivAgeVerification;
  eaivIdentityVerification: EaivIdentityVerification;
  fuelProductGroups: Maybe<Array<FuelProductGroup>>;
  getBraintreeClientToken: Maybe<ClientToken>;
  getFiservClientAccessToken: FiservClientToken;
  guestGetBraintreeClientToken: ClientToken;
  guestLocations: LocationConnection;
  guestSurvey: Maybe<Survey>;
  guestTransaction: GuestTransaction;
  locations: Maybe<LocationConnection>;
  productCategories: ProductCategoryConnection;
  rudderstack: Rudderstack;
  survey: Survey;
  surveys: SurveyConnection;
  transaction: Maybe<Transaction>;
};

export type QueryActivityFeedArgs = {
  after?: InputMaybe<Scalars['OCBase64Cursor']>;
  before?: InputMaybe<Scalars['OCBase64Cursor']>;
  filter: ActivityFeedFilter;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryBannersArgs = {
  filter: BannerFilter;
};

export type QueryDisplayCategoriesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<DisplayCategoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryFuelProductGroupsArgs = {
  filter?: InputMaybe<FuelProductGroupFilter>;
};

export type QueryGuestLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<GuestLocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryGuestSurveyArgs = {
  guestSurveyId: Scalars['String'];
};

export type QueryGuestTransactionArgs = {
  uuid: Scalars['ID'];
};

export type QueryLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryProductCategoriesArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<ProductCategoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QuerySurveyArgs = {
  uuid: Scalars['OCUUID'];
};

export type QuerySurveysArgs = {
  after?: InputMaybe<Scalars['OCBase64Cursor']>;
  before?: InputMaybe<Scalars['OCBase64Cursor']>;
  filter?: InputMaybe<SurveyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryTransactionArgs = {
  uuid: Scalars['ID'];
};

export enum RandomAwardType {
  NoAward = 'NO_AWARD',
  Points = 'POINTS',
  TryAgain = 'TRY_AGAIN',
  Vouchers = 'VOUCHERS',
}

export enum ResourceItemType {
  CarWash = 'CAR_WASH',
  Fuel = 'FUEL',
  Lottery = 'LOTTERY',
  MiscConvenience = 'MISC_CONVENIENCE',
  Snack = 'SNACK',
  Tax = 'TAX',
  Tobacco = 'TOBACCO',
}

export type RandomAwardConfig = {
  award: Maybe<AwardPointsOrVouchers>;
  awardType: RandomAwardType;
  limit: Scalars['Int'];
  sequenceNumber: Scalars['Int'];
};

export type ReceiptLine = {
  lineNumber: Maybe<Scalars['Int']>;
  value: Maybe<Scalars['String']>;
};

export type RedeemPromocodeResult = RedeemPromocodeResultFail | RedeemPromocodeResultSuccess;

export type RedeemPromocodeResultFail = {
  reason: Maybe<Scalars['String']>;
};

export type RedeemPromocodeResultSuccess = {
  award: Maybe<AwardPointsOrVouchers>;
};

export type ResourceItem = CarWashItem | FuelItem | StoreItem;

export type ResourceItemFilter = {
  descriptionContains?: InputMaybe<Scalars['String']>;
  descriptionEquals?: InputMaybe<Scalars['String']>;
  itemTypeContains?: InputMaybe<Array<InputMaybe<ResourceItemType>>>;
  itemTypeEquals?: InputMaybe<ResourceItemType>;
  merchandiseCodeContains?: InputMaybe<Scalars['String']>;
  merchandiseCodeEquals?: InputMaybe<Scalars['String']>;
  posCodeContains?: InputMaybe<Scalars['String']>;
  posCodeEquals?: InputMaybe<Scalars['String']>;
  posCodeFormatContains?: InputMaybe<Scalars['String']>;
  posCodeFormatEquals?: InputMaybe<Scalars['String']>;
  posCodeModifierContains?: InputMaybe<Scalars['String']>;
  posCodeModifierEquals?: InputMaybe<Scalars['String']>;
  receiptDescriptionContains?: InputMaybe<Scalars['String']>;
  receiptDescriptionEquals?: InputMaybe<Scalars['String']>;
  skuContains?: InputMaybe<Scalars['String']>;
  skuEquals?: InputMaybe<Scalars['String']>;
  unitOfMeasureContains?: InputMaybe<Scalars['String']>;
  unitOfMeasureEquals?: InputMaybe<Scalars['String']>;
  unitPriceEquals?: InputMaybe<Scalars['Float']>;
  unitPriceMax?: InputMaybe<Scalars['Float']>;
  unitPriceMin?: InputMaybe<Scalars['Float']>;
  uuid?: InputMaybe<Scalars['ID']>;
};

export type RewardProgram = {
  createdAt: Scalars['OCDateTime'];
  longDescription: Scalars['String'];
  programId: Scalars['String'];
  shortDescription: Scalars['String'];
  type: LoyaltyProviderTypeEnum;
  updatedAt: Scalars['OCDateTime'];
  uuid: Scalars['ID'];
};

export type RudderStackConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
};

export type Rudderstack = {
  audienceUuids: Array<Scalars['ID']>;
};

export type RuleFulfillment = {
  fulfilledOffer: Maybe<Offer>;
  fulfillmentCount: Scalars['Int'];
  id: Scalars['ID'];
};

export enum SecondaryTransactionStatus {
  Authorized = 'AUTHORIZED',
  Authorizing = 'AUTHORIZING',
  Cancelled = 'CANCELLED',
  CancellingAuthorized = 'CANCELLING_AUTHORIZED',
  CancellingAuthorizing = 'CANCELLING_AUTHORIZING',
  CancellingPumpEnabled = 'CANCELLING_PUMP_ENABLED',
  CancellingPumpEnabling = 'CANCELLING_PUMP_ENABLING',
  CancellingPumpReserved = 'CANCELLING_PUMP_RESERVED',
  CancellingReservingPump = 'CANCELLING_RESERVING_PUMP',
  Closed = 'CLOSED',
  Fueling = 'FUELING',
  GettingReceipt = 'GETTING_RECEIPT',
  PumpEnabled = 'PUMP_ENABLED',
  PumpEnabling = 'PUMP_ENABLING',
  PumpReserved = 'PUMP_RESERVED',
  ReservingPump = 'RESERVING_PUMP',
  Settled = 'SETTLED',
  Settling = 'SETTLING',
  SiteUnavailable = 'SITE_UNAVAILABLE',
  Started = 'STARTED',
  UnableToGetReceipt = 'UNABLE_TO_GET_RECEIPT',
  WaitingForStacScan = 'WAITING_FOR_STAC_SCAN',
}

export enum SignUpOrigin {
  Android = 'ANDROID',
  /** @deprecated ANDROID and IOS should be used */
  App = 'APP',
  Imported = 'IMPORTED',
  Ios = 'IOS',
  Pos = 'POS',
  Pump = 'PUMP',
  /** @deprecated PUMP and POS should be used */
  PumpPos = 'PUMP_POS',
  RetailerConnect = 'RETAILER_CONNECT',
  Sms = 'SMS',
  Unknown = 'UNKNOWN',
  Web = 'WEB',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Stac = {
  expiration: Maybe<Scalars['OCDateTime']>;
  id: Scalars['String'];
};

export enum StoreLocationEvaluationMethod {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE',
}

export enum SurveyType {
  General = 'GENERAL',
  Wallet = 'WALLET',
}

export type SignInUsernamePasswordUserInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpUsernamePasswordUserInput = {
  birthdate?: InputMaybe<Scalars['OCDate']>;
  brandUuid: Scalars['ID'];
  chainId?: InputMaybe<Scalars['ID']>;
  customData: Scalars['OCJSON'];
  email: Scalars['OCEmailAddress'];
  firstName?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type SiteSystemCarWashItem = {
  description: Maybe<Scalars['String']>;
  merchandiseCode: Maybe<Scalars['String']>;
  posCode: Scalars['String'];
  posCodeFormat: Maybe<Scalars['String']>;
  posCodeModifier: Scalars['String'];
  productCode: Scalars['String'];
  unitPrice: Scalars['Float'];
};

export type SiteSystemConfiguration = {
  details: Maybe<SiteSystemDetails>;
  hardwareVendor: HardwareVendor;
  identifier: Scalars['String'];
  loyaltyEnabled: Scalars['Boolean'];
  mobileEnabled: Scalars['Boolean'];
  uuid: Scalars['ID'];
};

export type SiteSystemConfigurationCreate = {
  hardwareVendor: HardwareVendor;
  identifier: Scalars['String'];
  loyaltyEnabled?: InputMaybe<Scalars['Boolean']>;
  mobileEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type SiteSystemConfigurationFilter = {
  hardwareVendorEquals?: InputMaybe<HardwareVendor>;
  identifierContains?: InputMaybe<Scalars['String']>;
  identifierEquals?: InputMaybe<Scalars['String']>;
  isLoyaltyEnabled?: InputMaybe<Scalars['Boolean']>;
  isMobileEnabled?: InputMaybe<Scalars['Boolean']>;
  isOnlineEquals?: InputMaybe<Scalars['Boolean']>;
  siteConfigurationIdContains?: InputMaybe<Scalars['String']>;
  siteConfigurationIdEquals?: InputMaybe<Scalars['String']>;
};

export type SiteSystemConfigurationUpdate = {
  hardwareVendor?: InputMaybe<HardwareVendor>;
  identifier?: InputMaybe<Scalars['String']>;
  loyaltyEnabled?: InputMaybe<Scalars['Boolean']>;
  mobileEnabled?: InputMaybe<Scalars['Boolean']>;
  uuid: Scalars['ID'];
};

export type SiteSystemDetails = {
  carWashDetails: Array<SiteSystemCarWashItem>;
  fuelConfigDetails: Array<SiteSystemFuelPumpProduct>;
  mobileActive: Maybe<Scalars['Boolean']>;
  mobileVersion: Maybe<Scalars['String']>;
  partialAuthAllowed: Maybe<Scalars['Boolean']>;
  pumpTimeout: Maybe<Scalars['Int']>;
  siteAddress: Maybe<Scalars['String']>;
  siteName: Maybe<Scalars['String']>;
};

export type SiteSystemFuelPumpProduct = {
  description: Maybe<Scalars['String']>;
  merchandiseCode: Maybe<Scalars['String']>;
  outdoorPosition: Maybe<Scalars['String']>;
  posCode: Scalars['String'];
  posCodeFormat: Maybe<Scalars['String']>;
  posCodeModifier: Scalars['String'];
  priceTier: Maybe<ConexxusPriceTier>;
  productCode: Scalars['String'];
  unitPrice: Scalars['Float'];
};

export type SitesSummary = {
  online: Scalars['Int'];
  total: Scalars['Int'];
};

export type SliderResponseRange = {
  count: Scalars['Int'];
  rangeEnd: Scalars['Int'];
  rangeStart: Scalars['Int'];
};

export type SmsOptInConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
  modalAppearanceDelaySeconds: Scalars['Int'];
  modalOfferLoyaltyEventName: Scalars['String'];
  optInAcceptedLoyaltyEventName: Scalars['String'];
  optInRejectedLoyaltyEventName: Scalars['String'];
};

export type SpinWheelGameExecuteInput = {
  offerId: Scalars['ID'];
};

export type SteeringWallet = {
  archivedAt: Maybe<Scalars['OCDateTime']>;
  chainId: Scalars['OCUUID'];
  configuration: SteeringWalletConfiguration;
  createdAt: Scalars['OCDateTime'];
  name: Scalars['String'];
  statistics: WalletSteeringDailySnapshot;
  status: SteeringWalletStatus;
  uuid: Scalars['OCUUID'];
};

export enum SteeringWalletBasis {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
}

export type SteeringWalletConfiguration = {
  basis: Maybe<SteeringWalletBasis>;
  productGroupId: Maybe<Scalars['OCUUID']>;
  reportingPeriod: Maybe<SteeringWalletReportingPeriod>;
  unitOfMeasure: Maybe<UnitOfMeasure>;
  uuid: Scalars['OCUUID'];
};

export type SteeringWalletConfigurationCreateInput = {
  basis: SteeringWalletBasis;
  productGroupId: Scalars['OCUUID'];
  reportingPeriod: SteeringWalletReportingPeriod;
  unitOfMeasure: UnitOfMeasure;
};

export type SteeringWalletConnection = {
  edges: Array<SteeringWalletEdge>;
  pageInfo: PageInfo;
};

export type SteeringWalletCreateInput = {
  chainId: Scalars['OCUUID'];
  configuration: SteeringWalletConfigurationCreateInput;
  name: Scalars['String'];
};

export type SteeringWalletEdge = {
  cursor: Scalars['OCBase64Cursor'];
  node: SteeringWallet;
};

export type SteeringWalletFilter = {
  chainIdEquals?: InputMaybe<Scalars['OCUUID']>;
  search?: InputMaybe<Scalars['String']>;
  statusEquals?: InputMaybe<SteeringWalletStatus>;
  uuidContains?: InputMaybe<Array<Scalars['OCUUID']>>;
};

export enum SteeringWalletReportingPeriod {
  NinetyDays = 'NINETY_DAYS',
  ThirtyDays = 'THIRTY_DAYS',
}

export enum SteeringWalletStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
}

export type SteeringWalletUpdateInput = {
  name: Scalars['String'];
  uuid: Scalars['OCUUID'];
};

export type StoreItem = {
  additionalAttributes: Maybe<Scalars['OCJSON']>;
  description: Maybe<Scalars['String']>;
  isAgeRestricted: Maybe<Scalars['Boolean']>;
  itemType: Maybe<ResourceItemType>;
  merchandiseCode: Maybe<Scalars['String']>;
  posCode: Maybe<Scalars['String']>;
  posCodeFormat: Maybe<Scalars['String']>;
  posCodeModifier: Maybe<Scalars['String']>;
  receiptDescription: Maybe<Scalars['String']>;
  sku: Scalars['String'];
  taxCode: Maybe<Scalars['String']>;
  unitOfMeasure: Maybe<Scalars['String']>;
  unitPrice: Maybe<ItemPrice>;
  uuid: Scalars['ID'];
};

export type StoreItemConnection = {
  edges: Maybe<Array<Maybe<StoreItemEdge>>>;
  pageInfo: PageInfo;
};

export type StoreItemEdge = {
  cursor: Scalars['ID'];
  node: Maybe<StoreItem>;
};

export type StoreLocatorConfiguration = {
  fuelPricesMode: FuelPricesMode;
};

export type Survey = {
  award: Maybe<AwardPointsOrVouchers>;
  questions: Array<SurveyQuestion>;
  uuid: Scalars['OCUUID'];
};

export type SurveyAnswerInput = {
  multipleChoice?: InputMaybe<SurveyAnswerMultipleChoiceInput>;
  singleChoice?: InputMaybe<SurveyAnswerSingleChoiceInput>;
  slider?: InputMaybe<SurveyAnswerIntSliderInput>;
};

export type SurveyAnswerIntSliderInput = {
  value: Scalars['Int'];
};

export type SurveyAnswerMultipleChoiceInput = {
  optionUuids: Array<Scalars['OCUUID']>;
};

export type SurveyAnswerSendInput = {
  fields: Array<SurveyFieldInput>;
  questionUuid: Scalars['OCUUID'];
};

export type SurveyAnswerSingleChoiceInput = {
  optionUuid: Scalars['OCUUID'];
};

export type SurveyAnswerSubmitInput = {
  fields: Array<SurveyFieldInput>;
  memberUuid: Scalars['OCUUID'];
  questionUuid: Scalars['OCUUID'];
};

export type SurveyAnswersSubmitInput = {
  answers: Array<SurveyQuestionAnswerInput>;
};

export type SurveyConnection = {
  edges: Array<SurveyEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type SurveyDeployInput = {
  audienceUuid: Scalars['OCUUID'];
  channel: SurveyDeploymentChannel;
  rolloutType: SurveyDeploymentRolloutType;
  surveyUuid: Scalars['OCUUID'];
};

export type SurveyDeployment = {
  configuration: SurveyDeploymentConfiguration;
  createdAt: Scalars['OCDateTime'];
  status: SurveyDeploymentStatus;
  uuid: Scalars['OCUUID'];
};

export enum SurveyDeploymentChannel {
  Experience = 'EXPERIENCE',
}

export type SurveyDeploymentConfiguration = {
  channel: Maybe<SurveyDeploymentChannel>;
  rolloutType: Maybe<SurveyDeploymentRolloutType>;
};

export type SurveyDeploymentConnection = {
  edges: Array<SurveyDeploymentEdge>;
  pageInfo: PageInfo;
};

export type SurveyDeploymentEdge = {
  cursor: Scalars['OCBase64Cursor'];
  node: SurveyDeployment;
};

export enum SurveyDeploymentRolloutType {
  AtDeployment = 'AT_DEPLOYMENT',
  OpenEnded = 'OPEN_ENDED',
}

export enum SurveyDeploymentStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Creating = 'CREATING',
  Deployed = 'DEPLOYED',
  Publishing = 'PUBLISHING',
}

export type SurveyDismissInput = {
  surveyUuid: Scalars['OCUUID'];
};

export type SurveyEdge = {
  cursor: Scalars['OCBase64Cursor'];
  node: Survey;
};

export type SurveyField = {
  label: Scalars['String'];
  uuid: Scalars['OCUUID'];
};

export type SurveyFieldInput = {
  answer: Scalars['String'];
  uuid: Scalars['OCUUID'];
};

export type SurveyFieldIntSlider = SurveyField & {
  configuration: SurveyFieldIntSliderConfiguration;
  label: Scalars['String'];
  uuid: Scalars['OCUUID'];
};

export type SurveyFieldIntSliderConfiguration = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export type SurveyFilter = {
  chainIdEquals?: InputMaybe<Scalars['OCUUID']>;
  includeDismissed?: InputMaybe<Scalars['Boolean']>;
  statusEquals?: InputMaybe<SurveyStatus>;
  typeEquals?: InputMaybe<SurveyType>;
  uuidContains?: InputMaybe<Array<Scalars['OCUUID']>>;
};

export type SurveyQuestion = {
  /** @deprecated Use the options field instead. This field will be removed in the future. */
  fields: Array<SurveyField>;
  index: Scalars['Int'];
  questionText: Scalars['String'];
  uuid: Scalars['OCUUID'];
};

export type SurveyQuestionAnswerInput = {
  answer: SurveyAnswerInput;
  uuid: Scalars['OCUUID'];
};

export type SurveyQuestionConfigurationInput = {
  multipleChoice?: InputMaybe<SurveyQuestionMultipleChoiceConfigurationInput>;
  singleChoice?: InputMaybe<SurveyQuestionSingleChoiceConfigurationInput>;
  slider?: InputMaybe<SurveyQuestionSliderConfigurationInput>;
};

export type SurveyQuestionIntSlider = SurveyQuestion & {
  /** @deprecated Use the options field instead. This field will be removed in the future. */
  fields: Array<SurveyField>;
  index: Scalars['Int'];
  max: Scalars['Int'];
  min: Scalars['Int'];
  questionText: Scalars['String'];
  uuid: Scalars['OCUUID'];
};

export type SurveyQuestionMultipleChoice = SurveyQuestion & {
  /** @deprecated Use the options field instead. This field will be removed in the future. */
  fields: Array<SurveyField>;
  index: Scalars['Int'];
  options: Array<SurveyQuestionOption>;
  questionText: Scalars['String'];
  uuid: Scalars['OCUUID'];
};

export type SurveyQuestionMultipleChoiceConfigurationInput = {
  options: Array<Scalars['String']>;
};

export type SurveyQuestionOption = {
  index: Scalars['Int'];
  label: Scalars['String'];
  uuid: Scalars['OCUUID'];
};

export type SurveyQuestionResponseStatistics = {
  locationUuid: Maybe<Scalars['OCUUID']>;
  optionSelectionCounters: Array<OptionSelectionCount>;
  sliderResponseRanges: Array<SliderResponseRange>;
};

export type SurveyQuestionSingleChoice = SurveyQuestion & {
  /** @deprecated Use the options field instead. This field will be removed in the future. */
  fields: Array<SurveyField>;
  index: Scalars['Int'];
  options: Array<SurveyQuestionOption>;
  questionText: Scalars['String'];
  uuid: Scalars['OCUUID'];
};

export type SurveyQuestionSingleChoiceConfigurationInput = {
  options: Array<Scalars['String']>;
};

export type SurveyQuestionSliderConfigurationInput = {
  max: Scalars['Int'];
  min: Scalars['Int'];
};

export enum SurveyStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
}

export type SurveyViewsCreateInput = {
  surveyUuids: Array<Scalars['OCUUID']>;
};

export enum TransactionStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Canceled = 'CANCELED',
  Closed = 'CLOSED',
  CompletedFailedAuthorization = 'COMPLETED_FAILED_AUTHORIZATION',
  CompletedFailedOnReceipt = 'COMPLETED_FAILED_ON_RECEIPT',
  CompletedFailedSettlement = 'COMPLETED_FAILED_SETTLEMENT',
  CompletedFailedToEnablePump = 'COMPLETED_FAILED_TO_ENABLE_PUMP',
  CompletedFailedToReservePump = 'COMPLETED_FAILED_TO_RESERVE_PUMP',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  FullReversal = 'FULL_REVERSAL',
  FullReversalPending = 'FULL_REVERSAL_PENDING',
  Open = 'OPEN',
  PartialPayment = 'PARTIAL_PAYMENT',
  PartialRefundTransaction = 'PARTIAL_REFUND_TRANSACTION',
  Pending = 'PENDING',
  PendingRefundReceipt = 'PENDING_REFUND_RECEIPT',
  Processing = 'PROCESSING',
  ReceiptPending = 'RECEIPT_PENDING',
  RefundPending = 'REFUND_PENDING',
  RefundTransaction = 'REFUND_TRANSACTION',
  Start = 'START',
  Unclaimed = 'UNCLAIMED',
  Unknown = 'UNKNOWN',
  VoidPending = 'VOID_PENDING',
  VoidTransaction = 'VOID_TRANSACTION',
}

export enum TransactionType {
  AtPump = 'AT_PUMP',
  InStore = 'IN_STORE',
}

export type Tenant = {
  name: Scalars['String'];
};

export type Tokens = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type TotalAvailableRollback = {
  fuelProductGroup: Maybe<FuelProductGroup>;
  locationName: Maybe<Scalars['String']>;
  locationUuid: Maybe<Scalars['ID']>;
  moneyAmount: Scalars['Int'];
  offers: Maybe<Array<Offer>>;
};

export type TotalAvailableRollbackConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
};

export type Transaction = {
  additionalReceiptData: Maybe<Scalars['OCJSON']>;
  amount: Maybe<ItemPrice>;
  authCode: Maybe<Scalars['String']>;
  brandSpecificLocationId: Maybe<Scalars['String']>;
  completedAt: Maybe<Scalars['OCDateTime']>;
  currency: Currency;
  customerUuid: Maybe<Scalars['ID']>;
  fuelPumpStatus: Maybe<FuelPumpStatus>;
  fuelReceipt: Maybe<FuelReceipt>;
  fuelingPosition: Maybe<Scalars['String']>;
  hardwareVendor: Maybe<HardwareVendor>;
  initiationResource: Maybe<LocationResourceType>;
  items: Maybe<Array<Maybe<TransactionItem>>>;
  lastUpdatedAt: Scalars['OCDateTime'];
  location: Maybe<Location>;
  loyaltyInstruments: Maybe<Array<Maybe<LoyaltyInstrument>>>;
  loyaltyLevel: Maybe<LoyaltyLevel>;
  paymentInfo: Maybe<Array<Maybe<TransactionPaymentInfo>>>;
  paymentInstrument: Maybe<PaymentInstrument>;
  paymentLevel: Maybe<PaymentLevel>;
  primaryStatus: PrimaryTransactionStatus;
  rawReceipt: Maybe<Scalars['String']>;
  receiptLines: Maybe<Array<Maybe<ReceiptLine>>>;
  secondaryStatus: SecondaryTransactionStatus;
  sourceId: Maybe<Scalars['ID']>;
  stac: Maybe<Stac>;
  startedAt: Scalars['OCDateTime'];
  /** @deprecated Use primaryStatus and secondaryStatus */
  status: Maybe<TransactionStatus>;
  tax: Maybe<ItemPrice>;
  transactionType: TransactionType;
  umti: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

export type TransactionConnection = {
  edges: Maybe<Array<Maybe<TransactionEdge>>>;
  pageInfo: PageInfo;
};

export type TransactionDiscountAwards = AwardTransactionMoneyDiscount | AwardTransactionPercentDiscount;

export type TransactionEdge = {
  cursor: Scalars['ID'];
  node: Maybe<Transaction>;
};

export type TransactionFilter = {
  amountMax?: InputMaybe<Scalars['Float']>;
  amountMin?: InputMaybe<Scalars['Float']>;
  authCodeContains?: InputMaybe<Scalars['String']>;
  authCodeEquals?: InputMaybe<Scalars['String']>;
  brand?: InputMaybe<BrandFilter>;
  completedAtMax?: InputMaybe<Scalars['OCDateTime']>;
  completedAtMin?: InputMaybe<Scalars['OCDateTime']>;
  fuelPumpStatusContains?: InputMaybe<Array<InputMaybe<FuelPumpStatus>>>;
  fuelPumpStatusEquals?: InputMaybe<FuelPumpStatus>;
  fuelingPositionEquals?: InputMaybe<Scalars['String']>;
  hardwareVendorContains?: InputMaybe<Array<InputMaybe<HardwareVendor>>>;
  hardwareVendorEquals?: InputMaybe<HardwareVendor>;
  initiationResourceContains?: InputMaybe<Array<InputMaybe<LocationResourceType>>>;
  initiationResourceEquals?: InputMaybe<LocationResourceType>;
  items?: InputMaybe<ResourceItemFilter>;
  lastUpdatedAtMax?: InputMaybe<Scalars['OCDateTime']>;
  lastUpdatedAtMin?: InputMaybe<Scalars['OCDateTime']>;
  location?: InputMaybe<LocationFilter>;
  loyaltyInstrument?: InputMaybe<LoyaltyInstrumentFilter>;
  loyaltyLevelContains?: InputMaybe<Array<InputMaybe<LoyaltyLevel>>>;
  loyaltyLevelEquals?: InputMaybe<LoyaltyLevel>;
  paymentInfo?: InputMaybe<TransactionPaymentInfoFilter>;
  paymentLevelContains?: InputMaybe<Array<InputMaybe<PaymentLevel>>>;
  paymentLevelEquals?: InputMaybe<PaymentLevel>;
  primaryStatusContains?: InputMaybe<Array<InputMaybe<PrimaryTransactionStatus>>>;
  primaryStatusEquals?: InputMaybe<PrimaryTransactionStatus>;
  secondaryStatusContains?: InputMaybe<Array<InputMaybe<SecondaryTransactionStatus>>>;
  secondaryStatusEquals?: InputMaybe<SecondaryTransactionStatus>;
  stacEquals?: InputMaybe<Scalars['String']>;
  startedAtMax?: InputMaybe<Scalars['OCDateTime']>;
  startedAtMin?: InputMaybe<Scalars['OCDateTime']>;
  taxMax?: InputMaybe<Scalars['Float']>;
  taxMin?: InputMaybe<Scalars['Float']>;
  typeContains?: InputMaybe<Array<InputMaybe<TransactionType>>>;
  typeEquals?: InputMaybe<TransactionType>;
  umtiContains?: InputMaybe<Scalars['String']>;
  umtiEquals?: InputMaybe<Scalars['String']>;
  uuidEquals?: InputMaybe<Scalars['ID']>;
};

export type TransactionItem = {
  amount: Maybe<ItemPrice>;
  discountApplied: Maybe<ItemPrice>;
  discountDescription: Maybe<Scalars['String']>;
  itemDescription: Maybe<Scalars['String']>;
  qty: Maybe<Scalars['Float']>;
  refundedAmount: Maybe<ItemPrice>;
  resourceItem: Maybe<ResourceItem>;
  tax: Maybe<ItemPrice>;
};

export type TransactionPaymentInfo = {
  cardCircuit: Maybe<Scalars['String']>;
  cardPANPrint: Maybe<Scalars['String']>;
  cardType: Maybe<Scalars['String']>;
  hostAuthNumber: Maybe<Scalars['String']>;
  paymentMethod: Maybe<PaymentMethod>;
  preAuthAmount: Maybe<Scalars['Float']>;
};

export type TransactionPaymentInfoFilter = {
  cardCircuitEquals?: InputMaybe<Scalars['String']>;
  cardPANPrintEquals?: InputMaybe<Scalars['String']>;
  cardTypeEquals?: InputMaybe<Scalars['String']>;
  hostAuthNumberEquals?: InputMaybe<Scalars['String']>;
  paymentMethodEquals?: InputMaybe<PaymentMethod>;
  preAuthAmountMax?: InputMaybe<Scalars['Float']>;
  preAuthAmountMin?: InputMaybe<Scalars['Float']>;
};

export type TransactionStart = {
  billingZipCode?: InputMaybe<Scalars['String']>;
  carWash?: InputMaybe<CarWashInput>;
  externalRequestData?: InputMaybe<Scalars['OCJSON']>;
  fuelingPosition?: InputMaybe<Scalars['String']>;
  geoLocation?: InputMaybe<GeoLocationCreate>;
  initiationResource?: InputMaybe<LocationResourceType>;
  items?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  kountSessionId?: InputMaybe<Scalars['String']>;
  locationUuid?: InputMaybe<Scalars['ID']>;
  loyaltyIdentifier?: InputMaybe<Scalars['String']>;
  loyaltyInstruments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  nonce?: InputMaybe<Scalars['String']>;
  paymentInstrumentType?: InputMaybe<PaymentInstrumentType>;
  paymentInstruments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  paymentToken?: InputMaybe<PaymentTokenInput>;
  pinCode?: InputMaybe<CustomerPinCode>;
  printReceipt?: InputMaybe<Scalars['Boolean']>;
  promptForCarWash?: InputMaybe<Scalars['Boolean']>;
  siteId?: InputMaybe<Scalars['String']>;
  stac?: InputMaybe<Scalars['String']>;
  transactionType: TransactionType;
};

export type TriggerPreferences = {
  triggerOnFulfillment: Scalars['Boolean'];
  triggerOnProgress: Scalars['Boolean'];
  triggerOnVisibility: Scalars['Boolean'];
};

export type TriggerPreferencesInput = {
  triggerOnFulfillment?: InputMaybe<Scalars['Boolean']>;
  triggerOnProgress?: InputMaybe<Scalars['Boolean']>;
  triggerOnVisibility?: InputMaybe<Scalars['Boolean']>;
};

export enum UnitOfMeasure {
  Amount = 'AMOUNT',
  Gallon = 'GALLON',
  Unit = 'UNIT',
}

export enum VouchersSortBy {
  CreatedAt = 'CREATED_AT',
}

export enum VoucherSourceType {
  GameSession = 'GAME_SESSION',
  LoyaltyEvent = 'LOYALTY_EVENT',
  Offer = 'OFFER',
  OfferPurchase = 'OFFER_PURCHASE',
  PromocodeUseRequest = 'PROMOCODE_USE_REQUEST',
  Transaction = 'TRANSACTION',
}

export enum VoucherState {
  Abandoned = 'ABANDONED',
  Expired = 'EXPIRED',
  Fulfilled = 'FULFILLED',
  Unfulfilled = 'UNFULFILLED',
}

export type ValidateAndResetCustomerPasswordInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  resetToken: Scalars['String'];
};

export type VarietyDiscountAwards = AwardPriceOverride | AwardProductDiscount | AwardTransactionMoneyDiscount;

export type VarietyProgress = {
  productGroups: Array<ProductGroup>;
  quantityPurchased: Scalars['Float'];
  quantityRequired: Scalars['Float'];
};

export type VisitsProgress = {
  visits: Scalars['Int'];
  visitsRequired: Scalars['Int'];
};

export type Voucher = {
  createdAt: Scalars['OCDateTime'];
  deletedAt: Maybe<Scalars['OCDateTime']>;
  expireAt: Maybe<Scalars['OCDateTime']>;
  fulfilledBy: Maybe<VoucherFulfilledBy>;
  fulfilledById: Maybe<Scalars['String']>;
  fulfilledByType: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  membership: Membership;
  offer: Maybe<Offer>;
  reservedKey: Maybe<Scalars['String']>;
  reservedUntil: Maybe<Scalars['OCDateTime']>;
  source: Maybe<VoucherSource>;
  sourceId: Maybe<Scalars['String']>;
  sourceType: Maybe<Scalars['String']>;
  state: VoucherState;
  updatedAt: Scalars['OCDateTime'];
};

export type VoucherConnection = {
  edges: Array<VoucherEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type VoucherEdge = {
  cursor: Scalars['ID'];
  node: Voucher;
};

export type VoucherFilter = {
  membershipUuid?: InputMaybe<Scalars['OCUUID']>;
  returnable?: InputMaybe<Scalars['Boolean']>;
  sourceType?: InputMaybe<VoucherSourceType>;
  state?: InputMaybe<VoucherState>;
};

export type VoucherFulfilledBy = ActivateTransaction | GameSession | LoyaltyEvent;

export type VoucherSource =
  | ActivateTransaction
  | GameSession
  | LoyaltyEvent
  | Offer
  | OfferPurchase
  | PromocodeUseRequest;

export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type WalletConfiguration = FeatureConfiguration & {
  enabled: Scalars['Boolean'];
};

export type WalletSteeringDailySnapshot = {
  date: Scalars['OCDate'];
  membershipCount: Maybe<Scalars['Int']>;
  uuid: Scalars['OCUUID'];
  walletCapacity: Scalars['Float'];
  walletOpportunity: Maybe<Scalars['Float']>;
  walletShare: Maybe<Scalars['Float']>;
  walletUuid: Scalars['OCUUID'];
};

export type WalletSteeringStatisticsFilter = {
  endDate: Scalars['OCDate'];
  startDate: Scalars['OCDate'];
  walletUuid: Scalars['OCUUID'];
};

export type WalletSteeringSurvey = Survey & {
  audienceUuid: Scalars['OCUUID'];
  award: Maybe<AwardPointsOrVouchers>;
  chainId: Scalars['OCUUID'];
  configuration: WalletSteeringSurveyConfiguration;
  createdAt: Scalars['OCDateTime'];
  deletedAt: Maybe<Scalars['OCDateTime']>;
  questions: Array<SurveyQuestion>;
  status: SurveyStatus;
  type: SurveyType;
  uuid: Scalars['OCUUID'];
};

export type WalletSteeringSurveyConfiguration = {
  maxUnits: Maybe<Scalars['Int']>;
  minUnits: Maybe<Scalars['Int']>;
  questionText: Maybe<Scalars['String']>;
  steeringWalletUnitOfMeasure: Maybe<UnitOfMeasure>;
  steeringWalletUuid: Maybe<Scalars['OCUUID']>;
};

export type WalletSteeringSurveyCreateInput = {
  maxUnits: Scalars['Int'];
  minUnits: Scalars['Int'];
  questionText: Scalars['String'];
  steeringWalletUuid: Scalars['OCUUID'];
};

export type WalletSteeringSurveyUpdateInput = {
  maxUnits: Scalars['Int'];
  minUnits: Scalars['Int'];
  questionText: Scalars['String'];
  uuid: Scalars['OCUUID'];
};

export type WorldpayConfiguration = PaymentProviderConfiguration & {
  customData: Maybe<Scalars['OCJSON']>;
  eProtectGooglePayPageId: Scalars['String'];
  eProtectPayPageId: Scalars['String'];
  eProtectWebSdkApiUrl: Scalars['String'];
  eProtectWebSdkUrl: Scalars['String'];
};

export type CreateOtpSessionResult = {
  session: Scalars['String'];
};

export type CustomerMakeActiveMutationVariables = Exact<{ [key: string]: never }>;

export type CustomerMakeActiveMutation = {
  membershipStateTransition: {
    __typename: 'Membership';
    id: string;
    customerUuid: string | null;
    state: MEMBERSHIP_STATE;
  } | null;
};

export type CustomerPaymentInstrumentCreateMutationVariables = Exact<{
  input: CustomerPaymentInstrumentCreate;
}>;

export type CustomerPaymentInstrumentCreateMutation = { customerPaymentInstrumentCreate: { uuid: string } };

export type CustomerPaymentInstrumentDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type CustomerPaymentInstrumentDeleteMutation = {
  customerPaymentInstrumentDelete: Array<{ uuid: string } | null>;
};

export type CustomerResetPinCodeMutationVariables = Exact<{ [key: string]: never }>;

export type CustomerResetPinCodeMutation = { success: boolean | null };

export type CustomerSetPinCodeMutationVariables = Exact<{
  pinCode: Scalars['String'];
}>;

export type CustomerSetPinCodeMutation = { success: boolean | null };

export type CustomerVerifyPinCodeMutationVariables = Exact<{
  pinCode: Scalars['String'];
}>;

export type CustomerVerifyPinCodeMutation = { success: boolean | null };

export type GetAtTheStoreWithPumpsQueryVariables = Exact<{
  geoLocation?: InputMaybe<GeoLocationFilter>;
}>;

export type GetAtTheStoreWithPumpsQuery = {
  store: {
    edges: Array<{
      node: {
        uuid: string;
        siteSystemConfiguration: Array<{
          uuid: string;
          mobileEnabled: boolean;
          details: { fuelConfigDetails: Array<{ outdoorPosition: string | null }> } | null;
        }>;
      };
    }> | null;
  } | null;
};

export type OfferByLoyaltyEventFragment = {
  id: string;
  customData: any | null;
  marketingContents: Array<{
    id: string;
    title: string | null;
    shortDescription: string | null;
    imageUrl: any | null;
    terms: string | null;
    disclaimer: string | null;
    disclaimerUrl: any | null;
  } | null>;
};

export type GetOffersByLoyaltyEventQueryVariables = Exact<{
  loyaltyEventEquals: Scalars['String'];
  publicationStatusEquals: PublicationStatusType;
  visibleStatusEquals: OfferVisibleStatus;
}>;

export type GetOffersByLoyaltyEventQuery = {
  currentCustomer: {
    uuid: string;
    membership: {
      id: string;
      offers: {
        edges: Array<{
          node: {
            id: string;
            customData: any | null;
            marketingContents: Array<{
              id: string;
              title: string | null;
              shortDescription: string | null;
              imageUrl: any | null;
              terms: string | null;
              disclaimer: string | null;
              disclaimerUrl: any | null;
            } | null>;
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type LoyaltyEventCreateMutationVariables = Exact<{
  input: LoyaltyEventCreate;
}>;

export type LoyaltyEventCreateMutation = { event: { eventData: any | null; eventType: string; createdAt: any } | null };

export type OfferPurchaseMutationVariables = Exact<{
  input: OfferPurchaseCreate;
}>;

export type OfferPurchaseMutation = { offers: Array<{ pointCost: number } | null> | null };

export type PointChangeFragment = {
  id: string;
  createdAt: any;
  quantity: number;
  type: PointChangeType | null;
  notes: string | null;
  source:
    | { __typename: 'ActivateTransaction'; id: string }
    | { __typename: 'ActivateUser'; id: string }
    | { __typename: 'Chain'; id: string }
    | { __typename: 'CommandCenterUser' }
    | { __typename: 'GameSession' }
    | { __typename: 'LoyaltyEvent'; eventType: string; createdAt: any }
    | {
        __typename: 'OfferPurchase';
        purchasedWith: {
          id: string;
          name: string;
          marketingContents: Array<{ id: string; title: string | null } | null>;
        };
      }
    | {
        __typename: 'PointChange';
        id: string;
        offer: {
          id: string;
          name: string;
          marketingContents: Array<{ id: string; title: string | null } | null>;
        } | null;
      }
    | { __typename: 'PromocodeUseRequest' }
    | null;
  offer: { id: string; name: string; marketingContents: Array<{ id: string; title: string | null } | null> } | null;
};

export type GetPointsChangeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPointsChangeQuery = {
  transactionHistory: {
    uuid: string;
    membership: {
      id: string;
      pointChanges: {
        edges: Array<{
          cursor: string;
          node: {
            id: string;
            createdAt: any;
            quantity: number;
            type: PointChangeType | null;
            notes: string | null;
            source:
              | { __typename: 'ActivateTransaction'; id: string }
              | { __typename: 'ActivateUser'; id: string }
              | { __typename: 'Chain'; id: string }
              | { __typename: 'CommandCenterUser' }
              | { __typename: 'GameSession' }
              | { __typename: 'LoyaltyEvent'; eventType: string; createdAt: any }
              | {
                  __typename: 'OfferPurchase';
                  purchasedWith: {
                    id: string;
                    name: string;
                    marketingContents: Array<{ id: string; title: string | null } | null>;
                  };
                }
              | {
                  __typename: 'PointChange';
                  id: string;
                  offer: {
                    id: string;
                    name: string;
                    marketingContents: Array<{ id: string; title: string | null } | null>;
                  } | null;
                }
              | { __typename: 'PromocodeUseRequest' }
              | null;
            offer: {
              id: string;
              name: string;
              marketingContents: Array<{ id: string; title: string | null } | null>;
            } | null;
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type CreateApplePaySessionMutationVariables = Exact<{
  input: PaymentApplePaySessionCreateInput;
}>;

export type CreateApplePaySessionMutation = { session: { data: any | null } };

export type ActivateTransactionFragment = {
  id: string;
  totalTax: number | null;
  totalAmount: number | null;
  subtotalAmount: number | null;
  totalDiscountAmount: number | null;
  totalAmountWithoutDiscounts: number | null;
  totalPointsEarned: number;
  totalPointsRefunded: number;
  totalPointsSpent: number;
  createdAt: any;
  lineItems: Array<{
    id: string;
    group: string | null;
    moneyAmount: number | null;
    quantity: number | null;
    sku: string | null;
    upc: string | null;
    fuelGradeId: string | null;
    promotions: Array<{
      promotionAmount: number;
      promotionReason: string;
      offer: {
        id: string;
        marketingContents: Array<{ id: string; title: string | null; shortDescription: string | null } | null>;
      } | null;
    }> | null;
    externalLineItemPromotions: Array<{ id: string; discountAmount: number; promotionReason: string }> | null;
  } | null>;
  location: {
    id: string;
    address1: string | null;
    address2: string | null;
    state: string | null;
    city: string | null;
    name: string | null;
    zipcode: string | null;
  } | null;
  paymentInfos: Array<{ tenderType: string | null; tenderSubtype: string | null }> | null;
};

export type ActivateLineItemPromotionFragment = {
  promotionAmount: number;
  promotionReason: string;
  offer: {
    id: string;
    marketingContents: Array<{ id: string; title: string | null; shortDescription: string | null } | null>;
  } | null;
};

export type GetActivateTransactionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetActivateTransactionQuery = {
  transactionHistory: {
    uuid: string;
    membership: {
      id: string;
      transactions: {
        edges: Array<{
          cursor: string;
          node: {
            id: string;
            totalTax: number | null;
            totalAmount: number | null;
            subtotalAmount: number | null;
            totalDiscountAmount: number | null;
            totalAmountWithoutDiscounts: number | null;
            totalPointsEarned: number;
            totalPointsRefunded: number;
            totalPointsSpent: number;
            createdAt: any;
            lineItems: Array<{
              id: string;
              group: string | null;
              moneyAmount: number | null;
              quantity: number | null;
              sku: string | null;
              upc: string | null;
              fuelGradeId: string | null;
              promotions: Array<{
                promotionAmount: number;
                promotionReason: string;
                offer: {
                  id: string;
                  marketingContents: Array<{
                    id: string;
                    title: string | null;
                    shortDescription: string | null;
                  } | null>;
                } | null;
              }> | null;
              externalLineItemPromotions: Array<{ id: string; discountAmount: number; promotionReason: string }> | null;
            } | null>;
            location: {
              id: string;
              address1: string | null;
              address2: string | null;
              state: string | null;
              city: string | null;
              name: string | null;
              zipcode: string | null;
            } | null;
            paymentInfos: Array<{ tenderType: string | null; tenderSubtype: string | null }> | null;
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type TransactionFragment = {
  uuid: string;
  primaryStatus: PrimaryTransactionStatus;
  secondaryStatus: SecondaryTransactionStatus;
  completedAt: any | null;
  fuelingPosition: string | null;
  amount: { displayPrice: string; price: number } | null;
  tax: { displayPrice: string; price: number } | null;
  fuelReceipt: {
    priceG: string;
    product: string;
    fuelSale: string;
    tax: string;
    gallons: string;
    carWashCode: string | null;
    carWashName: string | null;
    carWashPrice: string | null;
  } | null;
  receiptLines: Array<{ lineNumber: number | null; value: string | null } | null> | null;
  location: {
    address: {
      city: string;
      street1: string;
      street2: string | null;
      state: string;
      zipCode: string;
      geoLocation: { latitude: number; longitude: number } | null;
    } | null;
  } | null;
  paymentInstrument: {
    uuid: string;
    alias: string | null;
    providerNickName: string | null;
    accountNumber: string | null;
    accountType: PaymentAccountType;
    paymentType: PaymentInstrumentType | null;
    balance: number | null;
    isDefault: boolean | null;
    displayNumber: string | null;
    status: PaymentInstrumentStatus;
    address: { zipCode: string } | null;
  } | null;
};

export type GetTransactionQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type GetTransactionQuery = {
  transaction: {
    uuid: string;
    primaryStatus: PrimaryTransactionStatus;
    secondaryStatus: SecondaryTransactionStatus;
    completedAt: any | null;
    fuelingPosition: string | null;
    amount: { displayPrice: string; price: number } | null;
    tax: { displayPrice: string; price: number } | null;
    fuelReceipt: {
      priceG: string;
      product: string;
      fuelSale: string;
      tax: string;
      gallons: string;
      carWashCode: string | null;
      carWashName: string | null;
      carWashPrice: string | null;
    } | null;
    receiptLines: Array<{ lineNumber: number | null; value: string | null } | null> | null;
    location: {
      address: {
        city: string;
        street1: string;
        street2: string | null;
        state: string;
        zipCode: string;
        geoLocation: { latitude: number; longitude: number } | null;
      } | null;
    } | null;
    paymentInstrument: {
      uuid: string;
      alias: string | null;
      providerNickName: string | null;
      accountNumber: string | null;
      accountType: PaymentAccountType;
      paymentType: PaymentInstrumentType | null;
      balance: number | null;
      isDefault: boolean | null;
      displayNumber: string | null;
      status: PaymentInstrumentStatus;
      address: { zipCode: string } | null;
    } | null;
  } | null;
};

export type StartPayInsideTransactionMutationVariables = Exact<{
  input: TransactionStart;
}>;

export type StartPayInsideTransactionMutation = {
  transaction: { uuid: string; stac: { id: string; expiration: any | null } | null } | null;
};

export type StartTransactionMutationVariables = Exact<{
  input: TransactionStart;
}>;

export type StartTransactionMutation = {
  transaction: { uuid: string; primaryStatus: PrimaryTransactionStatus } | null;
};

export type CreateSurveyViewsMutationVariables = Exact<{
  input: SurveyViewsCreateInput;
}>;

export type CreateSurveyViewsMutation = { createSurveyViews: boolean };

export type DismissSurveyMutationVariables = Exact<{
  input: SurveyDismissInput;
}>;

export type DismissSurveyMutation = { surveyDismiss: boolean };

export type GetGuestSurveyQueryVariables = Exact<{
  guestSurveyId: Scalars['String'];
}>;

export type GetGuestSurveyQuery = {
  guestSurvey:
    | {
        __typename: 'GeneralSurvey';
        uuid: string;
        questions: Array<
          | {
              __typename: 'SurveyQuestionIntSlider';
              min: number;
              max: number;
              uuid: string;
              index: number;
              questionText: string;
            }
          | {
              __typename: 'SurveyQuestionMultipleChoice';
              uuid: string;
              index: number;
              questionText: string;
              options: Array<{ uuid: string; index: number; label: string }>;
            }
          | {
              __typename: 'SurveyQuestionSingleChoice';
              uuid: string;
              index: number;
              questionText: string;
              options: Array<{ uuid: string; index: number; label: string }>;
            }
        >;
        configuration: { startDate: any; endDate: any | null };
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucheredOffer: {
                id: string;
                marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
              };
            }
          | null;
      }
    | {
        __typename: 'WalletSteeringSurvey';
        uuid: string;
        questions: Array<
          | {
              __typename: 'SurveyQuestionIntSlider';
              uuid: string;
              index: number;
              questionText: string;
              fields: Array<{ uuid: string }>;
            }
          | {
              __typename: 'SurveyQuestionMultipleChoice';
              uuid: string;
              index: number;
              questionText: string;
              fields: Array<{ uuid: string }>;
            }
          | {
              __typename: 'SurveyQuestionSingleChoice';
              uuid: string;
              index: number;
              questionText: string;
              fields: Array<{ uuid: string }>;
            }
        >;
        configuration: {
          steeringWalletUuid: string | null;
          questionText: string | null;
          steeringWalletUnitOfMeasure: UnitOfMeasure | null;
          maxUnits: number | null;
          minUnits: number | null;
        };
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucheredOffer: {
                id: string;
                marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
              };
            }
          | null;
      }
    | null;
};

export type GetSurveyQueryVariables = Exact<{
  uuid: Scalars['OCUUID'];
}>;

export type GetSurveyQuery = {
  survey:
    | {
        __typename: 'GeneralSurvey';
        uuid: string;
        questions: Array<
          | {
              __typename: 'SurveyQuestionIntSlider';
              min: number;
              max: number;
              uuid: string;
              index: number;
              questionText: string;
            }
          | {
              __typename: 'SurveyQuestionMultipleChoice';
              uuid: string;
              index: number;
              questionText: string;
              options: Array<{ uuid: string; index: number; label: string }>;
            }
          | {
              __typename: 'SurveyQuestionSingleChoice';
              uuid: string;
              index: number;
              questionText: string;
              options: Array<{ uuid: string; index: number; label: string }>;
            }
        >;
        configuration: { startDate: any; endDate: any | null };
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucheredOffer: {
                id: string;
                marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
              };
            }
          | null;
      }
    | {
        __typename: 'WalletSteeringSurvey';
        uuid: string;
        questions: Array<
          | {
              __typename: 'SurveyQuestionIntSlider';
              uuid: string;
              index: number;
              questionText: string;
              fields: Array<{ uuid: string }>;
            }
          | {
              __typename: 'SurveyQuestionMultipleChoice';
              uuid: string;
              index: number;
              questionText: string;
              fields: Array<{ uuid: string }>;
            }
          | {
              __typename: 'SurveyQuestionSingleChoice';
              uuid: string;
              index: number;
              questionText: string;
              fields: Array<{ uuid: string }>;
            }
        >;
        configuration: {
          steeringWalletUuid: string | null;
          questionText: string | null;
          steeringWalletUnitOfMeasure: UnitOfMeasure | null;
          maxUnits: number | null;
          minUnits: number | null;
        };
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucheredOffer: {
                id: string;
                marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
              };
            }
          | null;
      };
};

export type GetSurveysQueryVariables = Exact<{
  filter?: InputMaybe<SurveyFilter>;
}>;

export type GetSurveysQuery = {
  surveys: {
    edges: Array<{
      cursor: any;
      node:
        | {
            __typename: 'GeneralSurvey';
            uuid: string;
            questions: Array<
              | {
                  __typename: 'SurveyQuestionIntSlider';
                  min: number;
                  max: number;
                  uuid: string;
                  index: number;
                  questionText: string;
                }
              | {
                  __typename: 'SurveyQuestionMultipleChoice';
                  uuid: string;
                  index: number;
                  questionText: string;
                  options: Array<{ uuid: string; index: number; label: string }>;
                }
              | {
                  __typename: 'SurveyQuestionSingleChoice';
                  uuid: string;
                  index: number;
                  questionText: string;
                  options: Array<{ uuid: string; index: number; label: string }>;
                }
            >;
            configuration: { startDate: any; endDate: any | null };
            award:
              | { __typename: 'AwardPoints'; points: number }
              | {
                  __typename: 'AwardVouchers';
                  voucheredOffer: {
                    id: string;
                    marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
                  };
                }
              | null;
          }
        | {
            __typename: 'WalletSteeringSurvey';
            uuid: string;
            questions: Array<
              | {
                  __typename: 'SurveyQuestionIntSlider';
                  uuid: string;
                  index: number;
                  questionText: string;
                  fields: Array<{ uuid: string }>;
                }
              | {
                  __typename: 'SurveyQuestionMultipleChoice';
                  uuid: string;
                  index: number;
                  questionText: string;
                  fields: Array<{ uuid: string }>;
                }
              | {
                  __typename: 'SurveyQuestionSingleChoice';
                  uuid: string;
                  index: number;
                  questionText: string;
                  fields: Array<{ uuid: string }>;
                }
            >;
            configuration: {
              steeringWalletUuid: string | null;
              questionText: string | null;
              steeringWalletUnitOfMeasure: UnitOfMeasure | null;
              maxUnits: number | null;
              minUnits: number | null;
            };
            award:
              | { __typename: 'AwardPoints'; points: number }
              | {
                  __typename: 'AwardVouchers';
                  voucheredOffer: {
                    id: string;
                    marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
                  };
                }
              | null;
          };
    }>;
  };
};

export type GetSurveysTotalCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetSurveysTotalCountQuery = { surveys: { totalCount: number } };

export type SubmitGuestSurveyAnswerMutationVariables = Exact<{
  input: GuestSurveyAnswerSendInput;
}>;

export type SubmitGuestSurveyAnswerMutation = { submitGuestSurveyAnswer: boolean };

export type SubmitGuestSurveyAnswersMutationVariables = Exact<{
  input: GuestSurveyAnswersSubmitInput;
}>;

export type SubmitGuestSurveyAnswersMutation = { submitGuestSurveyAnswers: boolean };

export type SubmitSurveyAnswerMutationVariables = Exact<{
  input: SurveyAnswerSendInput;
}>;

export type SubmitSurveyAnswerMutation = { submitSurveyAnswer: boolean };

export type SubmitSurveyAnswersMutationVariables = Exact<{
  input: SurveyAnswersSubmitInput;
}>;

export type SubmitSurveyAnswersMutation = { submitSurveyAnswers: boolean };

type BaseSurveyGeneralSurveyFragment = {
  __typename: 'GeneralSurvey';
  uuid: string;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucheredOffer: {
          id: string;
          marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
        };
      }
    | null;
};

type BaseSurveyWalletSteeringSurveyFragment = {
  __typename: 'WalletSteeringSurvey';
  uuid: string;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucheredOffer: {
          id: string;
          marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
        };
      }
    | null;
};

export type BaseSurveyFragment = BaseSurveyGeneralSurveyFragment | BaseSurveyWalletSteeringSurveyFragment;

type SurveyQuestionSurveyQuestionIntSliderFragment = {
  __typename: 'SurveyQuestionIntSlider';
  uuid: string;
  index: number;
  questionText: string;
};

type SurveyQuestionSurveyQuestionMultipleChoiceFragment = {
  __typename: 'SurveyQuestionMultipleChoice';
  uuid: string;
  index: number;
  questionText: string;
};

type SurveyQuestionSurveyQuestionSingleChoiceFragment = {
  __typename: 'SurveyQuestionSingleChoice';
  uuid: string;
  index: number;
  questionText: string;
};

export type SurveyQuestionFragment =
  | SurveyQuestionSurveyQuestionIntSliderFragment
  | SurveyQuestionSurveyQuestionMultipleChoiceFragment
  | SurveyQuestionSurveyQuestionSingleChoiceFragment;

type SurveyGeneralSurveyFragment = {
  __typename: 'GeneralSurvey';
  uuid: string;
  questions: Array<
    | {
        __typename: 'SurveyQuestionIntSlider';
        min: number;
        max: number;
        uuid: string;
        index: number;
        questionText: string;
      }
    | {
        __typename: 'SurveyQuestionMultipleChoice';
        uuid: string;
        index: number;
        questionText: string;
        options: Array<{ uuid: string; index: number; label: string }>;
      }
    | {
        __typename: 'SurveyQuestionSingleChoice';
        uuid: string;
        index: number;
        questionText: string;
        options: Array<{ uuid: string; index: number; label: string }>;
      }
  >;
  configuration: { startDate: any; endDate: any | null };
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucheredOffer: {
          id: string;
          marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
        };
      }
    | null;
};

type SurveyWalletSteeringSurveyFragment = {
  __typename: 'WalletSteeringSurvey';
  uuid: string;
  questions: Array<
    | {
        __typename: 'SurveyQuestionIntSlider';
        uuid: string;
        index: number;
        questionText: string;
        fields: Array<{ uuid: string }>;
      }
    | {
        __typename: 'SurveyQuestionMultipleChoice';
        uuid: string;
        index: number;
        questionText: string;
        fields: Array<{ uuid: string }>;
      }
    | {
        __typename: 'SurveyQuestionSingleChoice';
        uuid: string;
        index: number;
        questionText: string;
        fields: Array<{ uuid: string }>;
      }
  >;
  configuration: {
    steeringWalletUuid: string | null;
    questionText: string | null;
    steeringWalletUnitOfMeasure: UnitOfMeasure | null;
    maxUnits: number | null;
    minUnits: number | null;
  };
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucheredOffer: {
          id: string;
          marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
        };
      }
    | null;
};

export type SurveyFragment = SurveyGeneralSurveyFragment | SurveyWalletSteeringSurveyFragment;

export type WalletSteeringSurveyFragment = {
  __typename: 'WalletSteeringSurvey';
  uuid: string;
  questions: Array<
    | {
        __typename: 'SurveyQuestionIntSlider';
        uuid: string;
        index: number;
        questionText: string;
        fields: Array<{ uuid: string }>;
      }
    | {
        __typename: 'SurveyQuestionMultipleChoice';
        uuid: string;
        index: number;
        questionText: string;
        fields: Array<{ uuid: string }>;
      }
    | {
        __typename: 'SurveyQuestionSingleChoice';
        uuid: string;
        index: number;
        questionText: string;
        fields: Array<{ uuid: string }>;
      }
  >;
  configuration: {
    steeringWalletUuid: string | null;
    questionText: string | null;
    steeringWalletUnitOfMeasure: UnitOfMeasure | null;
    maxUnits: number | null;
    minUnits: number | null;
  };
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucheredOffer: {
          id: string;
          marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
        };
      }
    | null;
};

export type GeneralSurveyFragment = {
  __typename: 'GeneralSurvey';
  uuid: string;
  questions: Array<
    | {
        __typename: 'SurveyQuestionIntSlider';
        min: number;
        max: number;
        uuid: string;
        index: number;
        questionText: string;
      }
    | {
        __typename: 'SurveyQuestionMultipleChoice';
        uuid: string;
        index: number;
        questionText: string;
        options: Array<{ uuid: string; index: number; label: string }>;
      }
    | {
        __typename: 'SurveyQuestionSingleChoice';
        uuid: string;
        index: number;
        questionText: string;
        options: Array<{ uuid: string; index: number; label: string }>;
      }
  >;
  configuration: { startDate: any; endDate: any | null };
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucheredOffer: {
          id: string;
          marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
        };
      }
    | null;
};

export type AwardVouchersSurveyFragment = {
  __typename: 'AwardVouchers';
  voucheredOffer: {
    id: string;
    marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
  };
};

export type GetFiservClientAccessTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetFiservClientAccessTokenQuery = {
  getFiservClientAccessToken: {
    fdCustomerId: string;
    publicKey: string;
    algorithm: string;
    issuedOn: any;
    expiresInSeconds: number;
    oAuthToken: string;
    apiKey: string;
    baseUrl: string;
  };
};

export type RudderstackQueryVariables = Exact<{ [key: string]: never }>;

export type RudderstackQuery = { rudderstack: { audienceUuids: Array<string> } };

export type TransactionsWithOfferFulfillmentQueryVariables = Exact<{
  size: Scalars['Int'];
}>;

export type TransactionsWithOfferFulfillmentQuery = {
  customer: {
    uuid: string;
    membership: {
      id: string;
      transactions: {
        edges: Array<{
          node: {
            id: string;
            createdAt: any;
            offerFulfillments: Array<{
              id: string;
              fulfillmentCount: number;
              fulfilledOffer: {
                id: string;
                earningMechanism:
                  | { __typename: 'EarningMechanismExternalFulfillment'; id: string }
                  | { __typename: 'EarningMechanismLoyaltyEvent'; id: string }
                  | { __typename: 'EarningMechanismMembershipEvent'; id: string }
                  | { __typename: 'EarningMechanismPointSpend'; id: string }
                  | { __typename: 'EarningMechanismProductDiscount'; id: string }
                  | { __typename: 'EarningMechanismProductPurchase'; id: string }
                  | { __typename: 'EarningMechanismPromocodes' }
                  | { __typename: 'EarningMechanismSpinWheelGame' }
                  | { __typename: 'EarningMechanismTotalEligiblePurchase'; id: string }
                  | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime'; id: string }
                  | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
                  | { __typename: 'EarningMechanismTotalSpendOverTime'; id: string }
                  | { __typename: 'EarningMechanismTransactionDiscount'; id: string }
                  | { __typename: 'EarningMechanismVarietyDiscount'; id: string }
                  | { __typename: 'EarningMechanismVarietyPurchase'; id: string }
                  | { __typename: 'EarningMechanismVarietyPurchaseOverTime'; id: string }
                  | { __typename: 'EarningMechanismVisits'; id: string }
                  | null;
              } | null;
            }> | null;
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type TransactionWithOfferFulfillmentFragment = {
  id: string;
  createdAt: any;
  offerFulfillments: Array<{
    id: string;
    fulfillmentCount: number;
    fulfilledOffer: {
      id: string;
      earningMechanism:
        | { __typename: 'EarningMechanismExternalFulfillment'; id: string }
        | { __typename: 'EarningMechanismLoyaltyEvent'; id: string }
        | { __typename: 'EarningMechanismMembershipEvent'; id: string }
        | { __typename: 'EarningMechanismPointSpend'; id: string }
        | { __typename: 'EarningMechanismProductDiscount'; id: string }
        | { __typename: 'EarningMechanismProductPurchase'; id: string }
        | { __typename: 'EarningMechanismPromocodes' }
        | { __typename: 'EarningMechanismSpinWheelGame' }
        | { __typename: 'EarningMechanismTotalEligiblePurchase'; id: string }
        | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime'; id: string }
        | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
        | { __typename: 'EarningMechanismTotalSpendOverTime'; id: string }
        | { __typename: 'EarningMechanismTransactionDiscount'; id: string }
        | { __typename: 'EarningMechanismVarietyDiscount'; id: string }
        | { __typename: 'EarningMechanismVarietyPurchase'; id: string }
        | { __typename: 'EarningMechanismVarietyPurchaseOverTime'; id: string }
        | { __typename: 'EarningMechanismVisits'; id: string }
        | null;
    } | null;
  }> | null;
};

export type OfferFulfillmentFragment = {
  id: string;
  fulfillmentCount: number;
  fulfilledOffer: {
    id: string;
    earningMechanism:
      | { __typename: 'EarningMechanismExternalFulfillment'; id: string }
      | { __typename: 'EarningMechanismLoyaltyEvent'; id: string }
      | { __typename: 'EarningMechanismMembershipEvent'; id: string }
      | { __typename: 'EarningMechanismPointSpend'; id: string }
      | { __typename: 'EarningMechanismProductDiscount'; id: string }
      | { __typename: 'EarningMechanismProductPurchase'; id: string }
      | { __typename: 'EarningMechanismPromocodes' }
      | { __typename: 'EarningMechanismSpinWheelGame' }
      | { __typename: 'EarningMechanismTotalEligiblePurchase'; id: string }
      | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime'; id: string }
      | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
      | { __typename: 'EarningMechanismTotalSpendOverTime'; id: string }
      | { __typename: 'EarningMechanismTransactionDiscount'; id: string }
      | { __typename: 'EarningMechanismVarietyDiscount'; id: string }
      | { __typename: 'EarningMechanismVarietyPurchase'; id: string }
      | { __typename: 'EarningMechanismVarietyPurchaseOverTime'; id: string }
      | { __typename: 'EarningMechanismVisits'; id: string }
      | null;
  } | null;
};

export type FulfilledOfferFragment = {
  id: string;
  earningMechanism:
    | { __typename: 'EarningMechanismExternalFulfillment'; id: string }
    | { __typename: 'EarningMechanismLoyaltyEvent'; id: string }
    | { __typename: 'EarningMechanismMembershipEvent'; id: string }
    | { __typename: 'EarningMechanismPointSpend'; id: string }
    | { __typename: 'EarningMechanismProductDiscount'; id: string }
    | { __typename: 'EarningMechanismProductPurchase'; id: string }
    | { __typename: 'EarningMechanismPromocodes' }
    | { __typename: 'EarningMechanismSpinWheelGame' }
    | { __typename: 'EarningMechanismTotalEligiblePurchase'; id: string }
    | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime'; id: string }
    | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
    | { __typename: 'EarningMechanismTotalSpendOverTime'; id: string }
    | { __typename: 'EarningMechanismTransactionDiscount'; id: string }
    | { __typename: 'EarningMechanismVarietyDiscount'; id: string }
    | { __typename: 'EarningMechanismVarietyPurchase'; id: string }
    | { __typename: 'EarningMechanismVarietyPurchaseOverTime'; id: string }
    | { __typename: 'EarningMechanismVisits'; id: string }
    | null;
};

export type AuthPayloadFragment = {
  __typename: 'AuthPayload';
  tokens: { accessToken: string; refreshToken: string } | null;
};

export type CustomerOtpPayloadFragment = { __typename: 'CustomerOTPPayload'; session: string | null; success: boolean };

export type CreateOtpSessionByPlatformMutationVariables = Exact<{
  input: CreateOtpSessionInput;
}>;

export type CreateOtpSessionByPlatformMutation = { createOTPSessionByPlatform: { session: string } };

export type GetAccessTokenByRefreshTokenMutationVariables = Exact<{
  input: AccessTokenByRefreshTokenInput;
}>;

export type GetAccessTokenByRefreshTokenMutation = {
  getAccessTokenByRefreshToken: { accessToken: string; refreshToken: string } | null;
};

export type RequestMagicLinkMutationVariables = Exact<{
  input: MagicLinkRequestInput;
}>;

export type RequestMagicLinkMutation = { requestMagicLink: boolean };

export type SignupWithOtpMutationVariables = Exact<{
  input: Otp;
}>;

export type SignupWithOtpMutation = {
  exchangeSessionAndOTPToAuthToken:
    | { __typename: 'ExchangeSessionAndOTPToAuthTokenResultFailedIncorrectOTP'; success: boolean }
    | {
        __typename: 'ExchangeSessionAndOTPToAuthTokenResultSuccess';
        success: boolean;
        tokens: { accessToken: string; refreshToken: string };
      };
};

export type SignupWithOtpSuccessFragment = { success: boolean; tokens: { accessToken: string; refreshToken: string } };

export type SignupWithOtpIncorrectOtpFragment = { success: boolean };

export type SignupWithTokenMutationVariables = Exact<{
  input: MagicLinkExchangeSessionIdToAuthTokenInput;
}>;

export type SignupWithTokenMutation = { signupWithToken: { tokens: { accessToken: string; refreshToken: string } } };

export type CommunicationPreferenceUpdateMutationVariables = Exact<{
  input: CommunicationPreferenceUpdateInput;
}>;

export type CommunicationPreferenceUpdateMutation = { preference: { smsOptIn: boolean; pushOptIn: boolean } };

export type CommunicationPreferenceFragment = { smsOptIn: boolean; pushOptIn: boolean };

export type GetAppConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetAppConfigQuery = {
  appConfig: {
    supportTeamEmail: string;
    supportPhoneNumber: string | null;
    termsConditions: { activeAt: any | null; name: string; url: any | null; uuid: string };
    privacyPolicy: { activeAt: any | null; name: string; url: any | null; uuid: string };
    loyaltyProgram: {
      pointsStrategy: PointsStrategy;
      homePageOffersLimit: number;
      redeemOfferWithCompletedProfileOnly: boolean;
      membershipMinAge: number;
      isExplicitTermsAcceptanceRequired: boolean;
      isReturnRewardEnabled: boolean;
      isExplicitSmsPromotionAcceptanceEnabled: boolean;
      isPromocodesEnabled: boolean;
    };
    payAtPump: { enabled: boolean };
    payInside: { enabled: boolean };
    applePay: {
      merchantId: string;
      supportedNetworks: Array<ApplePayCardNetwork>;
      allowCreditCards: boolean;
      merchantName: string | null;
      allowPrepaidCards: boolean;
    } | null;
    googlePay: {
      environment: GooglePayEnvironment;
      gateway: string;
      merchantId: string;
      merchantName: string | null;
      allowedCardNetworks: Array<GooglePayCardNetwork>;
      allowPrepaidCards: boolean;
      allowCreditCards: boolean;
      gatewayMerchantId: string;
    } | null;
    kount: { merchantId: number; collectorHostname: string; environment: KountEnvironment } | null;
    pinCode: { blockingDuration: number; attemptThrottle: number; maxAttempts: number };
    wallet: { enabled: boolean };
    deleteAccount: { enabled: boolean };
    paymentProvider:
      | { __typename: 'FiservConfiguration'; customData: any | null }
      | {
          __typename: 'WorldpayConfiguration';
          customData: any | null;
          eProtectPayPageId: string;
          eProtectGooglePayPageId: string;
          eProtectWebSdkApiUrl: string;
          eProtectWebSdkUrl: string;
        }
      | null;
    paymentWallet: { maxInstruments: number; allowedAccountTypes: Array<PaymentAccountType> };
    communicationPreference: { enabled: boolean; isSmsOptInConfigurable: boolean; isPushOptInConfigurable: boolean };
    eaivAgeVerification: { enabled: boolean };
    eaivIdentityVerification: { enabled: boolean };
    productInterests: { enabled: boolean; isEAIVPromotionOnProfileCompletedEnabled: boolean };
    appRating: { enabled: boolean; promptIntervalDays: number; transactionHistoryLookupDays: number };
    auth: { primaryMethod: AuthMethod; otp: { enabled: boolean; channels: Array<OtpChannel> } };
    freshdeskSupportWidget: {
      widgetId: string | null;
      token: string | null;
      host: string | null;
      sdkUrl: string | null;
      enabled: boolean;
    };
    rudderStack: { enabled: boolean };
    totalAvailableRollback: { enabled: boolean };
    smsOptIn: {
      enabled: boolean;
      modalAppearanceDelaySeconds: number;
      modalOfferLoyaltyEventName: string;
      optInAcceptedLoyaltyEventName: string;
      optInRejectedLoyaltyEventName: string;
    };
    mobilePaymentsReceiptsView: { enabled: boolean };
    storeLocator: { fuelPricesMode: FuelPricesMode };
  };
};

export type EaivAgeVerificationStartMutationVariables = Exact<{
  input: EaivAgeVerificationStartInput;
}>;

export type EaivAgeVerificationStartMutation = {
  result:
    | { __typename: 'EaivAgeVerificationRequireAdditionalPrompt'; prompt: EaivAdditionalPromptFields }
    | { __typename: 'EaivAgeVerificationResultFailed'; reason: EaivAgeVerificationFailed | null }
    | { __typename: 'EaivAgeVerificationStartResultSuccess'; status: EaivAgeVerificationRequestStatusType };
};

export type AgeVerificationRequireAdditionalPromptFragment = { prompt: EaivAdditionalPromptFields };

export type EaivIdentityVerificationStartMutationVariables = Exact<{
  input: EaivIdentityVerificationStartInput;
}>;

export type EaivIdentityVerificationStartMutation = {
  result:
    | { __typename: 'EaivIdentityVerificationRequireAdditionalPrompt'; prompt: EaivAdditionalPromptFields }
    | { __typename: 'EaivIdentityVerificationResultFailed'; reason: EaivIdentityVerificationFailed | null }
    | {
        __typename: 'EaivIdentityVerificationStartResultSuccess';
        conversationId: string;
        questionSet: {
          id: string;
          questions: Array<{
            __typename: 'EaivIdentityVerificationChoiceQuestion';
            id: string;
            text: string;
            helpText: string | null;
            choices: Array<{ id: string; text: string }>;
          }>;
        };
      };
};

export type IdentityVerificationStartResultSuccessFragment = {
  conversationId: string;
  questionSet: {
    id: string;
    questions: Array<{
      __typename: 'EaivIdentityVerificationChoiceQuestion';
      id: string;
      text: string;
      helpText: string | null;
      choices: Array<{ id: string; text: string }>;
    }>;
  };
};

export type IdentityVerificationQuestionSetFragment = {
  id: string;
  questions: Array<{
    __typename: 'EaivIdentityVerificationChoiceQuestion';
    id: string;
    text: string;
    helpText: string | null;
    choices: Array<{ id: string; text: string }>;
  }>;
};

export type IdentityVerificationChoiceQuestionFragment = {
  id: string;
  text: string;
  helpText: string | null;
  choices: Array<{ id: string; text: string }>;
};

export type IdentityVerificationChoiceQuestionOptionFragment = { id: string; text: string };

export type IdentityVerificationRequireAdditionalPromptFragment = { prompt: EaivAdditionalPromptFields };

export type EaivIdentityVerificationSubmitMutationVariables = Exact<{
  input: EaivIdentityVerificationSubmitInput;
}>;

export type EaivIdentityVerificationSubmitMutation = {
  result:
    | { __typename: 'EaivIdentityVerificationResultFailed'; reason: EaivIdentityVerificationFailed | null }
    | {
        __typename: 'EaivIdentityVerificationSubmitResultContinue';
        conversationId: string;
        questionSet: {
          id: string;
          questions: Array<{
            __typename: 'EaivIdentityVerificationChoiceQuestion';
            id: string;
            text: string;
            helpText: string | null;
            choices: Array<{ id: string; text: string }>;
          }>;
        };
      }
    | { __typename: 'EaivIdentityVerificationSubmitResultSuccess'; status: EaivIdentityVerificationRequestStatusType };
};

export type IdentityVerificationStartResultContinueFragment = {
  conversationId: string;
  questionSet: {
    id: string;
    questions: Array<{
      __typename: 'EaivIdentityVerificationChoiceQuestion';
      id: string;
      text: string;
      helpText: string | null;
      choices: Array<{ id: string; text: string }>;
    }>;
  };
};

export type GetExternalOffersQueryVariables = Exact<{
  filter: BannerFilter;
}>;

export type GetExternalOffersQuery = {
  banners: Array<{
    trackingId: string;
    bannerConfigurationUuid: string;
    externalCampaignId: string | null;
    content: {
      title: string;
      title2: string | null;
      titleMiddle: string | null;
      imageUrl: any | null;
      surgeonGeneralWarning: string | null;
    };
  }>;
};

export type ExternalOfferFragment = {
  trackingId: string;
  bannerConfigurationUuid: string;
  externalCampaignId: string | null;
  content: {
    title: string;
    title2: string | null;
    titleMiddle: string | null;
    imageUrl: any | null;
    surgeonGeneralWarning: string | null;
  };
};

export type LogOfferActivityMutationVariables = Exact<{
  input: OfferActivityLogInput;
}>;

export type LogOfferActivityMutation = { offerActivityLog: boolean };

export type FuelProductGroupFragment = { id: string; productGroup: { id: string; name: string } };

export type FuelProductGroupsQueryVariables = Exact<{
  filter?: InputMaybe<FuelProductGroupFilter>;
}>;

export type FuelProductGroupsQuery = {
  fuelProductGroups: Array<{ id: string; productGroup: { id: string; name: string } }> | null;
};

export type ExecuteSpinWheelGameMutationVariables = Exact<{
  offerId: Scalars['ID'];
}>;

export type ExecuteSpinWheelGameMutation = {
  result: {
    sequenceNumber: number;
    awardType: RandomAwardType;
    award:
      | { __typename: 'AwardPoints'; points: number }
      | {
          __typename: 'AwardVouchers';
          voucherExpirationDays: number | null;
          voucheredOffer: {
            __typename: 'Offer';
            id: string;
            marketingContents: Array<{
              id: string;
              imageUrl: any | null;
              title: string | null;
              shortDescription: string | null;
              longDescription: string | null;
              disclaimer: string | null;
              disclaimerUrl: any | null;
              terms: string | null;
              termsUrl: any | null;
              surgeonGeneralsWarning: string | null;
              externalUrl: string | null;
            } | null>;
          };
        }
      | null;
  } | null;
};

export type GetDisplayCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<DisplayCategoryFilter>;
}>;

export type GetDisplayCategoriesQuery = {
  displayCategories: {
    edges: Array<{
      node: {
        id: string;
        name: string;
        rank: number;
        categoryRestrictions: CategoryRestrictions | null;
        bannerIds: Array<string> | null;
      };
    }>;
  };
};

export type DisplayCategoryFragment = {
  id: string;
  name: string;
  rank: number;
  categoryRestrictions: CategoryRestrictions | null;
  bannerIds: Array<string> | null;
};

export type ProductCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<ProductCategoryFilter>;
}>;

export type ProductCategoriesQuery = {
  productCategories: {
    edges: Array<{
      node: { id: string; name: string; rank: number; categoryRestriction: CategoryRestrictions | null };
    }>;
  };
};

export type ProductCategoryFragment = {
  id: string;
  name: string;
  rank: number;
  categoryRestriction: CategoryRestrictions | null;
};

export type CustomerLogoutMutationVariables = Exact<{ [key: string]: never }>;

export type CustomerLogoutMutation = { logout: boolean | null };

export type CustomerUpdateMutationVariables = Exact<{
  customer: CustomerUpdate;
}>;

export type CustomerUpdateMutation = {
  customer: {
    uuid: string;
    membership: {
      __typename: 'Membership';
      points: number;
      customData: any | null;
      id: string;
      customerUuid: string | null;
      state: MEMBERSHIP_STATE;
      isCompleted: boolean;
      firstName: string | null;
      lastName: string | null;
      birthdate: any | null;
      phone: any | null;
      email: any | null;
      programTermsAcceptanceAt: any | null;
      homeStores: Array<{ locationUuid: string }> | null;
      primaryAddress: {
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zipcode: string | null;
      } | null;
      fuelAndConvenienceProfile: {
        restrictionVerification: CategoryRestrictions | null;
        pendingVerification: CategoryRestrictions | null;
        memberDataShare: boolean | null;
        preferredFuelProductGroup: { id: string; productGroup: { id: string; name: string } } | null;
      } | null;
    } | null;
  } | null;
};

export type CurrentCustomerQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentCustomerQuery = {
  customer: {
    __typename: 'Customer';
    uuid: string;
    hasPasscode: boolean | null;
    membership: {
      __typename: 'Membership';
      points: number;
      customData: any | null;
      id: string;
      customerUuid: string | null;
      state: MEMBERSHIP_STATE;
      isCompleted: boolean;
      firstName: string | null;
      lastName: string | null;
      birthdate: any | null;
      phone: any | null;
      email: any | null;
      programTermsAcceptanceAt: any | null;
      homeStores: Array<{ locationUuid: string }> | null;
      primaryAddress: {
        address1: string | null;
        address2: string | null;
        city: string | null;
        state: string | null;
        zipcode: string | null;
      } | null;
      fuelAndConvenienceProfile: {
        restrictionVerification: CategoryRestrictions | null;
        pendingVerification: CategoryRestrictions | null;
        memberDataShare: boolean | null;
        preferredFuelProductGroup: { id: string; productGroup: { id: string; name: string } } | null;
      } | null;
    } | null;
    communicationPreference: { smsOptIn: boolean; pushOptIn: boolean };
  } | null;
};

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteAccountMutation = { deleteAccount: boolean };

export type FuelAndConvenienceProfileCreateOrUpdateMutationVariables = Exact<{
  input: FuelAndConvenienceProfileCreateOrUpdateInput;
}>;

export type FuelAndConvenienceProfileCreateOrUpdateMutation = {
  fuelAndConvenienceProfile: { memberDataShare: boolean | null; preferredFuelProductGroup: { id: string } | null };
};

export type MembershipProductInterestsQueryVariables = Exact<{ [key: string]: never }>;

export type MembershipProductInterestsQuery = {
  customer: {
    __typename: 'Customer';
    uuid: string;
    membership: {
      id: string;
      productInterests: Array<{
        id: string;
        name: string;
        rank: number;
        categoryRestriction: CategoryRestrictions | null;
      }>;
    } | null;
  } | null;
};

export type MembershipAddressFragment = {
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zipcode: string | null;
};

export type FuelAndConvenienceProfileFragment = {
  restrictionVerification: CategoryRestrictions | null;
  pendingVerification: CategoryRestrictions | null;
  memberDataShare: boolean | null;
  preferredFuelProductGroup: { id: string; productGroup: { id: string; name: string } } | null;
};

export type CustomerProfileFragment = {
  __typename: 'Membership';
  id: string;
  customerUuid: string | null;
  state: MEMBERSHIP_STATE;
  isCompleted: boolean;
  firstName: string | null;
  lastName: string | null;
  birthdate: any | null;
  phone: any | null;
  email: any | null;
  programTermsAcceptanceAt: any | null;
  primaryAddress: {
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipcode: string | null;
  } | null;
  fuelAndConvenienceProfile: {
    restrictionVerification: CategoryRestrictions | null;
    pendingVerification: CategoryRestrictions | null;
    memberDataShare: boolean | null;
    preferredFuelProductGroup: { id: string; productGroup: { id: string; name: string } } | null;
  } | null;
};

export type MembershipFragment = {
  __typename: 'Membership';
  points: number;
  customData: any | null;
  id: string;
  customerUuid: string | null;
  state: MEMBERSHIP_STATE;
  isCompleted: boolean;
  firstName: string | null;
  lastName: string | null;
  birthdate: any | null;
  phone: any | null;
  email: any | null;
  programTermsAcceptanceAt: any | null;
  homeStores: Array<{ locationUuid: string }> | null;
  primaryAddress: {
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    zipcode: string | null;
  } | null;
  fuelAndConvenienceProfile: {
    restrictionVerification: CategoryRestrictions | null;
    pendingVerification: CategoryRestrictions | null;
    memberDataShare: boolean | null;
    preferredFuelProductGroup: { id: string; productGroup: { id: string; name: string } } | null;
  } | null;
};

export type ProductInterestsReplaceMutationVariables = Exact<{
  input: ProductInterestsReplaceInput;
}>;

export type ProductInterestsReplaceMutation = {
  membership: {
    id: string;
    productInterests: Array<{
      id: string;
      name: string;
      rank: number;
      categoryRestriction: CategoryRestrictions | null;
    }>;
    fuelAndConvenienceProfile: { pendingVerification: CategoryRestrictions | null } | null;
  };
};

export type DeviceTokenRegisterMutationVariables = Exact<{
  input: DeviceTokenRegisterInput;
}>;

export type DeviceTokenRegisterMutation = { deviceTokenRegister: boolean };

export type ActivateOfferMutationVariables = Exact<{
  input: OfferActivate;
}>;

export type ActivateOfferMutation = { offerActivate: { id: string; activationStatus: OfferActivationStatus } | null };

type LimitLimitGlobalActivationFragment = { __typename: 'LimitGlobalActivation'; id: string };

type LimitLimitGlobalActivationInTimeFragment = { __typename: 'LimitGlobalActivationInTime'; id: string };

type LimitLimitGlobalAwardCountFragment = { __typename: 'LimitGlobalAwardCount'; id: string };

type LimitLimitGlobalAwardCountInTimeFragment = { __typename: 'LimitGlobalAwardCountInTime'; id: string };

type LimitLimitInTransactionAwardCountFragment = { __typename: 'LimitInTransactionAwardCount'; id: string };

type LimitLimitMembershipActivationFragment = { __typename: 'LimitMembershipActivation'; id: string };

type LimitLimitMembershipActivationInTimeFragment = { __typename: 'LimitMembershipActivationInTime'; id: string };

type LimitLimitMembershipAwardCountFragment = {
  __typename: 'LimitMembershipAwardCount';
  id: string;
  limit: number;
  membershipQuantityAvailable: number | null;
};

type LimitLimitMembershipAwardCountInTimeFragment = {
  __typename: 'LimitMembershipAwardCountInTime';
  id: string;
  limit: number;
  membershipQuantityAvailable: number | null;
};

export type LimitFragment =
  | LimitLimitGlobalActivationFragment
  | LimitLimitGlobalActivationInTimeFragment
  | LimitLimitGlobalAwardCountFragment
  | LimitLimitGlobalAwardCountInTimeFragment
  | LimitLimitInTransactionAwardCountFragment
  | LimitLimitMembershipActivationFragment
  | LimitLimitMembershipActivationInTimeFragment
  | LimitLimitMembershipAwardCountFragment
  | LimitLimitMembershipAwardCountInTimeFragment;

export type LimitMembershipAwardCountFragment = {
  __typename: 'LimitMembershipAwardCount';
  id: string;
  limit: number;
  membershipQuantityAvailable: number | null;
};

export type LimitMembershipAwardCountInTimeFragment = {
  __typename: 'LimitMembershipAwardCountInTime';
  id: string;
  limit: number;
  membershipQuantityAvailable: number | null;
};

export type LimitGlobalActivationFragment = { __typename: 'LimitGlobalActivation'; id: string };

export type LimitGlobalActivationInTimeFragment = { __typename: 'LimitGlobalActivationInTime'; id: string };

export type LimitGlobalAwardCountFragment = { __typename: 'LimitGlobalAwardCount'; id: string };

export type LimitGlobalAwardCountInTimeFragment = { __typename: 'LimitGlobalAwardCountInTime'; id: string };

export type LimitMembershipActivationFragment = { __typename: 'LimitMembershipActivation'; id: string };

export type LimitMembershipActivationInTimeFragment = { __typename: 'LimitMembershipActivationInTime'; id: string };

export type LimitInTransactionAwardCountFragment = { __typename: 'LimitInTransactionAwardCount'; id: string };

export type MarketingContentFragment = {
  id: string;
  imageUrl: any | null;
  title: string | null;
  shortDescription: string | null;
  longDescription: string | null;
  disclaimer: string | null;
  disclaimerUrl: any | null;
  terms: string | null;
  termsUrl: any | null;
  surgeonGeneralsWarning: string | null;
  externalUrl: string | null;
};

export type ReturnPurchasedVoucherMutationVariables = Exact<{
  input: OfferPurchaseReturn;
}>;

export type ReturnPurchasedVoucherMutation = { offerPurchaseReturn: { createdAt: any } | null };

export type StoreDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StoreDetailsQuery = {
  stores: {
    edges: Array<{
      node: {
        uuid: string;
        utcOffset: number | null;
        openStatus: LocationOpenStatus;
        address: {
          city: string;
          street1: string;
          street2: string | null;
          state: string;
          zipCode: string;
          geoLocation: { latitude: number; longitude: number } | null;
        } | null;
        fuelGradePrices: Array<{ description: string; unitPrice: { cashPrice: number; creditPrice: number } | null }>;
        hoursOfOperationGroup: { hours: Array<{ dayOfWeek: Weekday; openTime: any; closeTime: any }> } | null;
        phoneNumbers: Array<{ alias: string | null; phoneNumber: any; phoneType: PhoneType; uuid: string }>;
        attributes: Array<{ description: string | null; name: string; value: string }>;
      };
    }> | null;
  } | null;
};

export type StoreLocationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<LocationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;

export type StoreLocationsQuery = {
  stores: {
    pageInfo: { endCursor: string | null; hasNextPage: boolean; hasPreviousPage: boolean; startCursor: string | null };
    edges: Array<{
      node: {
        uuid: string;
        address: {
          city: string;
          street1: string;
          street2: string | null;
          state: string;
          zipCode: string;
          geoLocation: { latitude: number; longitude: number } | null;
        } | null;
        fuelGradePrices: Array<{ description: string; unitPrice: { cashPrice: number; creditPrice: number } | null }>;
      };
    }> | null;
  } | null;
};

export type StorePumpInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type StorePumpInfoQuery = {
  stores: {
    edges: Array<{
      node: {
        uuid: string;
        address: {
          city: string;
          street1: string;
          street2: string | null;
          state: string;
          zipCode: string;
          geoLocation: { latitude: number; longitude: number } | null;
        } | null;
        siteSystemConfiguration: Array<{
          uuid: string;
          identifier: string;
          details: {
            carWashDetails: Array<{
              description: string | null;
              posCode: string;
              posCodeModifier: string;
              productCode: string;
              unitPrice: number;
            }>;
            fuelConfigDetails: Array<{
              outdoorPosition: string | null;
              description: string | null;
              posCode: string;
              posCodeFormat: string | null;
              productCode: string;
            }>;
          } | null;
        }>;
      };
    }> | null;
  } | null;
};

export type StoreAddressFragment = {
  city: string;
  street1: string;
  street2: string | null;
  state: string;
  zipCode: string;
  geoLocation: { latitude: number; longitude: number } | null;
};

export type StoreFuelGradeFragment = {
  description: string;
  unitPrice: { cashPrice: number; creditPrice: number } | null;
};

export type StoreWorkingHoursFragment = { hours: Array<{ dayOfWeek: Weekday; openTime: any; closeTime: any }> };

export type StorePhoneNumberFragment = { alias: string | null; phoneNumber: any; phoneType: PhoneType; uuid: string };

export type StoreAttributeFragment = { description: string | null; name: string; value: string };

export type StorePumpConfigFragment = {
  outdoorPosition: string | null;
  description: string | null;
  posCode: string;
  posCodeFormat: string | null;
  productCode: string;
};

export type StoreCarWashItemFragment = {
  description: string | null;
  posCode: string;
  posCodeModifier: string;
  productCode: string;
  unitPrice: number;
};

export type StoreConfigurationFragment = {
  uuid: string;
  identifier: string;
  details: {
    carWashDetails: Array<{
      description: string | null;
      posCode: string;
      posCodeModifier: string;
      productCode: string;
      unitPrice: number;
    }>;
    fuelConfigDetails: Array<{
      outdoorPosition: string | null;
      description: string | null;
      posCode: string;
      posCodeFormat: string | null;
      productCode: string;
    }>;
  } | null;
};

export type StorePumpInfoFragment = {
  uuid: string;
  address: {
    city: string;
    street1: string;
    street2: string | null;
    state: string;
    zipCode: string;
    geoLocation: { latitude: number; longitude: number } | null;
  } | null;
  siteSystemConfiguration: Array<{
    uuid: string;
    identifier: string;
    details: {
      carWashDetails: Array<{
        description: string | null;
        posCode: string;
        posCodeModifier: string;
        productCode: string;
        unitPrice: number;
      }>;
      fuelConfigDetails: Array<{
        outdoorPosition: string | null;
        description: string | null;
        posCode: string;
        posCodeFormat: string | null;
        productCode: string;
      }>;
    } | null;
  }>;
};

export type GetTotalAvailableRollbackMoneyAmountQueryVariables = Exact<{ [key: string]: never }>;

export type GetTotalAvailableRollbackMoneyAmountQuery = {
  customer: {
    uuid: string;
    membership: {
      id: string;
      totalAvailableRollback: {
        locationUuid: string | null;
        moneyAmount: number;
        fuelProductGroup: { id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type GetTotalAvailableRollbackQueryVariables = Exact<{
  offerLimitsIncluded?: InputMaybe<Scalars['Boolean']>;
  offerVouchersIncluded?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetTotalAvailableRollbackQuery = {
  customer: {
    uuid: string;
    membership: {
      id: string;
      totalAvailableRollback: {
        locationUuid: string | null;
        locationName: string | null;
        moneyAmount: number;
        fuelProductGroup: { id: string; productGroup: { id: string; name: string } } | null;
        offers: Array<{
          __typename: 'Offer';
          id: string;
          expireAt: any | null;
          engagementType: OfferEngagementType | null;
          activationStatus: OfferActivationStatus;
          visibleStatus: OfferVisibleStatus;
          customData: any | null;
          voucherCount: number;
          presentationPriority: OfferPresentationPriority;
          presentationType: OfferPresentationType;
          marketingContents: Array<{
            id: string;
            imageUrl: any | null;
            title: string | null;
            shortDescription: string | null;
            longDescription: string | null;
            disclaimer: string | null;
            disclaimerUrl: any | null;
            terms: string | null;
            termsUrl: any | null;
            surgeonGeneralsWarning: string | null;
            externalUrl: string | null;
          } | null>;
          earningMechanism:
            | {
                __typename: 'EarningMechanismExternalFulfillment';
                id: string;
                optionalAward:
                  | { __typename: 'AwardPoints'; points: number }
                  | {
                      __typename: 'AwardVouchers';
                      voucherExpirationDays: number | null;
                      voucherCount: number;
                      voucheredOffer: { id: string; voucherCount: number };
                    }
                  | null;
              }
            | {
                __typename: 'EarningMechanismLoyaltyEvent';
                id: string;
                optionalAward:
                  | { __typename: 'AwardExternalPoints' }
                  | { __typename: 'AwardPoints'; points: number }
                  | {
                      __typename: 'AwardVouchers';
                      voucherExpirationDays: number | null;
                      voucherCount: number;
                      voucheredOffer: { id: string; voucherCount: number };
                    }
                  | null;
              }
            | {
                __typename: 'EarningMechanismMembershipEvent';
                id: string;
                award:
                  | { __typename: 'AwardPoints'; points: number }
                  | {
                      __typename: 'AwardVouchers';
                      voucherExpirationDays: number | null;
                      voucherCount: number;
                      voucheredOffer: { id: string; voucherCount: number };
                    };
              }
            | {
                __typename: 'EarningMechanismPointSpend';
                id: string;
                pointCost: number;
                award: {
                  __typename: 'AwardVouchers';
                  voucherExpirationDays: number | null;
                  voucherCount: number;
                  voucheredOffer: { id: string; voucherCount: number };
                };
              }
            | { __typename: 'EarningMechanismProductDiscount'; id: string }
            | {
                __typename: 'EarningMechanismProductPurchase';
                id: string;
                award:
                  | { __typename: 'AwardPoints'; points: number }
                  | {
                      __typename: 'AwardVouchers';
                      voucherExpirationDays: number | null;
                      voucherCount: number;
                      voucheredOffer: { id: string; voucherCount: number };
                    };
              }
            | { __typename: 'EarningMechanismPromocodes' }
            | {
                __typename: 'EarningMechanismSpinWheelGame';
                id: string;
                awards: Array<{
                  awardType: RandomAwardType;
                  limit: number;
                  sequenceNumber: number;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucheredOffer: {
                          __typename: 'Offer';
                          id: string;
                          marketingContents: Array<{
                            id: string;
                            imageUrl: any | null;
                            title: string | null;
                            shortDescription: string | null;
                            longDescription: string | null;
                            disclaimer: string | null;
                            disclaimerUrl: any | null;
                            terms: string | null;
                            termsUrl: any | null;
                            surgeonGeneralsWarning: string | null;
                            externalUrl: string | null;
                          } | null>;
                        };
                      }
                    | null;
                }>;
                purchasableOffer: {
                  __typename: 'Offer';
                  id: string;
                  earningMechanism:
                    | { __typename: 'EarningMechanismExternalFulfillment' }
                    | { __typename: 'EarningMechanismLoyaltyEvent' }
                    | { __typename: 'EarningMechanismMembershipEvent' }
                    | { __typename: 'EarningMechanismPointSpend'; id: string; pointCost: number }
                    | { __typename: 'EarningMechanismProductDiscount' }
                    | { __typename: 'EarningMechanismProductPurchase' }
                    | { __typename: 'EarningMechanismPromocodes' }
                    | { __typename: 'EarningMechanismSpinWheelGame' }
                    | { __typename: 'EarningMechanismTotalEligiblePurchase' }
                    | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime' }
                    | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
                    | { __typename: 'EarningMechanismTotalSpendOverTime' }
                    | { __typename: 'EarningMechanismTransactionDiscount' }
                    | { __typename: 'EarningMechanismVarietyDiscount' }
                    | { __typename: 'EarningMechanismVarietyPurchase' }
                    | { __typename: 'EarningMechanismVarietyPurchaseOverTime' }
                    | { __typename: 'EarningMechanismVisits' }
                    | null;
                } | null;
              }
            | {
                __typename: 'EarningMechanismTotalEligiblePurchase';
                id: string;
                award: { __typename: 'AwardPointsPer'; points: number; rounding: AwardPointsRoundingStrategy };
              }
            | {
                __typename: 'EarningMechanismTotalEligiblePurchaseOverTime';
                id: string;
                purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
                award:
                  | { __typename: 'AwardPoints'; points: number }
                  | {
                      __typename: 'AwardVouchers';
                      voucherExpirationDays: number | null;
                      voucherCount: number;
                      voucheredOffer: { id: string; voucherCount: number };
                    };
              }
            | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
            | {
                __typename: 'EarningMechanismTotalSpendOverTime';
                id: string;
                purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
                award:
                  | { __typename: 'AwardPoints'; points: number }
                  | {
                      __typename: 'AwardVouchers';
                      voucherExpirationDays: number | null;
                      voucherCount: number;
                      voucheredOffer: { id: string; voucherCount: number };
                    };
              }
            | { __typename: 'EarningMechanismTransactionDiscount'; id: string }
            | { __typename: 'EarningMechanismVarietyDiscount'; id: string }
            | {
                __typename: 'EarningMechanismVarietyPurchase';
                id: string;
                award:
                  | { __typename: 'AwardPoints'; points: number }
                  | {
                      __typename: 'AwardVouchers';
                      voucherExpirationDays: number | null;
                      voucherCount: number;
                      voucheredOffer: { id: string; voucherCount: number };
                    };
              }
            | {
                __typename: 'EarningMechanismVarietyPurchaseOverTime';
                id: string;
                varietyMemberProgress: Array<{ quantityPurchased: number; quantityRequired: number }>;
                award:
                  | { __typename: 'AwardPoints'; points: number }
                  | {
                      __typename: 'AwardVouchers';
                      voucherExpirationDays: number | null;
                      voucherCount: number;
                      voucheredOffer: { id: string; voucherCount: number };
                    };
              }
            | {
                __typename: 'EarningMechanismVisits';
                id: string;
                visitsMemberProgress: { visits: number; visitsRequired: number } | null;
                award:
                  | { __typename: 'AwardPoints'; points: number }
                  | {
                      __typename: 'AwardVouchers';
                      voucherExpirationDays: number | null;
                      voucherCount: number;
                      voucheredOffer: { id: string; voucherCount: number };
                    };
              }
            | null;
          vouchers?: {
            edges: Array<{ node: { id: string; expireAt: any | null; createdAt: any; state: VoucherState } }>;
          };
          displayCategories: Array<{
            id: string;
            name: string;
            rank: number;
            categoryRestrictions: CategoryRestrictions | null;
            bannerIds: Array<string> | null;
          }>;
          limits?: Array<
            | { __typename: 'LimitGlobalActivation'; id: string }
            | { __typename: 'LimitGlobalActivationInTime'; id: string }
            | { __typename: 'LimitGlobalAwardCount'; id: string }
            | { __typename: 'LimitGlobalAwardCountInTime'; id: string }
            | { __typename: 'LimitInTransactionAwardCount'; id: string }
            | { __typename: 'LimitMembershipActivation'; id: string }
            | { __typename: 'LimitMembershipActivationInTime'; id: string }
            | {
                __typename: 'LimitMembershipAwardCount';
                id: string;
                limit: number;
                membershipQuantityAvailable: number | null;
              }
            | {
                __typename: 'LimitMembershipAwardCountInTime';
                id: string;
                limit: number;
                membershipQuantityAvailable: number | null;
              }
          >;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export type LoyaltyActivateTransactionItemFragment = {
  id: string;
  createdAt: any;
  totalAmount: number | null;
  totalAmountWithoutDiscounts: number | null;
};

export type LoyaltyPointChangeItemFragment = {
  id: string;
  type: PointChangeType | null;
  createdAt: any;
  quantity: number;
  notes: string | null;
  offer: { id: string; name: string } | null;
  source:
    | { __typename: 'ActivateTransaction'; id: string }
    | { __typename: 'ActivateUser'; id: string }
    | { __typename: 'Chain'; id: string }
    | { __typename: 'CommandCenterUser' }
    | { __typename: 'GameSession' }
    | { __typename: 'LoyaltyEvent'; eventType: string; createdAt: any }
    | {
        __typename: 'OfferPurchase';
        purchasedWith: {
          id: string;
          name: string;
          marketingContents: Array<{ id: string; title: string | null } | null>;
        };
      }
    | {
        __typename: 'PointChange';
        id: string;
        offer: {
          id: string;
          name: string;
          marketingContents: Array<{ id: string; title: string | null } | null>;
        } | null;
      }
    | { __typename: 'PromocodeUseRequest' }
    | null;
};

export type LoyaltyActivitySourceChainFragment = { id: string };

export type LoyaltyActivitySourceActivateUserFragment = { id: string };

export type LoyaltyActivitySourceLoyaltyEventFragment = { eventType: string; createdAt: any };

export type LoyaltyActivitySourceActivateTransactionFragment = { id: string };

export type LoyaltyActivitySourceOfferPurchaseFragment = {
  purchasedWith: { id: string; name: string; marketingContents: Array<{ id: string; title: string | null } | null> };
};

export type LoyaltyActivitySourcePointChangeFragment = {
  id: string;
  offer: { id: string; name: string; marketingContents: Array<{ id: string; title: string | null } | null> } | null;
};

export type LoyaltyActionHistoryQueryVariables = Exact<{
  size: Scalars['Int'];
  transactionCursor?: InputMaybe<Scalars['String']>;
  pointChangeCursor?: InputMaybe<Scalars['String']>;
  skipPointChanges?: InputMaybe<Scalars['Boolean']>;
}>;

export type LoyaltyActionHistoryQuery = {
  customer: {
    uuid: string;
    membership: {
      id: string;
      transactions: {
        edges: Array<{
          cursor: string;
          node: { id: string; createdAt: any; totalAmount: number | null; totalAmountWithoutDiscounts: number | null };
        } | null>;
      } | null;
      pointChanges?: {
        edges: Array<{
          cursor: string;
          node: {
            id: string;
            type: PointChangeType | null;
            createdAt: any;
            quantity: number;
            notes: string | null;
            offer: { id: string; name: string } | null;
            source:
              | { __typename: 'ActivateTransaction'; id: string }
              | { __typename: 'ActivateUser'; id: string }
              | { __typename: 'Chain'; id: string }
              | { __typename: 'CommandCenterUser' }
              | { __typename: 'GameSession' }
              | { __typename: 'LoyaltyEvent'; eventType: string; createdAt: any }
              | {
                  __typename: 'OfferPurchase';
                  purchasedWith: {
                    id: string;
                    name: string;
                    marketingContents: Array<{ id: string; title: string | null } | null>;
                  };
                }
              | {
                  __typename: 'PointChange';
                  id: string;
                  offer: {
                    id: string;
                    name: string;
                    marketingContents: Array<{ id: string; title: string | null } | null>;
                  } | null;
                }
              | { __typename: 'PromocodeUseRequest' }
              | null;
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type TransactionHistoryItemFragment = {
  uuid: string;
  completedAt: any | null;
  primaryStatus: PrimaryTransactionStatus;
  secondaryStatus: SecondaryTransactionStatus;
  amount: { displayPrice: string; price: number } | null;
};

export type TransactionHistoryQueryVariables = Exact<{
  size: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  primaryTransactionStatus?: InputMaybe<PrimaryTransactionStatus>;
  secondaryTransactionStatus?: InputMaybe<SecondaryTransactionStatus>;
}>;

export type TransactionHistoryQuery = {
  customer: {
    uuid: string;
    transactions: {
      edges: Array<{
        cursor: string;
        node: {
          uuid: string;
          completedAt: any | null;
          primaryStatus: PrimaryTransactionStatus;
          secondaryStatus: SecondaryTransactionStatus;
          amount: { displayPrice: string; price: number } | null;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type GetPaymentInstrumentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPaymentInstrumentsQuery = {
  customer: {
    __typename: 'Customer';
    uuid: string;
    hasPasscode: boolean | null;
    paymentWallet: Array<{
      uuid: string;
      alias: string | null;
      providerNickName: string | null;
      accountNumber: string | null;
      accountType: PaymentAccountType;
      paymentType: PaymentInstrumentType | null;
      balance: number | null;
      isDefault: boolean | null;
      displayNumber: string | null;
      status: PaymentInstrumentStatus;
      address: { zipCode: string } | null;
    } | null> | null;
  } | null;
};

export type PaymentInstrumentFragment = {
  uuid: string;
  alias: string | null;
  providerNickName: string | null;
  accountNumber: string | null;
  accountType: PaymentAccountType;
  paymentType: PaymentInstrumentType | null;
  balance: number | null;
  isDefault: boolean | null;
  displayNumber: string | null;
  status: PaymentInstrumentStatus;
  address: { zipCode: string } | null;
};

export type AwardPointsFragment = { __typename: 'AwardPoints'; points: number };

export type AwardPointsPerFragment = {
  __typename: 'AwardPointsPer';
  points: number;
  rounding: AwardPointsRoundingStrategy;
};

export type AwardVouchersFragment = {
  __typename: 'AwardVouchers';
  voucherExpirationDays: number | null;
  voucherCount: number;
  voucheredOffer: { id: string; voucherCount: number };
};

type AwardPointsOrVouchersAwardPointsFragment = { __typename: 'AwardPoints'; points: number };

type AwardPointsOrVouchersAwardVouchersFragment = {
  __typename: 'AwardVouchers';
  voucherExpirationDays: number | null;
  voucherCount: number;
  voucheredOffer: { id: string; voucherCount: number };
};

export type AwardPointsOrVouchersFragment =
  | AwardPointsOrVouchersAwardPointsFragment
  | AwardPointsOrVouchersAwardVouchersFragment;

export type GameAwardVouchersFragment = {
  __typename: 'AwardVouchers';
  voucherExpirationDays: number | null;
  voucheredOffer: {
    __typename: 'Offer';
    id: string;
    marketingContents: Array<{
      id: string;
      imageUrl: any | null;
      title: string | null;
      shortDescription: string | null;
      longDescription: string | null;
      disclaimer: string | null;
      disclaimerUrl: any | null;
      terms: string | null;
      termsUrl: any | null;
      surgeonGeneralsWarning: string | null;
      externalUrl: string | null;
    } | null>;
  };
};

type GameAwardPointsOrVouchersAwardPointsFragment = { __typename: 'AwardPoints'; points: number };

type GameAwardPointsOrVouchersAwardVouchersFragment = {
  __typename: 'AwardVouchers';
  voucherExpirationDays: number | null;
  voucheredOffer: {
    __typename: 'Offer';
    id: string;
    marketingContents: Array<{
      id: string;
      imageUrl: any | null;
      title: string | null;
      shortDescription: string | null;
      longDescription: string | null;
      disclaimer: string | null;
      disclaimerUrl: any | null;
      terms: string | null;
      termsUrl: any | null;
      surgeonGeneralsWarning: string | null;
      externalUrl: string | null;
    } | null>;
  };
};

export type GameAwardPointsOrVouchersFragment =
  | GameAwardPointsOrVouchersAwardPointsFragment
  | GameAwardPointsOrVouchersAwardVouchersFragment;

export type BaseOffersQueryVariables = Exact<{
  first: Scalars['Int'];
  deleted: Scalars['Boolean'];
  publicationStatusEquals?: InputMaybe<PublicationStatusType>;
  visibleStatusEquals?: InputMaybe<OfferVisibleStatus>;
  presentationTypeIncludes?: InputMaybe<Array<OfferPresentationType> | OfferPresentationType>;
  engagementTypeEquals: OfferEngagementType;
  offerLimitsIncluded?: InputMaybe<Scalars['Boolean']>;
  offerVouchersIncluded?: InputMaybe<Scalars['Boolean']>;
}>;

export type BaseOffersQuery = {
  customer: {
    uuid: string;
    membership: {
      id: string;
      offers: {
        edges: Array<{
          node: {
            __typename: 'Offer';
            id: string;
            expireAt: any | null;
            engagementType: OfferEngagementType | null;
            activationStatus: OfferActivationStatus;
            visibleStatus: OfferVisibleStatus;
            customData: any | null;
            voucherCount: number;
            presentationPriority: OfferPresentationPriority;
            presentationType: OfferPresentationType;
            marketingContents: Array<{
              id: string;
              imageUrl: any | null;
              title: string | null;
              shortDescription: string | null;
              longDescription: string | null;
              disclaimer: string | null;
              disclaimerUrl: any | null;
              terms: string | null;
              termsUrl: any | null;
              surgeonGeneralsWarning: string | null;
              externalUrl: string | null;
            } | null>;
            earningMechanism:
              | {
                  __typename: 'EarningMechanismExternalFulfillment';
                  id: string;
                  optionalAward:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      }
                    | null;
                }
              | {
                  __typename: 'EarningMechanismLoyaltyEvent';
                  id: string;
                  optionalAward:
                    | { __typename: 'AwardExternalPoints' }
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      }
                    | null;
                }
              | {
                  __typename: 'EarningMechanismMembershipEvent';
                  id: string;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | {
                  __typename: 'EarningMechanismPointSpend';
                  id: string;
                  pointCost: number;
                  award: {
                    __typename: 'AwardVouchers';
                    voucherExpirationDays: number | null;
                    voucherCount: number;
                    voucheredOffer: { id: string; voucherCount: number };
                  };
                }
              | { __typename: 'EarningMechanismProductDiscount'; id: string }
              | {
                  __typename: 'EarningMechanismProductPurchase';
                  id: string;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | { __typename: 'EarningMechanismPromocodes' }
              | {
                  __typename: 'EarningMechanismSpinWheelGame';
                  id: string;
                  awards: Array<{
                    awardType: RandomAwardType;
                    limit: number;
                    sequenceNumber: number;
                    award:
                      | { __typename: 'AwardPoints'; points: number }
                      | {
                          __typename: 'AwardVouchers';
                          voucherExpirationDays: number | null;
                          voucheredOffer: {
                            __typename: 'Offer';
                            id: string;
                            marketingContents: Array<{
                              id: string;
                              imageUrl: any | null;
                              title: string | null;
                              shortDescription: string | null;
                              longDescription: string | null;
                              disclaimer: string | null;
                              disclaimerUrl: any | null;
                              terms: string | null;
                              termsUrl: any | null;
                              surgeonGeneralsWarning: string | null;
                              externalUrl: string | null;
                            } | null>;
                          };
                        }
                      | null;
                  }>;
                  purchasableOffer: {
                    __typename: 'Offer';
                    id: string;
                    earningMechanism:
                      | { __typename: 'EarningMechanismExternalFulfillment' }
                      | { __typename: 'EarningMechanismLoyaltyEvent' }
                      | { __typename: 'EarningMechanismMembershipEvent' }
                      | { __typename: 'EarningMechanismPointSpend'; id: string; pointCost: number }
                      | { __typename: 'EarningMechanismProductDiscount' }
                      | { __typename: 'EarningMechanismProductPurchase' }
                      | { __typename: 'EarningMechanismPromocodes' }
                      | { __typename: 'EarningMechanismSpinWheelGame' }
                      | { __typename: 'EarningMechanismTotalEligiblePurchase' }
                      | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime' }
                      | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
                      | { __typename: 'EarningMechanismTotalSpendOverTime' }
                      | { __typename: 'EarningMechanismTransactionDiscount' }
                      | { __typename: 'EarningMechanismVarietyDiscount' }
                      | { __typename: 'EarningMechanismVarietyPurchase' }
                      | { __typename: 'EarningMechanismVarietyPurchaseOverTime' }
                      | { __typename: 'EarningMechanismVisits' }
                      | null;
                  } | null;
                }
              | {
                  __typename: 'EarningMechanismTotalEligiblePurchase';
                  id: string;
                  award: { __typename: 'AwardPointsPer'; points: number; rounding: AwardPointsRoundingStrategy };
                }
              | {
                  __typename: 'EarningMechanismTotalEligiblePurchaseOverTime';
                  id: string;
                  purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
              | {
                  __typename: 'EarningMechanismTotalSpendOverTime';
                  id: string;
                  purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | { __typename: 'EarningMechanismTransactionDiscount'; id: string }
              | { __typename: 'EarningMechanismVarietyDiscount'; id: string }
              | {
                  __typename: 'EarningMechanismVarietyPurchase';
                  id: string;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | {
                  __typename: 'EarningMechanismVarietyPurchaseOverTime';
                  id: string;
                  varietyMemberProgress: Array<{ quantityPurchased: number; quantityRequired: number }>;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | {
                  __typename: 'EarningMechanismVisits';
                  id: string;
                  visitsMemberProgress: { visits: number; visitsRequired: number } | null;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | null;
            vouchers?: {
              edges: Array<{ node: { id: string; expireAt: any | null; createdAt: any; state: VoucherState } }>;
            };
            displayCategories: Array<{
              id: string;
              name: string;
              rank: number;
              categoryRestrictions: CategoryRestrictions | null;
              bannerIds: Array<string> | null;
            }>;
            limits?: Array<
              | { __typename: 'LimitGlobalActivation'; id: string }
              | { __typename: 'LimitGlobalActivationInTime'; id: string }
              | { __typename: 'LimitGlobalAwardCount'; id: string }
              | { __typename: 'LimitGlobalAwardCountInTime'; id: string }
              | { __typename: 'LimitInTransactionAwardCount'; id: string }
              | { __typename: 'LimitMembershipActivation'; id: string }
              | { __typename: 'LimitMembershipActivationInTime'; id: string }
              | {
                  __typename: 'LimitMembershipAwardCount';
                  id: string;
                  limit: number;
                  membershipQuantityAvailable: number | null;
                }
              | {
                  __typename: 'LimitMembershipAwardCountInTime';
                  id: string;
                  limit: number;
                  membershipQuantityAvailable: number | null;
                }
            >;
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type EarningMechanismProductPurchaseFragment = {
  id: string;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucherCount: number;
        voucheredOffer: { id: string; voucherCount: number };
      };
};

export type EarningMechanismPointSpendFragment = {
  __typename: 'EarningMechanismPointSpend';
  id: string;
  pointCost: number;
  award: {
    __typename: 'AwardVouchers';
    voucherExpirationDays: number | null;
    voucherCount: number;
    voucheredOffer: { id: string; voucherCount: number };
  };
};

export type EarningMechanismExternalFulfillmentFragment = {
  id: string;
  optionalAward:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucherCount: number;
        voucheredOffer: { id: string; voucherCount: number };
      }
    | null;
};

export type EarningMechanismLoyaltyEventFragment = {
  id: string;
  optionalAward:
    | { __typename: 'AwardExternalPoints' }
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucherCount: number;
        voucheredOffer: { id: string; voucherCount: number };
      }
    | null;
};

export type EarningMechanismMembershipEventFragment = {
  id: string;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucherCount: number;
        voucheredOffer: { id: string; voucherCount: number };
      };
};

export type EarningMechanismVarietyPurchaseFragment = {
  id: string;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucherCount: number;
        voucheredOffer: { id: string; voucherCount: number };
      };
};

export type EarningMechanismTotalEligiblePurchaseFragment = {
  id: string;
  award: { __typename: 'AwardPointsPer'; points: number; rounding: AwardPointsRoundingStrategy };
};

export type EarningMechanismTotalEligiblePurchaseOverTimeFragment = {
  id: string;
  purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucherCount: number;
        voucheredOffer: { id: string; voucherCount: number };
      };
};

export type EarningMechanismTotalSpendOverTimeFragment = {
  id: string;
  purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucherCount: number;
        voucheredOffer: { id: string; voucherCount: number };
      };
};

export type EarningMechanismVisitsFragment = {
  id: string;
  visitsMemberProgress: { visits: number; visitsRequired: number } | null;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucherCount: number;
        voucheredOffer: { id: string; voucherCount: number };
      };
};

export type EarningMechanismVarietyPurchaseOverTimeFragment = {
  id: string;
  varietyMemberProgress: Array<{ quantityPurchased: number; quantityRequired: number }>;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucherCount: number;
        voucheredOffer: { id: string; voucherCount: number };
      };
};

export type EarningMechanismProductDiscountFragment = { __typename: 'EarningMechanismProductDiscount'; id: string };

export type EarningMechanismTransactionDiscountFragment = {
  __typename: 'EarningMechanismTransactionDiscount';
  id: string;
};

export type EarningMechanismVarietyDiscountFragment = { __typename: 'EarningMechanismVarietyDiscount'; id: string };

export type SpinWheelGameVoucherPurchaseFragment = {
  __typename: 'Offer';
  id: string;
  earningMechanism:
    | { __typename: 'EarningMechanismExternalFulfillment' }
    | { __typename: 'EarningMechanismLoyaltyEvent' }
    | { __typename: 'EarningMechanismMembershipEvent' }
    | { __typename: 'EarningMechanismPointSpend'; id: string; pointCost: number }
    | { __typename: 'EarningMechanismProductDiscount' }
    | { __typename: 'EarningMechanismProductPurchase' }
    | { __typename: 'EarningMechanismPromocodes' }
    | { __typename: 'EarningMechanismSpinWheelGame' }
    | { __typename: 'EarningMechanismTotalEligiblePurchase' }
    | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime' }
    | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
    | { __typename: 'EarningMechanismTotalSpendOverTime' }
    | { __typename: 'EarningMechanismTransactionDiscount' }
    | { __typename: 'EarningMechanismVarietyDiscount' }
    | { __typename: 'EarningMechanismVarietyPurchase' }
    | { __typename: 'EarningMechanismVarietyPurchaseOverTime' }
    | { __typename: 'EarningMechanismVisits' }
    | null;
};

export type SpinWheelGamePrizeFragment = {
  awardType: RandomAwardType;
  limit: number;
  sequenceNumber: number;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucheredOffer: {
          __typename: 'Offer';
          id: string;
          marketingContents: Array<{
            id: string;
            imageUrl: any | null;
            title: string | null;
            shortDescription: string | null;
            longDescription: string | null;
            disclaimer: string | null;
            disclaimerUrl: any | null;
            terms: string | null;
            termsUrl: any | null;
            surgeonGeneralsWarning: string | null;
            externalUrl: string | null;
          } | null>;
        };
      }
    | null;
};

export type EarningMechanismSpinWheelGameFragment = {
  __typename: 'EarningMechanismSpinWheelGame';
  id: string;
  awards: Array<{
    awardType: RandomAwardType;
    limit: number;
    sequenceNumber: number;
    award:
      | { __typename: 'AwardPoints'; points: number }
      | {
          __typename: 'AwardVouchers';
          voucherExpirationDays: number | null;
          voucheredOffer: {
            __typename: 'Offer';
            id: string;
            marketingContents: Array<{
              id: string;
              imageUrl: any | null;
              title: string | null;
              shortDescription: string | null;
              longDescription: string | null;
              disclaimer: string | null;
              disclaimerUrl: any | null;
              terms: string | null;
              termsUrl: any | null;
              surgeonGeneralsWarning: string | null;
              externalUrl: string | null;
            } | null>;
          };
        }
      | null;
  }>;
  purchasableOffer: {
    __typename: 'Offer';
    id: string;
    earningMechanism:
      | { __typename: 'EarningMechanismExternalFulfillment' }
      | { __typename: 'EarningMechanismLoyaltyEvent' }
      | { __typename: 'EarningMechanismMembershipEvent' }
      | { __typename: 'EarningMechanismPointSpend'; id: string; pointCost: number }
      | { __typename: 'EarningMechanismProductDiscount' }
      | { __typename: 'EarningMechanismProductPurchase' }
      | { __typename: 'EarningMechanismPromocodes' }
      | { __typename: 'EarningMechanismSpinWheelGame' }
      | { __typename: 'EarningMechanismTotalEligiblePurchase' }
      | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime' }
      | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
      | { __typename: 'EarningMechanismTotalSpendOverTime' }
      | { __typename: 'EarningMechanismTransactionDiscount' }
      | { __typename: 'EarningMechanismVarietyDiscount' }
      | { __typename: 'EarningMechanismVarietyPurchase' }
      | { __typename: 'EarningMechanismVarietyPurchaseOverTime' }
      | { __typename: 'EarningMechanismVisits' }
      | null;
  } | null;
};

export type EarningMechanismPromocodesFragment = {
  id: string;
  award:
    | { __typename: 'AwardPoints'; points: number }
    | {
        __typename: 'AwardVouchers';
        voucherExpirationDays: number | null;
        voucherCount: number;
        voucheredOffer: { id: string; voucherCount: number };
      }
    | null;
};

export type GetOfferVoucherQueryVariables = Exact<{
  offerId: Scalars['ID'];
  voucherFilter: VoucherFilter;
}>;

export type GetOfferVoucherQuery = {
  customer: {
    uuid: string;
    membership: {
      id: string;
      offers: {
        edges: Array<{
          node: {
            id: string;
            vouchers: {
              edges: Array<{
                node: {
                  id: string;
                  source:
                    | { __typename: 'ActivateTransaction' }
                    | { __typename: 'GameSession' }
                    | { __typename: 'LoyaltyEvent' }
                    | { __typename: 'Offer' }
                    | { __typename: 'OfferPurchase'; purchasedWith: { id: string } }
                    | { __typename: 'PromocodeUseRequest' }
                    | null;
                };
              }>;
            };
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type OfferFragment = {
  __typename: 'Offer';
  id: string;
  expireAt: any | null;
  engagementType: OfferEngagementType | null;
  activationStatus: OfferActivationStatus;
  visibleStatus: OfferVisibleStatus;
  customData: any | null;
  voucherCount: number;
  presentationPriority: OfferPresentationPriority;
  presentationType: OfferPresentationType;
  marketingContents: Array<{
    id: string;
    imageUrl: any | null;
    title: string | null;
    shortDescription: string | null;
    longDescription: string | null;
    disclaimer: string | null;
    disclaimerUrl: any | null;
    terms: string | null;
    termsUrl: any | null;
    surgeonGeneralsWarning: string | null;
    externalUrl: string | null;
  } | null>;
  earningMechanism:
    | {
        __typename: 'EarningMechanismExternalFulfillment';
        id: string;
        optionalAward:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucherExpirationDays: number | null;
              voucherCount: number;
              voucheredOffer: { id: string; voucherCount: number };
            }
          | null;
      }
    | {
        __typename: 'EarningMechanismLoyaltyEvent';
        id: string;
        optionalAward:
          | { __typename: 'AwardExternalPoints' }
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucherExpirationDays: number | null;
              voucherCount: number;
              voucheredOffer: { id: string; voucherCount: number };
            }
          | null;
      }
    | {
        __typename: 'EarningMechanismMembershipEvent';
        id: string;
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucherExpirationDays: number | null;
              voucherCount: number;
              voucheredOffer: { id: string; voucherCount: number };
            };
      }
    | {
        __typename: 'EarningMechanismPointSpend';
        id: string;
        pointCost: number;
        award: {
          __typename: 'AwardVouchers';
          voucherExpirationDays: number | null;
          voucherCount: number;
          voucheredOffer: { id: string; voucherCount: number };
        };
      }
    | { __typename: 'EarningMechanismProductDiscount'; id: string }
    | {
        __typename: 'EarningMechanismProductPurchase';
        id: string;
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucherExpirationDays: number | null;
              voucherCount: number;
              voucheredOffer: { id: string; voucherCount: number };
            };
      }
    | { __typename: 'EarningMechanismPromocodes' }
    | {
        __typename: 'EarningMechanismSpinWheelGame';
        id: string;
        awards: Array<{
          awardType: RandomAwardType;
          limit: number;
          sequenceNumber: number;
          award:
            | { __typename: 'AwardPoints'; points: number }
            | {
                __typename: 'AwardVouchers';
                voucherExpirationDays: number | null;
                voucheredOffer: {
                  __typename: 'Offer';
                  id: string;
                  marketingContents: Array<{
                    id: string;
                    imageUrl: any | null;
                    title: string | null;
                    shortDescription: string | null;
                    longDescription: string | null;
                    disclaimer: string | null;
                    disclaimerUrl: any | null;
                    terms: string | null;
                    termsUrl: any | null;
                    surgeonGeneralsWarning: string | null;
                    externalUrl: string | null;
                  } | null>;
                };
              }
            | null;
        }>;
        purchasableOffer: {
          __typename: 'Offer';
          id: string;
          earningMechanism:
            | { __typename: 'EarningMechanismExternalFulfillment' }
            | { __typename: 'EarningMechanismLoyaltyEvent' }
            | { __typename: 'EarningMechanismMembershipEvent' }
            | { __typename: 'EarningMechanismPointSpend'; id: string; pointCost: number }
            | { __typename: 'EarningMechanismProductDiscount' }
            | { __typename: 'EarningMechanismProductPurchase' }
            | { __typename: 'EarningMechanismPromocodes' }
            | { __typename: 'EarningMechanismSpinWheelGame' }
            | { __typename: 'EarningMechanismTotalEligiblePurchase' }
            | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime' }
            | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
            | { __typename: 'EarningMechanismTotalSpendOverTime' }
            | { __typename: 'EarningMechanismTransactionDiscount' }
            | { __typename: 'EarningMechanismVarietyDiscount' }
            | { __typename: 'EarningMechanismVarietyPurchase' }
            | { __typename: 'EarningMechanismVarietyPurchaseOverTime' }
            | { __typename: 'EarningMechanismVisits' }
            | null;
        } | null;
      }
    | {
        __typename: 'EarningMechanismTotalEligiblePurchase';
        id: string;
        award: { __typename: 'AwardPointsPer'; points: number; rounding: AwardPointsRoundingStrategy };
      }
    | {
        __typename: 'EarningMechanismTotalEligiblePurchaseOverTime';
        id: string;
        purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucherExpirationDays: number | null;
              voucherCount: number;
              voucheredOffer: { id: string; voucherCount: number };
            };
      }
    | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
    | {
        __typename: 'EarningMechanismTotalSpendOverTime';
        id: string;
        purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucherExpirationDays: number | null;
              voucherCount: number;
              voucheredOffer: { id: string; voucherCount: number };
            };
      }
    | { __typename: 'EarningMechanismTransactionDiscount'; id: string }
    | { __typename: 'EarningMechanismVarietyDiscount'; id: string }
    | {
        __typename: 'EarningMechanismVarietyPurchase';
        id: string;
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucherExpirationDays: number | null;
              voucherCount: number;
              voucheredOffer: { id: string; voucherCount: number };
            };
      }
    | {
        __typename: 'EarningMechanismVarietyPurchaseOverTime';
        id: string;
        varietyMemberProgress: Array<{ quantityPurchased: number; quantityRequired: number }>;
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucherExpirationDays: number | null;
              voucherCount: number;
              voucheredOffer: { id: string; voucherCount: number };
            };
      }
    | {
        __typename: 'EarningMechanismVisits';
        id: string;
        visitsMemberProgress: { visits: number; visitsRequired: number } | null;
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucherExpirationDays: number | null;
              voucherCount: number;
              voucheredOffer: { id: string; voucherCount: number };
            };
      }
    | null;
  vouchers?: { edges: Array<{ node: { id: string; expireAt: any | null; createdAt: any; state: VoucherState } }> };
  displayCategories: Array<{
    id: string;
    name: string;
    rank: number;
    categoryRestrictions: CategoryRestrictions | null;
    bannerIds: Array<string> | null;
  }>;
  limits?: Array<
    | { __typename: 'LimitGlobalActivation'; id: string }
    | { __typename: 'LimitGlobalActivationInTime'; id: string }
    | { __typename: 'LimitGlobalAwardCount'; id: string }
    | { __typename: 'LimitGlobalAwardCountInTime'; id: string }
    | { __typename: 'LimitInTransactionAwardCount'; id: string }
    | { __typename: 'LimitMembershipActivation'; id: string }
    | { __typename: 'LimitMembershipActivationInTime'; id: string }
    | { __typename: 'LimitMembershipAwardCount'; id: string; limit: number; membershipQuantityAvailable: number | null }
    | {
        __typename: 'LimitMembershipAwardCountInTime';
        id: string;
        limit: number;
        membershipQuantityAvailable: number | null;
      }
  >;
};

export type OfferQueryVariables = Exact<{
  offerId: Scalars['ID'];
  offerLimitsIncluded?: InputMaybe<Scalars['Boolean']>;
  offerVouchersIncluded?: InputMaybe<Scalars['Boolean']>;
}>;

export type OfferQuery = {
  customer: {
    uuid: string;
    membership: {
      id: string;
      offers: {
        edges: Array<{
          node: {
            __typename: 'Offer';
            id: string;
            expireAt: any | null;
            engagementType: OfferEngagementType | null;
            activationStatus: OfferActivationStatus;
            visibleStatus: OfferVisibleStatus;
            customData: any | null;
            voucherCount: number;
            presentationPriority: OfferPresentationPriority;
            presentationType: OfferPresentationType;
            marketingContents: Array<{
              id: string;
              imageUrl: any | null;
              title: string | null;
              shortDescription: string | null;
              longDescription: string | null;
              disclaimer: string | null;
              disclaimerUrl: any | null;
              terms: string | null;
              termsUrl: any | null;
              surgeonGeneralsWarning: string | null;
              externalUrl: string | null;
            } | null>;
            earningMechanism:
              | {
                  __typename: 'EarningMechanismExternalFulfillment';
                  id: string;
                  optionalAward:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      }
                    | null;
                }
              | {
                  __typename: 'EarningMechanismLoyaltyEvent';
                  id: string;
                  optionalAward:
                    | { __typename: 'AwardExternalPoints' }
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      }
                    | null;
                }
              | {
                  __typename: 'EarningMechanismMembershipEvent';
                  id: string;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | {
                  __typename: 'EarningMechanismPointSpend';
                  id: string;
                  pointCost: number;
                  award: {
                    __typename: 'AwardVouchers';
                    voucherExpirationDays: number | null;
                    voucherCount: number;
                    voucheredOffer: { id: string; voucherCount: number };
                  };
                }
              | { __typename: 'EarningMechanismProductDiscount'; id: string }
              | {
                  __typename: 'EarningMechanismProductPurchase';
                  id: string;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | { __typename: 'EarningMechanismPromocodes' }
              | {
                  __typename: 'EarningMechanismSpinWheelGame';
                  id: string;
                  awards: Array<{
                    awardType: RandomAwardType;
                    limit: number;
                    sequenceNumber: number;
                    award:
                      | { __typename: 'AwardPoints'; points: number }
                      | {
                          __typename: 'AwardVouchers';
                          voucherExpirationDays: number | null;
                          voucheredOffer: {
                            __typename: 'Offer';
                            id: string;
                            marketingContents: Array<{
                              id: string;
                              imageUrl: any | null;
                              title: string | null;
                              shortDescription: string | null;
                              longDescription: string | null;
                              disclaimer: string | null;
                              disclaimerUrl: any | null;
                              terms: string | null;
                              termsUrl: any | null;
                              surgeonGeneralsWarning: string | null;
                              externalUrl: string | null;
                            } | null>;
                          };
                        }
                      | null;
                  }>;
                  purchasableOffer: {
                    __typename: 'Offer';
                    id: string;
                    earningMechanism:
                      | { __typename: 'EarningMechanismExternalFulfillment' }
                      | { __typename: 'EarningMechanismLoyaltyEvent' }
                      | { __typename: 'EarningMechanismMembershipEvent' }
                      | { __typename: 'EarningMechanismPointSpend'; id: string; pointCost: number }
                      | { __typename: 'EarningMechanismProductDiscount' }
                      | { __typename: 'EarningMechanismProductPurchase' }
                      | { __typename: 'EarningMechanismPromocodes' }
                      | { __typename: 'EarningMechanismSpinWheelGame' }
                      | { __typename: 'EarningMechanismTotalEligiblePurchase' }
                      | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime' }
                      | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
                      | { __typename: 'EarningMechanismTotalSpendOverTime' }
                      | { __typename: 'EarningMechanismTransactionDiscount' }
                      | { __typename: 'EarningMechanismVarietyDiscount' }
                      | { __typename: 'EarningMechanismVarietyPurchase' }
                      | { __typename: 'EarningMechanismVarietyPurchaseOverTime' }
                      | { __typename: 'EarningMechanismVisits' }
                      | null;
                  } | null;
                }
              | {
                  __typename: 'EarningMechanismTotalEligiblePurchase';
                  id: string;
                  award: { __typename: 'AwardPointsPer'; points: number; rounding: AwardPointsRoundingStrategy };
                }
              | {
                  __typename: 'EarningMechanismTotalEligiblePurchaseOverTime';
                  id: string;
                  purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
              | {
                  __typename: 'EarningMechanismTotalSpendOverTime';
                  id: string;
                  purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | { __typename: 'EarningMechanismTransactionDiscount'; id: string }
              | { __typename: 'EarningMechanismVarietyDiscount'; id: string }
              | {
                  __typename: 'EarningMechanismVarietyPurchase';
                  id: string;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | {
                  __typename: 'EarningMechanismVarietyPurchaseOverTime';
                  id: string;
                  varietyMemberProgress: Array<{ quantityPurchased: number; quantityRequired: number }>;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | {
                  __typename: 'EarningMechanismVisits';
                  id: string;
                  visitsMemberProgress: { visits: number; visitsRequired: number } | null;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | null;
            vouchers?: {
              edges: Array<{ node: { id: string; expireAt: any | null; createdAt: any; state: VoucherState } }>;
            };
            displayCategories: Array<{
              id: string;
              name: string;
              rank: number;
              categoryRestrictions: CategoryRestrictions | null;
              bannerIds: Array<string> | null;
            }>;
            limits?: Array<
              | { __typename: 'LimitGlobalActivation'; id: string }
              | { __typename: 'LimitGlobalActivationInTime'; id: string }
              | { __typename: 'LimitGlobalAwardCount'; id: string }
              | { __typename: 'LimitGlobalAwardCountInTime'; id: string }
              | { __typename: 'LimitInTransactionAwardCount'; id: string }
              | { __typename: 'LimitMembershipActivation'; id: string }
              | { __typename: 'LimitMembershipActivationInTime'; id: string }
              | {
                  __typename: 'LimitMembershipAwardCount';
                  id: string;
                  limit: number;
                  membershipQuantityAvailable: number | null;
                }
              | {
                  __typename: 'LimitMembershipAwardCountInTime';
                  id: string;
                  limit: number;
                  membershipQuantityAvailable: number | null;
                }
            >;
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type OffersQueryVariables = Exact<{
  first: Scalars['Int'];
  deleted: Scalars['Boolean'];
  publicationStatusEquals?: InputMaybe<PublicationStatusType>;
  visibleStatusEquals?: InputMaybe<OfferVisibleStatus>;
  presentationTypeIncludes?: InputMaybe<Array<OfferPresentationType> | OfferPresentationType>;
  engagementTypeEquals?: InputMaybe<OfferEngagementType>;
  engagementTypeExcludes?: InputMaybe<Array<OfferEngagementType> | OfferEngagementType>;
  offerLimitsIncluded?: InputMaybe<Scalars['Boolean']>;
  offerVouchersIncluded?: InputMaybe<Scalars['Boolean']>;
}>;

export type OffersQuery = {
  customer: {
    uuid: string;
    membership: {
      id: string;
      offers: {
        edges: Array<{
          node: {
            __typename: 'Offer';
            id: string;
            expireAt: any | null;
            engagementType: OfferEngagementType | null;
            activationStatus: OfferActivationStatus;
            visibleStatus: OfferVisibleStatus;
            customData: any | null;
            voucherCount: number;
            presentationPriority: OfferPresentationPriority;
            presentationType: OfferPresentationType;
            marketingContents: Array<{
              id: string;
              imageUrl: any | null;
              title: string | null;
              shortDescription: string | null;
              longDescription: string | null;
              disclaimer: string | null;
              disclaimerUrl: any | null;
              terms: string | null;
              termsUrl: any | null;
              surgeonGeneralsWarning: string | null;
              externalUrl: string | null;
            } | null>;
            earningMechanism:
              | {
                  __typename: 'EarningMechanismExternalFulfillment';
                  id: string;
                  optionalAward:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      }
                    | null;
                }
              | {
                  __typename: 'EarningMechanismLoyaltyEvent';
                  id: string;
                  optionalAward:
                    | { __typename: 'AwardExternalPoints' }
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      }
                    | null;
                }
              | {
                  __typename: 'EarningMechanismMembershipEvent';
                  id: string;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | {
                  __typename: 'EarningMechanismPointSpend';
                  id: string;
                  pointCost: number;
                  award: {
                    __typename: 'AwardVouchers';
                    voucherExpirationDays: number | null;
                    voucherCount: number;
                    voucheredOffer: { id: string; voucherCount: number };
                  };
                }
              | { __typename: 'EarningMechanismProductDiscount'; id: string }
              | {
                  __typename: 'EarningMechanismProductPurchase';
                  id: string;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | { __typename: 'EarningMechanismPromocodes' }
              | {
                  __typename: 'EarningMechanismSpinWheelGame';
                  id: string;
                  awards: Array<{
                    awardType: RandomAwardType;
                    limit: number;
                    sequenceNumber: number;
                    award:
                      | { __typename: 'AwardPoints'; points: number }
                      | {
                          __typename: 'AwardVouchers';
                          voucherExpirationDays: number | null;
                          voucheredOffer: {
                            __typename: 'Offer';
                            id: string;
                            marketingContents: Array<{
                              id: string;
                              imageUrl: any | null;
                              title: string | null;
                              shortDescription: string | null;
                              longDescription: string | null;
                              disclaimer: string | null;
                              disclaimerUrl: any | null;
                              terms: string | null;
                              termsUrl: any | null;
                              surgeonGeneralsWarning: string | null;
                              externalUrl: string | null;
                            } | null>;
                          };
                        }
                      | null;
                  }>;
                  purchasableOffer: {
                    __typename: 'Offer';
                    id: string;
                    earningMechanism:
                      | { __typename: 'EarningMechanismExternalFulfillment' }
                      | { __typename: 'EarningMechanismLoyaltyEvent' }
                      | { __typename: 'EarningMechanismMembershipEvent' }
                      | { __typename: 'EarningMechanismPointSpend'; id: string; pointCost: number }
                      | { __typename: 'EarningMechanismProductDiscount' }
                      | { __typename: 'EarningMechanismProductPurchase' }
                      | { __typename: 'EarningMechanismPromocodes' }
                      | { __typename: 'EarningMechanismSpinWheelGame' }
                      | { __typename: 'EarningMechanismTotalEligiblePurchase' }
                      | { __typename: 'EarningMechanismTotalEligiblePurchaseOverTime' }
                      | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
                      | { __typename: 'EarningMechanismTotalSpendOverTime' }
                      | { __typename: 'EarningMechanismTransactionDiscount' }
                      | { __typename: 'EarningMechanismVarietyDiscount' }
                      | { __typename: 'EarningMechanismVarietyPurchase' }
                      | { __typename: 'EarningMechanismVarietyPurchaseOverTime' }
                      | { __typename: 'EarningMechanismVisits' }
                      | null;
                  } | null;
                }
              | {
                  __typename: 'EarningMechanismTotalEligiblePurchase';
                  id: string;
                  award: { __typename: 'AwardPointsPer'; points: number; rounding: AwardPointsRoundingStrategy };
                }
              | {
                  __typename: 'EarningMechanismTotalEligiblePurchaseOverTime';
                  id: string;
                  purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | { __typename: 'EarningMechanismTotalSpendOnTransaction' }
              | {
                  __typename: 'EarningMechanismTotalSpendOverTime';
                  id: string;
                  purchaseMemberProgress: { quantityPurchased: number; quantityRequired: number } | null;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | { __typename: 'EarningMechanismTransactionDiscount'; id: string }
              | { __typename: 'EarningMechanismVarietyDiscount'; id: string }
              | {
                  __typename: 'EarningMechanismVarietyPurchase';
                  id: string;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | {
                  __typename: 'EarningMechanismVarietyPurchaseOverTime';
                  id: string;
                  varietyMemberProgress: Array<{ quantityPurchased: number; quantityRequired: number }>;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | {
                  __typename: 'EarningMechanismVisits';
                  id: string;
                  visitsMemberProgress: { visits: number; visitsRequired: number } | null;
                  award:
                    | { __typename: 'AwardPoints'; points: number }
                    | {
                        __typename: 'AwardVouchers';
                        voucherExpirationDays: number | null;
                        voucherCount: number;
                        voucheredOffer: { id: string; voucherCount: number };
                      };
                }
              | null;
            vouchers?: {
              edges: Array<{ node: { id: string; expireAt: any | null; createdAt: any; state: VoucherState } }>;
            };
            displayCategories: Array<{
              id: string;
              name: string;
              rank: number;
              categoryRestrictions: CategoryRestrictions | null;
              bannerIds: Array<string> | null;
            }>;
            limits?: Array<
              | { __typename: 'LimitGlobalActivation'; id: string }
              | { __typename: 'LimitGlobalActivationInTime'; id: string }
              | { __typename: 'LimitGlobalAwardCount'; id: string }
              | { __typename: 'LimitGlobalAwardCountInTime'; id: string }
              | { __typename: 'LimitInTransactionAwardCount'; id: string }
              | { __typename: 'LimitMembershipActivation'; id: string }
              | { __typename: 'LimitMembershipActivationInTime'; id: string }
              | {
                  __typename: 'LimitMembershipAwardCount';
                  id: string;
                  limit: number;
                  membershipQuantityAvailable: number | null;
                }
              | {
                  __typename: 'LimitMembershipAwardCountInTime';
                  id: string;
                  limit: number;
                  membershipQuantityAvailable: number | null;
                }
            >;
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type RedeemPromocodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type RedeemPromocodeMutation = {
  result:
    | { __typename: 'RedeemPromocodeResultFail'; reason: string | null }
    | {
        __typename: 'RedeemPromocodeResultSuccess';
        award:
          | { __typename: 'AwardPoints'; points: number }
          | {
              __typename: 'AwardVouchers';
              voucherCount: number;
              voucheredOffer: {
                id: string;
                voucherCount: number;
                marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
              };
            }
          | null;
      };
};

type PromocodeRedeemedAwardAwardPointsFragment = { __typename: 'AwardPoints'; points: number };

type PromocodeRedeemedAwardAwardVouchersFragment = {
  __typename: 'AwardVouchers';
  voucherCount: number;
  voucheredOffer: {
    id: string;
    voucherCount: number;
    marketingContents: Array<{ id: string; title: string | null; imageUrl: any | null } | null>;
  };
};

export type PromocodeRedeemedAwardFragment =
  | PromocodeRedeemedAwardAwardPointsFragment
  | PromocodeRedeemedAwardAwardVouchersFragment;

export const OfferByLoyaltyEventFragmentDoc = gql`
  fragment OfferByLoyaltyEvent on Offer {
    id
    customData
    marketingContents {
      id
      title
      shortDescription
      imageUrl
      terms
      disclaimer
      disclaimerUrl
    }
  }
`;
export const LoyaltyActivitySourceChainFragmentDoc = gql`
  fragment LoyaltyActivitySourceChain on Chain {
    id
  }
`;
export const LoyaltyActivitySourceActivateUserFragmentDoc = gql`
  fragment LoyaltyActivitySourceActivateUser on ActivateUser {
    id
  }
`;
export const LoyaltyActivitySourceLoyaltyEventFragmentDoc = gql`
  fragment LoyaltyActivitySourceLoyaltyEvent on LoyaltyEvent {
    eventType
    createdAt
  }
`;
export const LoyaltyActivitySourceActivateTransactionFragmentDoc = gql`
  fragment LoyaltyActivitySourceActivateTransaction on ActivateTransaction {
    id
  }
`;
export const LoyaltyActivitySourceOfferPurchaseFragmentDoc = gql`
  fragment LoyaltyActivitySourceOfferPurchase on OfferPurchase {
    purchasedWith {
      id
      name
      marketingContents {
        id
        title
      }
    }
  }
`;
export const LoyaltyActivitySourcePointChangeFragmentDoc = gql`
  fragment LoyaltyActivitySourcePointChange on PointChange {
    id
    offer {
      id
      name
      marketingContents {
        id
        title
      }
    }
  }
`;
export const PointChangeFragmentDoc = gql`
  fragment PointChange on PointChange {
    id
    createdAt
    quantity
    type
    notes
    source {
      __typename
      ...LoyaltyActivitySourceChain
      ...LoyaltyActivitySourceActivateUser
      ...LoyaltyActivitySourceLoyaltyEvent
      ...LoyaltyActivitySourceActivateTransaction
      ...LoyaltyActivitySourceOfferPurchase
      ...LoyaltyActivitySourcePointChange
    }
    offer {
      id
      name
      marketingContents {
        id
        title
      }
    }
  }
  ${LoyaltyActivitySourceChainFragmentDoc}
  ${LoyaltyActivitySourceActivateUserFragmentDoc}
  ${LoyaltyActivitySourceLoyaltyEventFragmentDoc}
  ${LoyaltyActivitySourceActivateTransactionFragmentDoc}
  ${LoyaltyActivitySourceOfferPurchaseFragmentDoc}
  ${LoyaltyActivitySourcePointChangeFragmentDoc}
`;
export const ActivateTransactionFragmentDoc = gql`
  fragment ActivateTransaction on ActivateTransaction {
    id
    totalTax
    totalAmount
    subtotalAmount
    totalDiscountAmount
    totalAmountWithoutDiscounts
    totalPointsEarned
    totalPointsRefunded
    totalPointsSpent
    lineItems {
      id
      group
      moneyAmount
      quantity
      sku
      upc
      fuelGradeId
      promotions {
        offer {
          id
          marketingContents {
            id
            title
            shortDescription
          }
        }
        promotionAmount
        promotionReason
      }
      externalLineItemPromotions {
        id
        discountAmount
        promotionReason
      }
    }
    createdAt
    location {
      id
      address1
      address2
      state
      city
      name
      zipcode
    }
    paymentInfos {
      tenderType
      tenderSubtype
    }
  }
`;
export const ActivateLineItemPromotionFragmentDoc = gql`
  fragment ActivateLineItemPromotion on ActivateLineItemPromotion {
    offer {
      id
      marketingContents {
        id
        title
        shortDescription
      }
    }
    promotionAmount
    promotionReason
  }
`;
export const StoreAddressFragmentDoc = gql`
  fragment StoreAddress on Address {
    city
    street1
    street2
    state
    zipCode
    geoLocation {
      latitude
      longitude
    }
  }
`;
export const PaymentInstrumentFragmentDoc = gql`
  fragment PaymentInstrument on PaymentInstrument {
    uuid
    alias
    providerNickName
    accountNumber
    accountType
    paymentType
    balance
    isDefault
    displayNumber
    status
    address {
      zipCode
    }
  }
`;
export const TransactionFragmentDoc = gql`
  fragment Transaction on Transaction {
    uuid
    primaryStatus
    secondaryStatus
    completedAt
    amount {
      displayPrice
      price
    }
    tax {
      displayPrice
      price
    }
    fuelReceipt {
      priceG
      product
      fuelSale
      tax
      gallons
      carWashCode
      carWashName
      carWashPrice
    }
    receiptLines {
      lineNumber
      value
    }
    location {
      address {
        ...StoreAddress
      }
    }
    fuelingPosition
    paymentInstrument {
      ...PaymentInstrument
    }
  }
  ${StoreAddressFragmentDoc}
  ${PaymentInstrumentFragmentDoc}
`;
export const AwardPointsFragmentDoc = gql`
  fragment AwardPoints on AwardPoints {
    __typename
    points
  }
`;
export const AwardVouchersSurveyFragmentDoc = gql`
  fragment AwardVouchersSurvey on AwardVouchers {
    __typename
    voucheredOffer {
      id
      marketingContents {
        id
        title
        imageUrl
      }
    }
  }
`;
export const BaseSurveyFragmentDoc = gql`
  fragment BaseSurvey on Survey {
    __typename
    uuid
    award {
      ...AwardPoints
      ...AwardVouchersSurvey
    }
  }
  ${AwardPointsFragmentDoc}
  ${AwardVouchersSurveyFragmentDoc}
`;
export const SurveyQuestionFragmentDoc = gql`
  fragment SurveyQuestion on SurveyQuestion {
    __typename
    uuid
    index
    questionText
  }
`;
export const WalletSteeringSurveyFragmentDoc = gql`
  fragment WalletSteeringSurvey on WalletSteeringSurvey {
    ...BaseSurvey
    questions {
      ...SurveyQuestion
      fields {
        uuid
      }
    }
    configuration {
      steeringWalletUuid
      questionText
      steeringWalletUnitOfMeasure
      maxUnits
      minUnits
    }
  }
  ${BaseSurveyFragmentDoc}
  ${SurveyQuestionFragmentDoc}
`;
export const GeneralSurveyFragmentDoc = gql`
  fragment GeneralSurvey on GeneralSurvey {
    ...BaseSurvey
    questions {
      ...SurveyQuestion
      ... on SurveyQuestionIntSlider {
        min
        max
      }
      ... on SurveyQuestionSingleChoice {
        options {
          uuid
          index
          label
        }
      }
      ... on SurveyQuestionMultipleChoice {
        options {
          uuid
          index
          label
        }
      }
    }
    configuration {
      startDate
      endDate
    }
  }
  ${BaseSurveyFragmentDoc}
  ${SurveyQuestionFragmentDoc}
`;
export const SurveyFragmentDoc = gql`
  fragment Survey on Survey {
    ... on WalletSteeringSurvey {
      ...WalletSteeringSurvey
    }
    ... on GeneralSurvey {
      ...GeneralSurvey
    }
  }
  ${WalletSteeringSurveyFragmentDoc}
  ${GeneralSurveyFragmentDoc}
`;
export const FulfilledOfferFragmentDoc = gql`
  fragment FulfilledOffer on Offer {
    id
    earningMechanism {
      __typename
      ... on EarningMechanismProductPurchase {
        id
      }
      ... on EarningMechanismVisits {
        id
      }
      ... on EarningMechanismVarietyPurchaseOverTime {
        id
      }
      ... on EarningMechanismPointSpend {
        id
      }
      ... on EarningMechanismExternalFulfillment {
        id
      }
      ... on EarningMechanismLoyaltyEvent {
        id
      }
      ... on EarningMechanismMembershipEvent {
        id
      }
      ... on EarningMechanismTotalEligiblePurchase {
        id
      }
      ... on EarningMechanismTotalEligiblePurchaseOverTime {
        id
      }
      ... on EarningMechanismTotalSpendOverTime {
        id
      }
      ... on EarningMechanismVarietyPurchase {
        id
      }
      ... on EarningMechanismProductDiscount {
        id
      }
      ... on EarningMechanismTransactionDiscount {
        id
      }
      ... on EarningMechanismVarietyDiscount {
        id
      }
    }
  }
`;
export const OfferFulfillmentFragmentDoc = gql`
  fragment OfferFulfillment on RuleFulfillment {
    id
    fulfillmentCount
    fulfilledOffer {
      ...FulfilledOffer
    }
  }
  ${FulfilledOfferFragmentDoc}
`;
export const TransactionWithOfferFulfillmentFragmentDoc = gql`
  fragment TransactionWithOfferFulfillment on ActivateTransaction {
    id
    createdAt
    offerFulfillments {
      ...OfferFulfillment
    }
  }
  ${OfferFulfillmentFragmentDoc}
`;
export const AuthPayloadFragmentDoc = gql`
  fragment AuthPayload on AuthPayload {
    __typename
    tokens {
      accessToken
      refreshToken
    }
  }
`;
export const CustomerOtpPayloadFragmentDoc = gql`
  fragment CustomerOTPPayload on CustomerOTPPayload {
    __typename
    session
    success
  }
`;
export const SignupWithOtpSuccessFragmentDoc = gql`
  fragment SignupWithOTPSuccess on ExchangeSessionAndOTPToAuthTokenResultSuccess {
    success
    tokens {
      accessToken
      refreshToken
    }
  }
`;
export const SignupWithOtpIncorrectOtpFragmentDoc = gql`
  fragment SignupWithOTPIncorrectOTP on ExchangeSessionAndOTPToAuthTokenResultFailedIncorrectOTP {
    success
  }
`;
export const CommunicationPreferenceFragmentDoc = gql`
  fragment CommunicationPreference on CommunicationPreference {
    smsOptIn
    pushOptIn
  }
`;
export const AgeVerificationRequireAdditionalPromptFragmentDoc = gql`
  fragment AgeVerificationRequireAdditionalPrompt on EaivAgeVerificationRequireAdditionalPrompt {
    prompt
  }
`;
export const IdentityVerificationChoiceQuestionOptionFragmentDoc = gql`
  fragment IdentityVerificationChoiceQuestionOption on EaivIdentityVerificationChoiceQuestionOption {
    id
    text
  }
`;
export const IdentityVerificationChoiceQuestionFragmentDoc = gql`
  fragment IdentityVerificationChoiceQuestion on EaivIdentityVerificationChoiceQuestion {
    id
    text
    helpText
    choices {
      ...IdentityVerificationChoiceQuestionOption
    }
  }
  ${IdentityVerificationChoiceQuestionOptionFragmentDoc}
`;
export const IdentityVerificationQuestionSetFragmentDoc = gql`
  fragment IdentityVerificationQuestionSet on EaivIdentityVerificationQuestionSet {
    id
    questions {
      __typename
      ...IdentityVerificationChoiceQuestion
    }
  }
  ${IdentityVerificationChoiceQuestionFragmentDoc}
`;
export const IdentityVerificationStartResultSuccessFragmentDoc = gql`
  fragment IdentityVerificationStartResultSuccess on EaivIdentityVerificationStartResultSuccess {
    conversationId
    questionSet {
      ...IdentityVerificationQuestionSet
    }
  }
  ${IdentityVerificationQuestionSetFragmentDoc}
`;
export const IdentityVerificationRequireAdditionalPromptFragmentDoc = gql`
  fragment IdentityVerificationRequireAdditionalPrompt on EaivIdentityVerificationRequireAdditionalPrompt {
    prompt
  }
`;
export const IdentityVerificationStartResultContinueFragmentDoc = gql`
  fragment IdentityVerificationStartResultContinue on EaivIdentityVerificationSubmitResultContinue {
    conversationId
    questionSet {
      ...IdentityVerificationQuestionSet
    }
  }
  ${IdentityVerificationQuestionSetFragmentDoc}
`;
export const ExternalOfferFragmentDoc = gql`
  fragment ExternalOffer on Banner {
    trackingId
    bannerConfigurationUuid
    externalCampaignId
    content {
      title
      title2
      titleMiddle
      imageUrl
      surgeonGeneralWarning
    }
  }
`;
export const ProductCategoryFragmentDoc = gql`
  fragment ProductCategory on ProductCategory {
    id
    name
    rank
    categoryRestriction
  }
`;
export const MembershipAddressFragmentDoc = gql`
  fragment MembershipAddress on MembershipAddress {
    address1
    address2
    city
    state
    zipcode
  }
`;
export const FuelProductGroupFragmentDoc = gql`
  fragment FuelProductGroup on FuelProductGroup {
    id
    productGroup {
      id
      name
    }
  }
`;
export const FuelAndConvenienceProfileFragmentDoc = gql`
  fragment FuelAndConvenienceProfile on FuelAndConvenienceProfile {
    restrictionVerification
    pendingVerification
    memberDataShare
    preferredFuelProductGroup {
      ...FuelProductGroup
    }
  }
  ${FuelProductGroupFragmentDoc}
`;
export const CustomerProfileFragmentDoc = gql`
  fragment CustomerProfile on Membership {
    __typename
    id
    customerUuid
    state
    isCompleted @client
    firstName
    lastName
    birthdate
    phone
    email
    programTermsAcceptanceAt
    primaryAddress {
      ...MembershipAddress
    }
    fuelAndConvenienceProfile {
      ...FuelAndConvenienceProfile
    }
  }
  ${MembershipAddressFragmentDoc}
  ${FuelAndConvenienceProfileFragmentDoc}
`;
export const MembershipFragmentDoc = gql`
  fragment Membership on Membership {
    ...CustomerProfile
    points
    customData
    homeStores {
      locationUuid
    }
  }
  ${CustomerProfileFragmentDoc}
`;
export const StoreFuelGradeFragmentDoc = gql`
  fragment StoreFuelGrade on FuelGradePrice {
    description
    unitPrice {
      cashPrice
      creditPrice
    }
  }
`;
export const StoreWorkingHoursFragmentDoc = gql`
  fragment StoreWorkingHours on HoursOfOperationGroup {
    hours {
      dayOfWeek
      openTime
      closeTime
    }
  }
`;
export const StorePhoneNumberFragmentDoc = gql`
  fragment StorePhoneNumber on ContactPhoneNumber {
    alias
    phoneNumber
    phoneType
    uuid
  }
`;
export const StoreAttributeFragmentDoc = gql`
  fragment StoreAttribute on LocationAttribute {
    description
    name
    value
  }
`;
export const StoreCarWashItemFragmentDoc = gql`
  fragment StoreCarWashItem on SiteSystemCarWashItem {
    description
    posCode
    posCodeModifier
    productCode
    unitPrice
  }
`;
export const StorePumpConfigFragmentDoc = gql`
  fragment StorePumpConfig on SiteSystemFuelPumpProduct {
    outdoorPosition
    description
    posCode
    posCodeFormat
    productCode
  }
`;
export const StoreConfigurationFragmentDoc = gql`
  fragment StoreConfiguration on SiteSystemConfiguration {
    uuid
    identifier
    details {
      carWashDetails {
        ...StoreCarWashItem
      }
      fuelConfigDetails {
        ...StorePumpConfig
      }
    }
  }
  ${StoreCarWashItemFragmentDoc}
  ${StorePumpConfigFragmentDoc}
`;
export const StorePumpInfoFragmentDoc = gql`
  fragment StorePumpInfo on Location {
    uuid
    address {
      ...StoreAddress
    }
    siteSystemConfiguration {
      ...StoreConfiguration
    }
  }
  ${StoreAddressFragmentDoc}
  ${StoreConfigurationFragmentDoc}
`;
export const LoyaltyActivateTransactionItemFragmentDoc = gql`
  fragment LoyaltyActivateTransactionItem on ActivateTransaction {
    id
    createdAt
    totalAmount
    totalAmountWithoutDiscounts
  }
`;
export const LoyaltyPointChangeItemFragmentDoc = gql`
  fragment LoyaltyPointChangeItem on PointChange {
    id
    type
    createdAt
    quantity
    notes
    offer {
      id
      name
    }
    source {
      __typename
      ...LoyaltyActivitySourceChain
      ...LoyaltyActivitySourceActivateUser
      ...LoyaltyActivitySourceLoyaltyEvent
      ...LoyaltyActivitySourceActivateTransaction
      ...LoyaltyActivitySourceOfferPurchase
      ...LoyaltyActivitySourcePointChange
    }
  }
  ${LoyaltyActivitySourceChainFragmentDoc}
  ${LoyaltyActivitySourceActivateUserFragmentDoc}
  ${LoyaltyActivitySourceLoyaltyEventFragmentDoc}
  ${LoyaltyActivitySourceActivateTransactionFragmentDoc}
  ${LoyaltyActivitySourceOfferPurchaseFragmentDoc}
  ${LoyaltyActivitySourcePointChangeFragmentDoc}
`;
export const TransactionHistoryItemFragmentDoc = gql`
  fragment TransactionHistoryItem on Transaction {
    uuid
    completedAt
    primaryStatus
    secondaryStatus
    amount {
      displayPrice
      price
    }
  }
`;
export const AwardPointsPerFragmentDoc = gql`
  fragment AwardPointsPer on AwardPointsPer {
    __typename
    points
    rounding
  }
`;
export const AwardVouchersFragmentDoc = gql`
  fragment AwardVouchers on AwardVouchers {
    __typename
    voucherExpirationDays
    voucheredOffer {
      id
      voucherCount
    }
    voucherCount
  }
`;
export const AwardPointsOrVouchersFragmentDoc = gql`
  fragment AwardPointsOrVouchers on AwardPointsOrVouchers {
    __typename
    ...AwardVouchers
    ...AwardPoints
  }
  ${AwardVouchersFragmentDoc}
  ${AwardPointsFragmentDoc}
`;
export const EarningMechanismPromocodesFragmentDoc = gql`
  fragment EarningMechanismPromocodes on EarningMechanismPromocodes {
    id
    award {
      ...AwardPointsOrVouchers
    }
  }
  ${AwardPointsOrVouchersFragmentDoc}
`;
export const MarketingContentFragmentDoc = gql`
  fragment MarketingContent on MarketingContent {
    id
    imageUrl
    title
    shortDescription
    longDescription
    disclaimer
    disclaimerUrl
    terms
    termsUrl
    surgeonGeneralsWarning
    externalUrl
  }
`;
export const EarningMechanismProductPurchaseFragmentDoc = gql`
  fragment EarningMechanismProductPurchase on EarningMechanismProductPurchase {
    id
    award {
      __typename
      ... on AwardPoints {
        __typename
        points
      }
      ... on AwardVouchers {
        __typename
        voucherExpirationDays
        voucheredOffer {
          id
          voucherCount
        }
        voucherCount
      }
    }
  }
`;
export const EarningMechanismVisitsFragmentDoc = gql`
  fragment EarningMechanismVisits on EarningMechanismVisits {
    id
    visitsMemberProgress {
      visits
      visitsRequired
    }
    award {
      __typename
      ... on AwardPoints {
        __typename
        points
      }
      ... on AwardVouchers {
        __typename
        voucherExpirationDays
        voucheredOffer {
          id
          voucherCount
        }
        voucherCount
      }
    }
  }
`;
export const EarningMechanismVarietyPurchaseOverTimeFragmentDoc = gql`
  fragment EarningMechanismVarietyPurchaseOverTime on EarningMechanismVarietyPurchaseOverTime {
    id
    varietyMemberProgress {
      quantityPurchased
      quantityRequired
    }
    award {
      __typename
      ... on AwardPoints {
        __typename
        points
      }
      ... on AwardVouchers {
        __typename
        voucherExpirationDays
        voucheredOffer {
          id
          voucherCount
        }
        voucherCount
      }
    }
  }
`;
export const EarningMechanismPointSpendFragmentDoc = gql`
  fragment EarningMechanismPointSpend on EarningMechanismPointSpend {
    __typename
    id
    award {
      __typename
      voucherExpirationDays
      voucheredOffer {
        id
        voucherCount
      }
      voucherCount
    }
    pointCost
  }
`;
export const EarningMechanismExternalFulfillmentFragmentDoc = gql`
  fragment EarningMechanismExternalFulfillment on EarningMechanismExternalFulfillment {
    id
    optionalAward: award {
      __typename
      ... on AwardPoints {
        __typename
        points
      }
      ... on AwardVouchers {
        __typename
        voucherExpirationDays
        voucheredOffer {
          id
          voucherCount
        }
        voucherCount
      }
    }
  }
`;
export const EarningMechanismLoyaltyEventFragmentDoc = gql`
  fragment EarningMechanismLoyaltyEvent on EarningMechanismLoyaltyEvent {
    id
    optionalAward: award {
      __typename
      ... on AwardPoints {
        __typename
        points
      }
      ... on AwardVouchers {
        __typename
        voucherExpirationDays
        voucheredOffer {
          id
          voucherCount
        }
        voucherCount
      }
    }
  }
`;
export const EarningMechanismMembershipEventFragmentDoc = gql`
  fragment EarningMechanismMembershipEvent on EarningMechanismMembershipEvent {
    id
    award {
      __typename
      ... on AwardPoints {
        __typename
        points
      }
      ... on AwardVouchers {
        __typename
        voucherExpirationDays
        voucheredOffer {
          id
          voucherCount
        }
        voucherCount
      }
    }
  }
`;
export const EarningMechanismTotalEligiblePurchaseFragmentDoc = gql`
  fragment EarningMechanismTotalEligiblePurchase on EarningMechanismTotalEligiblePurchase {
    id
    award {
      __typename
      points
      rounding
    }
  }
`;
export const EarningMechanismTotalEligiblePurchaseOverTimeFragmentDoc = gql`
  fragment EarningMechanismTotalEligiblePurchaseOverTime on EarningMechanismTotalEligiblePurchaseOverTime {
    id
    purchaseMemberProgress {
      quantityPurchased
      quantityRequired
    }
    award {
      __typename
      ... on AwardPoints {
        __typename
        points
      }
      ... on AwardVouchers {
        __typename
        voucherExpirationDays
        voucheredOffer {
          id
          voucherCount
        }
        voucherCount
      }
    }
  }
`;
export const EarningMechanismTotalSpendOverTimeFragmentDoc = gql`
  fragment EarningMechanismTotalSpendOverTime on EarningMechanismTotalSpendOverTime {
    id
    purchaseMemberProgress {
      quantityPurchased
      quantityRequired
    }
    award {
      __typename
      ... on AwardPoints {
        __typename
        points
      }
      ... on AwardVouchers {
        __typename
        voucherExpirationDays
        voucheredOffer {
          id
          voucherCount
        }
        voucherCount
      }
    }
  }
`;
export const EarningMechanismVarietyPurchaseFragmentDoc = gql`
  fragment EarningMechanismVarietyPurchase on EarningMechanismVarietyPurchase {
    id
    award {
      __typename
      ... on AwardPoints {
        __typename
        points
      }
      ... on AwardVouchers {
        __typename
        voucherExpirationDays
        voucheredOffer {
          id
          voucherCount
        }
        voucherCount
      }
    }
  }
`;
export const EarningMechanismProductDiscountFragmentDoc = gql`
  fragment EarningMechanismProductDiscount on EarningMechanismProductDiscount {
    __typename
    id
  }
`;
export const EarningMechanismTransactionDiscountFragmentDoc = gql`
  fragment EarningMechanismTransactionDiscount on EarningMechanismTransactionDiscount {
    __typename
    id
  }
`;
export const EarningMechanismVarietyDiscountFragmentDoc = gql`
  fragment EarningMechanismVarietyDiscount on EarningMechanismVarietyDiscount {
    __typename
    id
  }
`;
export const GameAwardVouchersFragmentDoc = gql`
  fragment GameAwardVouchers on AwardVouchers {
    __typename
    voucherExpirationDays
    voucheredOffer {
      __typename
      id
      marketingContents {
        ...MarketingContent
      }
    }
  }
  ${MarketingContentFragmentDoc}
`;
export const GameAwardPointsOrVouchersFragmentDoc = gql`
  fragment GameAwardPointsOrVouchers on AwardPointsOrVouchers {
    __typename
    ...AwardPoints
    ...GameAwardVouchers
  }
  ${AwardPointsFragmentDoc}
  ${GameAwardVouchersFragmentDoc}
`;
export const SpinWheelGamePrizeFragmentDoc = gql`
  fragment SpinWheelGamePrize on RandomAwardConfig {
    award {
      ...GameAwardPointsOrVouchers
    }
    awardType
    limit
    sequenceNumber
  }
  ${GameAwardPointsOrVouchersFragmentDoc}
`;
export const SpinWheelGameVoucherPurchaseFragmentDoc = gql`
  fragment SpinWheelGameVoucherPurchase on Offer {
    __typename
    id
    earningMechanism {
      __typename
      ... on EarningMechanismPointSpend {
        id
        pointCost
      }
    }
  }
`;
export const EarningMechanismSpinWheelGameFragmentDoc = gql`
  fragment EarningMechanismSpinWheelGame on EarningMechanismSpinWheelGame {
    __typename
    id
    awards {
      ...SpinWheelGamePrize
    }
    purchasableOffer {
      ...SpinWheelGameVoucherPurchase
    }
  }
  ${SpinWheelGamePrizeFragmentDoc}
  ${SpinWheelGameVoucherPurchaseFragmentDoc}
`;
export const DisplayCategoryFragmentDoc = gql`
  fragment DisplayCategory on DisplayCategory {
    id
    name
    rank
    categoryRestrictions
    bannerIds
  }
`;
export const LimitMembershipAwardCountFragmentDoc = gql`
  fragment LimitMembershipAwardCount on LimitMembershipAwardCount {
    __typename
    id
    limit
    membershipQuantityAvailable
  }
`;
export const LimitMembershipAwardCountInTimeFragmentDoc = gql`
  fragment LimitMembershipAwardCountInTime on LimitMembershipAwardCountInTime {
    __typename
    id
    limit
    membershipQuantityAvailable
  }
`;
export const LimitGlobalActivationFragmentDoc = gql`
  fragment LimitGlobalActivation on LimitGlobalActivation {
    __typename
    id
  }
`;
export const LimitGlobalActivationInTimeFragmentDoc = gql`
  fragment LimitGlobalActivationInTime on LimitGlobalActivationInTime {
    __typename
    id
  }
`;
export const LimitGlobalAwardCountFragmentDoc = gql`
  fragment LimitGlobalAwardCount on LimitGlobalAwardCount {
    __typename
    id
  }
`;
export const LimitGlobalAwardCountInTimeFragmentDoc = gql`
  fragment LimitGlobalAwardCountInTime on LimitGlobalAwardCountInTime {
    __typename
    id
  }
`;
export const LimitMembershipActivationFragmentDoc = gql`
  fragment LimitMembershipActivation on LimitMembershipActivation {
    __typename
    id
  }
`;
export const LimitMembershipActivationInTimeFragmentDoc = gql`
  fragment LimitMembershipActivationInTime on LimitMembershipActivationInTime {
    __typename
    id
  }
`;
export const LimitInTransactionAwardCountFragmentDoc = gql`
  fragment LimitInTransactionAwardCount on LimitInTransactionAwardCount {
    __typename
    id
  }
`;
export const LimitFragmentDoc = gql`
  fragment Limit on Limit {
    ...LimitMembershipAwardCount
    ...LimitMembershipAwardCountInTime
    ...LimitGlobalActivation
    ...LimitGlobalActivationInTime
    ...LimitGlobalAwardCount
    ...LimitGlobalAwardCountInTime
    ...LimitMembershipActivation
    ...LimitMembershipActivationInTime
    ...LimitInTransactionAwardCount
  }
  ${LimitMembershipAwardCountFragmentDoc}
  ${LimitMembershipAwardCountInTimeFragmentDoc}
  ${LimitGlobalActivationFragmentDoc}
  ${LimitGlobalActivationInTimeFragmentDoc}
  ${LimitGlobalAwardCountFragmentDoc}
  ${LimitGlobalAwardCountInTimeFragmentDoc}
  ${LimitMembershipActivationFragmentDoc}
  ${LimitMembershipActivationInTimeFragmentDoc}
  ${LimitInTransactionAwardCountFragmentDoc}
`;
export const OfferFragmentDoc = gql`
  fragment Offer on Offer {
    __typename
    id
    expireAt
    engagementType
    activationStatus
    visibleStatus
    marketingContents {
      ...MarketingContent
    }
    earningMechanism {
      __typename
      ...EarningMechanismProductPurchase
      ...EarningMechanismVisits
      ...EarningMechanismVarietyPurchaseOverTime
      ...EarningMechanismPointSpend
      ...EarningMechanismExternalFulfillment
      ...EarningMechanismLoyaltyEvent
      ...EarningMechanismMembershipEvent
      ...EarningMechanismTotalEligiblePurchase
      ...EarningMechanismTotalEligiblePurchaseOverTime
      ...EarningMechanismTotalSpendOverTime
      ...EarningMechanismVarietyPurchase
      ...EarningMechanismProductDiscount
      ...EarningMechanismTransactionDiscount
      ...EarningMechanismVarietyDiscount
      ...EarningMechanismSpinWheelGame
    }
    customData
    voucherCount
    vouchers @include(if: $offerVouchersIncluded) {
      edges {
        node {
          id
          expireAt
          createdAt
          state
        }
      }
    }
    presentationPriority
    presentationType
    displayCategories {
      ...DisplayCategory
    }
    limits @include(if: $offerLimitsIncluded) {
      ...Limit
    }
  }
  ${MarketingContentFragmentDoc}
  ${EarningMechanismProductPurchaseFragmentDoc}
  ${EarningMechanismVisitsFragmentDoc}
  ${EarningMechanismVarietyPurchaseOverTimeFragmentDoc}
  ${EarningMechanismPointSpendFragmentDoc}
  ${EarningMechanismExternalFulfillmentFragmentDoc}
  ${EarningMechanismLoyaltyEventFragmentDoc}
  ${EarningMechanismMembershipEventFragmentDoc}
  ${EarningMechanismTotalEligiblePurchaseFragmentDoc}
  ${EarningMechanismTotalEligiblePurchaseOverTimeFragmentDoc}
  ${EarningMechanismTotalSpendOverTimeFragmentDoc}
  ${EarningMechanismVarietyPurchaseFragmentDoc}
  ${EarningMechanismProductDiscountFragmentDoc}
  ${EarningMechanismTransactionDiscountFragmentDoc}
  ${EarningMechanismVarietyDiscountFragmentDoc}
  ${EarningMechanismSpinWheelGameFragmentDoc}
  ${DisplayCategoryFragmentDoc}
  ${LimitFragmentDoc}
`;
export const PromocodeRedeemedAwardFragmentDoc = gql`
  fragment PromocodeRedeemedAward on AwardPointsOrVouchers {
    __typename
    ... on AwardPoints {
      __typename
      points
    }
    ... on AwardVouchers {
      __typename
      voucheredOffer {
        id
        voucherCount
        marketingContents {
          id
          title
          imageUrl
        }
      }
      voucherCount
    }
  }
`;
export const CustomerMakeActiveDocument = gql`
  mutation customerMakeActive {
    membershipStateTransition(input: { state: ACTIVE }) {
      __typename
      id
      customerUuid
      state
    }
  }
`;
export type CustomerMakeActiveMutationFn = Apollo.MutationFunction<
  CustomerMakeActiveMutation,
  CustomerMakeActiveMutationVariables
>;

/**
 * __useCustomerMakeActiveMutation__
 *
 * To run a mutation, you first call `useCustomerMakeActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerMakeActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerMakeActiveMutation, { data, loading, error }] = useCustomerMakeActiveMutation({
 *   variables: {
 *   },
 * });
 */
export function useCustomerMakeActiveMutation(
  baseOptions?: Apollo.MutationHookOptions<CustomerMakeActiveMutation, CustomerMakeActiveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerMakeActiveMutation, CustomerMakeActiveMutationVariables>(
    CustomerMakeActiveDocument,
    options,
  );
}
export type CustomerMakeActiveMutationHookResult = ReturnType<typeof useCustomerMakeActiveMutation>;
export type CustomerMakeActiveMutationResult = Apollo.MutationResult<CustomerMakeActiveMutation>;
export type CustomerMakeActiveMutationOptions = Apollo.BaseMutationOptions<
  CustomerMakeActiveMutation,
  CustomerMakeActiveMutationVariables
>;
export const CustomerPaymentInstrumentCreateDocument = gql`
  mutation customerPaymentInstrumentCreate($input: CustomerPaymentInstrumentCreate!) {
    customerPaymentInstrumentCreate(input: $input) {
      uuid
    }
  }
`;
export type CustomerPaymentInstrumentCreateMutationFn = Apollo.MutationFunction<
  CustomerPaymentInstrumentCreateMutation,
  CustomerPaymentInstrumentCreateMutationVariables
>;

/**
 * __useCustomerPaymentInstrumentCreateMutation__
 *
 * To run a mutation, you first call `useCustomerPaymentInstrumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerPaymentInstrumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerPaymentInstrumentCreateMutation, { data, loading, error }] = useCustomerPaymentInstrumentCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerPaymentInstrumentCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomerPaymentInstrumentCreateMutation,
    CustomerPaymentInstrumentCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerPaymentInstrumentCreateMutation, CustomerPaymentInstrumentCreateMutationVariables>(
    CustomerPaymentInstrumentCreateDocument,
    options,
  );
}
export type CustomerPaymentInstrumentCreateMutationHookResult = ReturnType<
  typeof useCustomerPaymentInstrumentCreateMutation
>;
export type CustomerPaymentInstrumentCreateMutationResult =
  Apollo.MutationResult<CustomerPaymentInstrumentCreateMutation>;
export type CustomerPaymentInstrumentCreateMutationOptions = Apollo.BaseMutationOptions<
  CustomerPaymentInstrumentCreateMutation,
  CustomerPaymentInstrumentCreateMutationVariables
>;
export const CustomerPaymentInstrumentDeleteDocument = gql`
  mutation customerPaymentInstrumentDelete($uuid: ID!) {
    customerPaymentInstrumentDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type CustomerPaymentInstrumentDeleteMutationFn = Apollo.MutationFunction<
  CustomerPaymentInstrumentDeleteMutation,
  CustomerPaymentInstrumentDeleteMutationVariables
>;

/**
 * __useCustomerPaymentInstrumentDeleteMutation__
 *
 * To run a mutation, you first call `useCustomerPaymentInstrumentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerPaymentInstrumentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerPaymentInstrumentDeleteMutation, { data, loading, error }] = useCustomerPaymentInstrumentDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCustomerPaymentInstrumentDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CustomerPaymentInstrumentDeleteMutation,
    CustomerPaymentInstrumentDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerPaymentInstrumentDeleteMutation, CustomerPaymentInstrumentDeleteMutationVariables>(
    CustomerPaymentInstrumentDeleteDocument,
    options,
  );
}
export type CustomerPaymentInstrumentDeleteMutationHookResult = ReturnType<
  typeof useCustomerPaymentInstrumentDeleteMutation
>;
export type CustomerPaymentInstrumentDeleteMutationResult =
  Apollo.MutationResult<CustomerPaymentInstrumentDeleteMutation>;
export type CustomerPaymentInstrumentDeleteMutationOptions = Apollo.BaseMutationOptions<
  CustomerPaymentInstrumentDeleteMutation,
  CustomerPaymentInstrumentDeleteMutationVariables
>;
export const CustomerResetPinCodeDocument = gql`
  mutation customerResetPinCode {
    success: customerPinCodeReset
  }
`;
export type CustomerResetPinCodeMutationFn = Apollo.MutationFunction<
  CustomerResetPinCodeMutation,
  CustomerResetPinCodeMutationVariables
>;

/**
 * __useCustomerResetPinCodeMutation__
 *
 * To run a mutation, you first call `useCustomerResetPinCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerResetPinCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerResetPinCodeMutation, { data, loading, error }] = useCustomerResetPinCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useCustomerResetPinCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<CustomerResetPinCodeMutation, CustomerResetPinCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerResetPinCodeMutation, CustomerResetPinCodeMutationVariables>(
    CustomerResetPinCodeDocument,
    options,
  );
}
export type CustomerResetPinCodeMutationHookResult = ReturnType<typeof useCustomerResetPinCodeMutation>;
export type CustomerResetPinCodeMutationResult = Apollo.MutationResult<CustomerResetPinCodeMutation>;
export type CustomerResetPinCodeMutationOptions = Apollo.BaseMutationOptions<
  CustomerResetPinCodeMutation,
  CustomerResetPinCodeMutationVariables
>;
export const CustomerSetPinCodeDocument = gql`
  mutation customerSetPinCode($pinCode: String!) {
    success: customerPinCodeSet(input: { pinCode: { pinCode: $pinCode } })
  }
`;
export type CustomerSetPinCodeMutationFn = Apollo.MutationFunction<
  CustomerSetPinCodeMutation,
  CustomerSetPinCodeMutationVariables
>;

/**
 * __useCustomerSetPinCodeMutation__
 *
 * To run a mutation, you first call `useCustomerSetPinCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerSetPinCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerSetPinCodeMutation, { data, loading, error }] = useCustomerSetPinCodeMutation({
 *   variables: {
 *      pinCode: // value for 'pinCode'
 *   },
 * });
 */
export function useCustomerSetPinCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<CustomerSetPinCodeMutation, CustomerSetPinCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerSetPinCodeMutation, CustomerSetPinCodeMutationVariables>(
    CustomerSetPinCodeDocument,
    options,
  );
}
export type CustomerSetPinCodeMutationHookResult = ReturnType<typeof useCustomerSetPinCodeMutation>;
export type CustomerSetPinCodeMutationResult = Apollo.MutationResult<CustomerSetPinCodeMutation>;
export type CustomerSetPinCodeMutationOptions = Apollo.BaseMutationOptions<
  CustomerSetPinCodeMutation,
  CustomerSetPinCodeMutationVariables
>;
export const CustomerVerifyPinCodeDocument = gql`
  mutation customerVerifyPinCode($pinCode: String!) {
    success: customerPinCodeVerify(input: { pinCode: { pinCode: $pinCode } })
  }
`;
export type CustomerVerifyPinCodeMutationFn = Apollo.MutationFunction<
  CustomerVerifyPinCodeMutation,
  CustomerVerifyPinCodeMutationVariables
>;

/**
 * __useCustomerVerifyPinCodeMutation__
 *
 * To run a mutation, you first call `useCustomerVerifyPinCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerVerifyPinCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerVerifyPinCodeMutation, { data, loading, error }] = useCustomerVerifyPinCodeMutation({
 *   variables: {
 *      pinCode: // value for 'pinCode'
 *   },
 * });
 */
export function useCustomerVerifyPinCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<CustomerVerifyPinCodeMutation, CustomerVerifyPinCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerVerifyPinCodeMutation, CustomerVerifyPinCodeMutationVariables>(
    CustomerVerifyPinCodeDocument,
    options,
  );
}
export type CustomerVerifyPinCodeMutationHookResult = ReturnType<typeof useCustomerVerifyPinCodeMutation>;
export type CustomerVerifyPinCodeMutationResult = Apollo.MutationResult<CustomerVerifyPinCodeMutation>;
export type CustomerVerifyPinCodeMutationOptions = Apollo.BaseMutationOptions<
  CustomerVerifyPinCodeMutation,
  CustomerVerifyPinCodeMutationVariables
>;
export const GetAtTheStoreWithPumpsDocument = gql`
  query getAtTheStoreWithPumps($geoLocation: GeoLocationFilter) {
    store: locations(filter: { geoLocation: $geoLocation }, first: 1) {
      edges {
        node {
          uuid
          siteSystemConfiguration {
            uuid
            details {
              fuelConfigDetails {
                outdoorPosition
              }
            }
            mobileEnabled
          }
        }
      }
    }
  }
`;

/**
 * __useGetAtTheStoreWithPumpsQuery__
 *
 * To run a query within a React component, call `useGetAtTheStoreWithPumpsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAtTheStoreWithPumpsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAtTheStoreWithPumpsQuery({
 *   variables: {
 *      geoLocation: // value for 'geoLocation'
 *   },
 * });
 */
export function useGetAtTheStoreWithPumpsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAtTheStoreWithPumpsQuery, GetAtTheStoreWithPumpsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAtTheStoreWithPumpsQuery, GetAtTheStoreWithPumpsQueryVariables>(
    GetAtTheStoreWithPumpsDocument,
    options,
  );
}
export function useGetAtTheStoreWithPumpsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAtTheStoreWithPumpsQuery, GetAtTheStoreWithPumpsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAtTheStoreWithPumpsQuery, GetAtTheStoreWithPumpsQueryVariables>(
    GetAtTheStoreWithPumpsDocument,
    options,
  );
}
export type GetAtTheStoreWithPumpsQueryHookResult = ReturnType<typeof useGetAtTheStoreWithPumpsQuery>;
export type GetAtTheStoreWithPumpsLazyQueryHookResult = ReturnType<typeof useGetAtTheStoreWithPumpsLazyQuery>;
export type GetAtTheStoreWithPumpsQueryResult = Apollo.QueryResult<
  GetAtTheStoreWithPumpsQuery,
  GetAtTheStoreWithPumpsQueryVariables
>;
export const GetOffersByLoyaltyEventDocument = gql`
  query getOffersByLoyaltyEvent(
    $loyaltyEventEquals: String!
    $publicationStatusEquals: PUBLICATION_STATUS_TYPE!
    $visibleStatusEquals: OFFER_VISIBLE_STATUS!
  ) {
    currentCustomer {
      uuid
      membership {
        id
        offers(
          first: 1
          filter: {
            loyaltyEventEquals: $loyaltyEventEquals
            deleted: false
            publicationStatusEquals: $publicationStatusEquals
            visibleStatusEquals: $visibleStatusEquals
          }
        ) {
          edges {
            node {
              ...OfferByLoyaltyEvent
            }
          }
        }
      }
    }
  }
  ${OfferByLoyaltyEventFragmentDoc}
`;

/**
 * __useGetOffersByLoyaltyEventQuery__
 *
 * To run a query within a React component, call `useGetOffersByLoyaltyEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOffersByLoyaltyEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOffersByLoyaltyEventQuery({
 *   variables: {
 *      loyaltyEventEquals: // value for 'loyaltyEventEquals'
 *      publicationStatusEquals: // value for 'publicationStatusEquals'
 *      visibleStatusEquals: // value for 'visibleStatusEquals'
 *   },
 * });
 */
export function useGetOffersByLoyaltyEventQuery(
  baseOptions: Apollo.QueryHookOptions<GetOffersByLoyaltyEventQuery, GetOffersByLoyaltyEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOffersByLoyaltyEventQuery, GetOffersByLoyaltyEventQueryVariables>(
    GetOffersByLoyaltyEventDocument,
    options,
  );
}
export function useGetOffersByLoyaltyEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOffersByLoyaltyEventQuery, GetOffersByLoyaltyEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOffersByLoyaltyEventQuery, GetOffersByLoyaltyEventQueryVariables>(
    GetOffersByLoyaltyEventDocument,
    options,
  );
}
export type GetOffersByLoyaltyEventQueryHookResult = ReturnType<typeof useGetOffersByLoyaltyEventQuery>;
export type GetOffersByLoyaltyEventLazyQueryHookResult = ReturnType<typeof useGetOffersByLoyaltyEventLazyQuery>;
export type GetOffersByLoyaltyEventQueryResult = Apollo.QueryResult<
  GetOffersByLoyaltyEventQuery,
  GetOffersByLoyaltyEventQueryVariables
>;
export const LoyaltyEventCreateDocument = gql`
  mutation loyaltyEventCreate($input: LoyaltyEventCreate!) {
    event: loyaltyEventCreate(input: $input) {
      eventData
      eventType
      createdAt
    }
  }
`;
export type LoyaltyEventCreateMutationFn = Apollo.MutationFunction<
  LoyaltyEventCreateMutation,
  LoyaltyEventCreateMutationVariables
>;

/**
 * __useLoyaltyEventCreateMutation__
 *
 * To run a mutation, you first call `useLoyaltyEventCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyEventCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loyaltyEventCreateMutation, { data, loading, error }] = useLoyaltyEventCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoyaltyEventCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<LoyaltyEventCreateMutation, LoyaltyEventCreateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoyaltyEventCreateMutation, LoyaltyEventCreateMutationVariables>(
    LoyaltyEventCreateDocument,
    options,
  );
}
export type LoyaltyEventCreateMutationHookResult = ReturnType<typeof useLoyaltyEventCreateMutation>;
export type LoyaltyEventCreateMutationResult = Apollo.MutationResult<LoyaltyEventCreateMutation>;
export type LoyaltyEventCreateMutationOptions = Apollo.BaseMutationOptions<
  LoyaltyEventCreateMutation,
  LoyaltyEventCreateMutationVariables
>;
export const OfferPurchaseDocument = gql`
  mutation offerPurchase($input: OfferPurchaseCreate!) {
    offers: offerPurchaseCreate(input: $input) {
      pointCost
    }
  }
`;
export type OfferPurchaseMutationFn = Apollo.MutationFunction<OfferPurchaseMutation, OfferPurchaseMutationVariables>;

/**
 * __useOfferPurchaseMutation__
 *
 * To run a mutation, you first call `useOfferPurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOfferPurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offerPurchaseMutation, { data, loading, error }] = useOfferPurchaseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOfferPurchaseMutation(
  baseOptions?: Apollo.MutationHookOptions<OfferPurchaseMutation, OfferPurchaseMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OfferPurchaseMutation, OfferPurchaseMutationVariables>(OfferPurchaseDocument, options);
}
export type OfferPurchaseMutationHookResult = ReturnType<typeof useOfferPurchaseMutation>;
export type OfferPurchaseMutationResult = Apollo.MutationResult<OfferPurchaseMutation>;
export type OfferPurchaseMutationOptions = Apollo.BaseMutationOptions<
  OfferPurchaseMutation,
  OfferPurchaseMutationVariables
>;
export const GetPointsChangeDocument = gql`
  query getPointsChange($id: ID!) {
    transactionHistory: currentCustomer {
      uuid
      membership {
        id
        pointChanges(filter: { idEquals: $id }) {
          edges {
            cursor
            node {
              ...PointChange
            }
          }
        }
      }
    }
  }
  ${PointChangeFragmentDoc}
`;

/**
 * __useGetPointsChangeQuery__
 *
 * To run a query within a React component, call `useGetPointsChangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointsChangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointsChangeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPointsChangeQuery(
  baseOptions: Apollo.QueryHookOptions<GetPointsChangeQuery, GetPointsChangeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPointsChangeQuery, GetPointsChangeQueryVariables>(GetPointsChangeDocument, options);
}
export function useGetPointsChangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPointsChangeQuery, GetPointsChangeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPointsChangeQuery, GetPointsChangeQueryVariables>(GetPointsChangeDocument, options);
}
export type GetPointsChangeQueryHookResult = ReturnType<typeof useGetPointsChangeQuery>;
export type GetPointsChangeLazyQueryHookResult = ReturnType<typeof useGetPointsChangeLazyQuery>;
export type GetPointsChangeQueryResult = Apollo.QueryResult<GetPointsChangeQuery, GetPointsChangeQueryVariables>;
export const CreateApplePaySessionDocument = gql`
  mutation createApplePaySession($input: PaymentApplePaySessionCreateInput!) {
    session: applePaySessionCreate(input: $input) {
      data
    }
  }
`;
export type CreateApplePaySessionMutationFn = Apollo.MutationFunction<
  CreateApplePaySessionMutation,
  CreateApplePaySessionMutationVariables
>;

/**
 * __useCreateApplePaySessionMutation__
 *
 * To run a mutation, you first call `useCreateApplePaySessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplePaySessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplePaySessionMutation, { data, loading, error }] = useCreateApplePaySessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApplePaySessionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApplePaySessionMutation, CreateApplePaySessionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApplePaySessionMutation, CreateApplePaySessionMutationVariables>(
    CreateApplePaySessionDocument,
    options,
  );
}
export type CreateApplePaySessionMutationHookResult = ReturnType<typeof useCreateApplePaySessionMutation>;
export type CreateApplePaySessionMutationResult = Apollo.MutationResult<CreateApplePaySessionMutation>;
export type CreateApplePaySessionMutationOptions = Apollo.BaseMutationOptions<
  CreateApplePaySessionMutation,
  CreateApplePaySessionMutationVariables
>;
export const GetActivateTransactionDocument = gql`
  query getActivateTransaction($id: ID!) {
    transactionHistory: currentCustomer {
      uuid
      membership {
        id
        transactions(filter: { idEquals: $id }) {
          edges {
            cursor
            node {
              ...ActivateTransaction
            }
          }
        }
      }
    }
  }
  ${ActivateTransactionFragmentDoc}
`;

/**
 * __useGetActivateTransactionQuery__
 *
 * To run a query within a React component, call `useGetActivateTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivateTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivateTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivateTransactionQuery(
  baseOptions: Apollo.QueryHookOptions<GetActivateTransactionQuery, GetActivateTransactionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActivateTransactionQuery, GetActivateTransactionQueryVariables>(
    GetActivateTransactionDocument,
    options,
  );
}
export function useGetActivateTransactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActivateTransactionQuery, GetActivateTransactionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActivateTransactionQuery, GetActivateTransactionQueryVariables>(
    GetActivateTransactionDocument,
    options,
  );
}
export type GetActivateTransactionQueryHookResult = ReturnType<typeof useGetActivateTransactionQuery>;
export type GetActivateTransactionLazyQueryHookResult = ReturnType<typeof useGetActivateTransactionLazyQuery>;
export type GetActivateTransactionQueryResult = Apollo.QueryResult<
  GetActivateTransactionQuery,
  GetActivateTransactionQueryVariables
>;
export const GetTransactionDocument = gql`
  query getTransaction($uuid: ID!) {
    transaction(uuid: $uuid) {
      ...Transaction
    }
  }
  ${TransactionFragmentDoc}
`;

/**
 * __useGetTransactionQuery__
 *
 * To run a query within a React component, call `useGetTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetTransactionQuery(
  baseOptions: Apollo.QueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, options);
}
export function useGetTransactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, options);
}
export type GetTransactionQueryHookResult = ReturnType<typeof useGetTransactionQuery>;
export type GetTransactionLazyQueryHookResult = ReturnType<typeof useGetTransactionLazyQuery>;
export type GetTransactionQueryResult = Apollo.QueryResult<GetTransactionQuery, GetTransactionQueryVariables>;
export const StartPayInsideTransactionDocument = gql`
  mutation startPayInsideTransaction($input: TransactionStart!) {
    transaction: transactionStart(input: $input) {
      uuid
      stac {
        id
        expiration
      }
    }
  }
`;
export type StartPayInsideTransactionMutationFn = Apollo.MutationFunction<
  StartPayInsideTransactionMutation,
  StartPayInsideTransactionMutationVariables
>;

/**
 * __useStartPayInsideTransactionMutation__
 *
 * To run a mutation, you first call `useStartPayInsideTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartPayInsideTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startPayInsideTransactionMutation, { data, loading, error }] = useStartPayInsideTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartPayInsideTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartPayInsideTransactionMutation,
    StartPayInsideTransactionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartPayInsideTransactionMutation, StartPayInsideTransactionMutationVariables>(
    StartPayInsideTransactionDocument,
    options,
  );
}
export type StartPayInsideTransactionMutationHookResult = ReturnType<typeof useStartPayInsideTransactionMutation>;
export type StartPayInsideTransactionMutationResult = Apollo.MutationResult<StartPayInsideTransactionMutation>;
export type StartPayInsideTransactionMutationOptions = Apollo.BaseMutationOptions<
  StartPayInsideTransactionMutation,
  StartPayInsideTransactionMutationVariables
>;
export const StartTransactionDocument = gql`
  mutation startTransaction($input: TransactionStart!) {
    transaction: transactionStart(input: $input) {
      uuid
      primaryStatus
    }
  }
`;
export type StartTransactionMutationFn = Apollo.MutationFunction<
  StartTransactionMutation,
  StartTransactionMutationVariables
>;

/**
 * __useStartTransactionMutation__
 *
 * To run a mutation, you first call `useStartTransactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTransactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTransactionMutation, { data, loading, error }] = useStartTransactionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartTransactionMutation(
  baseOptions?: Apollo.MutationHookOptions<StartTransactionMutation, StartTransactionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<StartTransactionMutation, StartTransactionMutationVariables>(
    StartTransactionDocument,
    options,
  );
}
export type StartTransactionMutationHookResult = ReturnType<typeof useStartTransactionMutation>;
export type StartTransactionMutationResult = Apollo.MutationResult<StartTransactionMutation>;
export type StartTransactionMutationOptions = Apollo.BaseMutationOptions<
  StartTransactionMutation,
  StartTransactionMutationVariables
>;
export const CreateSurveyViewsDocument = gql`
  mutation createSurveyViews($input: SurveyViewsCreateInput!) {
    createSurveyViews: surveyViewsCreate(input: $input)
  }
`;
export type CreateSurveyViewsMutationFn = Apollo.MutationFunction<
  CreateSurveyViewsMutation,
  CreateSurveyViewsMutationVariables
>;

/**
 * __useCreateSurveyViewsMutation__
 *
 * To run a mutation, you first call `useCreateSurveyViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyViewsMutation, { data, loading, error }] = useCreateSurveyViewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSurveyViewsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSurveyViewsMutation, CreateSurveyViewsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSurveyViewsMutation, CreateSurveyViewsMutationVariables>(
    CreateSurveyViewsDocument,
    options,
  );
}
export type CreateSurveyViewsMutationHookResult = ReturnType<typeof useCreateSurveyViewsMutation>;
export type CreateSurveyViewsMutationResult = Apollo.MutationResult<CreateSurveyViewsMutation>;
export type CreateSurveyViewsMutationOptions = Apollo.BaseMutationOptions<
  CreateSurveyViewsMutation,
  CreateSurveyViewsMutationVariables
>;
export const DismissSurveyDocument = gql`
  mutation dismissSurvey($input: SurveyDismissInput!) {
    surveyDismiss(input: $input)
  }
`;
export type DismissSurveyMutationFn = Apollo.MutationFunction<DismissSurveyMutation, DismissSurveyMutationVariables>;

/**
 * __useDismissSurveyMutation__
 *
 * To run a mutation, you first call `useDismissSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissSurveyMutation, { data, loading, error }] = useDismissSurveyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDismissSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<DismissSurveyMutation, DismissSurveyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DismissSurveyMutation, DismissSurveyMutationVariables>(DismissSurveyDocument, options);
}
export type DismissSurveyMutationHookResult = ReturnType<typeof useDismissSurveyMutation>;
export type DismissSurveyMutationResult = Apollo.MutationResult<DismissSurveyMutation>;
export type DismissSurveyMutationOptions = Apollo.BaseMutationOptions<
  DismissSurveyMutation,
  DismissSurveyMutationVariables
>;
export const GetGuestSurveyDocument = gql`
  query getGuestSurvey($guestSurveyId: String!) {
    guestSurvey(guestSurveyId: $guestSurveyId) {
      ...Survey
    }
  }
  ${SurveyFragmentDoc}
`;

/**
 * __useGetGuestSurveyQuery__
 *
 * To run a query within a React component, call `useGetGuestSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGuestSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGuestSurveyQuery({
 *   variables: {
 *      guestSurveyId: // value for 'guestSurveyId'
 *   },
 * });
 */
export function useGetGuestSurveyQuery(
  baseOptions: Apollo.QueryHookOptions<GetGuestSurveyQuery, GetGuestSurveyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGuestSurveyQuery, GetGuestSurveyQueryVariables>(GetGuestSurveyDocument, options);
}
export function useGetGuestSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGuestSurveyQuery, GetGuestSurveyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGuestSurveyQuery, GetGuestSurveyQueryVariables>(GetGuestSurveyDocument, options);
}
export type GetGuestSurveyQueryHookResult = ReturnType<typeof useGetGuestSurveyQuery>;
export type GetGuestSurveyLazyQueryHookResult = ReturnType<typeof useGetGuestSurveyLazyQuery>;
export type GetGuestSurveyQueryResult = Apollo.QueryResult<GetGuestSurveyQuery, GetGuestSurveyQueryVariables>;
export const GetSurveyDocument = gql`
  query getSurvey($uuid: OCUUID!) {
    survey(uuid: $uuid) {
      ...Survey
    }
  }
  ${SurveyFragmentDoc}
`;

/**
 * __useGetSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetSurveyQuery(baseOptions: Apollo.QueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
}
export function useGetSurveyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSurveyQuery, GetSurveyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSurveyQuery, GetSurveyQueryVariables>(GetSurveyDocument, options);
}
export type GetSurveyQueryHookResult = ReturnType<typeof useGetSurveyQuery>;
export type GetSurveyLazyQueryHookResult = ReturnType<typeof useGetSurveyLazyQuery>;
export type GetSurveyQueryResult = Apollo.QueryResult<GetSurveyQuery, GetSurveyQueryVariables>;
export const GetSurveysDocument = gql`
  query getSurveys($filter: SurveyFilter) {
    surveys(first: 10, filter: $filter) {
      edges {
        cursor
        node {
          ...Survey
        }
      }
    }
  }
  ${SurveyFragmentDoc}
`;

/**
 * __useGetSurveysQuery__
 *
 * To run a query within a React component, call `useGetSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveysQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSurveysQuery(baseOptions?: Apollo.QueryHookOptions<GetSurveysQuery, GetSurveysQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSurveysQuery, GetSurveysQueryVariables>(GetSurveysDocument, options);
}
export function useGetSurveysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSurveysQuery, GetSurveysQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSurveysQuery, GetSurveysQueryVariables>(GetSurveysDocument, options);
}
export type GetSurveysQueryHookResult = ReturnType<typeof useGetSurveysQuery>;
export type GetSurveysLazyQueryHookResult = ReturnType<typeof useGetSurveysLazyQuery>;
export type GetSurveysQueryResult = Apollo.QueryResult<GetSurveysQuery, GetSurveysQueryVariables>;
export const GetSurveysTotalCountDocument = gql`
  query getSurveysTotalCount {
    surveys(filter: { includeDismissed: true }) {
      totalCount
    }
  }
`;

/**
 * __useGetSurveysTotalCountQuery__
 *
 * To run a query within a React component, call `useGetSurveysTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveysTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveysTotalCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSurveysTotalCountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSurveysTotalCountQuery, GetSurveysTotalCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSurveysTotalCountQuery, GetSurveysTotalCountQueryVariables>(
    GetSurveysTotalCountDocument,
    options,
  );
}
export function useGetSurveysTotalCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSurveysTotalCountQuery, GetSurveysTotalCountQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSurveysTotalCountQuery, GetSurveysTotalCountQueryVariables>(
    GetSurveysTotalCountDocument,
    options,
  );
}
export type GetSurveysTotalCountQueryHookResult = ReturnType<typeof useGetSurveysTotalCountQuery>;
export type GetSurveysTotalCountLazyQueryHookResult = ReturnType<typeof useGetSurveysTotalCountLazyQuery>;
export type GetSurveysTotalCountQueryResult = Apollo.QueryResult<
  GetSurveysTotalCountQuery,
  GetSurveysTotalCountQueryVariables
>;
export const SubmitGuestSurveyAnswerDocument = gql`
  mutation submitGuestSurveyAnswer($input: GuestSurveyAnswerSendInput!) {
    submitGuestSurveyAnswer: guestSurveyAnswerSend(input: $input)
  }
`;
export type SubmitGuestSurveyAnswerMutationFn = Apollo.MutationFunction<
  SubmitGuestSurveyAnswerMutation,
  SubmitGuestSurveyAnswerMutationVariables
>;

/**
 * __useSubmitGuestSurveyAnswerMutation__
 *
 * To run a mutation, you first call `useSubmitGuestSurveyAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGuestSurveyAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGuestSurveyAnswerMutation, { data, loading, error }] = useSubmitGuestSurveyAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitGuestSurveyAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitGuestSurveyAnswerMutation, SubmitGuestSurveyAnswerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitGuestSurveyAnswerMutation, SubmitGuestSurveyAnswerMutationVariables>(
    SubmitGuestSurveyAnswerDocument,
    options,
  );
}
export type SubmitGuestSurveyAnswerMutationHookResult = ReturnType<typeof useSubmitGuestSurveyAnswerMutation>;
export type SubmitGuestSurveyAnswerMutationResult = Apollo.MutationResult<SubmitGuestSurveyAnswerMutation>;
export type SubmitGuestSurveyAnswerMutationOptions = Apollo.BaseMutationOptions<
  SubmitGuestSurveyAnswerMutation,
  SubmitGuestSurveyAnswerMutationVariables
>;
export const SubmitGuestSurveyAnswersDocument = gql`
  mutation submitGuestSurveyAnswers($input: GuestSurveyAnswersSubmitInput!) {
    submitGuestSurveyAnswers: guestSurveyAnswersSubmit(input: $input)
  }
`;
export type SubmitGuestSurveyAnswersMutationFn = Apollo.MutationFunction<
  SubmitGuestSurveyAnswersMutation,
  SubmitGuestSurveyAnswersMutationVariables
>;

/**
 * __useSubmitGuestSurveyAnswersMutation__
 *
 * To run a mutation, you first call `useSubmitGuestSurveyAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGuestSurveyAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGuestSurveyAnswersMutation, { data, loading, error }] = useSubmitGuestSurveyAnswersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitGuestSurveyAnswersMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitGuestSurveyAnswersMutation, SubmitGuestSurveyAnswersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitGuestSurveyAnswersMutation, SubmitGuestSurveyAnswersMutationVariables>(
    SubmitGuestSurveyAnswersDocument,
    options,
  );
}
export type SubmitGuestSurveyAnswersMutationHookResult = ReturnType<typeof useSubmitGuestSurveyAnswersMutation>;
export type SubmitGuestSurveyAnswersMutationResult = Apollo.MutationResult<SubmitGuestSurveyAnswersMutation>;
export type SubmitGuestSurveyAnswersMutationOptions = Apollo.BaseMutationOptions<
  SubmitGuestSurveyAnswersMutation,
  SubmitGuestSurveyAnswersMutationVariables
>;
export const SubmitSurveyAnswerDocument = gql`
  mutation submitSurveyAnswer($input: SurveyAnswerSendInput!) {
    submitSurveyAnswer: surveyAnswerSend(input: $input)
  }
`;
export type SubmitSurveyAnswerMutationFn = Apollo.MutationFunction<
  SubmitSurveyAnswerMutation,
  SubmitSurveyAnswerMutationVariables
>;

/**
 * __useSubmitSurveyAnswerMutation__
 *
 * To run a mutation, you first call `useSubmitSurveyAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSurveyAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSurveyAnswerMutation, { data, loading, error }] = useSubmitSurveyAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitSurveyAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitSurveyAnswerMutation, SubmitSurveyAnswerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitSurveyAnswerMutation, SubmitSurveyAnswerMutationVariables>(
    SubmitSurveyAnswerDocument,
    options,
  );
}
export type SubmitSurveyAnswerMutationHookResult = ReturnType<typeof useSubmitSurveyAnswerMutation>;
export type SubmitSurveyAnswerMutationResult = Apollo.MutationResult<SubmitSurveyAnswerMutation>;
export type SubmitSurveyAnswerMutationOptions = Apollo.BaseMutationOptions<
  SubmitSurveyAnswerMutation,
  SubmitSurveyAnswerMutationVariables
>;
export const SubmitSurveyAnswersDocument = gql`
  mutation submitSurveyAnswers($input: SurveyAnswersSubmitInput!) {
    submitSurveyAnswers: surveyAnswersSubmit(input: $input)
  }
`;
export type SubmitSurveyAnswersMutationFn = Apollo.MutationFunction<
  SubmitSurveyAnswersMutation,
  SubmitSurveyAnswersMutationVariables
>;

/**
 * __useSubmitSurveyAnswersMutation__
 *
 * To run a mutation, you first call `useSubmitSurveyAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSurveyAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSurveyAnswersMutation, { data, loading, error }] = useSubmitSurveyAnswersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitSurveyAnswersMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitSurveyAnswersMutation, SubmitSurveyAnswersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitSurveyAnswersMutation, SubmitSurveyAnswersMutationVariables>(
    SubmitSurveyAnswersDocument,
    options,
  );
}
export type SubmitSurveyAnswersMutationHookResult = ReturnType<typeof useSubmitSurveyAnswersMutation>;
export type SubmitSurveyAnswersMutationResult = Apollo.MutationResult<SubmitSurveyAnswersMutation>;
export type SubmitSurveyAnswersMutationOptions = Apollo.BaseMutationOptions<
  SubmitSurveyAnswersMutation,
  SubmitSurveyAnswersMutationVariables
>;
export const GetFiservClientAccessTokenDocument = gql`
  query getFiservClientAccessToken {
    getFiservClientAccessToken {
      fdCustomerId
      publicKey
      algorithm
      issuedOn
      expiresInSeconds
      oAuthToken
      apiKey
      baseUrl
    }
  }
`;

/**
 * __useGetFiservClientAccessTokenQuery__
 *
 * To run a query within a React component, call `useGetFiservClientAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiservClientAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiservClientAccessTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFiservClientAccessTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFiservClientAccessTokenQuery, GetFiservClientAccessTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFiservClientAccessTokenQuery, GetFiservClientAccessTokenQueryVariables>(
    GetFiservClientAccessTokenDocument,
    options,
  );
}
export function useGetFiservClientAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFiservClientAccessTokenQuery, GetFiservClientAccessTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFiservClientAccessTokenQuery, GetFiservClientAccessTokenQueryVariables>(
    GetFiservClientAccessTokenDocument,
    options,
  );
}
export type GetFiservClientAccessTokenQueryHookResult = ReturnType<typeof useGetFiservClientAccessTokenQuery>;
export type GetFiservClientAccessTokenLazyQueryHookResult = ReturnType<typeof useGetFiservClientAccessTokenLazyQuery>;
export type GetFiservClientAccessTokenQueryResult = Apollo.QueryResult<
  GetFiservClientAccessTokenQuery,
  GetFiservClientAccessTokenQueryVariables
>;
export const RudderstackDocument = gql`
  query rudderstack {
    rudderstack {
      audienceUuids
    }
  }
`;

/**
 * __useRudderstackQuery__
 *
 * To run a query within a React component, call `useRudderstackQuery` and pass it any options that fit your needs.
 * When your component renders, `useRudderstackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRudderstackQuery({
 *   variables: {
 *   },
 * });
 */
export function useRudderstackQuery(
  baseOptions?: Apollo.QueryHookOptions<RudderstackQuery, RudderstackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RudderstackQuery, RudderstackQueryVariables>(RudderstackDocument, options);
}
export function useRudderstackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RudderstackQuery, RudderstackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RudderstackQuery, RudderstackQueryVariables>(RudderstackDocument, options);
}
export type RudderstackQueryHookResult = ReturnType<typeof useRudderstackQuery>;
export type RudderstackLazyQueryHookResult = ReturnType<typeof useRudderstackLazyQuery>;
export type RudderstackQueryResult = Apollo.QueryResult<RudderstackQuery, RudderstackQueryVariables>;
export const TransactionsWithOfferFulfillmentDocument = gql`
  query transactionsWithOfferFulfillment($size: Int!) {
    customer: currentCustomer {
      uuid
      membership {
        id
        transactions(first: $size) {
          edges {
            node {
              ...TransactionWithOfferFulfillment
            }
          }
        }
      }
    }
  }
  ${TransactionWithOfferFulfillmentFragmentDoc}
`;

/**
 * __useTransactionsWithOfferFulfillmentQuery__
 *
 * To run a query within a React component, call `useTransactionsWithOfferFulfillmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsWithOfferFulfillmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsWithOfferFulfillmentQuery({
 *   variables: {
 *      size: // value for 'size'
 *   },
 * });
 */
export function useTransactionsWithOfferFulfillmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransactionsWithOfferFulfillmentQuery,
    TransactionsWithOfferFulfillmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionsWithOfferFulfillmentQuery, TransactionsWithOfferFulfillmentQueryVariables>(
    TransactionsWithOfferFulfillmentDocument,
    options,
  );
}
export function useTransactionsWithOfferFulfillmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionsWithOfferFulfillmentQuery,
    TransactionsWithOfferFulfillmentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionsWithOfferFulfillmentQuery, TransactionsWithOfferFulfillmentQueryVariables>(
    TransactionsWithOfferFulfillmentDocument,
    options,
  );
}
export type TransactionsWithOfferFulfillmentQueryHookResult = ReturnType<
  typeof useTransactionsWithOfferFulfillmentQuery
>;
export type TransactionsWithOfferFulfillmentLazyQueryHookResult = ReturnType<
  typeof useTransactionsWithOfferFulfillmentLazyQuery
>;
export type TransactionsWithOfferFulfillmentQueryResult = Apollo.QueryResult<
  TransactionsWithOfferFulfillmentQuery,
  TransactionsWithOfferFulfillmentQueryVariables
>;
export const CreateOtpSessionByPlatformDocument = gql`
  mutation createOTPSessionByPlatform($input: CreateOTPSessionInput!) {
    createOTPSessionByPlatform(input: $input) {
      session
    }
  }
`;
export type CreateOtpSessionByPlatformMutationFn = Apollo.MutationFunction<
  CreateOtpSessionByPlatformMutation,
  CreateOtpSessionByPlatformMutationVariables
>;

/**
 * __useCreateOtpSessionByPlatformMutation__
 *
 * To run a mutation, you first call `useCreateOtpSessionByPlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOtpSessionByPlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOtpSessionByPlatformMutation, { data, loading, error }] = useCreateOtpSessionByPlatformMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOtpSessionByPlatformMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOtpSessionByPlatformMutation,
    CreateOtpSessionByPlatformMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOtpSessionByPlatformMutation, CreateOtpSessionByPlatformMutationVariables>(
    CreateOtpSessionByPlatformDocument,
    options,
  );
}
export type CreateOtpSessionByPlatformMutationHookResult = ReturnType<typeof useCreateOtpSessionByPlatformMutation>;
export type CreateOtpSessionByPlatformMutationResult = Apollo.MutationResult<CreateOtpSessionByPlatformMutation>;
export type CreateOtpSessionByPlatformMutationOptions = Apollo.BaseMutationOptions<
  CreateOtpSessionByPlatformMutation,
  CreateOtpSessionByPlatformMutationVariables
>;
export const GetAccessTokenByRefreshTokenDocument = gql`
  mutation getAccessTokenByRefreshToken($input: AccessTokenByRefreshTokenInput!) {
    getAccessTokenByRefreshToken(input: $input) {
      accessToken
      refreshToken
    }
  }
`;
export type GetAccessTokenByRefreshTokenMutationFn = Apollo.MutationFunction<
  GetAccessTokenByRefreshTokenMutation,
  GetAccessTokenByRefreshTokenMutationVariables
>;

/**
 * __useGetAccessTokenByRefreshTokenMutation__
 *
 * To run a mutation, you first call `useGetAccessTokenByRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetAccessTokenByRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getAccessTokenByRefreshTokenMutation, { data, loading, error }] = useGetAccessTokenByRefreshTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAccessTokenByRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetAccessTokenByRefreshTokenMutation,
    GetAccessTokenByRefreshTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GetAccessTokenByRefreshTokenMutation, GetAccessTokenByRefreshTokenMutationVariables>(
    GetAccessTokenByRefreshTokenDocument,
    options,
  );
}
export type GetAccessTokenByRefreshTokenMutationHookResult = ReturnType<typeof useGetAccessTokenByRefreshTokenMutation>;
export type GetAccessTokenByRefreshTokenMutationResult = Apollo.MutationResult<GetAccessTokenByRefreshTokenMutation>;
export type GetAccessTokenByRefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  GetAccessTokenByRefreshTokenMutation,
  GetAccessTokenByRefreshTokenMutationVariables
>;
export const RequestMagicLinkDocument = gql`
  mutation requestMagicLink($input: MagicLinkRequestInput!) {
    requestMagicLink(input: $input)
  }
`;
export type RequestMagicLinkMutationFn = Apollo.MutationFunction<
  RequestMagicLinkMutation,
  RequestMagicLinkMutationVariables
>;

/**
 * __useRequestMagicLinkMutation__
 *
 * To run a mutation, you first call `useRequestMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMagicLinkMutation, { data, loading, error }] = useRequestMagicLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestMagicLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestMagicLinkMutation, RequestMagicLinkMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestMagicLinkMutation, RequestMagicLinkMutationVariables>(
    RequestMagicLinkDocument,
    options,
  );
}
export type RequestMagicLinkMutationHookResult = ReturnType<typeof useRequestMagicLinkMutation>;
export type RequestMagicLinkMutationResult = Apollo.MutationResult<RequestMagicLinkMutation>;
export type RequestMagicLinkMutationOptions = Apollo.BaseMutationOptions<
  RequestMagicLinkMutation,
  RequestMagicLinkMutationVariables
>;
export const SignupWithOtpDocument = gql`
  mutation signupWithOTP($input: OTP!) {
    exchangeSessionAndOTPToAuthToken(input: $input) {
      __typename
      ...SignupWithOTPSuccess
      ...SignupWithOTPIncorrectOTP
    }
  }
  ${SignupWithOtpSuccessFragmentDoc}
  ${SignupWithOtpIncorrectOtpFragmentDoc}
`;
export type SignupWithOtpMutationFn = Apollo.MutationFunction<SignupWithOtpMutation, SignupWithOtpMutationVariables>;

/**
 * __useSignupWithOtpMutation__
 *
 * To run a mutation, you first call `useSignupWithOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupWithOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupWithOtpMutation, { data, loading, error }] = useSignupWithOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupWithOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<SignupWithOtpMutation, SignupWithOtpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupWithOtpMutation, SignupWithOtpMutationVariables>(SignupWithOtpDocument, options);
}
export type SignupWithOtpMutationHookResult = ReturnType<typeof useSignupWithOtpMutation>;
export type SignupWithOtpMutationResult = Apollo.MutationResult<SignupWithOtpMutation>;
export type SignupWithOtpMutationOptions = Apollo.BaseMutationOptions<
  SignupWithOtpMutation,
  SignupWithOtpMutationVariables
>;
export const SignupWithTokenDocument = gql`
  mutation signupWithToken($input: MagicLinkExchangeSessionIdToAuthTokenInput!) {
    signupWithToken: magicLinkExchangeSessionIdToAuthToken(input: $input) {
      tokens {
        accessToken
        refreshToken
      }
    }
  }
`;
export type SignupWithTokenMutationFn = Apollo.MutationFunction<
  SignupWithTokenMutation,
  SignupWithTokenMutationVariables
>;

/**
 * __useSignupWithTokenMutation__
 *
 * To run a mutation, you first call `useSignupWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupWithTokenMutation, { data, loading, error }] = useSignupWithTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignupWithTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<SignupWithTokenMutation, SignupWithTokenMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupWithTokenMutation, SignupWithTokenMutationVariables>(
    SignupWithTokenDocument,
    options,
  );
}
export type SignupWithTokenMutationHookResult = ReturnType<typeof useSignupWithTokenMutation>;
export type SignupWithTokenMutationResult = Apollo.MutationResult<SignupWithTokenMutation>;
export type SignupWithTokenMutationOptions = Apollo.BaseMutationOptions<
  SignupWithTokenMutation,
  SignupWithTokenMutationVariables
>;
export const CommunicationPreferenceUpdateDocument = gql`
  mutation communicationPreferenceUpdate($input: CommunicationPreferenceUpdateInput!) {
    preference: updateCommunicationPreference(input: $input) {
      ...CommunicationPreference
    }
  }
  ${CommunicationPreferenceFragmentDoc}
`;
export type CommunicationPreferenceUpdateMutationFn = Apollo.MutationFunction<
  CommunicationPreferenceUpdateMutation,
  CommunicationPreferenceUpdateMutationVariables
>;

/**
 * __useCommunicationPreferenceUpdateMutation__
 *
 * To run a mutation, you first call `useCommunicationPreferenceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommunicationPreferenceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [communicationPreferenceUpdateMutation, { data, loading, error }] = useCommunicationPreferenceUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommunicationPreferenceUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CommunicationPreferenceUpdateMutation,
    CommunicationPreferenceUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CommunicationPreferenceUpdateMutation, CommunicationPreferenceUpdateMutationVariables>(
    CommunicationPreferenceUpdateDocument,
    options,
  );
}
export type CommunicationPreferenceUpdateMutationHookResult = ReturnType<
  typeof useCommunicationPreferenceUpdateMutation
>;
export type CommunicationPreferenceUpdateMutationResult = Apollo.MutationResult<CommunicationPreferenceUpdateMutation>;
export type CommunicationPreferenceUpdateMutationOptions = Apollo.BaseMutationOptions<
  CommunicationPreferenceUpdateMutation,
  CommunicationPreferenceUpdateMutationVariables
>;
export const GetAppConfigDocument = gql`
  query getAppConfig {
    appConfig {
      termsConditions {
        activeAt
        name
        url
        uuid
      }
      privacyPolicy {
        activeAt
        name
        url
        uuid
      }
      supportTeamEmail
      supportPhoneNumber
      loyaltyProgram {
        pointsStrategy
        homePageOffersLimit
        redeemOfferWithCompletedProfileOnly
        membershipMinAge
        isExplicitTermsAcceptanceRequired
        isReturnRewardEnabled
        isExplicitSmsPromotionAcceptanceEnabled
        isPromocodesEnabled
      }
      payAtPump {
        enabled
      }
      payInside {
        enabled
      }
      applePay {
        merchantId
        supportedNetworks
        allowCreditCards
        merchantName
        allowPrepaidCards
      }
      googlePay {
        environment
        gateway
        merchantId
        merchantName
        allowedCardNetworks
        allowPrepaidCards
        allowCreditCards
        gatewayMerchantId
      }
      kount {
        merchantId
        collectorHostname
        environment
      }
      pinCode {
        blockingDuration
        attemptThrottle
        maxAttempts
      }
      wallet {
        enabled
      }
      deleteAccount {
        enabled
      }
      paymentProvider {
        __typename
        ... on FiservConfiguration {
          customData
        }
        ... on WorldpayConfiguration {
          customData
          eProtectPayPageId
          eProtectGooglePayPageId
          eProtectWebSdkApiUrl
          eProtectWebSdkUrl
        }
      }
      paymentWallet {
        maxInstruments
        allowedAccountTypes
      }
      communicationPreference {
        enabled
        isSmsOptInConfigurable
        isPushOptInConfigurable
      }
      eaivAgeVerification {
        enabled
      }
      eaivIdentityVerification {
        enabled
      }
      productInterests {
        enabled
        isEAIVPromotionOnProfileCompletedEnabled
      }
      appRating {
        enabled
        promptIntervalDays
        transactionHistoryLookupDays
      }
      auth {
        primaryMethod
        otp {
          enabled
          channels
        }
      }
      freshdeskSupportWidget {
        widgetId
        token
        host
        sdkUrl
        enabled
      }
      rudderStack {
        enabled
      }
      totalAvailableRollback {
        enabled
      }
      smsOptIn {
        enabled
        modalAppearanceDelaySeconds
        modalOfferLoyaltyEventName
        optInAcceptedLoyaltyEventName
        optInRejectedLoyaltyEventName
      }
      mobilePaymentsReceiptsView {
        enabled
      }
      storeLocator {
        fuelPricesMode
      }
    }
  }
`;

/**
 * __useGetAppConfigQuery__
 *
 * To run a query within a React component, call `useGetAppConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAppConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAppConfigQuery, GetAppConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppConfigQuery, GetAppConfigQueryVariables>(GetAppConfigDocument, options);
}
export function useGetAppConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppConfigQuery, GetAppConfigQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppConfigQuery, GetAppConfigQueryVariables>(GetAppConfigDocument, options);
}
export type GetAppConfigQueryHookResult = ReturnType<typeof useGetAppConfigQuery>;
export type GetAppConfigLazyQueryHookResult = ReturnType<typeof useGetAppConfigLazyQuery>;
export type GetAppConfigQueryResult = Apollo.QueryResult<GetAppConfigQuery, GetAppConfigQueryVariables>;
export const EaivAgeVerificationStartDocument = gql`
  mutation eaivAgeVerificationStart($input: EaivAgeVerificationStartInput!) {
    result: eaivAgeVerificationStart(input: $input) {
      __typename
      ... on EaivAgeVerificationResultFailed {
        reason
      }
      ... on EaivAgeVerificationStartResultSuccess {
        status
      }
      ...AgeVerificationRequireAdditionalPrompt
    }
  }
  ${AgeVerificationRequireAdditionalPromptFragmentDoc}
`;
export type EaivAgeVerificationStartMutationFn = Apollo.MutationFunction<
  EaivAgeVerificationStartMutation,
  EaivAgeVerificationStartMutationVariables
>;

/**
 * __useEaivAgeVerificationStartMutation__
 *
 * To run a mutation, you first call `useEaivAgeVerificationStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEaivAgeVerificationStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eaivAgeVerificationStartMutation, { data, loading, error }] = useEaivAgeVerificationStartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEaivAgeVerificationStartMutation(
  baseOptions?: Apollo.MutationHookOptions<EaivAgeVerificationStartMutation, EaivAgeVerificationStartMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EaivAgeVerificationStartMutation, EaivAgeVerificationStartMutationVariables>(
    EaivAgeVerificationStartDocument,
    options,
  );
}
export type EaivAgeVerificationStartMutationHookResult = ReturnType<typeof useEaivAgeVerificationStartMutation>;
export type EaivAgeVerificationStartMutationResult = Apollo.MutationResult<EaivAgeVerificationStartMutation>;
export type EaivAgeVerificationStartMutationOptions = Apollo.BaseMutationOptions<
  EaivAgeVerificationStartMutation,
  EaivAgeVerificationStartMutationVariables
>;
export const EaivIdentityVerificationStartDocument = gql`
  mutation eaivIdentityVerificationStart($input: EaivIdentityVerificationStartInput!) {
    result: eaivIdentityVerificationStart(input: $input) {
      __typename
      ... on EaivIdentityVerificationResultFailed {
        reason
      }
      ...IdentityVerificationStartResultSuccess
      ...IdentityVerificationRequireAdditionalPrompt
    }
  }
  ${IdentityVerificationStartResultSuccessFragmentDoc}
  ${IdentityVerificationRequireAdditionalPromptFragmentDoc}
`;
export type EaivIdentityVerificationStartMutationFn = Apollo.MutationFunction<
  EaivIdentityVerificationStartMutation,
  EaivIdentityVerificationStartMutationVariables
>;

/**
 * __useEaivIdentityVerificationStartMutation__
 *
 * To run a mutation, you first call `useEaivIdentityVerificationStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEaivIdentityVerificationStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eaivIdentityVerificationStartMutation, { data, loading, error }] = useEaivIdentityVerificationStartMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEaivIdentityVerificationStartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EaivIdentityVerificationStartMutation,
    EaivIdentityVerificationStartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EaivIdentityVerificationStartMutation, EaivIdentityVerificationStartMutationVariables>(
    EaivIdentityVerificationStartDocument,
    options,
  );
}
export type EaivIdentityVerificationStartMutationHookResult = ReturnType<
  typeof useEaivIdentityVerificationStartMutation
>;
export type EaivIdentityVerificationStartMutationResult = Apollo.MutationResult<EaivIdentityVerificationStartMutation>;
export type EaivIdentityVerificationStartMutationOptions = Apollo.BaseMutationOptions<
  EaivIdentityVerificationStartMutation,
  EaivIdentityVerificationStartMutationVariables
>;
export const EaivIdentityVerificationSubmitDocument = gql`
  mutation eaivIdentityVerificationSubmit($input: EaivIdentityVerificationSubmitInput!) {
    result: eaivIdentityVerificationSubmit(input: $input) {
      __typename
      ... on EaivIdentityVerificationResultFailed {
        reason
      }
      ... on EaivIdentityVerificationSubmitResultSuccess {
        status
      }
      ...IdentityVerificationStartResultContinue
    }
  }
  ${IdentityVerificationStartResultContinueFragmentDoc}
`;
export type EaivIdentityVerificationSubmitMutationFn = Apollo.MutationFunction<
  EaivIdentityVerificationSubmitMutation,
  EaivIdentityVerificationSubmitMutationVariables
>;

/**
 * __useEaivIdentityVerificationSubmitMutation__
 *
 * To run a mutation, you first call `useEaivIdentityVerificationSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEaivIdentityVerificationSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eaivIdentityVerificationSubmitMutation, { data, loading, error }] = useEaivIdentityVerificationSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEaivIdentityVerificationSubmitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EaivIdentityVerificationSubmitMutation,
    EaivIdentityVerificationSubmitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EaivIdentityVerificationSubmitMutation, EaivIdentityVerificationSubmitMutationVariables>(
    EaivIdentityVerificationSubmitDocument,
    options,
  );
}
export type EaivIdentityVerificationSubmitMutationHookResult = ReturnType<
  typeof useEaivIdentityVerificationSubmitMutation
>;
export type EaivIdentityVerificationSubmitMutationResult =
  Apollo.MutationResult<EaivIdentityVerificationSubmitMutation>;
export type EaivIdentityVerificationSubmitMutationOptions = Apollo.BaseMutationOptions<
  EaivIdentityVerificationSubmitMutation,
  EaivIdentityVerificationSubmitMutationVariables
>;
export const GetExternalOffersDocument = gql`
  query GetExternalOffers($filter: BannerFilter!) {
    banners(filter: $filter) {
      ...ExternalOffer
    }
  }
  ${ExternalOfferFragmentDoc}
`;

/**
 * __useGetExternalOffersQuery__
 *
 * To run a query within a React component, call `useGetExternalOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalOffersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetExternalOffersQuery(
  baseOptions: Apollo.QueryHookOptions<GetExternalOffersQuery, GetExternalOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExternalOffersQuery, GetExternalOffersQueryVariables>(GetExternalOffersDocument, options);
}
export function useGetExternalOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExternalOffersQuery, GetExternalOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExternalOffersQuery, GetExternalOffersQueryVariables>(
    GetExternalOffersDocument,
    options,
  );
}
export type GetExternalOffersQueryHookResult = ReturnType<typeof useGetExternalOffersQuery>;
export type GetExternalOffersLazyQueryHookResult = ReturnType<typeof useGetExternalOffersLazyQuery>;
export type GetExternalOffersQueryResult = Apollo.QueryResult<GetExternalOffersQuery, GetExternalOffersQueryVariables>;
export const LogOfferActivityDocument = gql`
  mutation LogOfferActivity($input: OfferActivityLogInput!) {
    offerActivityLog(input: $input)
  }
`;
export type LogOfferActivityMutationFn = Apollo.MutationFunction<
  LogOfferActivityMutation,
  LogOfferActivityMutationVariables
>;

/**
 * __useLogOfferActivityMutation__
 *
 * To run a mutation, you first call `useLogOfferActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOfferActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOfferActivityMutation, { data, loading, error }] = useLogOfferActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLogOfferActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<LogOfferActivityMutation, LogOfferActivityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogOfferActivityMutation, LogOfferActivityMutationVariables>(
    LogOfferActivityDocument,
    options,
  );
}
export type LogOfferActivityMutationHookResult = ReturnType<typeof useLogOfferActivityMutation>;
export type LogOfferActivityMutationResult = Apollo.MutationResult<LogOfferActivityMutation>;
export type LogOfferActivityMutationOptions = Apollo.BaseMutationOptions<
  LogOfferActivityMutation,
  LogOfferActivityMutationVariables
>;
export const FuelProductGroupsDocument = gql`
  query fuelProductGroups($filter: FuelProductGroupFilter) {
    fuelProductGroups(filter: $filter) {
      ...FuelProductGroup
    }
  }
  ${FuelProductGroupFragmentDoc}
`;

/**
 * __useFuelProductGroupsQuery__
 *
 * To run a query within a React component, call `useFuelProductGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFuelProductGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFuelProductGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFuelProductGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<FuelProductGroupsQuery, FuelProductGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FuelProductGroupsQuery, FuelProductGroupsQueryVariables>(FuelProductGroupsDocument, options);
}
export function useFuelProductGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FuelProductGroupsQuery, FuelProductGroupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FuelProductGroupsQuery, FuelProductGroupsQueryVariables>(
    FuelProductGroupsDocument,
    options,
  );
}
export type FuelProductGroupsQueryHookResult = ReturnType<typeof useFuelProductGroupsQuery>;
export type FuelProductGroupsLazyQueryHookResult = ReturnType<typeof useFuelProductGroupsLazyQuery>;
export type FuelProductGroupsQueryResult = Apollo.QueryResult<FuelProductGroupsQuery, FuelProductGroupsQueryVariables>;
export const ExecuteSpinWheelGameDocument = gql`
  mutation executeSpinWheelGame($offerId: ID!) {
    result: spinWheelGameExecute(input: { offerId: $offerId }) {
      sequenceNumber
      awardType
      award {
        ...GameAwardPointsOrVouchers
      }
    }
  }
  ${GameAwardPointsOrVouchersFragmentDoc}
`;
export type ExecuteSpinWheelGameMutationFn = Apollo.MutationFunction<
  ExecuteSpinWheelGameMutation,
  ExecuteSpinWheelGameMutationVariables
>;

/**
 * __useExecuteSpinWheelGameMutation__
 *
 * To run a mutation, you first call `useExecuteSpinWheelGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExecuteSpinWheelGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [executeSpinWheelGameMutation, { data, loading, error }] = useExecuteSpinWheelGameMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useExecuteSpinWheelGameMutation(
  baseOptions?: Apollo.MutationHookOptions<ExecuteSpinWheelGameMutation, ExecuteSpinWheelGameMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ExecuteSpinWheelGameMutation, ExecuteSpinWheelGameMutationVariables>(
    ExecuteSpinWheelGameDocument,
    options,
  );
}
export type ExecuteSpinWheelGameMutationHookResult = ReturnType<typeof useExecuteSpinWheelGameMutation>;
export type ExecuteSpinWheelGameMutationResult = Apollo.MutationResult<ExecuteSpinWheelGameMutation>;
export type ExecuteSpinWheelGameMutationOptions = Apollo.BaseMutationOptions<
  ExecuteSpinWheelGameMutation,
  ExecuteSpinWheelGameMutationVariables
>;
export const GetDisplayCategoriesDocument = gql`
  query getDisplayCategories($filter: DisplayCategoryFilter) {
    displayCategories(filter: $filter) {
      edges {
        node {
          ...DisplayCategory
        }
      }
    }
  }
  ${DisplayCategoryFragmentDoc}
`;

/**
 * __useGetDisplayCategoriesQuery__
 *
 * To run a query within a React component, call `useGetDisplayCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDisplayCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDisplayCategoriesQuery, GetDisplayCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDisplayCategoriesQuery, GetDisplayCategoriesQueryVariables>(
    GetDisplayCategoriesDocument,
    options,
  );
}
export function useGetDisplayCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDisplayCategoriesQuery, GetDisplayCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDisplayCategoriesQuery, GetDisplayCategoriesQueryVariables>(
    GetDisplayCategoriesDocument,
    options,
  );
}
export type GetDisplayCategoriesQueryHookResult = ReturnType<typeof useGetDisplayCategoriesQuery>;
export type GetDisplayCategoriesLazyQueryHookResult = ReturnType<typeof useGetDisplayCategoriesLazyQuery>;
export type GetDisplayCategoriesQueryResult = Apollo.QueryResult<
  GetDisplayCategoriesQuery,
  GetDisplayCategoriesQueryVariables
>;
export const ProductCategoriesDocument = gql`
  query productCategories($filter: ProductCategoryFilter) {
    productCategories(filter: $filter) {
      edges {
        node {
          ...ProductCategory
        }
      }
    }
  }
  ${ProductCategoryFragmentDoc}
`;

/**
 * __useProductCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
}
export function useProductCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(
    ProductCategoriesDocument,
    options,
  );
}
export type ProductCategoriesQueryHookResult = ReturnType<typeof useProductCategoriesQuery>;
export type ProductCategoriesLazyQueryHookResult = ReturnType<typeof useProductCategoriesLazyQuery>;
export type ProductCategoriesQueryResult = Apollo.QueryResult<ProductCategoriesQuery, ProductCategoriesQueryVariables>;
export const CustomerLogoutDocument = gql`
  mutation customerLogout {
    logout
  }
`;
export type CustomerLogoutMutationFn = Apollo.MutationFunction<CustomerLogoutMutation, CustomerLogoutMutationVariables>;

/**
 * __useCustomerLogoutMutation__
 *
 * To run a mutation, you first call `useCustomerLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerLogoutMutation, { data, loading, error }] = useCustomerLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useCustomerLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<CustomerLogoutMutation, CustomerLogoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerLogoutMutation, CustomerLogoutMutationVariables>(CustomerLogoutDocument, options);
}
export type CustomerLogoutMutationHookResult = ReturnType<typeof useCustomerLogoutMutation>;
export type CustomerLogoutMutationResult = Apollo.MutationResult<CustomerLogoutMutation>;
export type CustomerLogoutMutationOptions = Apollo.BaseMutationOptions<
  CustomerLogoutMutation,
  CustomerLogoutMutationVariables
>;
export const CustomerUpdateDocument = gql`
  mutation customerUpdate($customer: CustomerUpdate!) {
    customer: customerUpdate(input: $customer) {
      uuid
      membership {
        ...Membership
      }
    }
  }
  ${MembershipFragmentDoc}
`;
export type CustomerUpdateMutationFn = Apollo.MutationFunction<CustomerUpdateMutation, CustomerUpdateMutationVariables>;

/**
 * __useCustomerUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerUpdateMutation, { data, loading, error }] = useCustomerUpdateMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useCustomerUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<CustomerUpdateMutation, CustomerUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CustomerUpdateMutation, CustomerUpdateMutationVariables>(CustomerUpdateDocument, options);
}
export type CustomerUpdateMutationHookResult = ReturnType<typeof useCustomerUpdateMutation>;
export type CustomerUpdateMutationResult = Apollo.MutationResult<CustomerUpdateMutation>;
export type CustomerUpdateMutationOptions = Apollo.BaseMutationOptions<
  CustomerUpdateMutation,
  CustomerUpdateMutationVariables
>;
export const CurrentCustomerDocument = gql`
  query currentCustomer {
    customer: currentCustomer {
      __typename
      uuid
      hasPasscode
      membership {
        ...Membership
      }
      communicationPreference {
        ...CommunicationPreference
      }
    }
  }
  ${MembershipFragmentDoc}
  ${CommunicationPreferenceFragmentDoc}
`;

/**
 * __useCurrentCustomerQuery__
 *
 * To run a query within a React component, call `useCurrentCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentCustomerQuery, CurrentCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentCustomerQuery, CurrentCustomerQueryVariables>(CurrentCustomerDocument, options);
}
export function useCurrentCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentCustomerQuery, CurrentCustomerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentCustomerQuery, CurrentCustomerQueryVariables>(CurrentCustomerDocument, options);
}
export type CurrentCustomerQueryHookResult = ReturnType<typeof useCurrentCustomerQuery>;
export type CurrentCustomerLazyQueryHookResult = ReturnType<typeof useCurrentCustomerLazyQuery>;
export type CurrentCustomerQueryResult = Apollo.QueryResult<CurrentCustomerQuery, CurrentCustomerQueryVariables>;
export const DeleteAccountDocument = gql`
  mutation deleteAccount {
    deleteAccount: scrubData
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const FuelAndConvenienceProfileCreateOrUpdateDocument = gql`
  mutation fuelAndConvenienceProfileCreateOrUpdate($input: FuelAndConvenienceProfileCreateOrUpdateInput!) {
    fuelAndConvenienceProfile: fuelAndConvenienceProfileCreateOrUpdate(input: $input) {
      memberDataShare
      preferredFuelProductGroup {
        id
      }
    }
  }
`;
export type FuelAndConvenienceProfileCreateOrUpdateMutationFn = Apollo.MutationFunction<
  FuelAndConvenienceProfileCreateOrUpdateMutation,
  FuelAndConvenienceProfileCreateOrUpdateMutationVariables
>;

/**
 * __useFuelAndConvenienceProfileCreateOrUpdateMutation__
 *
 * To run a mutation, you first call `useFuelAndConvenienceProfileCreateOrUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFuelAndConvenienceProfileCreateOrUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fuelAndConvenienceProfileCreateOrUpdateMutation, { data, loading, error }] = useFuelAndConvenienceProfileCreateOrUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFuelAndConvenienceProfileCreateOrUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FuelAndConvenienceProfileCreateOrUpdateMutation,
    FuelAndConvenienceProfileCreateOrUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FuelAndConvenienceProfileCreateOrUpdateMutation,
    FuelAndConvenienceProfileCreateOrUpdateMutationVariables
  >(FuelAndConvenienceProfileCreateOrUpdateDocument, options);
}
export type FuelAndConvenienceProfileCreateOrUpdateMutationHookResult = ReturnType<
  typeof useFuelAndConvenienceProfileCreateOrUpdateMutation
>;
export type FuelAndConvenienceProfileCreateOrUpdateMutationResult =
  Apollo.MutationResult<FuelAndConvenienceProfileCreateOrUpdateMutation>;
export type FuelAndConvenienceProfileCreateOrUpdateMutationOptions = Apollo.BaseMutationOptions<
  FuelAndConvenienceProfileCreateOrUpdateMutation,
  FuelAndConvenienceProfileCreateOrUpdateMutationVariables
>;
export const MembershipProductInterestsDocument = gql`
  query membershipProductInterests {
    customer: currentCustomer {
      __typename
      uuid
      membership {
        id
        productInterests {
          ...ProductCategory
        }
      }
    }
  }
  ${ProductCategoryFragmentDoc}
`;

/**
 * __useMembershipProductInterestsQuery__
 *
 * To run a query within a React component, call `useMembershipProductInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembershipProductInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembershipProductInterestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMembershipProductInterestsQuery(
  baseOptions?: Apollo.QueryHookOptions<MembershipProductInterestsQuery, MembershipProductInterestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MembershipProductInterestsQuery, MembershipProductInterestsQueryVariables>(
    MembershipProductInterestsDocument,
    options,
  );
}
export function useMembershipProductInterestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MembershipProductInterestsQuery, MembershipProductInterestsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MembershipProductInterestsQuery, MembershipProductInterestsQueryVariables>(
    MembershipProductInterestsDocument,
    options,
  );
}
export type MembershipProductInterestsQueryHookResult = ReturnType<typeof useMembershipProductInterestsQuery>;
export type MembershipProductInterestsLazyQueryHookResult = ReturnType<typeof useMembershipProductInterestsLazyQuery>;
export type MembershipProductInterestsQueryResult = Apollo.QueryResult<
  MembershipProductInterestsQuery,
  MembershipProductInterestsQueryVariables
>;
export const ProductInterestsReplaceDocument = gql`
  mutation productInterestsReplace($input: ProductInterestsReplaceInput!) {
    membership: productInterestsReplace(input: $input) {
      id
      productInterests {
        ...ProductCategory
      }
      fuelAndConvenienceProfile {
        pendingVerification
      }
    }
  }
  ${ProductCategoryFragmentDoc}
`;
export type ProductInterestsReplaceMutationFn = Apollo.MutationFunction<
  ProductInterestsReplaceMutation,
  ProductInterestsReplaceMutationVariables
>;

/**
 * __useProductInterestsReplaceMutation__
 *
 * To run a mutation, you first call `useProductInterestsReplaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductInterestsReplaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productInterestsReplaceMutation, { data, loading, error }] = useProductInterestsReplaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductInterestsReplaceMutation(
  baseOptions?: Apollo.MutationHookOptions<ProductInterestsReplaceMutation, ProductInterestsReplaceMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ProductInterestsReplaceMutation, ProductInterestsReplaceMutationVariables>(
    ProductInterestsReplaceDocument,
    options,
  );
}
export type ProductInterestsReplaceMutationHookResult = ReturnType<typeof useProductInterestsReplaceMutation>;
export type ProductInterestsReplaceMutationResult = Apollo.MutationResult<ProductInterestsReplaceMutation>;
export type ProductInterestsReplaceMutationOptions = Apollo.BaseMutationOptions<
  ProductInterestsReplaceMutation,
  ProductInterestsReplaceMutationVariables
>;
export const DeviceTokenRegisterDocument = gql`
  mutation deviceTokenRegister($input: DeviceTokenRegisterInput!) {
    deviceTokenRegister(input: $input)
  }
`;
export type DeviceTokenRegisterMutationFn = Apollo.MutationFunction<
  DeviceTokenRegisterMutation,
  DeviceTokenRegisterMutationVariables
>;

/**
 * __useDeviceTokenRegisterMutation__
 *
 * To run a mutation, you first call `useDeviceTokenRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceTokenRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceTokenRegisterMutation, { data, loading, error }] = useDeviceTokenRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeviceTokenRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<DeviceTokenRegisterMutation, DeviceTokenRegisterMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeviceTokenRegisterMutation, DeviceTokenRegisterMutationVariables>(
    DeviceTokenRegisterDocument,
    options,
  );
}
export type DeviceTokenRegisterMutationHookResult = ReturnType<typeof useDeviceTokenRegisterMutation>;
export type DeviceTokenRegisterMutationResult = Apollo.MutationResult<DeviceTokenRegisterMutation>;
export type DeviceTokenRegisterMutationOptions = Apollo.BaseMutationOptions<
  DeviceTokenRegisterMutation,
  DeviceTokenRegisterMutationVariables
>;
export const ActivateOfferDocument = gql`
  mutation activateOffer($input: OfferActivate!) {
    offerActivate(input: $input) {
      id
      activationStatus
    }
  }
`;
export type ActivateOfferMutationFn = Apollo.MutationFunction<ActivateOfferMutation, ActivateOfferMutationVariables>;

/**
 * __useActivateOfferMutation__
 *
 * To run a mutation, you first call `useActivateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateOfferMutation, { data, loading, error }] = useActivateOfferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateOfferMutation, ActivateOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateOfferMutation, ActivateOfferMutationVariables>(ActivateOfferDocument, options);
}
export type ActivateOfferMutationHookResult = ReturnType<typeof useActivateOfferMutation>;
export type ActivateOfferMutationResult = Apollo.MutationResult<ActivateOfferMutation>;
export type ActivateOfferMutationOptions = Apollo.BaseMutationOptions<
  ActivateOfferMutation,
  ActivateOfferMutationVariables
>;
export const ReturnPurchasedVoucherDocument = gql`
  mutation returnPurchasedVoucher($input: OfferPurchaseReturn!) {
    offerPurchaseReturn(input: $input) {
      createdAt
    }
  }
`;
export type ReturnPurchasedVoucherMutationFn = Apollo.MutationFunction<
  ReturnPurchasedVoucherMutation,
  ReturnPurchasedVoucherMutationVariables
>;

/**
 * __useReturnPurchasedVoucherMutation__
 *
 * To run a mutation, you first call `useReturnPurchasedVoucherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnPurchasedVoucherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnPurchasedVoucherMutation, { data, loading, error }] = useReturnPurchasedVoucherMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReturnPurchasedVoucherMutation(
  baseOptions?: Apollo.MutationHookOptions<ReturnPurchasedVoucherMutation, ReturnPurchasedVoucherMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReturnPurchasedVoucherMutation, ReturnPurchasedVoucherMutationVariables>(
    ReturnPurchasedVoucherDocument,
    options,
  );
}
export type ReturnPurchasedVoucherMutationHookResult = ReturnType<typeof useReturnPurchasedVoucherMutation>;
export type ReturnPurchasedVoucherMutationResult = Apollo.MutationResult<ReturnPurchasedVoucherMutation>;
export type ReturnPurchasedVoucherMutationOptions = Apollo.BaseMutationOptions<
  ReturnPurchasedVoucherMutation,
  ReturnPurchasedVoucherMutationVariables
>;
export const StoreDetailsDocument = gql`
  query storeDetails($id: ID!) {
    stores: locations(filter: { uuidEquals: $id }) {
      edges {
        node {
          uuid
          utcOffset
          openStatus
          address {
            ...StoreAddress
          }
          fuelGradePrices {
            ...StoreFuelGrade
          }
          hoursOfOperationGroup {
            ...StoreWorkingHours
          }
          phoneNumbers {
            ...StorePhoneNumber
          }
          attributes {
            ...StoreAttribute
          }
        }
      }
    }
  }
  ${StoreAddressFragmentDoc}
  ${StoreFuelGradeFragmentDoc}
  ${StoreWorkingHoursFragmentDoc}
  ${StorePhoneNumberFragmentDoc}
  ${StoreAttributeFragmentDoc}
`;

/**
 * __useStoreDetailsQuery__
 *
 * To run a query within a React component, call `useStoreDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStoreDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<StoreDetailsQuery, StoreDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreDetailsQuery, StoreDetailsQueryVariables>(StoreDetailsDocument, options);
}
export function useStoreDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoreDetailsQuery, StoreDetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreDetailsQuery, StoreDetailsQueryVariables>(StoreDetailsDocument, options);
}
export type StoreDetailsQueryHookResult = ReturnType<typeof useStoreDetailsQuery>;
export type StoreDetailsLazyQueryHookResult = ReturnType<typeof useStoreDetailsLazyQuery>;
export type StoreDetailsQueryResult = Apollo.QueryResult<StoreDetailsQuery, StoreDetailsQueryVariables>;
export const StoreLocationsDocument = gql`
  query storeLocations($after: String, $before: String, $filter: LocationFilter, $first: Int, $last: Int) {
    stores: locations(after: $after, before: $before, filter: $filter, first: $first, last: $last) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          uuid
          address {
            ...StoreAddress
          }
          fuelGradePrices {
            ...StoreFuelGrade
          }
        }
      }
    }
  }
  ${StoreAddressFragmentDoc}
  ${StoreFuelGradeFragmentDoc}
`;

/**
 * __useStoreLocationsQuery__
 *
 * To run a query within a React component, call `useStoreLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoreLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoreLocationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useStoreLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<StoreLocationsQuery, StoreLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreLocationsQuery, StoreLocationsQueryVariables>(StoreLocationsDocument, options);
}
export function useStoreLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StoreLocationsQuery, StoreLocationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreLocationsQuery, StoreLocationsQueryVariables>(StoreLocationsDocument, options);
}
export type StoreLocationsQueryHookResult = ReturnType<typeof useStoreLocationsQuery>;
export type StoreLocationsLazyQueryHookResult = ReturnType<typeof useStoreLocationsLazyQuery>;
export type StoreLocationsQueryResult = Apollo.QueryResult<StoreLocationsQuery, StoreLocationsQueryVariables>;
export const StorePumpInfoDocument = gql`
  query storePumpInfo($id: ID!) {
    stores: locations(filter: { uuidEquals: $id }) {
      edges {
        node {
          ...StorePumpInfo
        }
      }
    }
  }
  ${StorePumpInfoFragmentDoc}
`;

/**
 * __useStorePumpInfoQuery__
 *
 * To run a query within a React component, call `useStorePumpInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorePumpInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorePumpInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStorePumpInfoQuery(
  baseOptions: Apollo.QueryHookOptions<StorePumpInfoQuery, StorePumpInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StorePumpInfoQuery, StorePumpInfoQueryVariables>(StorePumpInfoDocument, options);
}
export function useStorePumpInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<StorePumpInfoQuery, StorePumpInfoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StorePumpInfoQuery, StorePumpInfoQueryVariables>(StorePumpInfoDocument, options);
}
export type StorePumpInfoQueryHookResult = ReturnType<typeof useStorePumpInfoQuery>;
export type StorePumpInfoLazyQueryHookResult = ReturnType<typeof useStorePumpInfoLazyQuery>;
export type StorePumpInfoQueryResult = Apollo.QueryResult<StorePumpInfoQuery, StorePumpInfoQueryVariables>;
export const GetTotalAvailableRollbackMoneyAmountDocument = gql`
  query getTotalAvailableRollbackMoneyAmount {
    customer: currentCustomer {
      uuid
      membership {
        id
        totalAvailableRollback {
          fuelProductGroup {
            id
          }
          locationUuid
          moneyAmount
        }
      }
    }
  }
`;

/**
 * __useGetTotalAvailableRollbackMoneyAmountQuery__
 *
 * To run a query within a React component, call `useGetTotalAvailableRollbackMoneyAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalAvailableRollbackMoneyAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalAvailableRollbackMoneyAmountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTotalAvailableRollbackMoneyAmountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTotalAvailableRollbackMoneyAmountQuery,
    GetTotalAvailableRollbackMoneyAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTotalAvailableRollbackMoneyAmountQuery, GetTotalAvailableRollbackMoneyAmountQueryVariables>(
    GetTotalAvailableRollbackMoneyAmountDocument,
    options,
  );
}
export function useGetTotalAvailableRollbackMoneyAmountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTotalAvailableRollbackMoneyAmountQuery,
    GetTotalAvailableRollbackMoneyAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTotalAvailableRollbackMoneyAmountQuery,
    GetTotalAvailableRollbackMoneyAmountQueryVariables
  >(GetTotalAvailableRollbackMoneyAmountDocument, options);
}
export type GetTotalAvailableRollbackMoneyAmountQueryHookResult = ReturnType<
  typeof useGetTotalAvailableRollbackMoneyAmountQuery
>;
export type GetTotalAvailableRollbackMoneyAmountLazyQueryHookResult = ReturnType<
  typeof useGetTotalAvailableRollbackMoneyAmountLazyQuery
>;
export type GetTotalAvailableRollbackMoneyAmountQueryResult = Apollo.QueryResult<
  GetTotalAvailableRollbackMoneyAmountQuery,
  GetTotalAvailableRollbackMoneyAmountQueryVariables
>;
export const GetTotalAvailableRollbackDocument = gql`
  query getTotalAvailableRollback($offerLimitsIncluded: Boolean = false, $offerVouchersIncluded: Boolean = false) {
    customer: currentCustomer {
      uuid
      membership {
        id
        totalAvailableRollback {
          fuelProductGroup {
            ...FuelProductGroup
          }
          locationUuid
          locationName
          moneyAmount
          offers {
            ...Offer
          }
        }
      }
    }
  }
  ${FuelProductGroupFragmentDoc}
  ${OfferFragmentDoc}
`;

/**
 * __useGetTotalAvailableRollbackQuery__
 *
 * To run a query within a React component, call `useGetTotalAvailableRollbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalAvailableRollbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalAvailableRollbackQuery({
 *   variables: {
 *      offerLimitsIncluded: // value for 'offerLimitsIncluded'
 *      offerVouchersIncluded: // value for 'offerVouchersIncluded'
 *   },
 * });
 */
export function useGetTotalAvailableRollbackQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTotalAvailableRollbackQuery, GetTotalAvailableRollbackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTotalAvailableRollbackQuery, GetTotalAvailableRollbackQueryVariables>(
    GetTotalAvailableRollbackDocument,
    options,
  );
}
export function useGetTotalAvailableRollbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTotalAvailableRollbackQuery, GetTotalAvailableRollbackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTotalAvailableRollbackQuery, GetTotalAvailableRollbackQueryVariables>(
    GetTotalAvailableRollbackDocument,
    options,
  );
}
export type GetTotalAvailableRollbackQueryHookResult = ReturnType<typeof useGetTotalAvailableRollbackQuery>;
export type GetTotalAvailableRollbackLazyQueryHookResult = ReturnType<typeof useGetTotalAvailableRollbackLazyQuery>;
export type GetTotalAvailableRollbackQueryResult = Apollo.QueryResult<
  GetTotalAvailableRollbackQuery,
  GetTotalAvailableRollbackQueryVariables
>;
export const LoyaltyActionHistoryDocument = gql`
  query loyaltyActionHistory(
    $size: Int!
    $transactionCursor: String
    $pointChangeCursor: String
    $skipPointChanges: Boolean = false
  ) {
    customer: currentCustomer {
      uuid
      membership {
        id
        transactions(first: $size, after: $transactionCursor) {
          edges {
            cursor
            node {
              ...LoyaltyActivateTransactionItem
            }
          }
        }
        pointChanges(first: $size, after: $pointChangeCursor) @skip(if: $skipPointChanges) {
          edges {
            cursor
            node {
              ...LoyaltyPointChangeItem
            }
          }
        }
      }
    }
  }
  ${LoyaltyActivateTransactionItemFragmentDoc}
  ${LoyaltyPointChangeItemFragmentDoc}
`;

/**
 * __useLoyaltyActionHistoryQuery__
 *
 * To run a query within a React component, call `useLoyaltyActionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyActionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoyaltyActionHistoryQuery({
 *   variables: {
 *      size: // value for 'size'
 *      transactionCursor: // value for 'transactionCursor'
 *      pointChangeCursor: // value for 'pointChangeCursor'
 *      skipPointChanges: // value for 'skipPointChanges'
 *   },
 * });
 */
export function useLoyaltyActionHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<LoyaltyActionHistoryQuery, LoyaltyActionHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoyaltyActionHistoryQuery, LoyaltyActionHistoryQueryVariables>(
    LoyaltyActionHistoryDocument,
    options,
  );
}
export function useLoyaltyActionHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LoyaltyActionHistoryQuery, LoyaltyActionHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoyaltyActionHistoryQuery, LoyaltyActionHistoryQueryVariables>(
    LoyaltyActionHistoryDocument,
    options,
  );
}
export type LoyaltyActionHistoryQueryHookResult = ReturnType<typeof useLoyaltyActionHistoryQuery>;
export type LoyaltyActionHistoryLazyQueryHookResult = ReturnType<typeof useLoyaltyActionHistoryLazyQuery>;
export type LoyaltyActionHistoryQueryResult = Apollo.QueryResult<
  LoyaltyActionHistoryQuery,
  LoyaltyActionHistoryQueryVariables
>;
export const TransactionHistoryDocument = gql`
  query transactionHistory(
    $size: Int!
    $cursor: String
    $primaryTransactionStatus: PRIMARY_TRANSACTION_STATUS
    $secondaryTransactionStatus: SECONDARY_TRANSACTION_STATUS
  ) {
    customer: currentCustomer {
      uuid
      transactions(
        first: $size
        after: $cursor
        filter: { primaryStatusEquals: $primaryTransactionStatus, secondaryStatusEquals: $secondaryTransactionStatus }
      ) {
        edges {
          cursor
          node {
            ...TransactionHistoryItem
          }
        }
      }
    }
  }
  ${TransactionHistoryItemFragmentDoc}
`;

/**
 * __useTransactionHistoryQuery__
 *
 * To run a query within a React component, call `useTransactionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionHistoryQuery({
 *   variables: {
 *      size: // value for 'size'
 *      cursor: // value for 'cursor'
 *      primaryTransactionStatus: // value for 'primaryTransactionStatus'
 *      secondaryTransactionStatus: // value for 'secondaryTransactionStatus'
 *   },
 * });
 */
export function useTransactionHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<TransactionHistoryQuery, TransactionHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionHistoryQuery, TransactionHistoryQueryVariables>(
    TransactionHistoryDocument,
    options,
  );
}
export function useTransactionHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TransactionHistoryQuery, TransactionHistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionHistoryQuery, TransactionHistoryQueryVariables>(
    TransactionHistoryDocument,
    options,
  );
}
export type TransactionHistoryQueryHookResult = ReturnType<typeof useTransactionHistoryQuery>;
export type TransactionHistoryLazyQueryHookResult = ReturnType<typeof useTransactionHistoryLazyQuery>;
export type TransactionHistoryQueryResult = Apollo.QueryResult<
  TransactionHistoryQuery,
  TransactionHistoryQueryVariables
>;
export const GetPaymentInstrumentsDocument = gql`
  query getPaymentInstruments {
    customer: currentCustomer {
      __typename
      uuid
      hasPasscode
      paymentWallet {
        ...PaymentInstrument
      }
    }
  }
  ${PaymentInstrumentFragmentDoc}
`;

/**
 * __useGetPaymentInstrumentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentInstrumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentInstrumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentInstrumentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentInstrumentsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPaymentInstrumentsQuery, GetPaymentInstrumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPaymentInstrumentsQuery, GetPaymentInstrumentsQueryVariables>(
    GetPaymentInstrumentsDocument,
    options,
  );
}
export function useGetPaymentInstrumentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentInstrumentsQuery, GetPaymentInstrumentsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPaymentInstrumentsQuery, GetPaymentInstrumentsQueryVariables>(
    GetPaymentInstrumentsDocument,
    options,
  );
}
export type GetPaymentInstrumentsQueryHookResult = ReturnType<typeof useGetPaymentInstrumentsQuery>;
export type GetPaymentInstrumentsLazyQueryHookResult = ReturnType<typeof useGetPaymentInstrumentsLazyQuery>;
export type GetPaymentInstrumentsQueryResult = Apollo.QueryResult<
  GetPaymentInstrumentsQuery,
  GetPaymentInstrumentsQueryVariables
>;
export const BaseOffersDocument = gql`
  query baseOffers(
    $first: Int!
    $deleted: Boolean!
    $publicationStatusEquals: PUBLICATION_STATUS_TYPE
    $visibleStatusEquals: OFFER_VISIBLE_STATUS
    $presentationTypeIncludes: [OFFER_PRESENTATION_TYPE!]
    $engagementTypeEquals: OFFER_ENGAGEMENT_TYPE!
    $offerLimitsIncluded: Boolean = false
    $offerVouchersIncluded: Boolean = false
  ) {
    customer: currentCustomer {
      uuid
      membership {
        id
        offers(
          first: $first
          filter: {
            deleted: $deleted
            visibleStatusEquals: $visibleStatusEquals
            publicationStatusEquals: $publicationStatusEquals
            presentationTypeIncludes: $presentationTypeIncludes
            engagementTypeEquals: $engagementTypeEquals
          }
        ) {
          edges {
            node {
              ...Offer
            }
          }
        }
      }
    }
  }
  ${OfferFragmentDoc}
`;

/**
 * __useBaseOffersQuery__
 *
 * To run a query within a React component, call `useBaseOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseOffersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      deleted: // value for 'deleted'
 *      publicationStatusEquals: // value for 'publicationStatusEquals'
 *      visibleStatusEquals: // value for 'visibleStatusEquals'
 *      presentationTypeIncludes: // value for 'presentationTypeIncludes'
 *      engagementTypeEquals: // value for 'engagementTypeEquals'
 *      offerLimitsIncluded: // value for 'offerLimitsIncluded'
 *      offerVouchersIncluded: // value for 'offerVouchersIncluded'
 *   },
 * });
 */
export function useBaseOffersQuery(baseOptions: Apollo.QueryHookOptions<BaseOffersQuery, BaseOffersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BaseOffersQuery, BaseOffersQueryVariables>(BaseOffersDocument, options);
}
export function useBaseOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BaseOffersQuery, BaseOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BaseOffersQuery, BaseOffersQueryVariables>(BaseOffersDocument, options);
}
export type BaseOffersQueryHookResult = ReturnType<typeof useBaseOffersQuery>;
export type BaseOffersLazyQueryHookResult = ReturnType<typeof useBaseOffersLazyQuery>;
export type BaseOffersQueryResult = Apollo.QueryResult<BaseOffersQuery, BaseOffersQueryVariables>;
export const GetOfferVoucherDocument = gql`
  query getOfferVoucher($offerId: ID!, $voucherFilter: VoucherFilter!) {
    customer: currentCustomer {
      uuid
      membership {
        id
        offers(filter: { idEquals: $offerId }) {
          edges {
            node {
              id
              vouchers(first: 1, filter: $voucherFilter) {
                edges {
                  node {
                    id
                    source {
                      __typename
                      ... on OfferPurchase {
                        purchasedWith {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetOfferVoucherQuery__
 *
 * To run a query within a React component, call `useGetOfferVoucherQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferVoucherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferVoucherQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      voucherFilter: // value for 'voucherFilter'
 *   },
 * });
 */
export function useGetOfferVoucherQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfferVoucherQuery, GetOfferVoucherQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfferVoucherQuery, GetOfferVoucherQueryVariables>(GetOfferVoucherDocument, options);
}
export function useGetOfferVoucherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOfferVoucherQuery, GetOfferVoucherQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfferVoucherQuery, GetOfferVoucherQueryVariables>(GetOfferVoucherDocument, options);
}
export type GetOfferVoucherQueryHookResult = ReturnType<typeof useGetOfferVoucherQuery>;
export type GetOfferVoucherLazyQueryHookResult = ReturnType<typeof useGetOfferVoucherLazyQuery>;
export type GetOfferVoucherQueryResult = Apollo.QueryResult<GetOfferVoucherQuery, GetOfferVoucherQueryVariables>;
export const OfferDocument = gql`
  query offer($offerId: ID!, $offerLimitsIncluded: Boolean = false, $offerVouchersIncluded: Boolean = false) {
    customer: currentCustomer {
      uuid
      membership {
        id
        offers(first: 1, filter: { idEquals: $offerId }) {
          edges {
            node {
              ...Offer
            }
          }
        }
      }
    }
  }
  ${OfferFragmentDoc}
`;

/**
 * __useOfferQuery__
 *
 * To run a query within a React component, call `useOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      offerLimitsIncluded: // value for 'offerLimitsIncluded'
 *      offerVouchersIncluded: // value for 'offerVouchersIncluded'
 *   },
 * });
 */
export function useOfferQuery(baseOptions: Apollo.QueryHookOptions<OfferQuery, OfferQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OfferQuery, OfferQueryVariables>(OfferDocument, options);
}
export function useOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OfferQuery, OfferQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OfferQuery, OfferQueryVariables>(OfferDocument, options);
}
export type OfferQueryHookResult = ReturnType<typeof useOfferQuery>;
export type OfferLazyQueryHookResult = ReturnType<typeof useOfferLazyQuery>;
export type OfferQueryResult = Apollo.QueryResult<OfferQuery, OfferQueryVariables>;
export const OffersDocument = gql`
  query offers(
    $first: Int!
    $deleted: Boolean!
    $publicationStatusEquals: PUBLICATION_STATUS_TYPE
    $visibleStatusEquals: OFFER_VISIBLE_STATUS
    $presentationTypeIncludes: [OFFER_PRESENTATION_TYPE!]
    $engagementTypeEquals: OFFER_ENGAGEMENT_TYPE
    $engagementTypeExcludes: [OFFER_ENGAGEMENT_TYPE!]
    $offerLimitsIncluded: Boolean = false
    $offerVouchersIncluded: Boolean = false
  ) {
    customer: currentCustomer {
      uuid
      membership {
        id
        offers(
          first: $first
          filter: {
            deleted: $deleted
            visibleStatusEquals: $visibleStatusEquals
            publicationStatusEquals: $publicationStatusEquals
            presentationTypeIncludes: $presentationTypeIncludes
            engagementTypeEquals: $engagementTypeEquals
            engagementTypeExcludes: $engagementTypeExcludes
          }
        ) {
          edges {
            node {
              ...Offer
            }
          }
        }
      }
    }
  }
  ${OfferFragmentDoc}
`;

/**
 * __useOffersQuery__
 *
 * To run a query within a React component, call `useOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      deleted: // value for 'deleted'
 *      publicationStatusEquals: // value for 'publicationStatusEquals'
 *      visibleStatusEquals: // value for 'visibleStatusEquals'
 *      presentationTypeIncludes: // value for 'presentationTypeIncludes'
 *      engagementTypeEquals: // value for 'engagementTypeEquals'
 *      engagementTypeExcludes: // value for 'engagementTypeExcludes'
 *      offerLimitsIncluded: // value for 'offerLimitsIncluded'
 *      offerVouchersIncluded: // value for 'offerVouchersIncluded'
 *   },
 * });
 */
export function useOffersQuery(baseOptions: Apollo.QueryHookOptions<OffersQuery, OffersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OffersQuery, OffersQueryVariables>(OffersDocument, options);
}
export function useOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OffersQuery, OffersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OffersQuery, OffersQueryVariables>(OffersDocument, options);
}
export type OffersQueryHookResult = ReturnType<typeof useOffersQuery>;
export type OffersLazyQueryHookResult = ReturnType<typeof useOffersLazyQuery>;
export type OffersQueryResult = Apollo.QueryResult<OffersQuery, OffersQueryVariables>;
export const RedeemPromocodeDocument = gql`
  mutation redeemPromocode($code: String!) {
    result: redeemPromocode(promocode: $code) {
      ... on RedeemPromocodeResultSuccess {
        __typename
        award {
          ...PromocodeRedeemedAward
        }
      }
      ... on RedeemPromocodeResultFail {
        __typename
        reason
      }
    }
  }
  ${PromocodeRedeemedAwardFragmentDoc}
`;
export type RedeemPromocodeMutationFn = Apollo.MutationFunction<
  RedeemPromocodeMutation,
  RedeemPromocodeMutationVariables
>;

/**
 * __useRedeemPromocodeMutation__
 *
 * To run a mutation, you first call `useRedeemPromocodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemPromocodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemPromocodeMutation, { data, loading, error }] = useRedeemPromocodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useRedeemPromocodeMutation(
  baseOptions?: Apollo.MutationHookOptions<RedeemPromocodeMutation, RedeemPromocodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RedeemPromocodeMutation, RedeemPromocodeMutationVariables>(
    RedeemPromocodeDocument,
    options,
  );
}
export type RedeemPromocodeMutationHookResult = ReturnType<typeof useRedeemPromocodeMutation>;
export type RedeemPromocodeMutationResult = Apollo.MutationResult<RedeemPromocodeMutation>;
export type RedeemPromocodeMutationOptions = Apollo.BaseMutationOptions<
  RedeemPromocodeMutation,
  RedeemPromocodeMutationVariables
>;
