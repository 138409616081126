import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PointsStrategy } from '@ocx/graphql';
import { LoadingButton } from '@ocx/ui';
import { SurveyAward } from '../../modules/survey/survey.types';
import { AwardType } from '../../modules/offers/award.types';
import { buttonTextMessages } from '../../modules/localization/button-text.messages';
import { awardPointsButtonTextMessages, awardVouchersButtonTextMessages } from '../../modules/survey/survey.messages';
import { formatPrice } from '../../lib/formatters/formatPrice';

type SurveySubmitButtonProps = {
  submitting: boolean;
  disabled: boolean;
  award: SurveyAward;
  pointsStrategy: PointsStrategy;
};

export const SurveySubmitButton: React.FC<SurveySubmitButtonProps> = ({
  award,
  submitting,
  disabled,
  pointsStrategy,
}) => {
  const intl = useIntl();

  const hasNoAwardOrPointsStrategyNotUsed =
    !award || (award.type === AwardType.Points && pointsStrategy === PointsStrategy.NotUsed);

  if (hasNoAwardOrPointsStrategyNotUsed) {
    return (
      <LoadingButton loading={submitting} disabled={disabled} type="submit" fullWidth>
        <FormattedMessage {...buttonTextMessages['button-text:submit']} />
      </LoadingButton>
    );
  }

  const isAwardVouchers = award.type === AwardType.Vouchers;
  const isAwardPointsWithPointsStrategyPoints =
    award.type === AwardType.Points && pointsStrategy === PointsStrategy.Points;
  const isAwardPointsWithPointsStrategyCents =
    award.type === AwardType.Points && pointsStrategy === PointsStrategy.Cents;

  return (
    <LoadingButton loading={submitting} disabled={disabled} type="submit" fullWidth>
      {isAwardPointsWithPointsStrategyPoints && (
        <FormattedMessage
          {...awardPointsButtonTextMessages[PointsStrategy.Points]}
          values={{ points: intl.formatNumber(award.points), pointsPlural: award.points }}
        />
      )}
      {isAwardPointsWithPointsStrategyCents && (
        <FormattedMessage
          {...awardPointsButtonTextMessages[PointsStrategy.Cents]}
          values={{ points: formatPrice(award.points), pointsPlural: award.points }}
        />
      )}
      {isAwardVouchers && <FormattedMessage {...awardVouchersButtonTextMessages['survey:submit-button:award:title']} />}
    </LoadingButton>
  );
};
