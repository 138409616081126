import React from 'react';
import { useIntl } from 'react-intl';
import howDoesItWorkIconSrc from '@ocx/buildtime-retailer-configuration/assets/how-does-it-work-icon.svg';

import { OfferCard } from '../../../../components/OfferCard';

export interface IHowDoesItWorkDynamicCardProps {
  loading?: boolean;
  onClick?(): void;
}

export const HowDoesItWorkDynamicCard: React.FC<IHowDoesItWorkDynamicCardProps> = ({ onClick, loading = false }) => {
  const intl = useIntl();
  return (
    <OfferCard
      variant="featured"
      disabled={false}
      loading={loading}
      onClick={loading ? undefined : onClick}
      marketingContent={{
        title: intl.formatMessage({
          id: 'howDoesItWorkOffer:title',
          defaultMessage: 'How does it work?',
          description: 'How does it work offer title',
        }),
        shortDescription: intl.formatMessage({
          id: 'howDoesItWorkOffer:subtitle',
          defaultMessage: 'Learn how to earn COCO Rewards points using your phone number',
          description: 'How does it work offer subtitle',
        }),
        imageSrc: howDoesItWorkIconSrc,
        surgeonGeneralsWarning: null,
      }}
      /* This is hardcoded offer which intent is to show "How does the offer work" */
      /* It doesn't require to have pointsStrategy value from config */
      accessoryType="none"
      label={null}
      pointsDiff={null}
      progress={null}
    />
  );
};
