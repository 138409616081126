import { Box } from '@mui/material';

const DEFAULT_WIDTH = 40;
const DEFAULT_HEIGHT = 40;

type TransactionIconBoxProps = {
  children: React.ReactNode;
  width?: number;
  height?: number;
  iconColor?: string;
  backgroundColor?: string;
};

export const TransactionIconBox = (props: TransactionIconBoxProps) => {
  const { width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT, iconColor, backgroundColor, children } = props;

  return (
    <Box
      display="flex"
      width={width}
      height={height}
      alignItems="center"
      justifyContent="center"
      borderRadius={2}
      color={iconColor ?? 'Receipts.iconColor'}
      bgcolor={backgroundColor ?? 'Receipts.iconBackground'}>
      {children}
    </Box>
  );
};
