import { Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { PaymentInstrumentIcon } from '@ocx-app/components/payment-instrument-icon';
import { Paper, SizingMultiplier, Typography } from '@ocx/ui';

import { getPaymentInstrumentLabel } from './receipt-payment-instrument-card.utils';
import { messages } from './receipt-payment-instrument-card.messages';
import { ReceiptPaymentInstrumentType } from '../../receipts.types';

type ReceiptPaymentInstrumentCardProps = {
  paymentType: ReceiptPaymentInstrumentType;
  lastFourDigits: string | null;
};

export const ReceiptPaymentInstrumentCard = ({ paymentType, lastFourDigits }: ReceiptPaymentInstrumentCardProps) => (
  <Stack component={Paper} direction="row" alignItems="center" gap={SizingMultiplier.sm}>
    <PaymentInstrumentIcon type={paymentType} size="medium" />
    <Stack flex={1} gap={SizingMultiplier.xs}>
      <Typography variant="subtitle1" color="text.primary">
        <FormattedMessage
          {...messages['receipt-payment-instrument-card:paid-with']}
          values={{ paymentType: getPaymentInstrumentLabel(paymentType) }}
        />
      </Typography>
      {typeof lastFourDigits === 'string' && (
        <Typography variant="body2" textAlign="left" color="text.disabled">
          •••• •••• •••• {lastFourDigits}
        </Typography>
      )}
    </Stack>
  </Stack>
);
