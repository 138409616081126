import { WebPlugin } from '@capacitor/core';
import * as Sentry from '@sentry/react';

import { wait } from '@ocx/utils';
import { loadScript } from '../../scriptLoader/scriptLoader';
import {
  IGooglePayPlugin,
  IIsReadyToPayRequestData,
  IPaymentRequestData,
  PaymentData,
  PaymentsClient,
  Environment,
  GOOGLE_PAY_ERROR_CODE,
  GOOGLE_PAY_WEB_ERROR_CODE,
} from './types';
import { GPAY_SCRIPT_URL } from './constants';

const MAX_LOAD_ATTEMPTS = 10;
const LOAD_ATTEMPT_TIMEOUT = 1000; // ms

const errorCodeWebNativeMapping: { [key in GOOGLE_PAY_WEB_ERROR_CODE]: GOOGLE_PAY_ERROR_CODE } = {
  [GOOGLE_PAY_WEB_ERROR_CODE.CANCELED]: GOOGLE_PAY_ERROR_CODE.CANCELED,
};

export class GooglePayPluginWeb extends WebPlugin implements IGooglePayPlugin {
  loaded: boolean = false;

  // is global.google.payments exists and available
  isGooglePaymentsApiAvailable: boolean = false;

  paymentsClient: PaymentsClient | null = null;

  environment: Environment | null = null;

  async init(environment: Environment) {
    // Handle reinitialization only on environment change
    if (environment === this.environment) {
      return;
    }
    this.environment = environment;

    try {
      await this.initLoadScript();
    } catch (e: unknown) {
      Sentry.captureException(e, {
        level: 'warning',
      });
    }

    try {
      await this.initPaymentsClient();
    } catch (e: unknown) {
      Sentry.captureException(e, {
        level: 'warning',
      });
    }
  }

  // region | Initiation Helpers
  private async initLoadScript() {
    if (!this.loaded) {
      await loadScript(GPAY_SCRIPT_URL);
      this.loaded = true;
    }
  }

  private async initPaymentsClient() {
    if (!this.loaded || !this.environment) {
      return;
    }

    this.isGooglePaymentsApiAvailable = Boolean(global.google?.payments);
    if (!this.isGooglePaymentsApiAvailable) {
      throw new Error('[GooglePay Web Plugin] GooglePay SDK is loaded but no api available');
    }
    this.paymentsClient = new global.google.payments.api.PaymentsClient({
      environment: this.environment,
    });
  }
  // endregion

  async isLoaded(loadAttempts = 0): Promise<boolean> {
    if (!this.loaded && loadAttempts < MAX_LOAD_ATTEMPTS) {
      await wait(LOAD_ATTEMPT_TIMEOUT);
      return this.isLoaded(loadAttempts + 1);
    }

    return this.loaded;
  }

  async isAvailable(loadAttempts = 0): Promise<boolean> {
    if (this.isGooglePaymentsApiAvailable && !this.paymentsClient && loadAttempts < MAX_LOAD_ATTEMPTS) {
      await wait(LOAD_ATTEMPT_TIMEOUT);
      return this.isAvailable(loadAttempts + 1);
    }

    return this.isGooglePaymentsApiAvailable;
  }

  async isReadyToPay(request: IIsReadyToPayRequestData) {
    const paymentsClient = await this.getPaymentsClient();

    if (paymentsClient === null) {
      return { success: false };
    }

    const { result } = await paymentsClient.isReadyToPay(request.isReadyToPayRequest);
    return { success: result };
  }

  async requestPay(request: IPaymentRequestData): Promise<PaymentData | null> {
    try {
      const paymentsClient = await this.getPaymentsClient();
      if (paymentsClient === null) {
        return null;
      }

      return await paymentsClient.loadPaymentData(request.paymentRequest);
    } catch (e: any) {
      if (e.statusCode === GOOGLE_PAY_WEB_ERROR_CODE.CANCELED) {
        // eslint-disable-next-line no-throw-literal
        throw { code: errorCodeWebNativeMapping[e.statusCode as GOOGLE_PAY_WEB_ERROR_CODE], message: e.message };
      }

      throw e;
    }
  }

  async getPaymentsClient(): Promise<PaymentsClient | null> {
    const isLoaded = await this.isLoaded();
    const isAvailable = await this.isAvailable();

    if (!isLoaded || !isAvailable) {
      return null;
    }

    return this.paymentsClient;
  }
}
