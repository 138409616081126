import { Box, Stack } from '@mui/material';
import noDataIcon from '@ocx/buildtime-retailer-configuration/assets/no-data.svg';
import { SizingMultiplier } from '../../sizing-multiplier.constant';
import { Typography } from '../../typography/typography.component';

export type NoDataViewProps = {
  title: string;
  description?: string;
};

export const NoDataView = ({ title, description }: NoDataViewProps) => {
  return (
    <Stack px={SizingMultiplier.md} alignItems="center" justifyContent="center" flexGrow={1}>
      <Box component="img" src={noDataIcon} alt={title} mb={SizingMultiplier.lg} width={120} height={120} />
      <Typography variant="h5" mb={SizingMultiplier.xs} color="textPrimary">
        {title}
      </Typography>
      <Typography variant="body1" textAlign="center" color="textSecondary">
        {description}
      </Typography>
    </Stack>
  );
};
