import { useMemo } from 'react';

import {
  PaymentAccountType,
  PaymentInstrumentFragment,
  PaymentInstrumentStatus,
  useGetPaymentInstrumentsQuery,
} from '@ocx/graphql';
import { useGetOnDevicePaymentInstruments } from './useGetOnDevicePaymentInstruments';
import {
  isApplePayFragment,
  isDefaultPaymentInstrument,
  isGooglePayFragment,
  sanitizePaymentInstrumentFragment,
} from './wallet.utils';
import { PaymentInstrument } from './wallet.types';
import { useConfiguration } from '../configuration/useConfiguration';
import { ON_DEVICE_ACCOUNT_TYPES, TOKENIZABLE_ACCOUNT_TYPES } from './wallet.constants';

export interface IUsePaymentInstrumentsReturns {
  loading: boolean;
  refetch(): Promise<any>;
  hasPasscode: boolean;
  paymentInstruments: PaymentInstrument[];
  defaultPaymentInstrumentId: string | null;
  isWalletSizeLimitReached: boolean;
  walletSizeLimit: number;
  isWalletEnabled: boolean;
}

type UsePaymentInstrumentsParams = {
  visibleAccountTypes?: Readonly<PaymentAccountType[]>;
  canBeUsedToPayEquals?: boolean;
};

export const usePaymentInstruments = (params: UsePaymentInstrumentsParams = {}): IUsePaymentInstrumentsReturns => {
  const {
    sizeLimit: walletSizeLimit,
    // isWalletEnabled === showSavedPaymentInstruments
    enabled: isWalletEnabled,
    prepaidAccountTypeEnabled,
  } = useConfiguration('wallet');

  const { visibleAccountTypes = [...ON_DEVICE_ACCOUNT_TYPES, ...TOKENIZABLE_ACCOUNT_TYPES] } = params;
  const availableAccountTypes = visibleAccountTypes.filter((type) => {
    if (type === 'PREPAID' && !prepaidAccountTypeEnabled) {
      return false;
    }
    return true;
  });
  const showOnDevicePaymentInstruments =
    visibleAccountTypes.includes(PaymentAccountType.ApplePay) ||
    visibleAccountTypes.includes(PaymentAccountType.GooglePay);

  const { data, loading, refetch } = useGetPaymentInstrumentsQuery({
    skip: !isWalletEnabled,
    fetchPolicy: 'cache-and-network',
  });

  const isApplePayDefault = !!data?.customer?.paymentWallet?.find((value) => isApplePayFragment(value))?.isDefault;
  const isGooglePayDefault = !!data?.customer?.paymentWallet?.find((value) => isGooglePayFragment(value))?.isDefault;
  const { loading: onDevicePaymentInstrumentsLoading, paymentInstruments: onDeviceInstruments } =
    useGetOnDevicePaymentInstruments({ skip: !showOnDevicePaymentInstruments, isApplePayDefault, isGooglePayDefault });

  const paymentInstruments = useMemo(() => {
    // Apple/Google Pay and wallet are disabled
    if (!showOnDevicePaymentInstruments && !isWalletEnabled) {
      return [];
    }

    if (!isWalletEnabled) {
      // Return on device
      return onDeviceInstruments;
    }

    const activeWalletInstruments =
      data?.customer?.paymentWallet?.filter(
        // TODO: graphql schema needs to be fixed for not having that many nullables
        // TODO: active should be an API side filter?
        (instrument): instrument is PaymentInstrumentFragment => {
          if (!instrument) {
            return false;
          }
          if (instrument.status !== PaymentInstrumentStatus.Active) {
            return false;
          }
          return !(isApplePayFragment(instrument) || isGooglePayFragment(instrument));
        },
      ) || [];

    const notOnDeviceInstruments = activeWalletInstruments
      .map((value) => {
        return sanitizePaymentInstrumentFragment(value, {
          availableAccountTypes,
        });
      })
      .sort((a, b) => a.order - b.order)
      // TODO: this should be handled as a disabled UI in the future
      .filter((instrument) => {
        if (typeof params.canBeUsedToPayEquals === 'boolean') {
          return params.canBeUsedToPayEquals === instrument.canBeUsedToPay;
        }
        return true;
      });
    if (!showOnDevicePaymentInstruments) {
      return notOnDeviceInstruments;
    }

    return [...onDeviceInstruments, ...notOnDeviceInstruments];
  }, [
    availableAccountTypes,
    data?.customer?.paymentWallet,
    isWalletEnabled,
    onDeviceInstruments,
    params.canBeUsedToPayEquals,
    showOnDevicePaymentInstruments,
  ]);

  const defaultPaymentInstrumentId: string | null = useMemo(() => {
    const defaultInstrument = paymentInstruments.find((instrument) => {
      return isDefaultPaymentInstrument(instrument) && instrument.canBeUsedToPay;
    });
    // Return Apple Pay for iOS (or Google Pay for Android) as default,
    // This is achieved by pushing ApplePay as a first item in useGetOnDevicePaymentInstruments
    return defaultInstrument?.uuid || onDeviceInstruments[0]?.uuid || null;
  }, [onDeviceInstruments, paymentInstruments]);

  return useMemo(() => {
    return {
      paymentInstruments,
      refetch,
      defaultPaymentInstrumentId,
      loading: loading || onDevicePaymentInstrumentsLoading,
      hasPasscode: data?.customer?.hasPasscode ?? false,
      isWalletSizeLimitReached: paymentInstruments.length - onDeviceInstruments.length >= walletSizeLimit,
      walletSizeLimit,
      isWalletEnabled,
    };
  }, [
    paymentInstruments,
    refetch,
    defaultPaymentInstrumentId,
    loading,
    onDevicePaymentInstrumentsLoading,
    data?.customer?.hasPasscode,
    onDeviceInstruments.length,
    walletSizeLimit,
    isWalletEnabled,
  ]);
};
