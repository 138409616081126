import React from 'react';
import { Box, CircularProgress, Divider, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@ocx/ui';

import { PaymentInstrumentType } from '@ocx/graphql';
import { SelectedPump } from './SelectedPump/SelectedPump';
import { PrintReceipt } from './PrintReceipt/PrintReceipt';
import { SizingMultiplier } from '../../../../../theme/constants';
import { PaymentMethodSelector } from './PaymentMethodSelector/PaymentMethodSelector';
import { PayButton } from './PayButton/PayButton';
import { PAYMENT_INSTRUMENT_TYPE } from '../../../../../lib/payments/payments.types';
import { PaymentInstrument } from '../../../../../modules/wallet/wallet.types';
import { ListSelector } from '../../../../../hooks/useListController';
import { IStoreCarWashOption } from '../../../../../modules/stores/types';
import { CarWashSelector } from './CarWashSelector/CarWashSelector';
import { ModalPaper } from '../../../../../components/modals';

export interface IPaymentSectionProps {
  selectedPump: string | null;
  shouldPrintReceipt: boolean;
  togglePrintReceipt(value?: boolean): void;
  handlePurchaseGooglePay(): void;
  handlePurchaseApplePay(): void;
  onAddPaymentMethodClick(): void;
  handlePurchaseCard(): void;
  deselectPump(): void;
  carWashListController: ListSelector<IStoreCarWashOption>;
  paymentInstrumentsListSelector: ListSelector<PaymentInstrument>;
  isPaymentInstrumentsLoading: boolean;
  selectedPaymentInstrumentType: PAYMENT_INSTRUMENT_TYPE | null;
  isCardsAvailable: boolean;
}

export const PaymentSection: React.FC<IPaymentSectionProps> = (props) => {
  const {
    selectedPump,
    shouldPrintReceipt,
    togglePrintReceipt,
    deselectPump,
    handlePurchaseGooglePay,
    handlePurchaseApplePay,
    onAddPaymentMethodClick,
    handlePurchaseCard,
    carWashListController,
    paymentInstrumentsListSelector,
    isPaymentInstrumentsLoading,
    selectedPaymentInstrumentType,
    isCardsAvailable,
  } = props;

  return (
    <ModalPaper sx={{ minHeight: 400 }}>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={SizingMultiplier.md}
        p={[SizingMultiplier.lg, SizingMultiplier.md]}>
        {selectedPump !== null && <SelectedPump pumpNumber={selectedPump} onClick={deselectPump} />}
        {carWashListController.listItems.length > 0 && (
          <CarWashSelector carWashListController={carWashListController} />
        )}
      </Stack>
      <Divider />
      <Box marginTop={SizingMultiplier.xl} marginBottom={SizingMultiplier.lg}>
        <Typography variant="h6" align="center" color="textSecondary">
          <FormattedMessage
            id="paymentSection:payingWithText"
            defaultMessage="Paying With"
            description="Pay at pump: 'paying with' section title text"
          />
        </Typography>
      </Box>
      {isPaymentInstrumentsLoading ? (
        <Box my={SizingMultiplier.lg} alignSelf="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {isCardsAvailable && (
            <Box px={SizingMultiplier.md} paddingBottom={SizingMultiplier.sm}>
              <PaymentMethodSelector
                paymentInstrumentsListSelector={paymentInstrumentsListSelector}
                onAddPaymentMethodClick={onAddPaymentMethodClick}
              />
            </Box>
          )}
          <Box px={SizingMultiplier.md} paddingBottom={SizingMultiplier.md}>
            <Typography variant="caption" color="textSecondary" component="p">
              <FormattedMessage
                id="payAtPump:preAuthMoneyInfoMessage"
                defaultMessage="You may see a temporary hold. You will only be charged for your actual purchase amount."
                description="Pay At Pump: pre-authorisation money info message"
              />
            </Typography>
            {paymentInstrumentsListSelector.selectedListItem?.paymentType === PaymentInstrumentType.Prepaid && (
              <Typography variant="caption" color="textSecondary" component="p" mt={SizingMultiplier.sm}>
                <FormattedMessage
                  id="payAtPump:prepaid-payment-instrument:info-message"
                  defaultMessage="The fuel pump will only authorize up to current available gift card amount shown."
                  description="Pay At Pump: info message for prepaid payment instrument"
                />
              </Typography>
            )}
          </Box>
          <Box px={SizingMultiplier.md} paddingBottom={SizingMultiplier.md}>
            <PrintReceipt
              checked={Boolean(shouldPrintReceipt || carWashListController.selectedListItem)}
              disabled={Boolean(carWashListController.selectedListItem)}
              onClick={togglePrintReceipt}
            />
          </Box>
          <Box px={SizingMultiplier.md} paddingBottom={SizingMultiplier.lg}>
            <PayButton
              onGooglePayButtonClick={handlePurchaseGooglePay}
              onApplePayButtonClick={handlePurchaseApplePay}
              onCardPayButtonClick={handlePurchaseCard}
              paymentInstruments={paymentInstrumentsListSelector.listItems}
              selectedPaymentInstrumentType={selectedPaymentInstrumentType}
              isListMode={!isCardsAvailable}
            />
          </Box>
        </>
      )}
    </ModalPaper>
  );
};
