import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ApolloError } from '@apollo/client';

import { ScreenLoader } from '@ocx/ui';

import { FuelingStatus } from './FuelingStatus';
import { FuelingStatusLayout } from './FuelingStatusLayout';
import { useFuelingStatusController } from './hooks/useFuelingStatusController';
import { useGetNavigationActions } from '../../../hooks/useGetNavigationActions';
import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';
import { statusToMessageMap } from './statusesToMessagesMap';
import { PrimaryInternalFuelingStatus } from '../../../modules/transaction/types';
import { fuelingFailedMessage, transactionStatusTimedOutMessage } from './messages';
import { ReplaceToHome } from '../../Home/ReplaceToHome';
import { useInfoActionSheet } from '../../../components/modals/InfoActionSheet';

export interface IFuelingState {
  status: PrimaryInternalFuelingStatus;
  label: string;
  secondaryLabel: string | null;
  canLeave: boolean;
}

export const FuelingStatusController: React.FC = () => {
  const intl = useIntl();
  const { triggerEvent } = useRudderStack();
  const { open: openInfoActionSheet } = useInfoActionSheet();
  const handleTransactionError = useCallback(
    (error?: ApolloError) =>
      openInfoActionSheet({
        title: intl.formatMessage(fuelingFailedMessage),
        description: error?.message,
        isServiceLinkEnabled: true,
      }),
    [intl, openInfoActionSheet],
  );
  const { status, loading, isTransactionStatusTimedOut, transactionId, pumpNumber } = useFuelingStatusController({
    onError: handleTransactionError,
  });
  const { replaceToReceipt, pushToHome } = useGetNavigationActions();
  const navigateToReceipt = useCallback(() => {
    replaceToReceipt(transactionId);
    triggerEvent('pay_at_pump_view_receipt');
  }, [replaceToReceipt, transactionId, triggerEvent]);
  const navigateToHome = useCallback(() => pushToHome(), [pushToHome]);

  const state = useMemo<IFuelingState | null>(() => {
    if (!status) {
      return null;
    }

    const { primary, secondary } = statusToMessageMap[status];

    const label = intl.formatMessage(
      primary,
      status === PrimaryInternalFuelingStatus.Authorizing ? { pumpNumber } : undefined,
    );

    if (isTransactionStatusTimedOut) {
      return {
        status: PrimaryInternalFuelingStatus.Unknown,
        label,
        secondaryLabel: intl.formatMessage(transactionStatusTimedOutMessage),
        canLeave: true,
      };
    }

    return {
      status,
      label,
      secondaryLabel: secondary ? intl.formatMessage(secondary) : null,
      canLeave: [
        PrimaryInternalFuelingStatus.CompletedSuccessfully,
        PrimaryInternalFuelingStatus.CompletedUnsuccessfully,
      ].includes(status),
    };
  }, [intl, isTransactionStatusTimedOut, pumpNumber, status]);

  if (!state && loading) {
    return (
      <FuelingStatusLayout>
        <ScreenLoader />
      </FuelingStatusLayout>
    );
  }

  if (!state) {
    return <ReplaceToHome />;
  }

  return (
    <FuelingStatusLayout>
      <FuelingStatus
        status={state.status}
        label={state.label}
        secondaryLabel={state.secondaryLabel}
        navigateToReceipt={navigateToReceipt}
        navigateToHome={navigateToHome}
      />
    </FuelingStatusLayout>
  );
};
