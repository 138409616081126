import { Box } from '@mui/material';
import React from 'react';

import { SizingMultiplier, Typography, TypographyProps } from '@ocx/ui';

type TwoColsTypographyProps = Omit<TypographyProps, 'children' | 'title'> & {
  title: React.ReactNode;
  value: React.ReactNode;
};
export const TwoColsTypography = ({ title, value, ...restProps }: TwoColsTypographyProps) => {
  return (
    <Typography display="flex" justifyContent="space-between" gap={SizingMultiplier.md} {...restProps}>
      {title}
      <Box component="span" ml="auto">
        {value}
      </Box>
    </Typography>
  );
};
