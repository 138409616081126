import { ReactNode } from 'react';
import { defineMessage } from 'react-intl';
import { Stack } from '@mui/material';

import { PoweredByOpenCommerce, NavigationBar, Page, PageContent, SizingMultiplier } from '@ocx/ui';
import { VendorLogo } from '@ocx-app/components/Layout/NavigationBar/VendorLogo/VendorLogo';
import { BackButton } from '@ocx-app/components/Layout/NavigationBar/BackButton/BackButton';
import { HtmlTitle } from '@ocx-app/components/router/HtmlTitle';

export type ProfileLayoutProps = {
  children: ReactNode;
};

export const ProfileLayout = ({ children }: ProfileLayoutProps) => {
  return (
    <Page>
      <HtmlTitle
        title={defineMessage({
          id: 'profile:htmlTitle',
          defaultMessage: 'Profile',
          description: 'HTML title tag for Profile Page',
        })}
      />
      <NavigationBar left={<BackButton />} center={<VendorLogo />} />
      <PageContent>
        <Stack spacing={SizingMultiplier.sm} px={SizingMultiplier.sm} pt={SizingMultiplier.sm}>
          {children}
        </Stack>
        <PoweredByOpenCommerce />
      </PageContent>
    </Page>
  );
};
