import { Box, SxProps } from '@mui/material';

import React, { useEffect, useMemo } from 'react';

import { Privacy } from '@ocx-app/lib/privacy/Privacy';
import { isAndroidPlatform } from '@ocx/utils-device';
import { useIsAppFocused } from './use-is-app-focused.hook';

export const useBlurOverlayStylesWhenAppIsInactive = (): SxProps => {
  const isAppFocused = useIsAppFocused();
  useEffect(() => {
    const isAndroid = isAndroidPlatform();
    // Ensures screen is blurred when multitasking on Android, since pressing the home button may capture the UI state.
    // iOS handles this natively, so the effect is only needed for Android.
    if (isAndroid) {
      Privacy.enable();
    }

    return () => {
      if (isAndroid) {
        Privacy.disable();
      }
    };
  }, []);

  return useMemo(() => ({ filter: isAppFocused ? 'none' : 'blur(10px)', transition: 'filter 0.3s' }), [isAppFocused]);
};

type BlurOverlayWhenAppIsInactiveProps = {
  children: React.ReactNode;
};

export const BlurOverlayWhenAppIsInactive = ({ children }: BlurOverlayWhenAppIsInactiveProps) => {
  const blurOverlayStyles = useBlurOverlayStylesWhenAppIsInactive();

  return <Box sx={blurOverlayStyles}>{children}</Box>;
};
