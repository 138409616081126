import { ReactNode } from 'react';

import { TransactionPaymentInstrumentType } from '@ocx-app/components/payment-instrument-icon';
import { FuelReceipt, ItemPrice, ReceiptLine, StoreAddressFragment, Transaction } from '@ocx/graphql';
import { PaymentInstrument } from '../wallet/wallet.types';

export interface IReceiptCarWash {
  name: string;
  code: string;
  price: number;
}
export interface IReceipt {
  total: number;
  receiptLines: string[];
  gallons: number | null;
  pricePerGallon: number | null;
  fuelTotal: number | null;
  tax: number | null;
  date: string;
  carWash: IReceiptCarWash | null;
  address: string;
  locationName: string | null;
  paymentInstrument: PaymentInstrument | null;
}

export interface IReceiptOption {
  title: string;
  value?: string;
  icon?: ReactNode;
}

export type SanitizedReceipt = Pick<
  Transaction,
  'uuid' | 'primaryStatus' | 'secondaryStatus' | 'fuelingPosition' | 'tax' | 'paymentInstrument'
> & {
  amount: ItemPrice;
  completedAt: string;
  fuelReceipt: FuelReceipt | null;
  location: { address: StoreAddressFragment; name: string | null };
  receiptLines: ReceiptLine[];
};

export type ActivateLineItemPromotion = {
  offerId: string | null;
  reason: string;
  amount: number | null;
};

export interface IActivateTransactionReceiptLineItem {
  id: string;
  name: string | null;
  amount: number | null;
  quantity: number | null;
  promotions: ActivateLineItemPromotion[] | null;
  externalPromotionsTotalDiscount: number | null;
  fuelGradeId: string | null;
}

export type ActivateTransactionReceipt = {
  id: string;
  date: string;
  address: string | null;
  locationName: string | null;
  totalTax: number | null;
  totalAmount: number;
  subtotalAmount: number | null;
  totalAmountWithoutDiscounts: number;
  totalDiscountAmount: number | null;
  lineItems: IActivateTransactionReceiptLineItem[];
  pointChangeItems: ActivateTransactionLoyaltyItem[];
  numberOfAppliedDiscountOffers: number;
  paymentInstrumentType: TransactionPaymentInstrumentType | null;
};

export type ActivateTransactionLoyaltyItem = {
  pointsDiff: number;
  offersNumber: number;
  type: ActivateTransactionLoyaltyItemType;
};

export type ActivateTransactionLoyaltyItemType = 'spent' | 'earned' | 'refund';

export enum PrimaryInternalFuelingStatus {
  Authorizing = 'Authorizing',
  BeginFueling = 'BeginFueling',
  Fueling = 'Fueling',
  Cancelling = 'Cancelling',
  CompletedCancelled = 'CompletedCancelled',
  CompletedSuccessfully = 'CompletedSuccessfully',
  CompletedUnsuccessfully = 'CompletedUnsuccessfully',
  Unknown = 'Unknown',
}

export type PointChangeReceipt = {
  id: string;
  quantity: number;
  date: string;
  reason: string;
};
export type TransformLineItem = {
  id: string;
  amount: number | null;
  name: string | null;
  quantity: number | null;
  fuelGradeId: string | null;
  promotions: TransformedLineItemPromotion[];
  externalPromotionsTotalDiscount: number | null;
};

export type TransformedLineItemPromotion = {
  offerId: string | null;
  reason: string;
  amount: number | null;
};
