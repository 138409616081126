import { OfferActivityLogChannelType, OfferActivityLogType, useLogOfferActivityMutation } from '@ocx/graphql';
import { logInDev } from '../../lib/logger/logger';

export const useLogOfferActivity = () => {
  const [logActivity] = useLogOfferActivityMutation();

  /**
   * Log the card view event.
   */
  const logImpression = (
    offerId: string,
    externalCampaignId?: string | null,
    bannerConfigurationUuid?: string | null,
  ) => {
    logActivity({
      variables: {
        input: {
          channelType: OfferActivityLogChannelType.App,
          activityAt: new Date(),
          activityType: OfferActivityLogType.Impression,
          externalCampaignId: externalCampaignId || undefined,
          bannerConfigurationUuid: bannerConfigurationUuid || undefined,
          offerId,
        },
      },
    }).catch(logInDev);
  };

  /**
   * Log the card click event.
   */
  const logClick = (offerId: string, externalCampaignId?: string | null, bannerConfigurationUuid?: string | null) => {
    logActivity({
      variables: {
        input: {
          channelType: OfferActivityLogChannelType.App,
          activityAt: new Date(),
          activityType: OfferActivityLogType.Click,
          externalCampaignId: externalCampaignId || undefined,
          bannerConfigurationUuid: bannerConfigurationUuid || undefined,
          offerId,
        },
      },
    }).catch(logInDev);
  };

  return {
    logImpression,
    logClick,
  };
};
