import { Radio, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '@ebay/nice-modal-react';

import { PaymentInstrumentIcon } from '@ocx-app/components/payment-instrument-icon';
import { Typography, List, ListItemAction, ListItemButton, ListItemStartIcon, ListItemText, Button } from '@ocx/ui';

import { ModalDrawer } from '../components';
import { SizingMultiplier } from '../../../theme/constants';
import { WalletSizeLimitHint } from '../../WalletSizeLimitHint/WalletSizeLimitHint';
import { useConfiguration } from '../../../modules/configuration/useConfiguration';
import { getPaymentInstrumentListItemInfo } from '../../../modules/wallet/wallet.utils';
import { messages as walletMessages, paymentInstrumentBalanceLabel } from '../../../modules/wallet/wallet.messages';
import { messages } from './payment-method-selector-popup.messages';
import { PaymentMethodSelectorPopupProps } from './payment-method-selector-popup.types';

export const PaymentMethodSelectorPopupController: React.FC<PaymentMethodSelectorPopupProps> = (props) => {
  const walletSizeLimit = useConfiguration('wallet.sizeLimit');
  const modal = useModal();
  const { paymentInstrumentsListSelector, onAddPaymentMethodClick } = props;
  const { listItems, selectedListItem, handleSelect } = paymentInstrumentsListSelector;

  const paymentInstruments = useMemo(
    () =>
      listItems.map((instrument) => {
        const isSelected = instrument.uuid === selectedListItem?.uuid;
        const { displayName, formattedBalance } = getPaymentInstrumentListItemInfo(instrument);

        return (
          <ListItemButton
            key={instrument.uuid}
            onClick={() => {
              handleSelect(instrument);
              modal.hide();
            }}
            selected={isSelected}>
            {instrument.paymentType && (
              <ListItemStartIcon>
                <PaymentInstrumentIcon type={instrument.paymentType} />
              </ListItemStartIcon>
            )}
            <ListItemText
              secondary={
                formattedBalance && (
                  <FormattedMessage
                    {...paymentInstrumentBalanceLabel['payments:payment-instrument:balance']}
                    values={{ balance: formattedBalance }}
                  />
                )
              }>
              {displayName}
            </ListItemText>
            <ListItemAction>
              <Radio checked={isSelected} />
            </ListItemAction>
          </ListItemButton>
        );
      }),
    [handleSelect, listItems, modal, selectedListItem?.uuid],
  );

  return (
    <ModalDrawer open={modal.visible} onClose={modal.hide} onExited={modal.remove}>
      <Stack spacing={SizingMultiplier.md} p={SizingMultiplier.lg}>
        <Typography variant="subtitle2" color="textSecondary">
          <FormattedMessage {...messages['payment-method-selector-popup:title-text']} />
        </Typography>
        <List disabledPaddings>{paymentInstruments}</List>
        <Button onClick={onAddPaymentMethodClick} fullWidth>
          <FormattedMessage {...walletMessages['wallet:add-payment-instrument-button:title']} />
        </Button>
        <WalletSizeLimitHint sizeLimit={walletSizeLimit} />
      </Stack>
    </ModalDrawer>
  );
};
