import { defineMessages } from 'react-intl';

export const receiptLabels = defineMessages({
  'receipt-labels:title': {
    id: 'receipt-labels:title',
    defaultMessage: 'Your Receipt',
    description: 'Receipt body title',
  },
  'receipt-labels:tax': {
    id: 'receipt-labels:tax',
    defaultMessage: 'Tax',
    description: 'Receipt body footer tax label',
  },
  'receipt-labels:subtotal': {
    id: 'receipt-labels:subtotal',
    defaultMessage: 'Subtotal',
    description: 'Receipt body subtotal label',
  },
  'receipt-labels:total': {
    id: 'receipt-labels:total',
    defaultMessage: 'Total',
    description: 'Receipt body footer total label',
  },
  'receipt-labels:quantity': {
    id: 'receipt-labels:quantity',
    defaultMessage: 'Quantity',
    description: 'Receipt body quantity label',
  },
  'receipt-labels:total-discount': {
    id: 'receipt-labels:total-discount',
    defaultMessage: 'Total Savings',
    description: 'Receipt body total discount label',
  },
  'receipt-labels:line-item:total-external-discount': {
    id: 'receipt-labels:line-item:total-external-discount',
    defaultMessage: 'Other savings',
    description: 'Line item total external discount label',
  },
  'receipt-labels:line-item:promotion-offer:default-label': {
    id: 'receipt-labels:line-item:promotion-offer:default-label',
    defaultMessage: 'Loyalty Offer',
    description: 'Line item promotion offer default label',
  },
});
