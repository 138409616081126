import * as Sentry from '@sentry/react';

import { wait } from '@ocx/utils';
import { loadScript } from '../../../../scriptLoader/scriptLoader';
import { logInDev } from '../../../../logger/logger';
import { getRandomEProtectId } from './worldpay.utils';
import { IeProtectOptions, IeProtectTokenizationResponse, EProtectStatus, EProtectReportGroup } from './worldpay.types';
import { LOADING_ATTEMPT_TIMEOUT, MAX_LOADING_ATTEMPTS } from './worldpay.constants';
import { ApplePayPaymentToken } from '../../../ApplePay/types';

export class EProtect {
  private static status: EProtectStatus = EProtectStatus.INITIAL;

  static options: { paypageId: null | string; sdkApiUrl: null | string; sdkUrl: null | string } = {
    paypageId: null,
    sdkApiUrl: null,
    sdkUrl: null,
  };

  static setOptions(options: IeProtectOptions) {
    this.options = options;
  }

  static async load(): Promise<void> {
    try {
      if (this.status !== EProtectStatus.INITIAL) {
        return;
      }
      if (!this.options.sdkUrl) {
        return;
      }
      this.status = EProtectStatus.LOADING;

      // region | Loading dependencies
      await loadScript(this.options.sdkUrl);
      this.status = EProtectStatus.LOADED;
      // endregion

      if (window.eProtect !== undefined) {
        this.status = EProtectStatus.READY;
      } else {
        this.status = EProtectStatus.FAILED;
      }
    } catch (e: any) {
      this.status = EProtectStatus.FAILED;
      logInDev(e);
      Sentry.captureMessage('Error loading eProtect SDK', {
        level: 'error',
        extra: { message: e?.message || 'no info' },
      });
    }
  }

  static async waitIsAvailable(attempts = 0): Promise<boolean> {
    await this.load();
    if (this.status === EProtectStatus.READY) {
      return true;
    }
    if (this.status === EProtectStatus.FAILED) {
      throw new Error('eProtect is not available');
    }
    if (attempts >= MAX_LOADING_ATTEMPTS) {
      throw new Error('eProtect not loaded. Max attempts limit reached');
    }
    await wait(LOADING_ATTEMPT_TIMEOUT);
    return this.waitIsAvailable(attempts + 1);
  }

  static async tokenize(params: {
    cardNumber: string;
    cvv: string;
    reportGroup: EProtectReportGroup;
  }): Promise<IeProtectTokenizationResponse> {
    await this.waitIsAvailable();

    const card = document.createElement('input');
    card.value = params.cardNumber;
    const cvv = document.createElement('input');
    cvv.value = params.cvv;

    const eProtectRequest = {
      paypageId: this.options.paypageId,
      url: this.options.sdkApiUrl,
      id: getRandomEProtectId(),
      orderId: getRandomEProtectId(),
      reportGroup: params.reportGroup,
      checkoutIdMode: false,
    };

    return new Promise<IeProtectTokenizationResponse>((resolve, reject) => {
      const { eProtect: EProtectSDK } = window;
      new EProtectSDK().sendToEprotect(
        eProtectRequest,
        {
          cvv2: cvv,
          accountNum: card,
        },
        resolve, // on success
        reject, // on error
        reject, // on timeout
      );
    });
  }

  /**
   * Single method for Native and Web platforms
   * Will be split in future if Native/Web require different approaches
   */
  static async tokenizeApplePay(
    paymentData: ApplePayPaymentToken['paymentData'],
    params: { reportGroup: EProtectReportGroup },
  ): Promise<IeProtectTokenizationResponse> {
    await this.waitIsAvailable();

    const eProtectRequest: {
      applepay: ApplePayPaymentToken['paymentData'];
      checkoutIdMode: boolean;
      gateway: null | string;
      id: string;
      orderId: string;
      paypageId: null | string;
      reportGroup: string;
      url: null | string;
    } = {
      applepay: paymentData,
      checkoutIdMode: false,
      gateway: 'apple',
      id: getRandomEProtectId(),
      orderId: getRandomEProtectId(),
      paypageId: this.options.paypageId,
      reportGroup: params.reportGroup,
      url: this.options.sdkApiUrl,
    };

    return new Promise<IeProtectTokenizationResponse>((resolve, reject) => {
      const { eProtect: EProtectSDK } = window;
      new EProtectSDK().sendToEprotect(
        eProtectRequest,
        {},
        resolve, // on success
        reject, // on error
        reject, // on timeout
      );
    });
  }
}
