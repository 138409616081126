import { defineMessages } from 'react-intl';

import { PaymentAccountType, PaymentInstrumentType } from '@ocx/graphql';
import { PaymentInstrument, PaymentInstrumentOrder } from './wallet.types';

export const ON_DEVICE_ACCOUNT_TYPES = [PaymentAccountType.ApplePay, PaymentAccountType.GooglePay] as const;
export const TOKENIZABLE_ACCOUNT_TYPES = [PaymentAccountType.Credit, PaymentAccountType.Prepaid] as const;

export const digitalWalletInstrumentsTitle = defineMessages({
  [PaymentInstrumentType.ApplePay]: {
    id: 'paymentMethodSelect:ApplePayTitle',
    defaultMessage: 'Apple Pay',
    description: 'Apple Pay Instrument Type title',
  },
  [PaymentInstrumentType.GooglePay]: {
    id: 'paymentMethodSelect:GooglePayTitle',
    defaultMessage: 'Google Pay',
    description: 'Google Pay Instrument Type title',
  },
});

export const googlePayPaymentInstrument: PaymentInstrument = {
  uuid: 'GOOGLE_PAY',
  accountType: PaymentAccountType.GooglePay,
  alias: 'GOOGLE_PAY',
  paymentType: PaymentInstrumentType.GooglePay,
  providerNickName: 'GOOGLE_PAY',
  address: null,
  accountNumber: null,
  last4: null,
  displayName: null,
  balance: null,
  canBeDeleted: false,
  // It's verified by a payment module and button is visible only if it's possible to use Google Pay
  canBeUsedToPay: true,
  isDefault: false,
  order: PaymentInstrumentOrder.Priority,
};

export const applePayPaymentInstrument: PaymentInstrument = {
  uuid: 'APPLE_PAY',
  accountType: PaymentAccountType.ApplePay,
  alias: 'APPLE_PAY',
  providerNickName: 'APPLE_PAY',
  paymentType: PaymentInstrumentType.ApplePay,
  address: null,
  accountNumber: null,
  last4: null,
  displayName: null,
  balance: null,
  canBeDeleted: false,
  // It's verified by a payment module and button is visible only if it's possible to use Apple Pay
  canBeUsedToPay: true,
  isDefault: false,
  order: PaymentInstrumentOrder.Priority,
};

export const IM_MEMORY_PIN_CODE_STORAGE_KEY = 'WALLET_PIN_CODE' as const;
