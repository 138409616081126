import { useCallback, useState } from 'react';

import { useShowGraphqlUserVisibleErrorSnackbar } from '@ocx-app/hooks/use-show-graphql-user-visible-error-snackbar.hook';
import { CardFormFields } from '../vault-card.types';
import { logInDev } from '../../../lib/logger/logger';
import { useRudderStack } from '../../../lib/rudderStack/useRudderStack';
import { useCreatePaymentInstrument } from './useCreatePaymentInstrument';
import { useMembership } from '../../../modules/membership/use-membership.hook';
import { useWalletPinCodePopup } from '../../../components/modals/WalletPinCodePopup';
import { mapFormFieldsToTokenizationInput } from '../vault-card.utils';

export type UseAddCardControllerParams = {
  onCardAdded?(params: { paymentInstrumentId: string | null }): void;
};

export const useAddCardController = (params: UseAddCardControllerParams) => {
  const showGraphqlUserVisibleErrorSnackbar = useShowGraphqlUserVisibleErrorSnackbar();

  const { triggerEvent } = useRudderStack();
  const { open: openWalletPinCodePopup } = useWalletPinCodePopup();
  const [createTokenLoading, setCreateTokenLoading] = useState(false);
  const { loading, membership } = useMembership();
  const { onCardAdded } = params;

  const createPaymentInstrument = useCreatePaymentInstrument();

  const handleSubmit = useCallback(
    async (values: CardFormFields) => {
      try {
        // In case if user HAS passcode, we should not ask to enter it
        if (!membership.hasPasscode) {
          const pinCode = await openWalletPinCodePopup();
          // Handle failed or cancelled pin creation
          if (pinCode === null) {
            return;
          }
        }
        setCreateTokenLoading(true);
        const { paymentInstrumentId } = await createPaymentInstrument(mapFormFieldsToTokenizationInput(values));
        triggerEvent('add_payment');
        onCardAdded?.({ paymentInstrumentId });
      } catch (error) {
        logInDev(error);
        showGraphqlUserVisibleErrorSnackbar(error);
      } finally {
        setCreateTokenLoading(false);
      }
    },
    [
      createPaymentInstrument,
      membership.hasPasscode,
      onCardAdded,
      openWalletPinCodePopup,
      showGraphqlUserVisibleErrorSnackbar,
      triggerEvent,
    ],
  );

  const cardholderName =
    membership.firstName && membership.lastName ? `${membership.firstName} ${membership.lastName}` : '';

  return {
    loading: loading || createTokenLoading,
    cardholderName,
    handleSubmit,
  };
};
