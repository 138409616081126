import React from 'react';
import { CircularProgress, Grow, Stack, Box, alpha } from '@mui/material';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Typography, LoadingButton, LoadingButtonProps } from '@ocx/ui';

import { MarketingContent } from '../../modules/offers/offer.types';
import { SizingMultiplier } from '../../theme/constants';
import { OfferDetailsTermsAndDisclaimer } from './OfferDetailsTermsAndDisclaimer';
import { isOfferDisclaimerAvailable } from '../../modules/offers/marketing-content.utils';
import { OfferDetailsMedia } from './OfferDetailsMedia';
import { SurgeonWarning } from '../SurgeonWarning/SurgeonWarning';

export type OfferDetailsAction = {
  title: MessageDescriptor;
  buttonVariant?: LoadingButtonProps['variant'];
  isExecuting?: boolean;
  onClick: () => unknown;
};

export type OfferDetailsProps = {
  isLoading?: boolean;
  marketingContent: MarketingContent;
  action?: OfferDetailsAction | null;
  variant?: 'default' | 'banner' | 'big-image';
  note?: string | null;
};

export const OfferDetails: React.FC<OfferDetailsProps> = ({
  isLoading = false,
  marketingContent,
  action,
  variant = 'default',
  note,
}) => {
  const {
    title,
    longDescription,
    imageUrl,
    shortDescription,
    disclaimer,
    disclaimerUrl,
    terms,
    termsUrl,
    surgeonGeneralsWarning,
  } = marketingContent;
  const showTermsAndDisclaimerSection = isOfferDisclaimerAvailable(marketingContent);
  const description = longDescription || shortDescription;

  return (
    <Stack
      justifyContent="center"
      position="relative"
      overflow="hidden"
      sx={(theme) => ({ borderRadius: theme.spacing(SizingMultiplier.md, SizingMultiplier.md, 0, 0) })}>
      {surgeonGeneralsWarning && (
        <Box m={SizingMultiplier.lg} mb={SizingMultiplier.sm}>
          <SurgeonWarning square message={surgeonGeneralsWarning} size="large" />
        </Box>
      )}
      {imageUrl && <OfferDetailsMedia variant={variant} src={imageUrl} alt={title} />}
      <Stack
        spacing={2}
        pt={variant === 'banner' ? SizingMultiplier.lg : SizingMultiplier.xl}
        px={SizingMultiplier.lg}
        pb={SizingMultiplier.xl}>
        <div>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          {description && (
            <Typography variant="body2" gutterBottom>
              {description}
            </Typography>
          )}
          {note && (
            <Typography variant="body2" color="text.secondary">
              {note}
            </Typography>
          )}
        </div>
        {showTermsAndDisclaimerSection && (
          <OfferDetailsTermsAndDisclaimer
            terms={terms}
            termsUrl={termsUrl}
            disclaimer={disclaimer}
            disclaimerUrl={disclaimerUrl}
          />
        )}
        <Grow in={!!action} appear unmountOnExit>
          <LoadingButton
            fullWidth
            variant={action?.buttonVariant}
            loading={action?.isExecuting || false}
            disabled={action?.isExecuting || isLoading}
            onClick={action?.onClick}>
            {!!action?.title && <FormattedMessage {...action.title} />}
          </LoadingButton>
        </Grow>
      </Stack>
      {isLoading && (
        <Stack
          position="absolute"
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
          sx={(theme) => ({ backgroundColor: alpha(theme.palette.background.paper, 0.6) })}>
          <CircularProgress size={24} />
        </Stack>
      )}
    </Stack>
  );
};
