import { FormattedMessage } from 'react-intl';
import { Paper } from '@mui/material';
import AdminPanelSettingsOutlined from '@mui/icons-material/AdminPanelSettingsOutlined';
import AssignmentOutlined from '@mui/icons-material/AssignmentOutlined';
import ForumOutlined from '@mui/icons-material/ForumOutlined';
import LiveHelpOutlined from '@mui/icons-material/LiveHelpOutlined';

import {
  List,
  ListItemLink,
  ListItemText,
  ListItemButton,
  ListItemStartIcon,
  ListHeader,
  SizingMultiplier,
} from '@ocx/ui';
import { SupportListItem } from '@ocx/feat-support';
import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';

import { profileMessages as messages } from '../profile.messages';

export type AboutListProps = {
  isHowDoesItWorkEnabled: boolean;
  onHowDoesItWorkClick(): void;
};

export const AboutList = ({ isHowDoesItWorkEnabled, onHowDoesItWorkClick }: AboutListProps) => {
  // TODO: should it go from controller?
  const privacyPolicyUrl = useConfiguration('privacyPolicy.url');
  const termsConditionsUrl = useConfiguration('termsConditions.url');

  return (
    <Paper elevation={0} sx={{ overflow: 'hidden', paddingTop: SizingMultiplier.md }}>
      <ListHeader>
        <FormattedMessage {...messages['profile:aboutSectionTitle']} />
      </ListHeader>

      <List>
        {isHowDoesItWorkEnabled && (
          <ListItemButton onClick={onHowDoesItWorkClick}>
            <ListItemStartIcon>
              <LiveHelpOutlined />
            </ListItemStartIcon>
            <ListItemText>
              <FormattedMessage {...messages['profile:aboutActions:howDoesItWork']} />
            </ListItemText>
          </ListItemButton>
        )}

        <ListItemLink href={privacyPolicyUrl} target="_blank">
          <ListItemStartIcon>
            <AdminPanelSettingsOutlined />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...messages['profile:about:privacyPolicyButtonTitle']} />
          </ListItemText>
        </ListItemLink>

        <ListItemLink href={termsConditionsUrl} target="_blank">
          <ListItemStartIcon>
            <AssignmentOutlined />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...messages['profile:about:termsAndConditionsButtonTitle']} />
          </ListItemText>
        </ListItemLink>

        <SupportListItem>
          <ListItemStartIcon>
            <ForumOutlined />
          </ListItemStartIcon>
          <ListItemText>
            <FormattedMessage {...messages['profile:about:supportAndFeedbackButtonTitle']} />
          </ListItemText>
        </SupportListItem>
      </List>
    </Paper>
  );
};
