import { Divider, Stack } from '@mui/material';

import { Paper, SizingMultiplier } from '@ocx/ui';

import { DiscountItem } from './component/discount-item';
import { PointChangeItem } from './component/point-change-item';
import { ReceiptPointChangeItem } from '../../receipts.types';

type ReceiptLoyaltyCardProps = {
  pointChangeItems: ReceiptPointChangeItem[];
  savedAmount: number | null;
  numberOfAppliedDiscountOffers: number;
};

export const ReceiptLoyaltyCard = (props: ReceiptLoyaltyCardProps) => {
  const { pointChangeItems, savedAmount, numberOfAppliedDiscountOffers } = props;
  const isSavedAmountApplicable = typeof savedAmount === 'number' && savedAmount > 0;

  return (
    <Stack component={Paper} spacing={SizingMultiplier.md} divider={<Divider />}>
      {pointChangeItems?.map(({ pointsDiff, offersNumber, type }) => {
        if (!pointsDiff) return null;

        return <PointChangeItem key={type} type={type} pointsDiff={pointsDiff} appliedOffersNumber={offersNumber} />;
      })}
      {isSavedAmountApplicable && (
        <DiscountItem savedAmount={savedAmount} appliedOffersNumber={numberOfAppliedDiscountOffers} />
      )}
    </Stack>
  );
};
