import { FormattedMessage } from 'react-intl';
import { Grid, Container } from '@mui/material';
import { Form } from 'react-final-form';
import React from 'react';
import { PrepaidCardNumberField } from '@ocx-app/components/Fields/PrepaidCardNumberField';
import { PrepaidCardPinField } from '@ocx-app/components/Fields/PrepaidCardPinField';
import { Button, Typography } from '@ocx/ui';

import { PaymentAccountType } from '@ocx/graphql';
import { PrepaidCardSchema } from './vault-card.schema';
import { PrepaidCardFormFields } from './vault-card.types';
import { makeValidateSync } from '../../lib/form/utils';
import { SizingMultiplier } from '../../theme/constants';
import { buttonTextMessages } from '../../modules/localization/button-text.messages';
import { useShowCardNumberError } from './vault-card.utils';

export type AddPrepaidCardProps = {
  onSubmit(values: PrepaidCardFormFields): Promise<void>;
};

const validate = makeValidateSync(PrepaidCardSchema);
const initialValues: PrepaidCardFormFields = {
  accountType: PaymentAccountType.Prepaid,
  cardNumber: '',
  securityCode: '',
};

export const AddPrepaidCard = (props: AddPrepaidCardProps) => {
  const showCardNumberError = useShowCardNumberError();
  const { onSubmit } = props;

  return (
    <Container sx={{ pt: SizingMultiplier.lg }}>
      <Typography variant="h4" align="center" mb={SizingMultiplier.md}>
        <FormattedMessage
          id="add-card:prepaid:title"
          defaultMessage="Add Card"
          description="Title for Add Prepaid Card page"
        />
      </Typography>
      <Form<PrepaidCardFormFields>
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, hasValidationErrors, dirty }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PrepaidCardNumberField name="cardNumber" showError={showCardNumberError} />
              </Grid>
              <Grid item xs={12}>
                <PrepaidCardPinField name="securityCode" />
              </Grid>
              <Grid item xs={12}>
                <Button disabled={hasValidationErrors || !dirty} type="submit" fullWidth>
                  <FormattedMessage {...buttonTextMessages['button-text:submit']} />
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};
