import { Backdrop, alpha, styled } from '@mui/material';

import { ScreenLoader } from '../screen-loader/screen-loader.component';

export type ScreenLoaderBackdropProps = {
  open: boolean;
};

const BackdropStyled = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.appBar - 1,
  backgroundColor: alpha(theme.palette.text.disabled, 0.8),
}));

export const ScreenLoaderBackdrop = (props: ScreenLoaderBackdropProps) => {
  const { open } = props;

  return (
    <BackdropStyled data-testid="blocking-screen-loader" open={open} sx={{ display: 'block' }}>
      <ScreenLoader />
    </BackdropStyled>
  );
};
