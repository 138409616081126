import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  'pointsChangeReceipt:htmlTitle': {
    id: 'pointsChangeReceipt:htmlTitle',
    defaultMessage: 'Transaction details',
    description: 'HTML title tag for Points change receipt title',
  },
  'pointsChangeReceipt:navBar:title': {
    id: 'pointsChangeReceipt:navBar:title',
    defaultMessage: 'Transaction details',
    description: 'Points change receipt title',
  },
});
