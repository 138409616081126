import { useMemo } from 'react';

import { useGetPointsChangeQuery } from '@ocx/graphql';
import { mapPointChangeToPointChangeReceipt } from '../../../modules/transaction/utils';

export const usePointsChangeReceiptController = (id: string) => {
  const { data, loading } = useGetPointsChangeQuery({ variables: { id }, fetchPolicy: 'cache-and-network' });
  const pointChangeNode = data?.transactionHistory?.membership?.pointChanges?.edges[0]?.node || null;

  const receipt = useMemo(() => {
    if (!pointChangeNode) {
      return pointChangeNode;
    }
    return mapPointChangeToPointChangeReceipt(pointChangeNode);
  }, [pointChangeNode]);

  return { loading, receipt };
};
