import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Typography, TypographyProps } from '@ocx/ui';

import { PointsStrategy } from '@ocx/graphql';
import { SizingMultiplier } from '../../theme/constants';
import { formatPrice } from '../../lib/formatters/formatPrice';
import { PointsCoin } from '../Icons/PointsCoin';
import { DollarCoin } from '../Icons/DollarCoin';
import { DollarsCoins } from '../Icons/DollarsCoins';
import { PointsCoins } from '../Icons/PointsCoins';

type IconSize = 'sm' | 'md' | 'lg';

export type PointsChangeProps = {
  points: number;
  allowPlus?: boolean;
  iconPosition?: 'right' | 'left';
  pointsStrategy: Exclude<PointsStrategy, PointsStrategy.NotUsed>;
  typographyProps?: TypographyProps;
  iconSize?: IconSize;
  iconMode?: 'single' | 'multiple';
};

const iconSizeMap: Record<IconSize, number> = {
  sm: 16,
  md: 20,
  lg: 32,
};

export const PointsChange = (props: PointsChangeProps) => {
  const {
    points,
    allowPlus = true,
    iconPosition = 'left',
    pointsStrategy,
    typographyProps,
    iconSize = 'sm',
    iconMode = 'single',
  } = props;
  const isPositionRight = iconPosition === 'right';
  const iconWidthHeight = iconSizeMap[iconSize] || iconSizeMap.sm;
  const isPointsSingleMode = pointsStrategy === PointsStrategy.Points && iconMode === 'single';
  const isPointsMultipleMode = pointsStrategy === PointsStrategy.Points && iconMode === 'multiple';
  const isCentsSingleMode = pointsStrategy === PointsStrategy.Cents && iconMode === 'single';
  const isCentsMultipleMode = pointsStrategy === PointsStrategy.Cents && iconMode === 'multiple';

  const value = useMemo(() => {
    if (pointsStrategy === PointsStrategy.Cents) {
      return formatPrice(points);
    }

    return points;
  }, [pointsStrategy, points]);

  // TODO: discuss points position with designer - it should be the same
  return (
    <Box display="flex" justifyContent="center">
      {isCentsSingleMode && <DollarCoin width={iconWidthHeight} height={iconWidthHeight} />}
      {isCentsMultipleMode && <DollarsCoins width={iconWidthHeight} height={iconWidthHeight} />}
      {isPointsSingleMode && <PointsCoin width={iconWidthHeight} height={iconWidthHeight} />}
      {isPointsMultipleMode && <PointsCoins width={iconWidthHeight} height={iconWidthHeight} />}
      <Typography
        variant="subtitle2"
        component="span"
        sx={{
          order: isPositionRight ? -1 : 1,
          wordBreak: 'keep-all',
          mr: isPositionRight ? SizingMultiplier.xs : 0,
          ml: !isPositionRight ? SizingMultiplier.xs : 0,
        }}
        {...typographyProps}>
        {allowPlus && points > 0 && '+'}
        {value}
      </Typography>
    </Box>
  );
};
