import { Box } from '@mui/material';

import { Loading } from '@ocx/ui';

export const SignupWithLink = () => {
  return (
    <Box textAlign="center" margin="auto">
      <Loading />
    </Box>
  );
};
