import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { isAndroidPlatform, isIosPlatform } from '@ocx/utils-device';
import { InternalUrl } from './deep-linking.types';
import { useConfiguration } from '../configuration/useConfiguration';

export const useDeepLinkNavigate = () => {
  const navigate = useNavigate();
  return useCallback(
    (url: InternalUrl) => {
      const { pathname, search } = new URL(url);
      if (!pathname) {
        return;
      }
      // Search if present, starts with '?'
      const target = pathname + search;
      /**
       * Timeout is implemented here to address a potential race condition between this handler and AuthGuard.
       * This issue specifically impacts native apps due to the nature of Capacitor and its event handling mechanism.
       * However, there is an opportunity to enhance synchronicity by utilizing Capacitor's App.getLaunchUrl() method.
       *
       * iOS Seems to be less affected, that's why timeout time here is smaller
       * both 25 and 50 are experimental practical values
       *
       * The issue is an edge case https://stuzo.atlassian.net/browse/OCST-4455
       * Potential improvement path might be adding timeouts only for initial apps.
       *
       * A complete rearchitecture of the auth might be a better approach to resolve the issue, but it is unlikely necessary.
       * We might capture other Capacitor-related issues or potentially making the web experience less reliant on Capacitor.
       */
      if (isIosPlatform()) {
        setTimeout(() => navigate(target), 25);
        return;
      }
      if (isAndroidPlatform()) {
        setTimeout(() => navigate(target), 50);
        return;
      }

      navigate(target);
    },
    [navigate],
  );
};

export const useGetIsInternalUrl = () => {
  const universalLinkOrigins = useConfiguration('localRetailerConfig.universalLinkOrigins');
  return useCallback(
    (url: string): url is InternalUrl => {
      const { origin } = new URL(url);
      return universalLinkOrigins.includes(origin);
    },
    [universalLinkOrigins],
  );
};
