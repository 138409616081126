import { RuntimeConfig } from '../app-config.types';
import { MembershipConfigOverrides } from '../../membership/membership.types';

export const mergeRuntimeConfigWithMembershipOverrides = (
  config: RuntimeConfig,
  overrides: MembershipConfigOverrides,
): RuntimeConfig => {
  return {
    ...config,
    deleteAccount: {
      ...config.deleteAccount,
      enabled: overrides.deleteAccountEnabled ?? config.deleteAccount.enabled,
    },
    payAtPump: {
      ...config.payAtPump,
      enabled: overrides.payAtPumpEnabled ?? config.payAtPump.enabled,
    },
    payInside: {
      ...config.payInside,
      enabled: overrides.payInsideEnabled ?? config.payInside.enabled,
    },
    wallet: {
      ...config.wallet,
      enabled: overrides.walletEnabled ?? config.wallet.enabled,
      prepaidAccountTypeEnabled: overrides.prepaidCardsEnabled ?? config.wallet.prepaidAccountTypeEnabled,
    },
    appRating: {
      ...config.appRating,
      enabled: overrides.appRatingEnabled ?? config.appRating.enabled,
      promptIntervalDays: overrides.promptIntervalDays ?? config.appRating.promptIntervalDays,
      transactionHistoryLookupDays:
        overrides.transactionHistoryLookupDays ?? config.appRating.transactionHistoryLookupDays,
    },
    productInterests: {
      ...config.productInterests,
      enabled: overrides.productInterestsEnabled ?? config.productInterests.enabled,
      isEAIVPromotionOnProfileCompletedEnabled:
        overrides.productInterestsEAIVPromotionOnProfileCompletedEnabled ??
        config.productInterests.isEAIVPromotionOnProfileCompletedEnabled,
    },
    eaivAgeVerification: {
      ...config.eaivAgeVerification,
      enabled: overrides.eaivAgeVerificationEnabled ?? config.eaivAgeVerification.enabled,
    },
    eaivIdentityVerification: {
      ...config.eaivIdentityVerification,
      enabled: overrides.eaivIdentityVerificationEnabled ?? config.eaivIdentityVerification.enabled,
    },
    freshdesk: {
      supportWidget: {
        ...config.freshdesk.supportWidget,
        id: overrides.freshdeskWidgetId ?? config.freshdesk.supportWidget.id,
        enabled: overrides.freshdeskEnabled ?? config.freshdesk.supportWidget.enabled,
        token: overrides.freshdeskWidgetToken ?? config.freshdesk.supportWidget.token,
        host: overrides.freshdeskWidgetHost ?? config.freshdesk.supportWidget.host,
        sdkUrl: overrides.freshdeskWidgetSdkUrl ?? config.freshdesk.supportWidget.sdkUrl,
      },
    },
    totalAvailableRollback: {
      ...config.totalAvailableRollback,
      enabled: overrides.totalAvailableRollbackEnabled ?? config.totalAvailableRollback.enabled,
    },
    loyaltyProgram: {
      ...config.loyaltyProgram,
      isPromocodesEnabled: overrides.loyaltyProgramPromocodesEnabled ?? config.loyaltyProgram.isPromocodesEnabled,
    },
    mobilePaymentsReceiptsView: {
      ...config.mobilePaymentsReceiptsView,
      enabled: overrides.mobilePaymentsReceiptsViewEnabled ?? config.mobilePaymentsReceiptsView.enabled,
    },
  };
};
