import { GetAppConfigQuery, PaymentAccountType } from '@ocx/graphql';
import { LocalRetailerConfiguration, RemoteConfiguration, RuntimeConfig } from '../app-config.types';
import { mapPaymentProviderConfigurationFragment } from './mapPaymentProviderConfigurationFragment';
import { logInDev } from '../../../lib/logger/logger';
import * as appConfigConstants from '../app-config.constants';

const buildGooglePayConfig = (remoteConfig: RemoteConfiguration): RuntimeConfig['googlePay'] => {
  if (!remoteConfig.googlePay) {
    logInDev('[Payments]: GooglePay is not configured');
    return null;
  }

  return {
    environment: remoteConfig.googlePay.environment,
    gateway: remoteConfig.googlePay.gateway,
    merchantId: remoteConfig.googlePay.merchantId,
    merchantName: remoteConfig.googlePay.merchantName,
    allowedCardNetworks: remoteConfig.googlePay.allowedCardNetworks,
    allowPrepaidCards: remoteConfig.googlePay.allowPrepaidCards,
    allowCreditCards: remoteConfig.googlePay.allowCreditCards,
    gatewayMerchantId: remoteConfig.googlePay.gatewayMerchantId,
  };
};

const buildApplePayConfig = (remoteConfig: RemoteConfiguration): RuntimeConfig['applePay'] => {
  if (!remoteConfig.applePay) {
    logInDev('[Payments]: ApplePay is not configured');
    return null;
  }

  return {
    merchantId: remoteConfig.applePay.merchantId,
    supportedNetworks: remoteConfig.applePay.supportedNetworks,
    allowCreditCards: remoteConfig.applePay.allowCreditCards,
    merchantName: remoteConfig.applePay.merchantName,
    allowPrepaidCards: remoteConfig.applePay.allowPrepaidCards,
  };
};

const buildPaymentProviderConfig = (remoteConfig: RemoteConfiguration): RuntimeConfig['paymentProvider'] => {
  if (!remoteConfig.paymentProvider) {
    logInDev('[Payments]: Payment Provider is not configured');
    return null;
  }

  const config = mapPaymentProviderConfigurationFragment(remoteConfig.paymentProvider);
  logInDev(`[Payments]: ${config?.type} is used`);
  return config;
};

const buildKountConfig = (remoteConfig: RemoteConfiguration): RuntimeConfig['kount'] => {
  if (!remoteConfig.kount) {
    logInDev(`[Payments]: Kount is not enabled`);
    return null;
  }

  logInDev(`[Payments]: Kount is up and running using ${remoteConfig.kount.environment} environment`);

  return {
    merchantId: remoteConfig.kount.merchantId,
    collectorHostname: remoteConfig.kount.collectorHostname,
    environment: remoteConfig.kount.environment,
  };
};

const buildStoreLocatorConfig = (
  envConfig: ISchemaConfig,
  remoteConfig: RemoteConfiguration,
): RuntimeConfig['storeLocator'] => {
  return {
    fuelPricesMode: remoteConfig.storeLocator.fuelPricesMode,
    googleMapsApiKey: envConfig.storeLocator.googleMapsApiKey,
    defaultLocation: envConfig.storeLocator.defaultLocation,
    watchTimeout: appConfigConstants.STORE_LOCATOR_WATCH_TIMEOUT,
    atTheStoreDistance: appConfigConstants.STORE_LOCATOR_AT_THE_STORE_DISTANCE,
  };
};

const buildPinCodeConfig = (remoteConfig: RemoteConfiguration): RuntimeConfig['pinCode'] => {
  return remoteConfig.pinCode
    ? {
        blockingDuration: remoteConfig.pinCode.blockingDuration,
        attemptThrottle: remoteConfig.pinCode.attemptThrottle,
        maxAttempts: remoteConfig.pinCode.maxAttempts,
        pinInMemoryTtl: appConfigConstants.PIN_IN_MEMORY_TTL,
      }
    : {
        pinInMemoryTtl: appConfigConstants.PIN_IN_MEMORY_TTL,
      };
};

type BuildConfigParams = {
  remoteConfig: GetAppConfigQuery['appConfig'];
  envConfig: ISchemaConfig;
  localRetailerConfig: LocalRetailerConfiguration;
};
export const buildRuntimeConfig = ({
  remoteConfig,
  envConfig,
  localRetailerConfig,
}: BuildConfigParams): RuntimeConfig => {
  return {
    release: envConfig.release,
    chainId: envConfig.chainId,
    brandUuid: envConfig.brandUuid,

    termsConditions: {
      uuid: remoteConfig.termsConditions.uuid,
      name: remoteConfig.termsConditions.name,
      url: remoteConfig.termsConditions.url,
      activeAt: remoteConfig.termsConditions.activeAt,
    },
    privacyPolicy: {
      uuid: remoteConfig.privacyPolicy.uuid,
      name: remoteConfig.privacyPolicy.name,
      url: remoteConfig.privacyPolicy.url,
      activeAt: remoteConfig.privacyPolicy.activeAt,
    },
    supportTeamEmail: remoteConfig.supportTeamEmail,
    supportPhoneNumber: remoteConfig.supportPhoneNumber,

    rudderStack: {
      writeKey: envConfig.rudderStack.writeKey,
      dataPlaneUrl: envConfig.rudderStack.dataPlaneUrl,
      enabled: remoteConfig.rudderStack.enabled,
    },

    totalAvailableRollback: {
      enabled: remoteConfig.totalAvailableRollback.enabled,
    },

    marketing: {
      title: envConfig.marketing.title,
    },
    storeLocator: buildStoreLocatorConfig(envConfig, remoteConfig),

    wallet: {
      enabled: remoteConfig.wallet.enabled,
      sizeLimit: remoteConfig.paymentWallet.maxInstruments,
      prepaidAccountTypeEnabled: remoteConfig.paymentWallet.allowedAccountTypes.includes(PaymentAccountType.Prepaid),
    },
    pinCode: buildPinCodeConfig(remoteConfig),
    paymentProvider: buildPaymentProviderConfig(remoteConfig),
    applePay: buildApplePayConfig(remoteConfig),
    googlePay: buildGooglePayConfig(remoteConfig),
    kount: buildKountConfig(remoteConfig),
    transaction: {
      pollInterval: appConfigConstants.TRANSACTION_POLL_INTERVAL,
      errorLimit: appConfigConstants.TRANSACTION_ERROR_LIMIT,
    },
    payAtPump: {
      enabled: remoteConfig.payAtPump.enabled,
    },
    payInside: {
      enabled: remoteConfig.payInside.enabled,
    },

    loyaltyProgram: {
      redeemOfferWithCompletedProfileOnly: remoteConfig.loyaltyProgram.redeemOfferWithCompletedProfileOnly,
      pointsStrategy: remoteConfig.loyaltyProgram.pointsStrategy,
      homePageOffersLimit: remoteConfig.loyaltyProgram.homePageOffersLimit,
      membershipMinAge: remoteConfig.loyaltyProgram.membershipMinAge,
      membershipMaxAge: appConfigConstants.MEMBERSHIP_MAX_AGE,
      isExplicitTermsAcceptanceRequired: remoteConfig.loyaltyProgram.isExplicitTermsAcceptanceRequired,
      isReturnRewardEnabled: remoteConfig.loyaltyProgram.isReturnRewardEnabled,
      isExplicitSmsPromotionAcceptanceEnabled: remoteConfig.loyaltyProgram.isExplicitSmsPromotionAcceptanceEnabled,
      isPromocodesEnabled: remoteConfig.loyaltyProgram.isPromocodesEnabled,
    },
    localRetailerConfig,

    deleteAccount: {
      enabled: remoteConfig.deleteAccount.enabled,
    },
    communicationPreference: {
      enabled: remoteConfig.communicationPreference.enabled,
      isSmsOptInConfigurable: remoteConfig.communicationPreference.isSmsOptInConfigurable,
      isPushOptInConfigurable: remoteConfig.communicationPreference.isPushOptInConfigurable,
    },
    eaivAgeVerification: remoteConfig.eaivAgeVerification,
    eaivIdentityVerification: remoteConfig.eaivIdentityVerification,
    productInterests: remoteConfig.productInterests,
    appRating: remoteConfig.appRating,
    auth: {
      primaryMethod: remoteConfig.auth.primaryMethod,
      otp: {
        enabled: remoteConfig.auth.otp.enabled && remoteConfig.auth.otp.channels.length > 0,
        channels: remoteConfig.auth.otp.channels,
      },
    },
    freshdesk: {
      supportWidget: {
        id: remoteConfig.freshdeskSupportWidget.widgetId,
        token: remoteConfig.freshdeskSupportWidget.token,
        host: remoteConfig.freshdeskSupportWidget.host,
        sdkUrl: remoteConfig.freshdeskSupportWidget.sdkUrl,
        enabled: remoteConfig.freshdeskSupportWidget.enabled,
      },
    },
    smsOptIn: {
      enabled: remoteConfig.smsOptIn.enabled,
      modalAppearanceDelaySeconds: remoteConfig.smsOptIn.modalAppearanceDelaySeconds,
      modalOfferLoyaltyEventName: remoteConfig.smsOptIn.modalOfferLoyaltyEventName,
      optInAcceptedLoyaltyEventName: remoteConfig.smsOptIn.optInAcceptedLoyaltyEventName,
      optInRejectedLoyaltyEventName: remoteConfig.smsOptIn.optInRejectedLoyaltyEventName,
    },
    mobilePaymentsReceiptsView: {
      enabled: remoteConfig.mobilePaymentsReceiptsView.enabled,
    },
  };
};
