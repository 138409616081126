import { PaymentInstrumentType } from '@ocx/graphql';

import { ReceiptPaymentInstrumentType } from '../../receipts.types';

export const formatPaymentInstrumentType = (type: ReceiptPaymentInstrumentType): string => {
  if (type === PaymentInstrumentType.Jcb) {
    return type;
  }

  return type
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');
};

export const getPaymentInstrumentLabel = (type: ReceiptPaymentInstrumentType): string => {
  if (
    type === PaymentInstrumentType.Credit ||
    type === PaymentInstrumentType.Debit ||
    type === PaymentInstrumentType.Prepaid
  ) {
    return `${formatPaymentInstrumentType(type)} Card`;
  }

  if (type === 'SPLIT_PAYMENT') {
    return 'Split Payment';
  }

  return formatPaymentInstrumentType(type);
};
