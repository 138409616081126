import { SpinWheelGameSectorRenderParams } from '@ocx/spin-wheel';

import { useConfiguration } from '../../../../../modules/configuration/useConfiguration';
import { PointsChangeProps, PointsChange } from '../../../../../components/PointsChange/PointsChange';

export const SpinWheelSectorContent = (props: SpinWheelGameSectorRenderParams) => {
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');
  const { type, points = 0, placeholder = null } = props;

  if (type === 'VOUCHERS') {
    return placeholder;
  }
  return (
    <PointsChange
      points={points}
      iconSize="md"
      pointsStrategy={pointsStrategy as PointsChangeProps['pointsStrategy']}
    />
  );
};
