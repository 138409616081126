import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ScreenLoaderBackdrop } from '@ocx/ui';
import { OfferPresentationType, PointsStrategy } from '@ocx/graphql';

import {
  OfferDetailsFullScreenLayout,
  OfferDetailsLayout,
  OfferFullScreenLayout,
  useOfferDetailsLayout,
} from './offer-details.layout';
import { useOfferDetailsController } from './offer-details.controller.hook';
import { OfferDetails } from '../../components/OfferDetails/OfferDetails';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';
import {
  isOfferSweepstakesPoints,
  isOfferSweepstakesProduct,
  isSpinWheelGameOffer,
  isVoucherOffer,
} from '../../modules/offers/offer.type-guards';
import { useConfiguration } from '../../modules/configuration/useConfiguration';
import { PointsSweepsController } from '../Sweeps/PointsSweeps/PointsSweepsController';
import { useMembership } from '../../modules/membership/use-membership.hook';
import { ProductSweeps } from '../Sweeps/ProductSweeps';
import { useInfoActionSheet } from '../../components/modals/InfoActionSheet';
import { messages } from './offer-details.messages';
import { useOfferAutoActivator } from './offer-auto-activator.hook';
import { buttonTextMessages } from '../../modules/localization/button-text.messages';
import { genericMessages } from '../../modules/localization/genericMessages';
import { InfoActionSheet } from '../../components/modals/InfoActionSheet/info-action-sheet';
import { SpinWheelGameController } from '../Games/SpinWheel/spin-wheel-game.controller';
import { isOfferVisible } from './offer-details.utils';
import { getRewardExpiresIn, getRewardExpiresInMessage } from '../../modules/offers/award.utils';
import { getVouchersExpirationData } from '../../modules/offers/offers.utils';

type OfferDetailsControllerProps = {
  returnPath: string;
};

export const OfferDetailsController = (props: OfferDetailsControllerProps) => {
  const navigate = useNavigate();
  const { returnPath } = props;
  const { offerId = '' } = useParams<{ offerId: string }>();

  const intl = useIntl();
  const { triggerEvent } = useRudderStack();
  const { open: openInfoActionSheet } = useInfoActionSheet();
  const offerDetailsLayout = useOfferDetailsLayout({ returnPath });
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');
  const { membership } = useMembership();

  const { offer, isOfferLoading, isOfferVoucherLoading, action } = useOfferDetailsController({
    offerId,
    onOfferDetailsClose: offerDetailsLayout.handleClose,
  });

  const { activating } = useOfferAutoActivator(offer);

  const [isNotFoundPopupVisible, setIsNotFoundPopupVisible] = useState(true);

  const onNotFoundPopupClose = useCallback(() => {
    setIsNotFoundPopupVisible(false);
  }, []);

  const onNotFoundPopupExit = useCallback(() => {
    navigate(returnPath, { replace: true });
  }, [navigate, returnPath]);

  useEffect(() => {
    if (offer?.id) {
      triggerEvent('offer_details_modal_opened', { uuid: offer.id });
    }
  }, [offer?.id, triggerEvent]);

  if (!offer && isOfferLoading) {
    return <ScreenLoaderBackdrop open />;
  }

  if (!offer || !isOfferVisible(offer)) {
    return (
      <InfoActionSheet
        isVisible={isNotFoundPopupVisible}
        title={`${intl.formatMessage(genericMessages['generic:vocabulary:oops'])}!`}
        description={intl.formatMessage(messages.offerDetailsNotFoundPopupDescription)}
        actionButtonText={intl.formatMessage(buttonTextMessages['button-text:ok'])}
        isServiceLinkEnabled={false}
        onActionButtonClick={onNotFoundPopupClose}
        onClose={onNotFoundPopupClose}
        onExited={onNotFoundPopupExit}
      />
    );
  }

  if (isSpinWheelGameOffer(offer) && offer.earningMechanism.prizePool.length) {
    return (
      <OfferFullScreenLayout
        isOpen={offerDetailsLayout.isOpen}
        onClose={offerDetailsLayout.handleClose}
        onExited={offerDetailsLayout.handleExited}>
        <SpinWheelGameController offer={offer} onLastGameplayEnd={offerDetailsLayout.handleClose} />
      </OfferFullScreenLayout>
    );
  }

  if (isOfferSweepstakesPoints(offer) && pointsStrategy === PointsStrategy.NotUsed) {
    return <Navigate to={returnPath} replace />;
  }

  if (isOfferSweepstakesPoints(offer)) {
    return (
      <OfferDetailsFullScreenLayout
        pointsStrategy={pointsStrategy}
        membershipPoints={membership.points}
        isOpen={offerDetailsLayout.isOpen}
        onClose={offerDetailsLayout.handleClose}
        onExited={offerDetailsLayout.handleExited}>
        <PointsSweepsController
          memberEntries={offer.earningMechanism.award.memberEntries}
          memberPoints={membership.points}
          offerId={offer.id}
          entryPrice={offer.earningMechanism.fulfilmentPrice}
          marketingContent={offer.marketingContent}
          onCompleted={(entriesAmount) => {
            offerDetailsLayout.handleClose();
            openInfoActionSheet({
              variant: 'congrats',
              title: intl.formatMessage(messages.buyEntriesCongratsDialogTitle, { entries: entriesAmount }),
              description: intl.formatMessage(messages.buyEntriesCongratsDialogDescription, { entries: entriesAmount }),
            });
          }}
        />
      </OfferDetailsFullScreenLayout>
    );
  }

  if (isOfferSweepstakesProduct(offer)) {
    return (
      <OfferDetailsFullScreenLayout
        pointsStrategy={pointsStrategy}
        membershipPoints={membership.points}
        isOpen={offerDetailsLayout.isOpen}
        onClose={offerDetailsLayout.handleClose}
        onExited={offerDetailsLayout.handleExited}>
        <ProductSweeps
          memberEntries={offer.earningMechanism.award.memberEntries}
          offerId={offer.id}
          marketingContent={offer.marketingContent}
          presentationType={offer.presentationType}
          // ProductSweeps offer can be activated, but for now UI does not support it
          // Slack Thread: https://stuzo.slack.com/archives/C04N6LF2WTV/p1698242120741569
          offerAction={null}
          onClose={offerDetailsLayout.handleClose}
        />
      </OfferDetailsFullScreenLayout>
    );
  }

  const { unfulfilledVouchersCount, voucherExpirationDate } = getVouchersExpirationData(offer.vouchers);

  const rewardExpirationMessage = isVoucherOffer(offer)
    ? getRewardExpiresInMessage({
        expiresIn: getRewardExpiresIn({ rewardExpiresIn: voucherExpirationDate || offer.expireAt }),
        isMultipleVouchers: !!unfulfilledVouchersCount,
        intl,
      })
    : null;

  return (
    <OfferDetailsLayout
      isOpen={offerDetailsLayout.isOpen}
      onClose={offerDetailsLayout.handleClose}
      onExited={offerDetailsLayout.handleExited}>
      <OfferDetails
        isLoading={activating || isOfferVoucherLoading}
        marketingContent={offer.marketingContent}
        action={action}
        variant={offer.presentationType === OfferPresentationType.Banner ? 'banner' : 'default'}
        note={rewardExpirationMessage}
      />
    </OfferDetailsLayout>
  );
};
