import { EarningMechanismPointSpendFragment, OfferEngagementType, OfferFragment } from '@ocx/graphql';
import {
  EarningMechanism,
  EarningMechanismType,
  OfferMemberProgress,
  PurchaseVoucherEarningMechanism,
  SweepstakesPointsEarningMechanism,
} from './earning-mechanism.types';
import { mapAward, mapAwardVouchersFragment } from './award.utils';
import { Award, AwardType } from './award.types';
import { isEarningMechanismTypenameIsWithProgress } from './earning-mechanism.type-guards';

const roundToTwo = (num: number) => parseFloat(num.toFixed(2));

export const getOfferMembershipProgress = (
  mechanism: OfferFragment['earningMechanism'],
): OfferMemberProgress | null => {
  if (!mechanism) {
    return null;
  }

  let progress: OfferMemberProgress | null = null;

  if (mechanism?.__typename === 'EarningMechanismVisits' && mechanism?.visitsMemberProgress) {
    progress = {
      required: roundToTwo(mechanism.visitsMemberProgress.visitsRequired),
      achieved: roundToTwo(mechanism.visitsMemberProgress.visits),
    };
  }

  if (
    (mechanism?.__typename === 'EarningMechanismTotalSpendOverTime' ||
      mechanism?.__typename === 'EarningMechanismTotalEligiblePurchaseOverTime') &&
    mechanism?.purchaseMemberProgress
  ) {
    progress = {
      required: roundToTwo(mechanism.purchaseMemberProgress.quantityRequired),
      achieved: roundToTwo(mechanism.purchaseMemberProgress.quantityPurchased),
    };
  }

  // TODO: varietyMemberProgress for multiple product challenge is not supported yet
  // Example: 2 coffees + 2 donuts => free coffee and donut
  if (
    mechanism.__typename === 'EarningMechanismVarietyPurchaseOverTime' &&
    mechanism.varietyMemberProgress.length === 1
  ) {
    const firstProgress = mechanism.varietyMemberProgress[0];
    progress = {
      required: roundToTwo(firstProgress.quantityRequired),
      achieved: roundToTwo(firstProgress.quantityPurchased),
    };
  }

  // R TODO: should we really do any kind of extra checks here?
  // return null;
  return progress?.required ? progress : null;
};

const mapEarningMechanismPointSpend = ({
  earningMechanism,
  engagementType,
}: {
  earningMechanism: EarningMechanismPointSpendFragment;
  engagementType: OfferEngagementType | null;
}): SweepstakesPointsEarningMechanism | PurchaseVoucherEarningMechanism => {
  const award = mapAwardVouchersFragment({
    award: earningMechanism.award,
    engagementType,
  });
  if (award.type === AwardType.SweepstakeEntries) {
    return {
      type: EarningMechanismType.PurchaseSweepstakesEntry,
      award,
      fulfilmentPrice: earningMechanism.pointCost,
    };
  }
  return {
    type: EarningMechanismType.PurchaseVoucher,
    award,
    fulfilmentPrice: earningMechanism.pointCost,
  };
};

export const buildEarningMechanism = (fragment: OfferFragment): EarningMechanism | null => {
  if (!fragment.earningMechanism) {
    return null;
  }

  const typename = fragment.earningMechanism.__typename;

  if (typename === 'EarningMechanismPointSpend') {
    return mapEarningMechanismPointSpend({
      earningMechanism: fragment.earningMechanism,
      engagementType: fragment.engagementType,
    });
  }

  if (isEarningMechanismTypenameIsWithProgress(typename)) {
    return {
      __typename: typename,
      type: EarningMechanismType.UnknownWithProgress,
      award: mapAward(fragment),
      memberProgress: getOfferMembershipProgress(fragment.earningMechanism),
    };
  }

  if (typename === 'EarningMechanismSpinWheelGame') {
    return {
      type: EarningMechanismType.SpinWheelGame,
      award: null,
      prizePool: fragment.earningMechanism.awards,
      gameplayVoucher:
        fragment.earningMechanism.purchasableOffer?.earningMechanism?.__typename === 'EarningMechanismPointSpend'
          ? {
              id: fragment.earningMechanism.purchasableOffer.id,
              price: fragment.earningMechanism.purchasableOffer.earningMechanism.pointCost,
            }
          : null,
    };
  }

  if (typename === 'EarningMechanismPromocodes') {
    return {
      type: EarningMechanismType.Promocodes,
      award: mapAward(fragment) as Extract<Award, { type: AwardType.Points | AwardType.Vouchers }>,
    };
  }

  return {
    __typename: typename,
    type: EarningMechanismType.Unknown,
    award: mapAward(fragment),
  };
};
