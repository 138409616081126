import React from 'react';

// TODO: adjust to allow PointsStrategy.Cents + customize dollar icon(s) for each vendor - import from @ocx/buildtime-retailer-configuration/assets
export const DollarsCoins = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none" {...props}>
      <mask id="a" width="18" height="18" x="18" y="4" maskUnits="userSpaceOnUse">
        <circle cx="27" cy="13" r="8" fill="#FEC807" transform="rotate(-10 27 13)" />
      </mask>
      <g mask="url(#a)">
        <circle cx="27" cy="13" r="8" fill="#FEC807" transform="rotate(-10 27 13)" />
        <path
          fill="#FFDA56"
          fillRule="evenodd"
          d="m15.742 14.284 8.571-12.242 3.932 2.754-8.57 12.24-3.933-2.753Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA56"
          fillRule="evenodd"
          d="M23.662 21.712 34.045 6.884l3.277 2.294-10.383 14.828-3.277-2.294Z"
          clipRule="evenodd"
          opacity=".8"
        />
        <path
          fill="#EEB13D"
          fillRule="evenodd"
          d="M27.625 16.545a3.6 3.6 0 1 0-1.25-7.09 3.6 3.6 0 0 0 1.25 7.09Zm.347 1.97a5.6 5.6 0 1 0-1.944-11.03 5.6 5.6 0 0 0 1.944 11.03Z"
          clipRule="evenodd"
        />
        <g filter="url(#b)">
          <path
            fill="#EEB13D"
            fillRule="evenodd"
            d="M27.972 18.515a5.6 5.6 0 1 0-1.944-11.03 5.6 5.6 0 0 0 1.944 11.03Z"
            clipRule="evenodd"
          />
        </g>
        <path
          fill="#FEC807"
          fillRule="evenodd"
          d="m27.317 18.63-.227-1.284c-.835.106-1.682.004-2.204-.21l.092-1.647c.575.22 1.354.374 2.157.233.705-.124 1.135-.508 1.04-1.048-.091-.513-.543-.768-1.518-.944-1.41-.254-2.43-.77-2.662-2.082-.21-1.19.393-2.258 1.665-2.774l-.226-1.284 1.212-.214.21 1.19c.835-.106 1.424-.015 1.87.13l-.088 1.59c-.348-.092-.972-.302-1.862-.145-.803.141-.996.566-.93.944.08.446.562.653 1.69.9 1.57.31 2.295.948 2.51 2.165.212 1.202-.386 2.367-1.76 2.888l.243 1.379-1.212.213Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFD646"
          fillRule="evenodd"
          d="m27.09 17.346.227 1.285 1.212-.214-.243-1.379c1.374-.52 1.972-1.686 1.76-2.888a2.592 2.592 0 0 0-.382-1.01l-2.97 4.24c.132-.006.264-.017.396-.034Z"
          clipRule="evenodd"
          opacity=".8"
        />
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M26 12c0 .227-.005.453-.016.677a12.039 12.039 0 0 1 6.733 5.919 8 8 0 1 1-8.345-13.154A13.94 13.94 0 0 1 26 12Z"
        clipRule="evenodd"
        opacity=".1"
      />
      <mask id="c" width="22" height="22" x="11" y="13" maskUnits="userSpaceOnUse">
        <circle cx="22" cy="24" r="10" fill="#FEC807" transform="rotate(15 22 24)" />
      </mask>
      <g mask="url(#c)">
        <circle cx="22" cy="24" r="10" fill="#FEC807" transform="rotate(15 22 24)" />
        <path
          fill="#FFDA56"
          fillRule="evenodd"
          d="m8.568 19.507 16.177-9.34 3 5.196-16.177 9.34-3-5.196Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA56"
          fillRule="evenodd"
          d="m13.616 32.106 19.596-11.313 2.5 4.33-19.596 11.314-2.5-4.33Z"
          clipRule="evenodd"
          opacity=".8"
        />
        <path
          fill="#EEB13D"
          fillRule="evenodd"
          d="M20.835 28.347a4.5 4.5 0 1 0 2.33-8.694 4.5 4.5 0 0 0-2.33 8.694Zm-.647 2.415a7 7 0 1 0 3.624-13.523 7 7 0 0 0-3.624 13.523Z"
          clipRule="evenodd"
        />
        <g filter="url(#d)">
          <path
            fill="#EEB13D"
            fillRule="evenodd"
            d="M20.188 30.762a7 7 0 1 0 3.624-13.523 7 7 0 0 0-3.624 13.523Z"
            clipRule="evenodd"
          />
        </g>
        <path
          fill="#FEC807"
          fillRule="evenodd"
          d="m19.385 30.546.422-1.575c-1.003-.321-1.908-.884-2.387-1.402l.975-1.817c.535.552 1.336 1.14 2.32 1.403.865.232 1.554.025 1.731-.638.169-.63-.209-1.157-1.22-1.872-1.462-1.031-2.347-2.156-1.916-3.764.39-1.458 1.637-2.35 3.352-2.263l.422-1.574 1.486.398-.391 1.458c1.002.322 1.622.736 2.051 1.136l-.94 1.754c-.346-.287-.941-.855-2.033-1.147-.985-.264-1.428.114-1.552.578-.147.547.29 1.037 1.437 1.913 1.617 1.18 2.101 2.285 1.701 3.777-.395 1.475-1.688 2.48-3.52 2.343l-.453 1.69-1.485-.398Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFD646"
          fillRule="evenodd"
          d="m19.807 28.971-.422 1.575 1.486.398.453-1.69c1.831.136 3.124-.868 3.52-2.343.13-.483.166-.927.101-1.345l-5.605 3.236c.152.062.308.119.467.17Z"
          clipRule="evenodd"
          opacity=".8"
        />
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M25.736 14.721C24.482 21.088 18.912 25.905 12.2 26c-.131-.646-.2-1.315-.2-1.999 0-5.523 4.477-10 10-10 1.32 0 2.582.256 3.736.721Z"
        clipRule="evenodd"
        opacity=".2"
      />
      <mask id="e" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse">
        <circle cx="12" cy="12" r="12" fill="#FEC807" />
      </mask>
      <g mask="url(#e)">
        <circle cx="12" cy="12" r="12" fill="#FEC807" />
        <path
          fill="#FFDA56"
          fillRule="evenodd"
          d="m-4.964 10.964 15.85-15.85 5.09 5.09-15.85 15.85-5.09-5.09Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFDA56"
          fillRule="evenodd"
          d="M4.8 24 24 4.8l4.243 4.243-19.2 19.2L4.8 24Z"
          clipRule="evenodd"
          opacity=".8"
        />
        <path
          fill="#EEB13D"
          fillRule="evenodd"
          d="M12 17.4a5.4 5.4 0 1 0 0-10.8 5.4 5.4 0 0 0 0 10.8Zm0 3a8.4 8.4 0 1 0 0-16.8 8.4 8.4 0 0 0 0 16.8Z"
          clipRule="evenodd"
        />
        <g filter="url(#f)">
          <path
            fill="#EEB13D"
            fillRule="evenodd"
            d="M12 20.4a8.4 8.4 0 1 0 0-16.8 8.4 8.4 0 0 0 0 16.8Z"
            clipRule="evenodd"
          />
        </g>
        <path
          fill="#FEC807"
          fillRule="evenodd"
          d="M11.002 20.4v-1.956c-1.262-.062-2.486-.433-3.202-.885l.565-2.409c.792.473 1.903.906 3.126.906 1.074 0 1.808-.454 1.808-1.277 0-.782-.602-1.276-1.996-1.79-2.015-.742-3.39-1.771-3.39-3.77 0-1.81 1.168-3.232 3.183-3.663V3.6h1.845v1.812c1.262.062 2.11.35 2.73.68l-.545 2.325c-.49-.226-1.356-.7-2.711-.7-1.225 0-1.62.578-1.62 1.153 0 .68.66 1.112 2.26 1.771 2.24.865 3.145 1.997 3.145 3.85 0 1.832-1.187 3.398-3.352 3.809v2.1H11Z"
          clipRule="evenodd"
        />
        <path
          fill="#FFD646"
          fillRule="evenodd"
          d="M11.002 18.444V20.4h1.846v-2.1c2.165-.411 3.352-1.977 3.352-3.808 0-.602-.095-1.127-.3-1.592l-5.492 5.492c.196.024.394.042.594.052Z"
          clipRule="evenodd"
          opacity=".8"
        />
      </g>
      <defs>
        <filter
          id="b"
          width="11.202"
          height="11.202"
          x="21.399"
          y="7.399"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx=".8" dy=".8" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.883333 0 0 0 0 0.578751 0 0 0 0 0.298125 0 0 0 0.6 0" />
          <feBlend in2="shape" result="effect1_innerShadow_6208_29549" />
        </filter>
        <filter
          id="d"
          width="14.004"
          height="14.004"
          x="14.998"
          y="16.998"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx=".8" dy=".8" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.883333 0 0 0 0 0.578751 0 0 0 0 0.298125 0 0 0 0.6 0" />
          <feBlend in2="shape" result="effect1_innerShadow_6208_29549" />
        </filter>
        <filter
          id="f"
          width="16.8"
          height="16.8"
          x="3.6"
          y="3.6"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx=".8" dy=".8" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.883333 0 0 0 0 0.578751 0 0 0 0 0.298125 0 0 0 0.6 0" />
          <feBlend in2="shape" result="effect1_innerShadow_6208_29549" />
        </filter>
      </defs>
    </svg>
  );
};
