import React, { ReactEventHandler, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { NetworkStatus } from '@apollo/client';

import { ScreenLoader, NoDataView } from '@ocx/ui';

import { useConfiguration } from '../../modules/configuration/useConfiguration';
import { messages } from './transaction-history.messages';
import { TransactionsList } from './transactions-list';
import {
  TransactionHistoryItemType,
  TransactionHistorySegment,
} from '../../modules/transaction-history/transaction-history.types';
import { useRudderStack } from '../../lib/rudderStack/useRudderStack';

type CommonControllerProps = {
  networkStatus: NetworkStatus;
  segments: TransactionHistorySegment[];
  onListItemClick: (id: string, type: TransactionHistoryItemType) => void;
  refetch: any;
  fetchMore: any;
};
export const TransactionsListController: React.FC<CommonControllerProps> = ({
  networkStatus,
  segments,
  refetch,
  fetchMore,
  onListItemClick,
}) => {
  const { triggerEvent } = useRudderStack();
  const intl = useIntl();
  const pointsStrategy = useConfiguration('loyaltyProgram.pointsStrategy');

  const loading = networkStatus < NetworkStatus.refetch;
  const handleScrollEnd = useCallback<ReactEventHandler<HTMLDivElement>>(
    (e) => {
      if (
        Math.round(e.currentTarget.scrollTop) ===
        Math.round(e.currentTarget.scrollHeight - e.currentTarget.offsetHeight)
      ) {
        fetchMore();
        triggerEvent('transaction_history_load_more');
      }
    },
    [fetchMore, triggerEvent],
  );

  return (
    <>
      {loading && !segments.length && <ScreenLoader />}
      {!loading && !segments.length && (
        <NoDataView
          title={intl.formatMessage(messages['transaction-history:no-receipts-title'])}
          description={intl.formatMessage(messages['transaction-history:no-receipts-description'])}
        />
      )}
      {Boolean(segments.length) && (
        <TransactionsList
          onScroll={handleScrollEnd}
          loading={loading}
          onRefresh={refetch}
          segments={segments}
          onListItemClick={onListItemClick}
          pointsStrategy={pointsStrategy}
        />
      )}
    </>
  );
};
