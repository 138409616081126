import { Stack } from '@mui/material';

import { formatDateTime } from '@ocx-app/lib/date/date';
import { DateTimeFormat } from '@ocx-app/lib/date/date.types';
import { convertDollarsToCents, formatPrice } from '@ocx-app/lib/formatters/formatPrice';
import { Paper, Typography } from '@ocx/ui';

export type ReceiptSummaryCardProps = {
  totalAmount: number;
  date: string;
};
export const ReceiptSummaryCard = (props: ReceiptSummaryCardProps) => {
  const { totalAmount, date } = props;

  return (
    <Stack component={Paper} textAlign="center">
      <Typography variant="h2">{formatPrice(convertDollarsToCents(totalAmount))}</Typography>

      <Typography variant="body2" color="text.disabled">
        {formatDateTime(new Date(date), DateTimeFormat.DateTimeFull)}
      </Typography>
    </Stack>
  );
};
