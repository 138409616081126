import { Box, BoxProps, Link, LinkProps } from '@mui/material';
import { default as MarkdownToJSXComponent, MarkdownToJSX } from 'markdown-to-jsx';
import { Typography, TypographyProps } from '../typography/typography.component';

type AProps = Omit<LinkProps, 'color'>;
type PProps = Omit<TypographyProps, 'color'>;
type UlProps = BoxProps;
type LiProps = Omit<TypographyProps, 'color'>;

export type MarkdownProps = {
  aProps?: AProps;
  pProps?: PProps;
  ulProps?: UlProps;
  liProps?: LiProps;
  color?: string;
  children: string;
};

const getMarkdownOptions = ({
  aProps,
  pProps,
  ulProps,
  liProps,
  color,
}: Omit<MarkdownProps, 'children'>): MarkdownToJSX.Options => ({
  wrapper: 'article',
  overrides: {
    a: {
      component: Link,
      props: {
        color,
        underline: 'always',
        target: '_blank',
        rel: 'noreferrer',
        ...aProps,
      },
    },
    p: {
      component: Typography,
      props: {
        color,
        variant: 'caption',
        display: 'inline-block',
        lineHeight: 1.6,
        gutterBottom: true,
        ...pProps,
      },
    },
    ul: {
      component: Box,
      props: {
        component: 'ul',
        ...ulProps,
      },
    },
    li: {
      component: Typography,
      props: {
        component: 'li',
        color,
        ...liProps,
      },
    },
  },
});

export const Markdown = ({ children, aProps, pProps, ulProps, liProps, color = 'textSecondary' }: MarkdownProps) => {
  const markdownOptions = getMarkdownOptions({ aProps, pProps, ulProps, liProps, color });
  return <MarkdownToJSXComponent options={markdownOptions}>{children}</MarkdownToJSXComponent>;
};
