import React from 'react';
import { alpha, Paper, Stack } from '@mui/material';
import MarkerIcon from '@ocx/buildtime-retailer-configuration/assets/map-marker.svg';
import { Typography } from '@ocx/ui';

import { SizingMultiplier } from '../../theme/constants';

export interface ISelectedStoreCardProps {
  addressLine1: string;
  addressLine2: string;
}

export const SelectedStoreCard: React.FC<ISelectedStoreCardProps> = (props) => {
  const { addressLine1, addressLine2 } = props;

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.9),
      }}>
      <Stack
        minHeight={140}
        p={SizingMultiplier.md}
        spacing={SizingMultiplier.sm}
        alignItems="center"
        justifyContent="center">
        <img width={40} src={MarkerIcon} alt="marker" />
        <Typography variant="h5">{addressLine1}</Typography>
        <Typography variant="body2" color="text.disabled">
          {addressLine2}
        </Typography>
      </Stack>
    </Paper>
  );
};
