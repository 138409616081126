import { useCallback, useMemo } from 'react';

import { useConfiguration } from '@ocx-app/modules/configuration/useConfiguration';
import { useContactSupportPage } from '@ocx-app/pages/ContactSupport/use-contact-support';
import { useRudderStack } from '@ocx-app/lib/rudderStack/useRudderStack';

import { UseSupportActionReturns } from './support.types';
import { useSupportWidget } from './use-support-widget.hook';

export const useSupportAction = (): UseSupportActionReturns => {
  const { triggerEvent } = useRudderStack();
  const triggerAnalyticEvent = useCallback(() => triggerEvent('support_and_feedback_clicked'), [triggerEvent]);
  const withEvent = useCallback(
    (additionalFunction: () => void) => {
      return () => {
        triggerAnalyticEvent();
        additionalFunction();
      };
    },
    [triggerAnalyticEvent],
  );

  const {
    config: { supportPhoneNumber, supportTeamEmail },
  } = useConfiguration();
  const { open: openContactSupportPage } = useContactSupportPage();
  const { isEnabled: isSupportWidgetEnabled, open: openSupportWidget } = useSupportWidget();

  return useMemo(() => {
    if ([supportTeamEmail, supportPhoneNumber, isSupportWidgetEnabled].filter(Boolean).length > 1) {
      return {
        type: 'on-click',
        onClick: withEvent(openContactSupportPage),
      };
    }

    if (supportTeamEmail) {
      return { type: 'external-url', href: `mailto:${supportTeamEmail}`, onClick: triggerAnalyticEvent };
    }

    if (supportPhoneNumber) {
      return { type: 'external-url', href: `tel:${supportPhoneNumber}`, onClick: triggerAnalyticEvent };
    }

    if (isSupportWidgetEnabled) {
      return {
        type: 'on-click',
        onClick: withEvent(openSupportWidget),
      };
    }

    return {
      type: 'on-click',
      onClick: withEvent(openContactSupportPage),
    };
  }, [
    supportTeamEmail,
    supportPhoneNumber,
    isSupportWidgetEnabled,
    withEvent,
    openContactSupportPage,
    openSupportWidget,
    triggerAnalyticEvent,
  ]);
};
