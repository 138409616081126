import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Grid, Container } from '@mui/material';
import { Form } from 'react-final-form';
import { Button, Typography } from '@ocx/ui';

import { PaymentAccountType } from '@ocx/graphql';
import { CreditCardSchema, ZIP_CODE_LENGTH } from './vault-card.schema';
import { CardNumberField } from '../../components/Fields/CardNumberField/CardNumberField';
import { CardExpirationDateField } from '../../components/Fields/CardExpirationDateField/CardExpirationDateField';
import { CardCVVField } from '../../components/CardCVVField';
import { TextField } from '../../components/TextField';
import { CreditCardFormFields } from './vault-card.types';
import { getMaxLengthParser, makeValidateSync } from '../../lib/form/utils';
import { ADD_CARD_TEST_IDS } from './vault-card.test-ids';
import { SizingMultiplier } from '../../theme/constants';
import { buttonTextMessages } from '../../modules/localization/button-text.messages';
import { useShowCardNumberError } from './vault-card.utils';

export type AddCardProps = {
  cardholderName: string;
  onSubmit(values: CreditCardFormFields): Promise<void>;
};

const zipCodeParser = getMaxLengthParser(ZIP_CODE_LENGTH);
const validate = makeValidateSync(CreditCardSchema);

export const AddCreditCard: React.FC<AddCardProps> = (props) => {
  const { formatMessage } = useIntl();
  const showCardNumberError = useShowCardNumberError();
  const { cardholderName, onSubmit } = props;

  const initialValues: CreditCardFormFields = useMemo(
    () => ({
      accountType: PaymentAccountType.Credit,
      cardholderName,
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      zipCode: '',
    }),
    [cardholderName],
  );

  return (
    <Container sx={{ pt: SizingMultiplier.lg }}>
      <Typography variant="h4" align="center" mb={SizingMultiplier.md}>
        <FormattedMessage id="add-card:credit:title" defaultMessage="Add Card" description="Title for Add Card page" />
      </Typography>
      <Form<CreditCardFormFields>
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, hasValidationErrors, dirty }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CardNumberField
                  name="cardNumber"
                  label={formatMessage({
                    id: 'add-card:credit:card-number-field-label',
                    defaultMessage: 'Card Number',
                    description: 'Card Number field label',
                  })}
                  showError={showCardNumberError}
                />
              </Grid>
              <Grid item xs={6}>
                <CardExpirationDateField
                  name="expirationDate"
                  label={formatMessage({
                    id: 'add-card:credit:expiration-date-label',
                    defaultMessage: 'Expiration Date',
                    description: 'Expiration Date field label',
                  })}
                />
              </Grid>
              <Grid item xs={6}>
                <CardCVVField
                  name="cvv"
                  label={formatMessage({
                    id: 'add-card:credit:cvv-label',
                    defaultMessage: 'CVV',
                    description: 'CVV field label',
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="cardholderName"
                  label={formatMessage({
                    id: 'add-card:credit:cardholder-name-label',
                    defaultMessage: 'Cardholder Name',
                    description: 'Cardholder Name field label',
                  })}
                  helperText={formatMessage({
                    id: 'add-card:credit:cardholder-name-helper-text',
                    defaultMessage: 'Enter the name as it appears on card',
                    description: 'Cardholder Name helper text',
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="zipCode"
                  type="number"
                  inputProps={{
                    inputMode: 'numeric',
                    autoComplete: 'on',
                    autocompletetype: 'cc-zip',
                    pattern: '\\d*',
                    'data-testid': ADD_CARD_TEST_IDS.ZIP_CODE_FIELD,
                  }}
                  fieldProps={{
                    parse: zipCodeParser,
                  }}
                  label={formatMessage({
                    id: 'add-card:credit:billing-zip-label',
                    defaultMessage: 'Billing ZIP',
                    description: 'Billing ZIP field label',
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <Button disabled={hasValidationErrors || !dirty} type="submit" fullWidth>
                  <FormattedMessage {...buttonTextMessages['button-text:submit']} />
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      />
    </Container>
  );
};
