import { Stack } from '@mui/material';
import { useIntl } from 'react-intl';

import { SizingMultiplier } from '@ocx/ui';
import { formatPrice, convertDollarsToCents } from '@ocx-app/lib/formatters/formatPrice';

import { LineItemPromotion } from '../../../receipts.types';
import { ReceiptPromotionChip } from './receipt-promotion-chip.components';
import { receiptLabels } from '../receipt-labels.messages';
import { TwoColsTypography } from '../../two-cols-typography';

type ReceiptLineItemProps = {
  title: string | null;
  value: number | null;
  quantity: number | null;
  promotions: LineItemPromotion[] | null;
  externalPromotionsTotalDiscount: number | null;
  unit: string;
};

export const ReceiptLineItem: React.FC<ReceiptLineItemProps> = (props) => {
  const { title, value, quantity, promotions, externalPromotionsTotalDiscount, unit } = props;
  const intl = useIntl();

  const quantityValue = unit ? `${quantity || 0} ${unit}` : `${quantity}`;

  return (
    <Stack spacing={SizingMultiplier.sm}>
      <TwoColsTypography
        variant="subtitle1"
        title={title}
        value={typeof value === 'number' ? formatPrice(convertDollarsToCents(value)) : null}
      />

      {typeof quantity === 'number' && (
        <TwoColsTypography
          variant="body2"
          color="text.disabled"
          title={intl.formatMessage(receiptLabels['receipt-labels:quantity'])}
          value={quantityValue}
        />
      )}

      {promotions?.map(({ offerId, reason, amount }, i) => (
        <TwoColsTypography
          key={`${offerId}${i}`}
          component="span"
          color="text.disabled"
          title={
            <ReceiptPromotionChip
              label={
                reason ||
                (typeof amount === 'number'
                  ? `${formatPrice(convertDollarsToCents(amount))} Off`
                  : intl.formatMessage(receiptLabels['receipt-labels:line-item:promotion-offer:default-label']))
              }
            />
          }
          value={typeof amount === 'number' ? formatPrice(convertDollarsToCents(-amount)) : null}
        />
      ))}

      {typeof externalPromotionsTotalDiscount === 'number' && (
        <TwoColsTypography
          variant="body2"
          color="text.disabled"
          title={intl.formatMessage(receiptLabels['receipt-labels:line-item:total-external-discount'])}
          value={formatPrice(convertDollarsToCents(-externalPromotionsTotalDiscount))}
        />
      )}
    </Stack>
  );
};
