import { styled } from '@mui/material/styles';
import confetti, { Options } from 'canvas-confetti';
import React, { useRef, useEffect, CSSProperties } from 'react';

type ConfettiAnimationProps = {
  onComplete?: () => void;
  onReady?: () => void;
  style?: CSSProperties;
  shouldPlay?: boolean;
  options?: Options;
};

const DEFAULT_PARTICLE_COUNT = 80;
const DEFAULT_SPREAD = 50;
const DEFAULT_ORIGIN_Y = 1.5;

const ConfettiAnimationContainer = styled('canvas')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const ConfettiAnimation: React.FC<ConfettiAnimationProps> = ({
  onComplete,
  onReady,
  style,
  shouldPlay = false,
  options,
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const hasPlayedRef = useRef<boolean>(false); // Track whether the animation has already played

  useEffect(() => {
    // Trigger onReady once the component is mounted and canvas is initialized
    if (onReady && canvasRef.current) {
      onReady();
    }
  }, [onReady]);

  useEffect(() => {
    let animation: ReturnType<typeof confetti.create>;

    if (shouldPlay && !hasPlayedRef.current && canvasRef.current) {
      animation = confetti.create(canvasRef.current, {
        resize: true,
        useWorker: true,
      });

      animation({
        particleCount: DEFAULT_PARTICLE_COUNT,
        spread: DEFAULT_SPREAD,
        origin: { y: DEFAULT_ORIGIN_Y },
        ...options,
      })
        ?.then(() => {
          // Call onComplete after the confetti animation finishes
          onComplete?.();
          // Mark the animation as played to prevent repeated animation
          hasPlayedRef.current = true;
        })
        .catch((error) => {
          console.error('Error occurred during confetti animation:', error);
        });
    }

    return () => {
      animation?.reset();
    };
  }, [shouldPlay, onComplete, options]);

  return <ConfettiAnimationContainer ref={canvasRef} style={style} />;
};
