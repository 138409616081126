import React from 'react';
import { Box, Stack } from '@mui/material';

import { PointsStrategy } from '@ocx/graphql';
import { List, ListHeader, PageContent, Paper, ScreenLoaderBackdrop } from '@ocx/ui';

import { SizingMultiplier } from '../../theme/constants';
import { TransactionHistoryItem } from './components/TransactionHistoryItem';

import {
  TransactionHistoryItemType,
  TransactionHistorySegment,
} from '../../modules/transaction-history/transaction-history.types';
import { PullToRefresh } from '../../components/PullToRefresh/PullToRefresh';

export interface ITransactionsListProps {
  segments: TransactionHistorySegment[];
  onListItemClick(id: string, type: TransactionHistoryItemType): void;
  pointsStrategy: PointsStrategy;
  loading: boolean;
  onRefresh: any;
  onScroll: any;
}

export const TransactionsList: React.FC<ITransactionsListProps> = (props) => {
  const { loading, segments, onRefresh, onScroll, pointsStrategy, onListItemClick } = props;
  const contentRef = React.useRef<HTMLDivElement>(null);

  return (
    <PageContent onScroll={onScroll} ref={contentRef}>
      <PullToRefresh onRefresh={onRefresh} scrollRef={contentRef}>
        <Box px={SizingMultiplier.sm}>
          {segments.map(([date, transactions]) => (
            <Stack key={date} my={SizingMultiplier.sm} component={Paper} padding={0}>
              <List>
                <ListHeader>{date}</ListHeader>
                {transactions.map((transaction) => (
                  <TransactionHistoryItem
                    key={transaction.id}
                    date={transaction.dateTitle}
                    title={transaction.title}
                    points={transaction.points}
                    transactionType={transaction.type}
                    totalAmount={transaction.totalAmount}
                    totalAmountWithoutDiscounts={transaction.totalAmountWithoutDiscounts}
                    onClick={() => onListItemClick(transaction.id, transaction.type)}
                    pointsStrategy={pointsStrategy}
                  />
                ))}
              </List>
            </Stack>
          ))}
          <ScreenLoaderBackdrop open={loading} />
        </Box>
      </PullToRefresh>
    </PageContent>
  );
};
