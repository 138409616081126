import React from 'react';
import { useParams } from 'react-router-dom';

import { Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Loading, SizingMultiplier, Typography } from '@ocx/ui';
import { PointsChangeReceipt, PointsChangeReceiptLayout } from '@ocx/ui-receipts';
import { usePointsChangeReceiptController } from './hooks/usePointsChangeReceiptController';
import { ReplaceToHome } from '../Home/ReplaceToHome';
import { messages } from './messages';

export const PointsChangeReceiptController: React.FC = () => {
  const { pointsChangeId = '' } = useParams<{ pointsChangeId: string }>();
  const { loading, receipt } = usePointsChangeReceiptController(pointsChangeId);

  if (!receipt && loading) {
    return (
      <PointsChangeReceiptLayout>
        <Stack flex={1} alignItems="center" justifyContent="center" spacing={SizingMultiplier.lg}>
          <Loading />
          <Typography variant="h6" color="primary.main">
            <FormattedMessage {...messages['pointsChangeReceipt:loading-label']} />
          </Typography>
        </Stack>
      </PointsChangeReceiptLayout>
    );
  }

  if (!receipt) {
    return <ReplaceToHome />;
  }

  return (
    <PointsChangeReceiptLayout>
      <PointsChangeReceipt quantity={receipt.quantity} date={receipt.date} reason={receipt.reason} />
    </PointsChangeReceiptLayout>
  );
};

export default PointsChangeReceiptController;
