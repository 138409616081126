import React from 'react';

import { ReactComponent as VisaIcon } from '@ocx-app/components/payment-instrument-icon/assets/visa-icon.svg';
import { ReactComponent as MastercardIcon } from '@ocx-app/components/payment-instrument-icon/assets/mastercard-icon.svg';
import { ReactComponent as DiscoverIcon } from '@ocx-app/components/payment-instrument-icon/assets/discover-icon.svg';
import { ReactComponent as AmexIcon } from '@ocx-app/components/payment-instrument-icon/assets/amex-icon.svg';
import { ReactComponent as ApplePayIcon } from '@ocx-app/components/payment-instrument-icon/assets/apple-pay-icon.svg';
import { ReactComponent as GooglePayIcon } from '@ocx-app/components/payment-instrument-icon/assets/google-pay-icon.svg';
import { ReactComponent as GeneralCardIcon } from '@ocx-app/components/payment-instrument-icon/assets/general-card-icon.svg';
import { ReactComponent as SplitPaymentIcon } from '@ocx-app/components/payment-instrument-icon/assets/multi-payment-icon.svg';
import { ReactComponent as UnknownPaymentInstrumentIcon } from '@ocx-app/components/payment-instrument-icon/assets/unknown-payment-instrument-icon.svg';
import { ReactComponent as CashIcon } from '@ocx-app/components/payment-instrument-icon/assets/cash-icon.svg';
import { PaymentInstrumentType } from '@ocx/graphql';

import { ReactComponent as PrepaidIcon } from '@ocx/buildtime-retailer-configuration/assets/prepaid-card-icon.svg';

export type TransactionPaymentInstrumentType = PaymentInstrumentType | 'CASH' | 'SPLIT_PAYMENT';

export const paymentTypeToPaymentIconMap: {
  [key in TransactionPaymentInstrumentType]?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
} = {
  [PaymentInstrumentType.Visa]: VisaIcon,
  [PaymentInstrumentType.ApplePayVisa]: VisaIcon,
  [PaymentInstrumentType.GooglePayVisa]: VisaIcon,
  [PaymentInstrumentType.Mastercard]: MastercardIcon,
  [PaymentInstrumentType.ApplePayMastercard]: MastercardIcon,
  [PaymentInstrumentType.GooglePayMastercard]: MastercardIcon,
  [PaymentInstrumentType.Discover]: DiscoverIcon,
  [PaymentInstrumentType.ApplePayDiscover]: DiscoverIcon,
  [PaymentInstrumentType.GooglePayDiscover]: DiscoverIcon,
  [PaymentInstrumentType.AmericanExpress]: AmexIcon,
  [PaymentInstrumentType.ApplePayAmericanExpress]: AmexIcon,
  [PaymentInstrumentType.GooglePayAmericanExpress]: AmexIcon,
  [PaymentInstrumentType.Prepaid]: PrepaidIcon,
  [PaymentInstrumentType.ApplePay]: ApplePayIcon,
  [PaymentInstrumentType.GooglePay]: GooglePayIcon,
  [PaymentInstrumentType.Credit]: GeneralCardIcon,
  [PaymentInstrumentType.Debit]: GeneralCardIcon,
  [PaymentInstrumentType.Unknown]: UnknownPaymentInstrumentIcon,
  CASH: CashIcon,
  SPLIT_PAYMENT: SplitPaymentIcon,
};

type IconStyles = {
  width: number;
  height: number;
} | null;

const largeIconStyles = {
  width: 72,
  height: 51,
};

const mediumIconStyles = {
  width: 40,
  height: 28,
};

type PaymentInsrumentIconSize = 'small' | 'medium' | 'large';

export type PaymentInstrumentIconProps = {
  type: TransactionPaymentInstrumentType;
  /**
   * @default 'medium'
   */
  size?: PaymentInsrumentIconSize;
};

const stylesBySizeMapping: Record<PaymentInsrumentIconSize, IconStyles> = {
  small: null,
  medium: mediumIconStyles,
  large: largeIconStyles,
};

export const PaymentInstrumentIcon = (props: PaymentInstrumentIconProps) => {
  const { type, size = 'small' } = props;
  const Icon = paymentTypeToPaymentIconMap[type];

  if (!Icon) {
    return null;
  }

  if (type === PaymentInstrumentType.ApplePay || type === PaymentInstrumentType.GooglePay) {
    return <Icon />;
  }

  return <Icon {...(stylesBySizeMapping[size] ?? {})} />;
};
