import SavingsIcon from '@mui/icons-material/Savings';
import { Box, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { convertDollarsToCents, formatPrice } from '@ocx-app/lib/formatters/formatPrice';
import { SizingMultiplier, Typography } from '@ocx/ui';

import { TransactionIconBox } from '../../../transaction-icon-box';
import { TwoColsTypography } from '../../../two-cols-typography';
import { messages } from '../../receipt-loyalty-card.messages';

type DiscountItemProps = {
  savedAmount: number;
  appliedOffersNumber: number;
};

export const DiscountItem = (props: DiscountItemProps) => {
  const { savedAmount, appliedOffersNumber } = props;
  const isAppliedOffersNumberEligible = appliedOffersNumber > 0;

  return (
    <Stack direction="row" spacing={SizingMultiplier.sm} alignItems="center">
      <TransactionIconBox>
        <SavingsIcon />
      </TransactionIconBox>
      <Box flex={1}>
        <TwoColsTypography
          title={messages['receipt:loyalty-card:saved'].defaultMessage}
          value={formatPrice(convertDollarsToCents(savedAmount))}
          variant="subtitle1"
        />
        {isAppliedOffersNumberEligible && (
          <Typography variant="body2" color={'text.disabled'} mt={SizingMultiplier.xs}>
            <FormattedMessage {...messages['receipt:loyalty-card:offers-applied']} values={{ appliedOffersNumber }} />
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
