import { Box, Button, ButtonProps, CircularProgress, Link, Stack, styled, Typography, useTheme } from '@mui/material'; // do not import from the @ocx/ui, this component copy/pasted as it is to the CC
import { alpha, darken } from '@mui/system/colorManipulator';
import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  SpinWheel,
  SpinWheelSector,
  RisingSun,
  SpinWheelGameSectorIcon,
  SpinWheelGameSectorRenderParams,
} from './index';
import { messages } from './messages';
import { SpinWheelGameSector, SpinWheelGameSectorType } from './SpinWheelGameSector.component';

const SIZING_MULTIPLIER = {
  xs: 0.5,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
};

const RISING_SUN_STYLES: CSSProperties = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

const GAME_HEADER_TOP_OFFSET = 0.12; // 12% of the screen height
const GAME_WHEEL_SIZE = 0.85; // size is 85% of the screen height or width
const GAME_WHEEL_MAX_SIZE = '550px'; // max possible size
const DISCLAIMER_BACKGROUND_COLOR = 'rgba(255, 255, 255, 0.05)';

const FullScreenImage = styled('img')(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  pointerEvents: 'none',
  userSelect: 'none',
}));

const SpinWheelGameButton = styled(Button, {
  shouldForwardProp: (prop) => !['backgroundColor', 'textColor'].includes(prop as string),
})<
  ButtonProps & {
    backgroundColor?: string;
    textColor?: string;
  }
>(({ theme, textColor, backgroundColor }) => ({
  color: textColor || theme.palette.SpinWheel.risingSunTextColor,
  backgroundColor: backgroundColor || theme.palette.SpinWheel.risingSunBackColor,
  '&:hover, &:active, &:focus': {
    color: backgroundColor || theme.palette.SpinWheel.risingSunBackColor,
    backgroundColor: textColor || theme.palette.SpinWheel.risingSunTextColor,
    '& > p': {
      color: textColor || theme.palette.SpinWheel.risingSunTextColor,
      backgroundColor: backgroundColor || theme.palette.SpinWheel.risingSunBackColor,
    },
  },
  '&:disabled': {
    color: alpha(textColor || theme.palette.SpinWheel.risingSunTextColor, 0.5),
    backgroundColor: backgroundColor || theme.palette.SpinWheel.risingSunBackColor,
  },
}));

export type SpinWheelGameSectorProps = {
  type: SpinWheelGameSectorType;
  points?: number;
  color?: string;
  icon?: SpinWheelGameSectorIcon;
};

export type SpinWheelGameProps = {
  sectors: SpinWheelGameSectorProps[];
  sectorContentRender?: (params: SpinWheelGameSectorRenderParams) => ReactNode;
  disclaimer?: string;
  disclaimerUrl?: string;
  disclaimerTextColor?: string;
  disclaimerBackgroundColor?: string;
  terms?: string;
  termsUrl?: string;
  numberOfGamePlays?: number | null;
  purchasePrice?: ReactNode | null;
  isPlayButtonVisible: boolean;
  isPurchaseButtonVisible: boolean;
  isPlayButtonDisabled: boolean;
  isPurchaseButtonDisabled: boolean;
  isPurchaseButtonLoading: boolean;
  onPlayClick: () => void;
  onPurchaseClick?: () => void;
  onSpinningEnd?: (sectorIndex: number) => void;
  isSpinning?: boolean;
  winningSectorIndex?: number;
  headerText?: string;
  headerTextColor?: string;
  borderColor?: string;
  backgroundPrimaryColor?: string;
  backgroundSecondaryColor?: string;
  backgroundImage?: string;
  foregroundImage?: string;
  buttonColor?: string;
  buttonTextColor?: string;
  sizingMultiplier?: typeof SIZING_MULTIPLIER;
  cssHeight?: string;
  cssWidth?: string;
};

export const SpinWheelGame = ({
  sectors,
  sectorContentRender,
  disclaimer,
  disclaimerUrl,
  disclaimerTextColor,
  disclaimerBackgroundColor,
  terms,
  termsUrl,
  numberOfGamePlays,
  purchasePrice,
  isPlayButtonVisible,
  isPurchaseButtonVisible,
  isPlayButtonDisabled,
  isPurchaseButtonDisabled,
  isPurchaseButtonLoading,
  onPlayClick,
  onPurchaseClick,
  onSpinningEnd,
  isSpinning,
  winningSectorIndex,
  headerText,
  headerTextColor,
  borderColor,
  backgroundPrimaryColor,
  backgroundSecondaryColor,
  backgroundImage,
  foregroundImage,
  buttonColor,
  buttonTextColor,
  sizingMultiplier = SIZING_MULTIPLIER,
  cssHeight,
  cssWidth,
}: SpinWheelGameProps) => {
  const intl = useIntl();
  const { palette } = useTheme();
  const buttonBackColor = buttonColor || palette.SpinWheel?.buttonColor?.color || palette.SpinWheel.risingSunTextColor;
  const buttonLabelColor =
    buttonTextColor ||
    palette.SpinWheel?.buttonColor?.contrastText ||
    darken(palette.SpinWheel.risingSunBackColor, 0.2);
  const infoColor = disclaimerTextColor || palette.SpinWheel.risingSunTextColor;

  const items = useMemo<SpinWheelSector[]>(() => {
    return sectors.map(({ color }, index) => ({
      id: String(index),
      color,
    }));
  }, [sectors]);

  return (
    <Box position="relative" width={cssWidth || '100%'}>
      <RisingSun style={RISING_SUN_STYLES} backColor={backgroundPrimaryColor} raysColor={backgroundSecondaryColor} />
      {backgroundImage && <FullScreenImage src={backgroundImage} />}
      <Stack justifyContent="space-between" textAlign="center" minHeight={cssHeight || '100dvh'} position="relative">
        <Typography
          variant="h3"
          textTransform="none"
          textAlign="center"
          lineHeight="1.2"
          mt={cssHeight ? `calc(${cssHeight} * ${GAME_HEADER_TOP_OFFSET})` : `${GAME_HEADER_TOP_OFFSET * 100}vh`}
          px={sizingMultiplier.md}
          sx={{ transform: 'translateY(-50%)' }}
          color={headerTextColor || palette.SpinWheel.risingSunTextColor}>
          {headerText || intl.formatMessage(messages['spinWheelGame:title'])}
        </Typography>

        <Box sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)' }}>
          <SpinWheel
            onSpinningEnd={onSpinningEnd}
            size={`min(${cssWidth ? `calc(${cssWidth} * ${GAME_WHEEL_SIZE})` : `${GAME_WHEEL_SIZE * 100}vw`}, ${
              cssHeight ? `calc(${cssHeight} * ${GAME_WHEEL_SIZE})` : `${GAME_WHEEL_SIZE * 100}vh`
            }, ${GAME_WHEEL_MAX_SIZE})`}
            rimWidth={7}
            rimColor={borderColor}
            sectors={items}
            renderSectorContent={(_, index) => (
              <SpinWheelGameSector index={index} onRender={sectorContentRender} {...sectors[index]} />
            )}
            isInfinitySpinning={isSpinning}
            sectorIndex={winningSectorIndex}
          />
        </Box>

        <Box padding={sizingMultiplier.lg} marginBottom="var(--ion-safe-area-bottom)">
          {disclaimer || disclaimerUrl || terms || termsUrl ? (
            <Typography
              variant="caption"
              display="inline-block"
              mt={sizingMultiplier.sm}
              py={sizingMultiplier.xs}
              px={sizingMultiplier.md}
              mb={sizingMultiplier.lg}
              borderRadius={sizingMultiplier.md}
              color={infoColor}
              sx={{
                background: disclaimerBackgroundColor
                  ? alpha(disclaimerBackgroundColor, 0.5)
                  : DISCLAIMER_BACKGROUND_COLOR,
              }}>
              <div>
                {disclaimer}
                {disclaimerUrl && (
                  <Link
                    href={disclaimerUrl}
                    target="_blank"
                    ml={sizingMultiplier.xs}
                    color={infoColor}
                    variant="caption"
                    gutterBottom
                    underline="always">
                    <FormattedMessage
                      id="offerDetailsPopup:disclaimerText"
                      defaultMessage="Disclaimer"
                      description="Disclaimer link title on the Offer Details popup"
                    />
                  </Link>
                )}
              </div>
              {terms}
              {termsUrl && (
                <Link
                  href={termsUrl}
                  target="_blank"
                  ml={sizingMultiplier.xs}
                  color={infoColor}
                  variant="caption"
                  gutterBottom
                  underline="always">
                  <FormattedMessage
                    id="offerDetailsPopup:termsAndConditionsText"
                    defaultMessage="Terms & Conditions"
                    description="Terms & Conditions link title on the Offer Details popup"
                  />
                </Link>
              )}
            </Typography>
          ) : null}
          {isPurchaseButtonVisible && (
            <SpinWheelGameButton
              disabled={isPurchaseButtonDisabled}
              onClick={onPurchaseClick}
              variant="contained"
              size="large"
              backgroundColor={buttonBackColor}
              textColor={buttonLabelColor}
              disableElevation
              fullWidth>
              {isPurchaseButtonLoading ? (
                <CircularProgress
                  size={22}
                  sx={{ position: 'absolute', left: (theme) => theme.spacing(sizingMultiplier.sm) }}
                />
              ) : null}
              {intl.formatMessage(messages['spinWheelGame:purchase-gameplay-button:text'])}
              {purchasePrice && (
                <Box
                  display="inline-flex"
                  position="absolute"
                  alignItems="center"
                  px={sizingMultiplier.md}
                  py={sizingMultiplier.xs}
                  sx={{
                    right: 0,
                    opacity: isPurchaseButtonDisabled ? 0.5 : 1,
                    pointerEvents: 'none',
                  }}>
                  {purchasePrice}
                </Box>
              )}
            </SpinWheelGameButton>
          )}
          {isPlayButtonVisible && (
            <SpinWheelGameButton
              disabled={isPlayButtonDisabled}
              onClick={onPlayClick}
              variant="contained"
              size="large"
              backgroundColor={buttonBackColor}
              textColor={buttonLabelColor}
              disableElevation
              fullWidth>
              {intl.formatMessage(messages['spinWheelGame:play-button:text'])}
              {numberOfGamePlays && numberOfGamePlays > 0 ? (
                <Typography
                  display="inline-flex"
                  position="absolute"
                  alignItems="center"
                  px={sizingMultiplier.md}
                  py={sizingMultiplier.xs}
                  borderRadius={sizingMultiplier.lg}
                  sx={{
                    color: buttonBackColor,
                    backgroundColor: buttonLabelColor,
                    right: (theme) => theme.spacing(sizingMultiplier.sm),
                    opacity: isPlayButtonDisabled ? 0.5 : 1,
                    pointerEvents: 'none',
                  }}>
                  &times;{numberOfGamePlays}
                </Typography>
              ) : null}
            </SpinWheelGameButton>
          )}
        </Box>
      </Stack>
      {foregroundImage && <FullScreenImage src={foregroundImage} />}
    </Box>
  );
};
