import { IconButton as MUIIconButton } from '@mui/material';
import React from 'react';
import { ButtonProps } from '../button/button.component';

export type IconButtonProps = Omit<ButtonProps, 'variant' | 'endIcon' | 'color'> & {
  /**
   * The color of the button.
   * @defaultValue 'default'
   */
  color?: 'default' | 'inherit';
};

export const IconButton: React.FC<IconButtonProps> = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const { size = 'medium', color = 'default', ...rest } = props;
    return <MUIIconButton size={size} color={color} {...rest} ref={ref} />;
  },
);
