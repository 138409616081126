import { defineMessages } from 'react-intl';

export const validationMessages = defineMessages({
  'validation:form:email:required': {
    id: 'validation:form:email:required',
    defaultMessage: 'Enter a valid email',
    description: 'Profile page email validation field message',
  },
  'validation:form:phone:general': {
    id: 'validation:form:phone:general',
    defaultMessage: 'Invalid phone number',
    description: 'Profile page phone number validation field message',
  },
  'validation:form:first-name:required': {
    id: 'validation:form:first-name:required',
    defaultMessage: 'Enter a valid first name',
    description: 'Profile page first name validation field message',
  },
  'validation:form:last-name:required': {
    id: 'validation:form:last-name:required',
    defaultMessage: 'Enter a valid last name',
    description: 'Profile page last name validation field message',
  },
  'validation:form:birthday:required': {
    id: 'simpleDatePicker:errorMessage',
    defaultMessage: 'Enter a valid birthday',
    description: 'Error message for simple date picker',
  },
  'validation:form:birthdate:min-age': {
    id: 'validation:form:birthdate:min-age',
    defaultMessage: 'Sorry! You must be over {minAge} to use the app',
    description: 'Profile page birthdate validation field message',
  },
  'validation:form:card-number:general': {
    id: 'validation:form:card:number:general',
    defaultMessage: 'Enter a valid card number',
    description: 'Card number field validation message',
  },
  'validation:form:card-expiration:general': {
    id: 'validation:form:card:expiration:general',
    defaultMessage: 'Enter a valid expiration date',
    description: 'Expiration date field validation message',
  },
  'validation:form:cardholder-name:required': {
    id: 'validation:form:cardholder-name:required',
    defaultMessage: 'Enter the name as it appears on card',
    description: 'Cardholder Name field validation message',
  },
  'validation:form:card-cvv:general': {
    id: 'validation:form:card-cvv:general',
    defaultMessage: 'Enter a valid CVV',
    description: 'CVV field validation message',
  },
  'validation:form:zip-code:general': {
    id: 'validation:form:zip-code:general',
    defaultMessage: 'Enter a valid ZIP Code',
    description: 'Zip field validation message',
  },
  'validation:form:street-address:required': {
    id: 'validation:form:street-address:required',
    defaultMessage: 'Enter a valid street address',
    description: 'Street Address field validation message',
  },
  'validation:form:city:required': {
    id: 'validation:form:city:required',
    defaultMessage: 'Enter a valid city',
    description: 'City field validation message',
  },
  'validation:form:state:required': {
    id: 'validation:form:state:required',
    defaultMessage: 'Enter a valid state',
    description: 'State field validation message',
  },
  'validation:form:prepaid-card-pin-code:general': {
    id: 'validation:form:prepaid-card-pin-code:general',
    defaultMessage: 'Enter a valid PIN number',
    description: 'PIN field validation message for Prepaid card',
  },
});
