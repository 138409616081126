import { Typography } from '@mui/material';

import { SizingMultiplier } from '@ocx/ui';

export type ReceiptPromotionChipProps = {
  label: string;
};
export const ReceiptPromotionChip = (props: ReceiptPromotionChipProps) => {
  return (
    <Typography
      sx={(theme) => ({
        borderRadius: theme.spacing(SizingMultiplier.sm),
        bgcolor: theme.palette.Receipts.offerLinkBackground,
        display: 'inline-block',
        color: theme.palette.Receipts.offerLinkColor,
        padding: theme.spacing(SizingMultiplier.xs),
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '70%',
      })}
      variant="subtitle2">
      {props.label}
    </Typography>
  );
};
