import React, { useMemo } from 'react';

import { Typography, TypographyProps } from '@ocx/ui';

import { useSupportAction } from './use-support-action.hook';

export type SupportLinkProps = {
  children?: React.ReactNode;
  typographyProps?: TypographyProps;
};

export const SupportLink = (props: SupportLinkProps) => {
  const supportAction = useSupportAction();
  const { children, typographyProps } = props;

  const styleProps = useMemo<TypographyProps>(
    () => ({
      variant: 'caption',
      color: 'primary',
      ...typographyProps,
      sx: { cursor: 'pointer', textDecoration: 'none', ...typographyProps?.sx },
    }),
    [typographyProps],
  );

  if (supportAction.type === 'external-url') {
    return (
      <Typography
        onClick={supportAction.onClick}
        component="a"
        target="_blank"
        rel="noreferrer"
        href={supportAction.href}
        {...styleProps}>
        {children}
      </Typography>
    );
  }
  return (
    <Typography onClick={supportAction.onClick} {...styleProps}>
      {children}
    </Typography>
  );
};
